import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import authModule from './modules/auth/index'
import plantModule from './modules/plant/index'

Vue.use(Vuex)

const state = {
  visibleSidebar: true,
  selectedCustomer: null,
  generateReportNotification: null,
  generateSqliteDbFilesNotification: null,
  customers: [],
  ediCustomers: [],
  endUsers: [],
  groupFileSettings: {
    data: [],
    loading: false,
    error: null
  },
  movementFields: {
    data: [],
    loading: false,
    error: null
  },
  movementTables: {
    data: {},
    loading: false,
    error: null
  },
  customerExportRecap: {
    data: [],
    loading: false,
    error: null
  },
  exportDetails: {
    data: [],
    loading: false,
    error: null
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: authModule,
    plantModule
  }
})
