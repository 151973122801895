<template>
  <div class="modal-card" :style="{minHeight: 60 + 'vh', minWidth: 60 + 'vw'}">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Email reminder history detail
      </p>
      <button
        type="button"
        class="delete"
        @click="$emit('on-close')"
      />
    </header>
    <section class="modal-card-body">
      <b-loading v-model="isLoading" :is-full-page="false" />
      <b-table :data="histories" hoverable>
        <b-table-column
          v-slot="props"
          field="distributorCode"
          label="Distributor"
        >
          <template>
            {{ props.row.distributorCode }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="sendAt"
          label="Sent on"
        >
          <template>
            {{ props.row.sendAt }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="sendTo"
          label="Sent to"
        >
          <template>
            {{ props.row.sendTo }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label="Declaration"
        >
          <template>
            <span
              :class="
                [
                  'tag',
                  {'is-danger': !props.row.declarationId},
                  {'is-primary': props.row.declarationId}
                ]"
            >
              {{ props.row.declarationId ? 'Yes' : 'No' }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="sendType"
          label="Type"
        >
          <template>
            {{ props.row.sendType }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label="File"
        >
          <template v-if="props.row.declarationId">
            <b-button size="is-small" type="is-link" @click="downloadFile(props.row.declarationId)">
              Download
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import Distribution from '../../services/v2/distribution'
import { saveAs } from 'file-saver'

export default {
  props: {
    distributor: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      isLoading: false,
      histories: []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.isLoading = true
      const result = await Distribution.getHistory(this.distributor.id)
      this.histories = result
      this.isLoading = false
    },

    async downloadFile (declarationId) {
      try {
        const history = await Distribution.getDeclarations({ declarationId: declarationId })
        const result = await Distribution.getDeclarationFile(history[0].distributorCode, history[0].fileName)
        saveAs(result.data, `Declaration_${history[0].distributorCode}_${history[0].fileName}`)
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    }
  }

}
</script>

<style scoped>
</style>
