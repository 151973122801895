<template>
  <section class="section">
    <div>
      <Header v-bind="$route.meta" />
      <div class="columns is-multiline">
        <div class="column">
          <div class="is-flex is-align-content-center ">
            <b-field>
              <b-select v-model="selectedDuration" placeholder="Period">
                <option
                  v-for="option in duration"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.text }}
                </option>
              </b-select>
            </b-field>

            <b-button class="ml-2" icon-left="calendar-alt" @click="generateBarcodes">
              {{ $t('pages.loads.planning') }}
            </b-button>
          </div>
        </div>
      </div>
      <b-loading v-model="isLoading" :is-full-page="false" />
      <div>
        <ag-grid-vue
          style="width: 100%; height: 700px;"
          class="ag-theme-alpine"
          :row-data="shipments"
          :grid-options="gridOptions"
          :pagination="true"
          :animate-rows="true"
          :row-selection="'multiple'"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          :get-context-menu-items="getContextMenuItems"
          :group-selects-children="true"
          :group-selects-filtered="true"

          :detail-row-height="200"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          @grid-ready="gridReady"
        />
      </div>
    </div>
    <b-modal :active.sync="isMaterialModalActive" scroll="keep" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('global.materials') }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field grouped>
            <b-field>
              <b-input v-model="searchMaterial" placeholder="Search..." type="search" icon="search" />
            </b-field>
            <div class="field">
              <b-button type="is-info" icon-left="file-csv" @click="exportMaterials">
                {{ $t('global.export') }}
              </b-button>
            </div>
          </b-field>
          <b-table
            :per-page="15"
            :data="filteredMaterials"
            :loading="isLoading"
            narrowed
            striped
            paginated
          >
            <template slot="header" slot-scope="props">
              <span>{{ props.column.label }}</span>
            </template>
            <b-table-column v-slot="props" field="barcode" label="Barcode" sortable>
              {{ props.row.barcode }}
            </b-table-column>
            <b-table-column v-slot="props" field="material" label="Material" sortable>
              {{ props.row.material }}
            </b-table-column>
            <b-table-column v-slot="props" field="scannedAt" label="Scanned at" sortable>
              {{ new Date(props.row.scannedAt).toLocaleString() }}
            </b-table-column>
            <template slot="bottom-left">
              <b>
                {{ $t('global.total') }}
              </b>: {{ filteredMaterials.length }}
            </template>
            <template slot="empty">
              <nothing-here />
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot" />
      </div>
    </b-modal>
    <b-modal :active.sync="isAttachModalActive" scroll="keep" has-modal-card @close="resetAttachForm">
      <div class="modal-card" style="min-height: 630px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('global.materials') }}
          </p>
        </header>
        <section class="modal-card-body">
          <form id="attachForm" @submit.prevent="submitAttachForm">
            <b-field :label="$t('pages.loads.receipt_date')">
              <b-datetimepicker
                v-model="attachForm.dateField"
                placeholder="Click to select..."
                icon="calendar"
                :max-datetime="maxDatetime"
                :min-datetime="minDatetime"
                required
              >
                <template #left>
                  <b-button
                    label="Now"
                    type="is-primary"
                    icon-left="clock"
                    @click="attachForm.dateField = new Date()"
                  />
                </template>
              </b-datetimepicker>
            </b-field>
            <b-field :label="$t('pages.loads.separator')">
              <b-select v-model="attachForm.separator" required @input="processFile">
                <option value="newline">
                  {{ $t('pages.loads.new_line') }}
                </option>
                <option value="semicolon">
                  {{ $t('pages.loads.semicolon') }}
                </option>
                <option value="comma">
                  {{ $t('pages.loads.comma') }}
                </option>
              </b-select>
            </b-field>
            <b-field :label="$t('pages.loads.process')">
              <b-checkbox v-model="attachForm.ignoreProcess">
                Ignore
              </b-checkbox>
            </b-field>
            <b-field>
              <b-button type="is-primary" @click="copyFormClipBoard">
                Copy from clipboard
              </b-button>
            </b-field>
            <b-field :label="$t('pages.loads.document')">
              <b-upload
                v-if="!attachForm.fileField"
                v-model="attachForm.fileField"
                accept=".txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                drag-drop
                required
                @input="processFile"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-csv" size="is-large" />
                    </p>
                    <p>
                      {{ $t('pages.loads.drop_file_here') }}
                    </p>
                  </div>
                </section>
              </b-upload>

              <div v-else>
                <b-field>
                  <span v-if="attachForm.fileField" class="tag is-info">
                    {{ attachForm.fileField.name }}
                    <button class="delete is-small" type="button" @click="attachForm.fileField = null" />
                  </span>
                </b-field>
                <b-table
                  :data="attachForm.fileData"
                  :per-page="5"
                  narrowed
                  striped
                  paginated
                >
                  <b-table-column v-slot="props" label="Barcode">
                    {{ props.row }}
                  </b-table-column>
                  <template slot="bottom-left">
                    <b>
                      {{ $t('global.total') }}
                    </b>: {{ attachForm.fileData.length }}
                  </template>
                  <template slot="empty">
                    <nothing-here />
                  </template>
                </b-table>
              </div>
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
          <b-field>
            <b-button
              type="is-primary"
              native-type="submit"
              icon-left="file-upload"
              form="attachForm"
              :loading="attachForm.isSubmitting"
            >
              {{ $t('global.submit') }}
            </b-button>
          </b-field>
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isAttachByQtyModalActive"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <AttachModal
        :shipment="selectedShipment"
        :max-datetime="maxDatetime"
        :min-datetime="minDatetime"
        @on-close="close"
      />
    </b-modal>
  </section>
</template>

<script>
import { jsPDF as JSPDF } from 'jspdf'
import 'jspdf-autotable'
import Shipment from '@/services/v2/shipment'
import Resource from '@/services/v2/resource'
import Plant from '@/services/v2/plant'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import bwipjs from 'bwip-js'
import AttachModal from './attachModal.vue'

export default {
  components: {
    AttachModal
  },
  data: function () {
    return {
      shipments: null,
      isLoading: false,
      modules: AllModules,
      duration: [
        { id: 1, text: 'Recent 1 month', value: this.$dayjs().subtract(1, 'month').format('YYYYMMDD') },
        { id: 2, text: 'Recent 3 months', value: this.$dayjs().subtract(3, 'months').format('YYYYMMDD') },
        { id: 3, text: 'Recent 6 months', value: this.$dayjs().subtract(6, 'months').format('YYYYMMDD') },
        { id: 4, text: 'Recent 1 year', value: this.$dayjs().subtract(12, 'months').format('YYYYMMDD') },
        { id: 5, text: 'All time', value: '' }
      ],
      selectedDuration: 1,
      gridOptions: {
        masterDetail: true,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true
        },
        groupDefaultExpanded: 1,
        autoGroupColumnDef: {
          sort: 'desc'
          // headerName: 'Vakey',
        //   // field: 'vakey',
        //   width: 200,
        //   cellRenderer: 'agGroupCellRenderer',
        //   cellRendererParams: { checkbox: true }
        },
        columnDefs: [
          { headerName: this.$t('pages.loads.grid.vakey'),
            checkboxSelection: true,
            cellRenderer: 'agGroupCellRenderer',
            field: 'vakey',
            width: 250 },
          { headerName: this.$t('pages.loads.grid.status'),
            field: 'status',
            width: 120,
            cellRenderer: (data) => {
              if (data.value === 'C') { return 'Todo' } else if (data.value === 'T') { return 'Done' } else return null
            },
            cellClassRules: {
              'rag-green': function (params) { return params.value === 'T' },
              'rag-yellow': function (params) { return params.value === 'C' }
            }
          },
          { headerName: this.$t('pages.loads.grid.requirement'), field: 'requirement', filter: 'agTextColumnFilter', width: 130 },
          { headerName: this.$t('pages.loads.grid.scan_date'),
            field: 'scanDate',
            filter: 'agTextColumnFilter',
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString(undefined, { timeZone: 'UTC' })
            },
            width: 130 },
          { headerName: this.$t('pages.loads.grid.date_at_plant'),
            field: 'dateAtPlant',
            rowGroup: true,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString(undefined, { timeZone: 'UTC' })
            },
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                if (this.$dayjs(cellValue).isSame(filterLocalDateAtMidnight)) {
                  return 0
                } else if (this.$dayjs(cellValue).isBefore(filterLocalDateAtMidnight)) {
                  return -1
                } else if (this.$dayjs(cellValue).isAfter(filterLocalDateAtMidnight)) {
                  return 1
                }
              }
            },
            sort: 'desc',
            hide: true,
            width: 140 },
          { headerName: this.$t('pages.loads.grid.shipment_number'), field: 'shipmentNumber', filter: 'agTextColumnFilter', width: 150 },
          { headerName: this.$t('pages.loads.grid.delivery_number'), field: 'deliveryNumber', filter: 'agTextColumnFilter', width: 150 },
          { headerName: this.$t('pages.loads.grid.collection_number'), field: 'collectNumber', filter: 'agTextColumnFilter', width: 150 },
          { headerName: this.$t('pages.loads.grid.forwarder'), field: 'forwarder', filter: 'agTextColumnFilter', width: 180 },
          { headerName: this.$t('pages.loads.grid.information'), field: 'information', filter: 'agTextColumnFilter', width: 150 }
        ],
        detailCellRendererParams: {
        // provide detail grid options
          detailGridOptions: {
            columnDefs: [
              { headerName: this.$t('pages.loads.grid.storage_location'), field: 'storageLocation', filter: 'agTextColumnFilter', width: 100 },
              { headerName: this.$t('pages.loads.grid.quantity'), field: 'quantity', filter: 'agNumberColumnFilter', width: 130 },
              { headerName: this.$t('pages.loads.grid.material'), field: 'material', filter: 'agTextColumnFilter', width: 130 },
              { headerName: this.$t('pages.loads.grid.consignee_id'), field: 'consigneeId', filter: 'agTextColumnFilter', width: 130 },
              { headerName: this.$t('pages.loads.grid.consignee_name'), field: 'consigneeName', filter: 'agTextColumnFilter', width: 450 }

            ]
          },
          // extract and supply row data for detail
          getDetailRowData: function (params) {
            params.successCallback(params.data.loads)
          }
        }
      },
      selectedShipment: null,
      selectedShipmentMaterials: [],
      searchMaterial: '',
      isMaterialModalActive: false,
      isAttachModalActive: false,
      attachForm: {
        dateField: null,
        fileField: null,
        fileData: [],
        separator: 'newline',
        ignoreProcess: false,
        isSubmitting: false
      },
      isAttachByQtyModalActive: false
    }
  },
  computed: {
    filteredMaterials: function () {
      return this.selectedShipmentMaterials.filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key]).toLowerCase().indexOf(this.searchMaterial.toLowerCase()) > -1
        })
      })
    },
    barcodeSeparator: function () {
      switch (this.attachForm.separator) {
        case 'newline':
          return '\n'
        case 'semicolon':
          return ';'
        case 'comma':
          return ','
        default:
          return null
      }
    },
    maxDatetime () {
      return this.$dayjs().add(7, 'day').toDate()
    },
    minDatetime () {
      return this.$dayjs().subtract(6, 'month').toDate()
    }
  },
  watch: {
    async selectedDuration () {
      await this.getData()
    }

  },
  mounted: async function () {
    try {
      await this.getData()
      const currentDate = this.$dayjs().format('YYYY-MM-DD')
      this.gridOptions.api.forEachNode((rowNode, index) => {
        if (!rowNode.group && rowNode.data.dateAtPlant === currentDate) {
          rowNode.setSelected(true)
        }
      })
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  methods: {
    gridReady: function () {

    },
    getContextMenuItems (params) {
      this.selectedShipment = params.node.data
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (this.selectedShipment) {
        customMenu.push({
          name: this.$t('pages.loads.attached_materials'),
          action: () => {
            this.openMaterialModal()
          },
          icon: '<i class="fas fa-fw fa-barcode" ></i>'
        })

        if (this.selectedShipment.status === 'T') {
          customMenu.push(
            {
              name: this.$t('pages.loads.delivery_note'),
              action: () => {
                this.downloadPdfFile()
              },
              icon: '<i class="far fa-fw fa-file-pdf"></i>'
            })
        } else if (this.$route.params.plant_id.charAt(2) === 'Z') {
          customMenu.push({
            name: 'Attach materials by quantity',
            icon: '<i class="fas fa-fw fa-paperclip"></i>',
            action: () => {
              this.openAttachByQtyModal()
            }
          })
        } else {
          customMenu.push(
            {
              name: this.$t('pages.loads.attach_materials'),
              action: () => {
                this.openAttachModal()
              },
              icon: '<i class="fas fa-fw fa-paperclip"></i>'
            })
        }
        if (this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED') {
          customMenu.push({
            name: 'Notify',
            action: () => {
              this.notify()
            },
            icon: '<i class="fas fa-fw fa-envelope"></i>'
          })
        }
      }
      const result = [...customMenu, ...genericMenu]
      return result
    },
    getData: async function () {
      this.isLoading = true
      const duration = this.duration.find(d => d.id === this.selectedDuration)
      this.shipments = await Plant.getShipments(this.$route.params.plant_id, {
        from: duration.value
      })
      this.isLoading = false
    },
    showMenu: function (item, event) {
      this.selectedShipment = item
      this.$refs.loadContextMenu.show(event)
    },
    downloadPdfFile: async function () {
      try {
        const data = await Resource.downloadPdf(this.selectedShipment.deliveryNumber)
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    processFile: async function () {
      if (!this.attachForm.fileField) return
      const reader = new FileReader()
      reader.readAsText(this.attachForm.fileField)
      reader.onload = e => {
        const regex = RegExp('^[0-9]{6,8}$')
        let barcodes = e.target.result.split(this.barcodeSeparator).map(e => e.trim()).filter(e => e !== null && regex.test(e))
        this.attachForm.fileData = barcodes
      }
    },
    openMaterialModal: async function () {
      try {
        let result = await Shipment.getMovements(this.selectedShipment.vakey)

        this.selectedShipmentMaterials = result.filter(e => e.barcode !== null)
        this.isMaterialModalActive = true
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    openAttachModal: async function () {
      try {
        this.isAttachModalActive = true
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    openAttachByQtyModal: function () {
      this.isAttachByQtyModalActive = true
    },
    isTimeValid (selectedDate) {
      return !(this.$dayjs(selectedDate).format('HHmmss') === '000000')
    },
    resetAttachForm: function () {
      this.attachForm.fileData = []
      this.attachForm.separator = 'newline'
      this.attachForm.dateField = null
      this.attachForm.fileField = null
      this.attachForm.ignoreProcess = false
    },
    submitAttachForm: async function () {
      this.attachForm.isSubmitting = true
      try {
        const reducer = (acc, val) => acc + val.quantity

        if (this.attachForm.dateField == null || !this.isTimeValid(this.attachForm.dateField)) {
          throw Error('Date missing or invalid')
        }
        if (['DEL', 'TRFO'].includes(this.selectedShipment.requirement) &&
          (this.selectedShipment.loads.reduce(reducer, 0) !== this.attachForm.fileData.length && !this.selectedShipment.quantityControl)) {
          throw Error(this.$t('pages.loads.invalid_quantity'))
        }

        const movements = this.attachForm.fileData.map(e => {
          return {
            GPS_BARCODE: e,
            GPS_PLANT: this.selectedShipment.plantId,
            GPS_STORLOC_IN: this.selectedShipment.storageLocation || undefined,
            ST_TIMESTAMP: this.$dayjs(this.attachForm.dateField).utc().format('YYYYMMDDHHmmss'),
            GPS_TIMESTAMP: this.$dayjs(this.attachForm.dateField).utc().format('YYYYMMDDHHmmss'),
            GPS_REQUIREMENT: this.selectedShipment.requirement,
            GPS_DELIVERY_NUMBER: this.selectedShipment.deliveryNumber || undefined,
            GPS_COLLECT_ID: this.selectedShipment.collectNumber || undefined
          }
        })
        await Shipment.postMovements(this.selectedShipment.vakey, movements, this.attachForm.ignoreProcess)
        await Shipment.updateStatus(this.selectedShipment.vakey, 'T')
        this.resetAttachForm()
        this.isAttachModalActive = false
        this.getData()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
      this.attachForm.isSubmitting = false
    },
    exportMaterials: function () {
      const result = this.filteredMaterials.map(e => Object.values(e).join(';')).join('\n')
      const csvBlob = new Blob([result], { type: 'text/csv' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')
      anchorElement.href = blobUrl
      anchorElement.download = this.selectedShipment.vakey + '-export.csv'
      anchorElement.click()
    },
    generateBarcodes: async function () {
      const selectedNodes = this.gridOptions.api.getSelectedNodes().filter(node => node.data.status === 'C')
      const doc = new JSPDF()

      let startHeight = 0
      for (const [index, selectedNode] of selectedNodes.entries()) {
        // const pageHeight = doc.internal.pageSize.getHeight()
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber

        if (index / pageNumber >= 4) {
          doc.addPage(1)
          startHeight = 0
        }
        let shpt = selectedNode.data

        const params = {
          bcid: 'code128',
          text: selectedNode.data.vakey,
          width: 50,
          height: 15 }
        const canvas = document.createElement('canvas')

        const imgData = await bwipjs.toCanvas(canvas, params).toDataURL()
        doc.addImage(imgData, 'PNG', 155, startHeight + 10)

        doc.autoTable({
          tableWidth: 130,
          startY: startHeight + 20,
          body:
          [
            [
              { content: 'Shipment : ' + (shpt.shipmentNumber || 'N/A'), styles: { fontStyle: 'bold' } },
              { content: 'Forwarder : ' + (shpt.forwarderName || 'N/A'), styles: { fontStyle: 'bold' } }
            ],
            [
              shpt.dateAtPlant + ' - ' + shpt.requirement + ' : ' + (shpt.collectNumber || shpt.deliveryNumber) || 'N/A'
              // 'Stor. Loc. : ' + (shpt.storageLocation || 'N/A')
            ]
          ]
        })

        doc.autoTable({
          startY: startHeight + 40,
          body:
            shpt.loads.map(e => [e.material, { content: e.quantity, styles: { fontStyle: 'bold' } }, e.consigneeName, e.storageLocation])
        })

        doc.line(10, startHeight + 70, 200, startHeight + 70) // horizontal line
        startHeight += 70
      }

      doc.output('dataurlnewwindow')
    },
    changeStatus: async function () {
      try {
        await Shipment.updateStatus(this.selectedShipment.vakey, this.selectedShipment.status === 'C' ? 'T' : 'C')
        this.getData()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    notify: async function () {
      try {
        await Shipment.notify(this.selectedShipment.vakey)
        this.$buefy.notification.open({ message: `Notify request has been sent`, position: 'is-top', type: 'is-success' })
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    copyFormClipBoard: async function () {
      let res = await navigator.clipboard.readText()
      const regex = RegExp('^[0-9]{6,8}$')
      let barcodes = res.split(this.barcodeSeparator).map(e => e.trim()).filter(e => e !== null && regex.test(e))
      if (barcodes.length === 0) return
      this.attachForm.fileField = { name: 'clipboard' }
      this.attachForm.fileData = barcodes
    },
    close () {
      this.isAttachByQtyModalActive = false
      this.getData()
    }
  }

}
</script>
