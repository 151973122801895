<template>
  <section>
    <div class="container has-text-centered">
      <div class="custom-centered">
        <img
          class="logo"
          src="@/assets/gps_logo.png"
          alt="Main logo"
          height="400"
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.custom-centered {
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full height of the viewport */
}
</style>
