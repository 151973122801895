const markerUrl = 'https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-star&background='

const markerTypes = new Map()
markerTypes.set('enduser', {
  type: 'enduser',
  color: '90A4AE',
  label: 'Enduser'
})

markerTypes.set('customer', {
  type: 'customer',
  color: 'd32f2f',
  label: 'Customer'
})

markerTypes.set('plant', {
  type: 'plant',
  color: '9CCC65',
  label: 'GPS Warehouse'
})

markerTypes.set('subcontractor', {
  type: 'subcontractor',
  color: '42a5f5  ',
  label: 'Subcontractor'
})

markerTypes.forEach(element => {
  element.markerIcon = markerUrl + element.color
})

export {
  markerUrl,
  markerTypes
}
