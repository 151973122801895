export default {
  methods: {
    getFilters: function (request) {
      const groups = this.getGroups(request).filter(e => e.key !== null)

      const filterModel = request.filterModel
      let filter = {}

      groups.forEach(e => {
        filter[e.column] = {}
        filter[e.column]['equals'] = e.key
      })
      // Creating graphql filters from aggrid params
      Object.keys(filterModel).forEach(key => {
        const val = filterModel[key]
        filter[key] = {}
        if (val.filterType === 'date') {
          filter[key][val.type] = val.type === 'inRange' ? [this.$dayjs.utc(val.dateFrom).format('YYYY-MM-DD'), this.$dayjs.utc(val.dateTo).format('YYYY-MM-DD')] : this.$dayjs.utc(val.dateFrom).format('YYYY-MM-DD')
        } else if (val.filterType === 'text' || val.filterType === 'number') {
          filter[key][val.type] = val.type === 'inRange' ? [val.filter, val.filterTo] : val.filter
        }
      })
      return filter
    },
    getSorts: function (request) {
      const currentRowGroup = this.getGroups(request).filter(e => e.isHere === true)[0]

      return request.sortModel.filter(srt => {
        if (currentRowGroup) {
          return srt.colId === currentRowGroup.column
        } else {
          return true
        }
      }).map(srt => {
        return { column: srt.colId, order: srt.sort }
      })
    },
    getGroups: function (request) {
      return request.rowGroupCols.map((grp, index) => {
        const groupKeys = request.groupKeys[index] || null
        return { column: grp.field, key: groupKeys, isHere: index === request.groupKeys.length }
      })
    },
    getAggregate: function (request) {
      let aggregate = []

      request.valueCols.forEach(e => {
        const found = aggregate.find(e => e.hasOwnProperty(e.aggFun))

        if (found) {
          found[e.aggFunc].push(e.id)
        } else {
          const newAgg = {}
          newAgg[e.aggFunc] = [e.id]
          aggregate.push(newAgg)
        }
      })
      return aggregate
    },
    dateSort: function (dateA, dateB) {
      if (dateA && dateB) {
        if (this.$dayjs(dateA, 'DD/MM/YYYY').isSame(this.$dayjs(dateB, 'DD/MM/YYYY'))) {
          return 0
        }
        return this.$dayjs(dateA, 'DD/MM/YYYY').isAfter(this.$dayjs(dateB, 'DD/MM/YYYY')) ? 1 : -1
      } else {
        if (dateA && !dateB) {
          return 1
        } else if (!dateA && dateB) {
          return -1
        } else {
          return 0
        }
      }
    },
    basicStringComparator: (a, b) => {
      if (a > b) {
        return 1
      }
      if (a === b) {
        return 0
      } else {
        return -1
      }
    },
    defaultStringAgg: function (values) {
      return values[0]
    },
    intAgg: function (values) {
      let sum = 0
      values.forEach(v => {
        if (v) {
          sum += parseInt(v)
        }
      })
      return sum
    },
    stringAgg: function (values) {
      let agg = ''
      if (values.length > 0) {
        values.forEach(v => {
          agg += v + ', '
        })
        agg = agg.slice(0, -2)
      }
      return agg
    },
    lastDateAgg: function (values) {
      let max = null
      if (values.length > 0) {
        values.forEach(v => {
          if (v) {
            const vDate = this.$dayjs(v, 'DD/MM/YYYY')
            if (!max || vDate.isAfter(max)) {
              max = vDate
            }
          }
        })
      }
      return max ? max.format('DD/MM/YYYY') : ''
    }
  }

}
