<template>
  <section>
    <form>
      <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="save">
        <b-field label="Template name">
          <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
            <b-input v-model="template.name" />
          </ValidationProvider>
        </b-field>
        <b-field label="Subject">
          <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
            <b-input v-model="template.subject" />
          </ValidationProvider>
        </b-field>
        <b-field label="Sender">
          <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
            <b-input v-model="template.sender" />
          </ValidationProvider>
          <p class="control">
            <span class="button is-static">@fr-gps.com</span>
          </p>
        </b-field>
        <b-field label="Language">
          <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
            <b-select v-model="template.lang" placeholder="select a mail display language">
              <option
                v-for="lang in langs"
                :key="lang"
                :value="lang"
              >
                {{ lang }}
              </option>
            </b-select>
          </ValidationProvider>
        </b-field>
        <b-field label="Content">
          <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
            <b-input v-model="template.content" type="textarea" />
          </ValidationProvider>
        </b-field>
        <b-field label="Comment">
          <b-input v-model="template.comment" type="textarea" />
        </b-field>

        <p class="control buttons level-right">
          <b-button label="Cancel" @click="$emit('on-close')" />
          <b-button
            class="button is-primary"
            label="Save"
            :disabled="!valid"
            type="submit"
            @click.prevent="submit"
          />
        </p>
      </ValidationObserver>
    </form>
  </section>
</template>

<script>
import Distribution from '../../services/v2/distribution'

export default {
  props: {
    template: {
      default: () => {},
      type: Object
    },
    langs: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async submit () {
      let newTemplate = {
        name: this.template.name,
        subject: this.template.subject,
        sender: this.template.sender,
        lang: this.template.lang,
        content: this.template.content,
        comment: this.template.comment
      }
      if (this.template.id) newTemplate = { id: this.template.id, ...newTemplate }

      try {
        await Distribution.postTemplate(newTemplate)
        this.$buefy.toast.open({
          message: 'Successful',
          type: 'is-primary' })
        this.$emit('on-close')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
    }
  }
}
</script>
