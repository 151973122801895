<template>
  <div class="modal-card custom">
    <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="submit">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ role ? "Edit role" : "Create role" }}
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="name">
          <ValidationProvider v-slot="{ errors }">
            <b-input
              v-model="roleToEdit.name"
              name="name"
              type="text"
              pattern="[a-zA-Z0-9 ]+"
              required
            />
          </ValidationProvider>
        </b-field>
        <b-tabs v-if="role" class="rights" type="is-toggle" expanded>
          <b-tab-item v-for="parent in parentRights" :key="parent.id" :label="parent.name">
            <b-button :class="{'is-primary': !!parent.authorized, 'is-danger': !parent.authorized}" @click="changeValue(parent.id)">
              {{ !!parent.authorized ? 'Activé' : 'Désactivé' }}
            </b-button>

            <div class="children-rights" :class="{ 'children-rights-disabled': !parent.authorized}">
              <div
                v-for="children in getChildrens(parent.id)"
                :key="children.id"
                class="hero"
                :class="{ 'is-primary': children.authorized, 'is-white': !children.authorized}"
                @click="parent.authorized ? changeValue(children.id): null"
              >
                <div>
                  {{ children.name }}
                  <div v-if="children.description" class="is-pulled-right">
                    <b-tooltip
                      :label="children.description"
                      type="is-dark"
                      multilined
                      position="is-left"
                    >
                      <b-icon icon="info" type="is-link" size="is-small" />
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
      <footer class="modal-card-foot">
        <button
          class="is-primary button is-fullwidth"
          :class=" {'is-loading' : isSubmitting || loading}"
          type="submit"
        >
          Submit
        </button>
      </footer>
    </ValidationObserver>
  </div>
</template>

<script>

import Role from '@/services/v2/role'

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    rights: {
      type: Array,
      default: () => []
    },
    role: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      roleRights: [],
      loading: false,
      roleToEdit: {
        name: ''
      }
    }
  },
  computed: {
    computedRights: function () {
      const { roleRights, rights } = this
      return rights.map(e => {
        const found = roleRights.find(rr => rr.rightId === e.id)
        e.authorized = !!found
        return e
      })
    },
    parentRights: function () {
      return this.computedRights.filter((right) => {
        return right['parentId'] === null
      })
    },
    childrenRights: function () {
      return this.computedRights.filter((right) => {
        return right['parentId'] !== null
      })
    }
  },
  mounted: async function () {
    this.loading = true
    if (this.role) {
      this.roleToEdit = {
        name: this.role.name
      }
      this.roleRights = await Role.getRoleRights(this.role.id)
    } else {
      this.roleToEdit = {
        name: ''
      }
    }
    this.loading = false
  },
  methods: {
    async submit () {
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          if (this.role) {
            await this.$emit('submitUpdate', { rights: this.roleRights, role: this.roleToEdit })
          } else {
            await this.$emit('submitCreate', this.roleToEdit)
          }
        }
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    },
    getChildrens: function (parentId) {
      return this.rights.filter((right) => {
        return right.parentId === parentId
      })
    },
    changeValue: function (rightId) {
      const rrIndex = this.roleRights.findIndex(rr => rr.rightId === rightId)
      if (rrIndex !== -1) {
        this.roleRights.splice(rrIndex, 1)
      } else {
        this.roleRights.push({ roleId: this.role.id, rightId: rightId })
      }
    }
  }
}
</script>

<style scoped>
  .rights {
    margin-top: 50px;
  }
  .modal-card-body {
    min-height: 82vh;
  }
  .children-rights {
    width: 100%;
    margin-top: 25px;
  }
  .children-rights > div {
    margin: 5px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #6abf9e1a;
  }
  .children-rights-disabled {
    opacity: 0.3;
  }
  .flex{
    display: flex;
    justify-content: space-between;
  }

  .is-primary {
    background-color: #6abf9e !important;
  }

  .custom {
    width: 750px;
    overflow-y: auto !important;
  }
</style>
