import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/endusers'

const endusers = axios.create({
  baseURL
})

interceptorsSetup(endusers)

export default class Enduser {
  static async getAll () {
    const result = await endusers.get('/')
    return result.data
  }

  static async findOne (enduserId) {
    const result = await endusers.get('/' + enduserId)
    return result.data
  }
}
