<template>
  <section>
    <div ref="pbiContainer" class="pbi-container">
      <span v-if="isLoading">Fetching token...</span>
    </div>
  </section>
</template>
<script>
import * as pbi from 'powerbi-client'
import Powerbi from '@/services/v2/powerbi'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      isLoading: true
    }
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    }
  },
  mounted: async function () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      if (!this.$store.state.auth.user.group.powerBis[this.$route.params.id].id) {
        throw Error('Invalid role')
      }

      this.isLoading = true
      const models = pbi.models
      const embedUrl = `https://app.powerbi.com/reportEmbed`
      const res = await Powerbi.getPowerBiToken(this.$store.state.auth.user.group.powerBis[this.$route.params.id].id)
      const config = {
        type: 'report',
        permissions: models.Permissions.All,
        tokenType: models.TokenType.Embed,
        accessToken: res.token,
        embedUrl: embedUrl,
        id: this.$store.state.auth.user.group.powerBis[this.$route.params.id].id
      }

      // Get a reference to the embedded report HTML element
      const reportContainer = this.$refs.pbiContainer
      // Embed the report and display it within the div container.
      window.powerbi.embed(reportContainer, config)
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
  .pbi-container {
    height: 85vh;
  }
</style>
