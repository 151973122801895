<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="$emit('submit', shippingInstruction)">
      <header class="modal-card-head">
        Edit
      </header>
      <section class="modal-card-body" style="height: 510px">
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Departure plant"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="plant"
                :loading="isLoading"
                :data="parsePlants"
                :open-on-focus="true"
                field="name"
                @select="plantSelected"
                @typing="findPlant"
              />
            </b-field>
          </ValidationProvider>
        </b-field>
        <!----------------------------------------------------------------
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="ETD"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-datepicker
                v-model="estimatedTimeOfDeparture"
                trap-focus
                placeholder="Select a date"
              />
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Customer"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="customers.selected"
                :loading="isLoading"
                :data="customers.data"
                :open-on-focus="true"
                field="name"
                @select="customerSelected"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Processor"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="processors.selected"
                :loading="isLoading"
                :data="processors.data"
                :open-on-focus="true"
                field="name"
                @select="option => (shippingInstruction.processorId = option ? option.id : '')"
              />
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Enduser"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="endusers.selected"
                :loading="isLoading"
                :data="endusers.data"
                :open-on-focus="true"
                field="name"
                @select="option => (shippingInstruction.enduserId = option ? option.id : '')"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Port"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="ports.selected"
                :loading="isLoading"
                :data="ports.data"
                :open-on-focus="true"
                field="name"
                @select="option => (shippingInstruction.loadingPortId = option ? option.id : '')"
              />
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Material"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-autocomplete
                v-model="shippingInstruction.material"
                :data="materials.data"
                :open-on-focus="true"
                field="name"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Quantity"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-input v-model="shippingInstruction.quantity" type="number" />
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field grouped>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Id"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"

              expanded
            >
              <b-input v-model="shippingInstruction.ref" />
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field
              label="Version"
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
              expanded
            >
              <b-input v-model="shippingInstruction.version" type="number" />
            </b-field>
          </ValidationProvider>
        </b-field>
        -->
      </section>
      <footer class="modal-card-foot">
        <div class="buttons">
          <b-button
            icon-left="save"
            type="is-primary"
            :loading="isSubmitting"
            :disabled="invalid"
            native-type="submit"
          >
            Save
          </b-button>
        </div>
      </footer>
    </form>
  </ValidationObserver>
</template>

<script>
// import * as NR from '@/services/v1/nr'
import gql from 'graphql-tag'

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    populateWith: {
      type: Object,
      default: () => null
    }
  },
  data: function () {
    return {
      shippingInstruction: {},
      plants: [],
      plant: '',
      parsePlants: [],
      // materials: {
      //   data: ['GPS5', 'GPS1']
      // },
      // customers: {
      //   data: [],
      //   selected: null
      // },
      // ports: {
      //   data: [],
      //   selected: null
      // },
      // endusers: {
      //   data: [],
      //   selected: null
      // },
      // processors: {
      //   data: [],
      //   selected: null
      // },
      isLoading: true
    }
  },
  computed: {
    // estimatedTimeOfDeparture: {
    //   get () {
    //     return new Date(this.shippingInstruction.estimatedTimeOfDeparture)
    //   },
    //   set (val) {
    //     this.shippingInstruction.enduserId = val.id
    //   }
    // }
  },
  mounted: async function () {
    const res = await this.$apollo.query({
      query: gql`
        {
          plants {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `
    })
    this.plants = res.data.plants.edges.map(el => el.node)
    const plant = this.plants.find(plant =>
      plant.id === this.populateWith.departurePlantId ? plant.name : ''
    )
    this.plant = plant.name
    this.parsePlants = [this.plants]
    // const gqlQuery = `
    //   {
    //     customers(filter: { type: { equals: "NR"}, accountGroup: { equals: "CLT1" } }) {
    //       totalCount
    //       edges {
    //         node {
    //           id
    //           name
    //           accountGroup
    //           type
    //           departurePlants {
    //             id
    //             name
    //           }
    //           endusers (filter: { type: { equals: "EU"}, accountGroup: { equals: "CLT2" } }){
    //             edges {
    //               node {
    //                 id
    //                 name
    //               }
    //             }
    //           }
    //           processors: endusers (filter: { type: { equals: "NR"}, accountGroup: { in: ["CLT1", "CLT2"] } }){
    //             edges {
    //               node {
    //                 id
    //                 name
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }`

    // const [ports, result] = await Promise.all([
    //   NR.getNRLoadingPorts(),
    //   this.$apollo.query({
    //     fetchPolicy: 'no-cache',
    //     query: gql`
    //       ${gqlQuery}
    //     `
    //   })
    // ])

    // this.ports.data = ports
    // this.customers.data = result.data.customers.edges.map(e => e.node)

    if (this.populateWith) {
      this.shippingInstruction = { ...this.populateWith }

      // const selectedCustomer = this.customers.data.find(
      //   e => e.id === this.shippingInstruction.customerId
      // )

      // this.endusers.data = selectedCustomer.endusers.edges.map(e => e.node)
      // this.processors.data = selectedCustomer.processors.edges.map(e => e.node)

      // const selectedEnduser = this.endusers.data.find(
      //   e => e.id === this.shippingInstruction.enduserId
      // )
      // const selectedProcessor = this.processors.data.find(
      //   e => e.id === this.shippingInstruction.processorId
      // )
      // const selectedPort = this.ports.data.find(
      //   e => String(e.id) === this.shippingInstruction.loadingPortId
      // )

      // this.customers.selected = selectedCustomer?.name
      // this.endusers.selected = selectedEnduser?.name
      // this.processors.selected = selectedProcessor?.name
      // this.ports.selected = selectedPort?.name
    }
    this.isLoading = false
  },
  methods: {
    // customerSelected (option) {
    //   this.endusers.data = option.endusers.edges.map(e => e.node)
    //   this.endusers.selected = ''

    //   this.processors.data = option.processors.edges.map(e => e.node)
    //   this.processors.selected = ''

    //   this.shippingInstruction.customerId = option ? option.id : ''
    // },
    plantSelected (plant) {
      this.shippingInstruction.departurePlantId = plant.id
    },
    findPlant (text) {
      this.parsePlants = this.plants.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 ||
          option.id
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    }
  }
}
</script>
