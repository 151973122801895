<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <p>
        <b-icon icon="frown" pack="far" size="is-large" />
      </p>
      <p>{{ content }}</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: 'Nothing here.'
    }
  }
}
</script>
