import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/distribution'

const distribution = axios.create({
  baseURL
})

interceptorsSetup(distribution)

export default class Distribution {
  static async all (query) {
    const queryString = constructQueryString(query)
    const { data: result } = await distribution.get(`/all?${queryString}`)
    return result
  }

  static async getAllDistributors () {
    const { data: result } = await distribution.get('/distributors')
    return result
  }

  static async getTemplates (query) {
    const queryString = constructQueryString(query)
    const { data: result } = await distribution.get(`/templates?${queryString}`)
    return result
  }

  static async postTemplate (data) {
    const { data: result } = await distribution.post(`/templates`, data)
    return result
  }

  static async postSetting (data) {
    const { distributorCode } = data
    const { data: result } = await distribution.post(`/distributor/${distributorCode}/settings`, data)
    return result
  }

  static async manualReminder (data) {
    const { distributorCode } = data
    const { data: result } = await distribution.post(`/distributor/${distributorCode}/manual-reminder`, data)
    return result
  }

  static async getGroup (query) {
    const queryString = constructQueryString(query)
    const { data: result } = await distribution.get(`/group?${queryString}`)
    return result
  }

  static async setGroup (data) {
    const { data: result } = await distribution.post('/group', data)
    return result
  }

  static async getHistory (distributorCode) {
    const { data: result } = await distribution.get(`/distributor/${distributorCode}/hisotry`)
    return result
  }

  static async getDeclarations (query) {
    const queryString = constructQueryString(query)
    const { data: result } = await distribution.get(`/declarations?${queryString}`)
    return result
  }

  static async getDeclarationFile (distributorCode, fileName) {
    const result = await distribution.get(`/download-declaration/${distributorCode}/${fileName}`, {
      responseType: 'blob'
    })
    return result
  }
}
