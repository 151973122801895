export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_EDI_CUSTOMERS = 'SET_EDI_CUSTOMERS'
export const SET_END_USERS = 'SET_END_USERS'

export const SWITCH_SIDE_BAR = 'SWITCH_SIDE_BAR'
export const HIDE_SIDE_BAR = 'HIDE_SIDE_BAR'
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'

export const CUSTOMER_FILE_SETTINGS_REQUEST = 'CUSTOMER_FILE_SETTINGS_REQUEST'
export const CUSTOMER_FILE_SETTINGS_SUCCESS = 'CUSTOMER_FILE_SETTINGS_SUCCESS'
export const CUSTOMER_FILE_SETTINGS_FAILURE = 'CUSTOMER_FILE_SETTINGS_FAILURE'

export const TABLES_MOVEMENT_FIELDS_REQUEST = 'TABLES_MOVEMENT_FIELDS_REQUEST'
export const TABLES_MOVEMENT_FIELDS_SUCCESS = 'TABLES_MOVEMENT_FIELDS_SUCCESS'
export const TABLES_MOVEMENT_FIELDS_FAILURE = 'TABLES_MOVEMENT_FIELDS_FAILURE'

export const TABLES_MOVEMENT_TABLES_REQUEST = 'TABLES_MOVEMENT_TABLES_REQUEST'
export const TABLES_MOVEMENT_TABLES_SUCCESS = 'TABLES_MOVEMENT_TABLES_SUCCESS'
export const TABLES_MOVEMENT_TABLES_FAILURE = 'TABLES_MOVEMENT_TABLES_FAILURE'

export const SHOW_GENERATE_REPORT_NOTIFICATION = 'SHOW_GENERATE_REPORT_NOTIFICATION'
export const SHOW_GENERATE_SQLITEDBFILES_NOTIFICATION = 'SHOW_GENERATE_SQLITEDBFILES_NOTIFICATION'
