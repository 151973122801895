<template>
  <section class="padding">
    <div class="level m-10">
      <b-field class="level-left">
        <b-select v-model="selectedRegion" placeholder="Select a region" rounded>
          <option value="CN">
            China
          </option>
          <option value="notCN">
            Rest of world
          </option>
          <option value="all">
            All countries
          </option>
        </b-select>
      </b-field>
      <div class="buttons level-right">
        <b-button
          rounded
          type="is-info"
          icon-left="folder"
          @click="isEditingTemplate = true"
        >
          Manage template
        </b-button>
        <b-button rounded icon-left="rotate" @click="refresh">
          Refresh
        </b-button>
        <b-button rounded icon-left="filter" @click="clearFilter">
          Clear Filter
        </b-button>
      </div>
    </div>
    <b-loading v-model="isLoading" :is-full-page="false" />
    <ag-grid-vue
      style="width: 100%; height: 700px; z-index: 1;"
      class="ag-theme-alpine"
      :modules="modules"
      :row-data="data"
      :grid-options="gridOptions"
      :pagination="true"
      :pagination-auto-page-size="true"
      :get-context-menu-items="getContextMenuItems"
      :overlay-no-rows-template="overlayNoRowsTemplate"
      @grid-ready="gridReady"
    />

    <b-modal
      :active.sync="isEditingTemplate"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <TemplateModal @on-close="close" />
    </b-modal>

    <b-modal
      :active.sync="isEditingSetting"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <ReminderSettingModal :distributor="selectedDi" @on-close="close" />
    </b-modal>

    <b-modal
      :active.sync="isEditingCurSetting"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <ReminderSettingModal :distributor="selectedDi" :cur-config="curConfig" @on-close="close" />
    </b-modal>

    <b-modal
      :active.sync="isShowingHistory"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <HistoryModal :distributor="selectedDi" @on-close="close" />
    </b-modal>

    <b-modal
      :active.sync="isEditingDistributorSetting"
      has-modal-card
      trap-focus
      scroll="keep"
    >
      <DistributorSettingModal :distributor="selectedDi" @on-close="close" />
    </b-modal>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Distribution from '@/services/v2/distribution'
import TemplateModal from './template-modal.vue'
import ReminderSettingModal from './reminder-setting.vue'
import DistributorSettingModal from './distributor-setting.vue'
import HistoryModal from './history.vue'
import ProgressBarComponent from './progressBarComponent.vue'

export default {
  components: {
    TemplateModal,
    ReminderSettingModal,
    DistributorSettingModal,
    HistoryModal,
    // eslint-disable-next-line vue/no-unused-components
    ProgressBarComponent
  },
  data () {
    return {
      modules: AllModules,
      selectedRegion: null,
      distributors: [],
      data: [],
      isEditingTemplate: false,
      isEditingSetting: false,
      isEditingDistributorSetting: false,
      isShowingHistory: false,
      selectedDi: {},
      curConfig: {},
      isEditingCurSetting: false,
      isLoading: false,
      overlayNoRowsTemplate: '<div class="level"><p class="level-item has-text-centered"> Please choose a region to begin with... </p></div>',
      gridOptions: {
        defaultColDef: {
          sortable: true,
          filter: true,
          floatingFilter: true,
          resizable: true
        },
        columnDefs: [
          {
            headerName: 'Reminder setting',
            children: [
              {
                headerName: 'Template',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  return params.data.emailConfig?.template.name || ''
                }
              },
              {
                headerName: 'Cycle length',
                width: 150,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  return params.data.emailConfig?.setting.frequency ? `${params.data.emailConfig?.setting.frequency} days` : ''
                }
              },
              {
                headerName: 'Receiver email',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  return params.data.emailConfig?.setting.sendTo || ''
                }
              },
              {
                headerName: 'Status',
                maxWidth: 100,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  return params.data.emailConfig?.setting.status ? params.data.emailConfig?.setting.status : ''
                },
                cellRenderer: (params) => {
                  if (params.data.emailConfig) {
                    switch (params.data.emailConfig.setting.status) {
                      case 'Active':
                        return `<i class="fa-sharp fa-solid fa-circle" style="color: #34c759;"></i>`
                      case 'Pending':
                        return `<i class="fa-sharp fa-solid fa-circle" style="color: #ffcc00;"></i>`
                      case 'Inactive':
                        return `<i class="fa-sharp fa-solid fa-circle" style="color: #ff3b30;"></i>`
                    }
                  } else return ''
                }
              },
              {
                headerName: 'Starting date',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  const date = params.data.emailConfig?.setting.startDate.toString()
                  return date ? this.$dayjs(date).format('DD/MM/YYYY') : ''
                }
              }
            ]
          },
          {
            headerName: 'Response rate',
            width: 150,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            valueGetter: (params) => {
              return params.data.emailConfig?.setting.status === 'Active' ? +params.data.replyRate : null
            },
            cellRendererFramework: 'ProgressBarComponent'
          },
          {
            headerName: 'Distributor info',
            children: [
              {
                headerName: 'Distributor',
                colId: 'distributor',
                minWidth: 360,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (data) => {
                  return data.data.id + ' - ' + data.data.name
                }
              },
              {
                headerName: 'Country',
                field: 'country',
                width: 150,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                cellRenderer: this.flagRender
              },
              {
                headerName: 'Group',
                width: 150,
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                valueGetter: (params) => {
                  return params.data.group.length ? params.data.group[0].group : ''
                }
              },
              {
                headerName: 'Tel',
                field: 'tel'
              },
              {
                headerName: 'Email',
                field: 'email'
              },
              {
                headerName: 'Address',
                filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                colId: 'address',
                minWidth: 460,
                valueGetter: (data) => {
                  return data.data.address ? data.data.address + ' , ' + data.data.city + ' , ' + data.data.postalCode : data.data.city + ' , ' + data.data.postalCode
                }
              }

            ]
          }
        ]
      }
    }
  },
  watch: {
    async selectedRegion (value) {
      this.loadData(value)
    }
  },
  mounted () {
    this.hideSideBar()
  },
  beforeMount () {
    this.context = { componentParant: this }
  },
  methods: {
    ...mapActions(['switchSideBar', 'hideSideBar', 'archiveAction']),
    async loadData (value) {
      this.isLoading = true
      if (value === 'CN') {
        this.data = await Distribution.all({ inChina: true })
      } else if (value === 'notCN') {
        this.data = await Distribution.all({ inChina: false })
      } else {
        this.data = await Distribution.all({})
      }
      this.isLoading = false
    },
    async refresh () {
      this.loadData(this.selectedRegion)
      this.gridOptions.api.refreshCells()
    },
    flagRender: function (params) {
      if (params.data) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    gridReady: function (gridApi) {
      // gridApi.api.sizeColumnsToFit()
      this.gridApi = gridApi
    },
    getContextMenuItems (params) {
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'export'
      ]
      const customMenu = []

      customMenu.push({
        name: 'Distributor settings',
        action: async () => {
          this.selectedDi = params.node.data
          this.isEditingDistributorSetting = true
        },
        icon: '<i class="fa-solid fa-user"></i>'
      })

      customMenu.push({
        name: 'Reminder settings',
        action: async () => {
          this.selectedDi = params.node.data
          if (params.node.data.emailConfig) {
            this.curConfig = params.node.data.emailConfig.setting
            this.isEditingCurSetting = true
          } else {
            this.isEditingSetting = true
          }
        },
        icon: '<i class="fa-solid fa-gear"></i>'
      })

      if (params.node.data.emailConfig) {
        customMenu.push({
          name: 'Resend last reminder',
          action: async () => {
            try {
              this.isLoading = true
              await Distribution.manualReminder(params.node.data.emailConfig.setting)
              this.$buefy.toast.open({
                message: 'Successful',
                type: 'is-primary' })
            } catch (e) {
              this.$buefy.toast.open({
                message: e.message,
                type: 'is-danger' })
            }
            this.isLoading = false
          },
          icon: '<i class="fa-sharp fa-solid fa-envelope"></i>'
        })

        customMenu.push({
          name: 'Email reminder history',
          action: async () => {
            this.selectedDi = params.node.data
            this.isShowingHistory = true
          },
          icon: '<i class="fa-solid fa-eye"></i>'
        })
      }

      return [...customMenu, ...genericMenu]
    },
    async close () {
      this.isEditingTemplate = false
      this.isEditingSetting = false
      this.isEditingCurSetting = false
      this.isEditingDistributorSetting = false
      this.isShowingHistory = false
      await this.refresh()
    },
    clearFilter () {
      this.gridOptions.api.setFilterModel()
    }
  }
}
</script>
