import { Icon, Marker } from 'leaflet'

export default {
  methods: {
    refreshMapMarker: function (marker) {
      marker.mapMarker = new Marker(
        [marker.lat, marker.lng],
        {
          icon: new Icon({
            iconUrl: marker.selected ? marker.selectedIconUrl : marker.normalIconUrl,
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: marker.selected ? [62, 60] : [42, 40],
            iconAnchor: marker.selected ? [22, 61] : [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
            shadowAnchor: [5, 43] // the same for the shadow
          })
        })
    },
    generateMarkers: function (type, items, iconUrl) {
      const markerSelectedUrl = 'https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-eye&background=' + iconUrl.split('background=')[1]
      let tmpMarkers = []
      items.forEach(e => {
        e.selectedIconUrl = markerSelectedUrl
        e.normalIconUrl = iconUrl
        if (!(e.lng && e.lat)) return
        let marker = {
          ...e,
          type: type,
          hoverEvent: async () => {
            this.isFetchingMarkerData = true
            // this.markerData = await Customer.getStock({ customerId: marker.id, limit: 0 })
            this.isFetchingMarkerData = false

            return {}
          },
          mapMarker: new Marker(
            [e.lat, e.lng],
            {
              icon: new Icon({
                iconUrl: e.selected ? markerSelectedUrl : iconUrl,
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: e.selected ? [62, 60] : [42, 40],
                iconAnchor: e.selected ? [22, 61] : [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
                shadowAnchor: [5, 43] // the same for the shadow
              })
            })
        }
        tmpMarkers.push(marker)
      })
      return tmpMarkers
    }
  }

}
