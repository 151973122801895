import MapPage from '@/views/Map/'
import HomePage from '@/views/Home/home-page'

import LoginPage from '@/views/Auth/login-page'

import Main from '@/views/Layout'

import ProfilePage from '@/views/Profile/profile-page'
import SettingsPage from '@/views/Settings/settings-page'

import PageNotFound from '@/views/NotFound/not-found-page'
import gpsRoutes from './gps-routes'

export const routes = [
  // basic routes
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
    meta: { title: 'Login' }
  },
  { path: '*',
    component: PageNotFound,
    meta: { title: 'Oups...' }
  },
  // shared auth routes
  {
    path: '/',
    component: Main,
    meta: { },
    children: [
      {
        path: '',
        component: HomePage,
        name: 'home',
        meta: { title: 'Home', authorize: [] }
      },
      {
        path: '/maps',
        component: MapPage,
        name: 'maps',
        meta: { title: 'Maps', authorize: ['Map'] }
      },
      {
        path: '/profile',
        component: ProfilePage,
        name: 'profile',
        meta: { title: 'Profile', authorize: [] }
      },
      {
        path: '/settings',
        component: SettingsPage,
        name: 'settings',
        meta: { title: 'Settings', authorize: [] }
      },
      ...gpsRoutes
    ]
  }

]
