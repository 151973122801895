<template>
  <section class="section">
    <div class="container is-fluid">
      <b-field class="file">
        <b-upload v-model="file">
          <a class="button is-info">
            <b-icon icon="file-csv" />
            <span>Pick movement file</span>
          </a>
        </b-upload>
        <span v-if="file" class="file-name">
          {{ file.name }}
        </span>
      </b-field>
      <b-field>
        <b-checkbox v-model="launchProcess">
          Process it
        </b-checkbox>
      </b-field>
      <button :class="{ 'is-loading': isUploading }" :disabled="!file" class="button is-primary" @click="upload">
        <b-icon icon="upload" size="is-small" />
        <span>Upload</span>
      </button>
    </div>
  </section>
</template>

<script>

import { postSapShipping } from '@/services/v1/file'
export default {
  data: function () {
    return {
      isLoading: true,
      isFetching: false,
      file: null,
      launchProcess: false,
      isUploading: false
    }
  },
  mounted: async function () {
    this.isLoading = false
  },
  methods: {
    upload: async function () {
      this.isUploading = true
      try {
        let formData = new FormData()
        formData.append('file', this.file)
        formData.append('launchProcess', this.launchProcess)
        await postSapShipping(formData)
        this.file = null
        this.$buefy.toast.open({ type: 'is-primary', message: 'Done !', duration: 10000 })
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 10000 })
      }
      this.isUploading = false
    }
  }
}
</script>

<style>

</style>
