<template>
  <div class="section">
    <b-loading :active.sync="isLoading" is-full-page />
    <div class="container is-fluid">
      <div>
        <div class="field">
          <label class="label">
            Group(s)
          </label>
          <b-autocomplete
            v-model="groupName"
            :data="$store.state.groups"
            placeholder="Pick one"
            open-on-focus
            field="name"
            @select="selectGroup"
          />
        </div>
        <div v-if="selectedGroup" class="content">
          <h1 class="title">
            Files for {{ selectedGroup.name }}
          </h1>
          <div class="box">
            <select v-model="selectedCategory" class="select">
              <option v-for="c in categories" :key="c.id" :value="c">
                {{ c.name }}
              </option>
            </select>
            <h1 class="subtitle">
              {{ selectedCategory.name }}
            </h1>
            <div class="box">
              <transition-group name="fade" tag="div" class="field is-grouped is-grouped-multiline">
                <template v-if="!$lodash.isEmpty(filesForCategory(selectedCategory.id))">
                  <div v-for="file in filesForCategory(selectedCategory.id)" :key="file.id" class="control">
                    <div class="tags has-addons">
                      <span class="tag is-link">
                        {{ file.displayName }}
                      </span>
                      <a class="tag is-delete" @click="removeFile(file)" />
                    </div>
                  </div>
                </template>
                <div v-else :key="0" class="control">
                  <p>There is no file here.</p>
                </div>
              </transition-group>
            </div>

            <form @submit.prevent="uploadFile">
              <div class="field">
                <div class="control">
                  <div class="file has-name is-info">
                    <label class="file-label">
                      <ValidationProvider v-slot="{ errors }">
                        <input
                          class="file-input"
                          type="file"
                          name="resume"
                          required
                          @change="onFileChange"
                        >
                      </ValidationProvider>
                      <span class="file-cta">
                        <span class="file-icon">
                          <i class="fa fa-upload" />
                        </span>
                        <span class="file-label">
                          Upload
                        </span>
                      </span>
                      <span class="file-name">
                        {{ inputFile.name || 'Click to pick file' }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">
                  Name
                </label>
                <div class="control">
                  <ValidationProvider v-slot="{ errors }">
                    <input
                      v-model="inputDescription"
                      class="input"
                      type="text"
                      name="description"
                      placeholder="Description"
                      required
                    >
                  </ValidationProvider>
                </div>
              </div>
              <div class="field">
                <p class="control">
                  <button :class="{ 'is-loading' : isUploading}" class="button is-primary" type="submit">
                    <span class="icon is-small">
                      <i class="fa fa-check" />
                    </span>
                    <span>Submit</span>
                  </button>
                </p>
              </div>
            </form>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getCategories, addOne } from '@/services/v1/file'
import { getFiles, deleteFile } from '@/services/v1/group'

export default {
  data: function () {
    return {
      selectedCategory: {},
      isLoading: true,
      files: [],
      inputFile: '',
      inputDescription: '',
      isUploading: false,
      categories: [],
      groupName: '',
      selectedGroup: ''
    }
  },
  mounted: async function () {
    try {
      this.getGroups()
      this.categories = await getCategories()
      this.selectedCategory = this.categories[0] || {}
    } catch (error) {
      this.$buefy.toast.open({ message: error.message, type: 'is-danger' })
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions(['getGroups']),
    getFiles: async function () {
      this.files = await getFiles(this.selectedGroup.id)
    },
    selectGroup: async function (group) {
      this.selectedGroup = group
      await this.getFiles()
    },
    filesForCategory (categoryId) {
      return this.files.filter(e => e.fileType === categoryId)
    },
    uploadFile: async function (event) {
      const { id, name } = this.selectedCategory
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.isUploading = true
        let formData = new FormData()
        formData.append('file', this.inputFile)
        formData.append('fileCategoryId', id)
        formData.append('fileCategoryName', name)
        formData.append('customerId', this.selectedGroup.id)
        formData.append('description', this.inputDescription)

        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        try {
          await addOne(formData, config)
          this.inputFile = ''
          this.inputDescription = ''
          this.getFiles()
        } catch (e) {
          this.isUploading = false
        }
        this.isUploading = false
      }
    },
    onFileChange: async function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.inputFile = files[0]
    },
    removeFile: async function (file) {
      await deleteFile(this.selectedGroup.id, file.id)
      this.getFiles()
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
