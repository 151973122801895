<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="min-height: 750px">
      <header class="modal-card-head">
        <p class="modal-card-title">
          New report
        </p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <multiselect
            v-model="reportForm.selectedCustomer"
            :options="customers"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick ship to..."
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            track-by="name"
            style="min-width: 400px;"
            @input="getFormParameters"
          />
        </b-field>
        <b-field>
          <multiselect
            v-model="reportForm.selectedCustomerSoldTo"
            :options="customers"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick sold to..."
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            track-by="name"
            style="min-width: 400px;"
            @input="getFormParameters"
          />
        </b-field>
        <b-field>
          <b-datepicker
            v-model="reportForm.reportStartDate"
            name="date"
            icon="calendar-alt"
            placeholder="Start date"
            :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
          />
        </b-field>
        <b-field>
          <b-datepicker
            v-model="reportForm.reportEndDate"
            name="date"
            icon="calendar-alt"
            placeholder="End date"
            :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
          />
        </b-field>
        <b-field>
          <b-datepicker
            v-model="reportForm.historyStartDate"
            name="date"
            icon="calendar-alt"
            placeholder="History start date"
            :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
            :years-range="[-50, 50]"
          />
        </b-field>

        <b-field>
          <b-switch
            v-model="reportForm.translation"
            type="is-primary"
          >
            Translation
          </b-switch>
        </b-field>
        <!-- <b-field>
          <b-switch v-model="reportForm.soldTo">
            Sold to
          </b-switch>
        </b-field> -->
        <b-field>
          <b-switch v-model="reportForm.tabByYear">
            Tab by year
          </b-switch>
        </b-field>
        <b-field>
          <b-radio
            v-model="reportForm.cratesLoss"
            native-value="0"
            name="name"
          >
            No crates loss
          </b-radio>
          <b-radio
            v-model="reportForm.cratesLoss"
            native-value="1"
            name="name"
          >
            Month crates loss
          </b-radio>
          <b-radio
            v-model="reportForm.cratesLoss"
            native-value="2"
            name="name"
          >
            All crates loss
          </b-radio>
        </b-field>
        <b-field>
          <ReportFormColumn :depth="0" :data="columns" />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div class="is-flex is-align-content-center ml-auto">
          <b-checkbox v-model="isTest" class="mr-5">
            Test
          </b-checkbox>
          <b-button :type="isTest? 'is-primary' : 'is-info'" :icon-left="isTest? 'vial' : 'play'" native-type="submit" :disabled="isDisabled()">
            {{ isTest? 'Go testing' : 'Generate' }}
          </b-button>
        </div>
      </footer>
    </div>
  </form>
</template>
<script>
// import columns from './test.json'
import Report from '@/services/v2/report'

import ReportFormColumn from './report-form-column'
export default {
  components: {
    ReportFormColumn
  },
  props: {
    customers: {
      type: Array,
      required: true
    },
    reports: {
      type: Array,
      required: true
    },
    extendedReports: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      columns: [],
      isTest: false,
      reportForm: {
        selectedCustomer: null,
        selectedCustomerSoldTo: null,
        reportStartDate: null,
        reportEndDate: null,
        translation: false,
        cratesLoss: '0',
        tabByYear: false,
        historyStartDate: new Date('2000/01/01')
      }
    }
  },
  mounted: function () {
  },
  methods: {
    isDisabled () {
      if (this.reportForm.reportStartDate && this.reportForm.reportEndDate) {
        if (!this.reportForm.selectedCustomer && !this.reportForm.selectedCustomerSoldTo) return false
        let allValid
        if (this.reportForm.selectedCustomer && this.reportForm.selectedCustomerSoldTo) {
          allValid = this.reports.filter(c => c.validate === true && this.reportForm.selectedCustomer.id === c.customerCode && this.reportForm.selectedCustomerSoldTo.id === c.customerSoldTo)
        } else if (this.reportForm.selectedCustomer && !this.reportForm.selectedCustomerSoldTo) {
          allValid = this.reports.filter(c => c.validate === true && this.reportForm.selectedCustomer.id === c.customerCode && c.customerSoldTo === '-1')
        } else if (this.reportForm.selectedCustomerSoldTo && !this.reportForm.selectedCustomer) {
          allValid = this.reports.filter(c => c.validate === true && c.customerCode === '-1' && this.reportForm.selectedCustomerSoldTo.id === c.customerSoldTo)
        }
        const dayToFind = this.$dayjs(this.reportForm.reportStartDate).subtract(1, 'day').format('DDMMYYYY')
        const find = allValid.find(c => c.dateEnd === dayToFind)
        if (find === undefined) {
          return true
        }
        const isDuplicated = this.isProcessing()
        if (isDuplicated) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Same report is in process...', duration: 5000 })
          return true
        }
        return false
      }
      return true
    },
    isProcessing () {
      const customerCode = this.reportForm.selectedCustomer?.id || -1
      const customerSoldTo = this.reportForm.selectedCustomerSoldTo?.id || -1
      const sameReport = this.extendedReports.find(report => {
        return report.customerCode === customerCode &&
        report.customerSoldTo === customerSoldTo &&
        report.dateStart === this.$dayjs(this.reportForm.reportStartDate).format('DDMMYYYY') &&
        report.dateEnd === this.$dayjs(this.reportForm.reportEndDate).format('DDMMYYYY')
      })
      if (sameReport && sameReport.hasReport === false) {
        return true
      }
      return false
    },
    async getFormParameters (data) {
      let params
      if (this.reportForm.selectedCustomer && this.reportForm.selectedCustomerSoldTo) {
        params = await Report.getParameters({ customerCode: this.reportForm.selectedCustomer.id, customerSoldTo: this.reportForm.selectedCustomerSoldTo.id })
      } else if (this.reportForm.selectedCustomerSoldTo) {
        params = await Report.getParameters({ customerCode: '-1', customerSoldTo: this.reportForm.selectedCustomerSoldTo.id })
      } else if (this.reportForm.selectedCustomer) {
        params = await Report.getParameters({ customerCode: this.reportForm.selectedCustomer.id, customerSoldTo: '-1' })
      }
      this.columns = params?.columnData
      this.reportForm.translation = params?.otherParam[0]?.translation || false
      this.reportForm.cratesLoss = params?.otherParam[0]?.cratesLoss || '0'
      this.reportForm.tabByYear = params?.otherParam[0]?.tabByYear || false
    },
    async submit () {
      this.$parent.close()
      try {
        const submitable = {
          isTest: this.isTest,
          customerCode: this.reportForm.selectedCustomer?.id || '-1',
          customerSoldTo: this.reportForm.selectedCustomerSoldTo?.id || '-1',
          startDate: this.$dayjs(this.reportForm.reportStartDate).format('DDMMYYYY'),
          endDate: this.$dayjs(this.reportForm.reportEndDate).format('DDMMYYYY'),
          translation: this.reportForm.translation,
          columns: this.columns,
          cratesLoss: this.reportForm.cratesLoss,
          tabByYear: this.reportForm.tabByYear,
          historyStartDate: this.$dayjs(this.reportForm.historyStartDate).format('DDMMYYYY')
        }
        this.$emit('submit', submitable)
        return
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    }
  }
}
</script>

<style type="css" scoped>
  .modal-card-body {
    min-height: 50vh;
  }
</style>
