import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import { setupCache } from 'axios-cache-interceptor'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/powerbi'

const powerbi = axios.create({
  baseURL
})

interceptorsSetup(powerbi)

const powerbiCache = setupCache(powerbi)

export default class Powerbi {
  static async getPowerBiToken (powerBiID) {
    try {
      const res = await powerbi.get(baseURL + '/reports/' + powerBiID + '/generateToken')
      return res.data
    } catch {
      throw Error('Could not get report')
    }
  }
  static async getPowerBIReports () {
    try {
      const res = await powerbiCache.get(baseURL + '/reports')
      return res.data
    } catch {
      throw Error('Could not get reports names')
    }
  }
  static async getRoles (powerBiId) {
    try {
      const res = await powerbi.get(baseURL + '/reports/' + powerBiId + '/roles')
      return res.data
    } catch {
      throw Error('Could not get reports roles')
    }
  }
}
