<template>
  <section class="section">
    <div class="container">
      <div class="field">
        <b-button icon-left="arrow-left" @click="$router.go(-1)">
          Back
        </b-button>
      </div>
      <div v-if="fileImportProperty && partner" class="columns">
        <div class="column">
          <p class="title is-4">
            {{ partner.id }} - {{ partner.name }}
          </p>
          <b-loading v-model="isLoading" />
          <b-message v-show="hasWarning" type="is-warning" has-icon>
            Attention: this client has <strong>{{ pendingShippings.length }} </strong> pending shippments, please go to partner web portal:
            <br><a href="https://partner.fr-gps.com/shippingManagement">https://partner.fr-gps.com/shippingManagement</a>
          </b-message>
          <b-field grouped group-multiline>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Format
                </b-tag>
                <b-tag type="is-primary">
                  {{ fileImportProperty.dateFormat }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Delimiter
                </b-tag>
                <b-tag type="is-primary">
                  {{ fileImportProperty.delimiter }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Type
                </b-tag>
                <b-tag type="is-primary">
                  {{ fileImportProperty.table }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Parser
                </b-tag>
                <b-tag type="is-primary">
                  {{ fileImportProperty.parser }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Has header
                </b-tag>
                <b-tag type="is-primary">
                  {{ fileImportProperty.hasHeader }}
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field>
            <b-button
              icon-left="plus"
              type="is-info"
              size="is-small"
              @click="upload.isOpen = true"
            >
              Upload file
            </b-button>
          </b-field>
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-alpine"
            :row-data="files"
            :grid-options="gridOptions"
            :pagination="true"
            :animate-rows="true"
            :row-selection="'multiple'"
            :pagination-auto-page-size="true"
            :side-bar="sideBar"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            @grid-ready="gridReady"
            @first-data-rendered="firstDataRendered"
            @rowClicked="rowClicked"
          />
        </div>
      </div>
    </div>
    <b-modal scroll="keep" has-modal-card :active.sync="upload.isOpen" @close="upload.dropFiles = []">
      <div class="modal-card" style="min-height: 560px">
        <header class="modal-card-head">
          Upload files
        </header>
        <section class="modal-card-body">
          <b-field label="Files">
            <b-upload
              v-model="upload.dropFiles"
              :loading="upload.isLoading"
              multiple
              accept=".txt, .csv, .xlsx"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="file" size="is-large" />
                  </p>
                  <p>Drop your files here or click to select files</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <span v-for="(file, index) in upload.dropFiles" :key="index" class="tag is-info">
              {{ file.name }}
              <button class="delete is-small" type="button" @click="deleteDropFile(index)" />
            </span>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons">
            <b-button
              type="info"
              icon-left="upload"
              :disabled="!upload.dropFiles.length"
              :loading="upload.isLoading"
              @click="uploadFiles"
            >
              Upload
            </b-button>
          </div>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
import FileImport from '@/services/v2/fileImport'
import Customer from '@/services/v2/customer'
import ShippingManagement from '@/services/v2/shippingManagement'

export default {
  data () {
    return {
      hasWarning: false,
      pendingShippings: [],
      isLoading: false,
      partner: null,
      fipId: this.$route.params.fip_id,
      fileImportProperty: null,
      modules: AllModules,
      files: [],
      upload: {
        dropFiles: [],
        isLoading: false,
        isOpen: false
      },
      sideBar: {
        toolPanels: ['columns']
      },
      gridOptions: {
        enableBrowserTooltips: true,
        onFilterChanged: this.filterChanged,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true
        },
        columnDefs: [
          {
            headerName: 'File name',
            filter: 'agTextColumnFilter',
            field: 'filename',
            width: 500
          },
          {
            headerName: 'Lines',
            filter: 'agNumberColumnFilter',
            field: 'lines',
            width: 100
          },
          {
            headerName: 'Parser',
            filter: 'agTextColumnFilter',
            field: 'parser',
            width: 100
          },
          {
            headerName: 'Created at',
            filter: 'agDateColumnFilter',
            field: 'created',
            width: 190,
            sort: 'desc',
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleString()
            }
          },
          {
            headerName: 'Status',
            filter: 'agSetColumnFilter',
            field: 'process_status',
            width: 150,
            cellStyle: function (params) {
              if (params.value === 'Integrated') {
                return { backgroundColor: '#C8E6C9' }
              } else if (params.value === 'Deleted') {
                return { backgroundColor: '#bdbdbd' }
              } else if (params.value === 'Error') {
                return { backgroundColor: '#ffcdd2' }
              } else if (params.value === 'Pending') {
                return { backgroundColor: '#FFF9C4' }
              }
            },
            valueGetter: (params) => {
              if (params.data.process_status === 2) {
                return 'Error'
              } else if (params.data.process_status === 1) {
                return 'Integrated'
              } else if (params.data.process_status === 0) {
                return 'Pending'
              } else if (params.data.process_status === -1) {
                return 'Deleted'
              }
            }
          }
        ]
      }

    }
  },
  methods: {
    gridReady: async function () {
      await this.getData()
      await this.getWarning()
    },
    getData: async function () {
      this.gridOptions.api.showLoadingOverlay()
      if (this.fipId) {
        this.fileImportProperty = await FileImport.find(this.fipId)
        this.partner = await Customer.findOne({ customerId: this.fileImportProperty.customerId })
        this.files = await FileImport.getHistory(this.fipId)
      } else {
        this.files = await FileImport.getAllHistory()
      }
      const ediFilters = JSON.parse(localStorage.getItem(`ediFilters-${this.fileImportProperty.customerId}`) || null)
      setTimeout(() => {
        this.gridOptions.api.setFilterModel(ediFilters)
        this.gridOptions.api.refreshCells()
      }, 500)
    },
    getWarning: async function () {
      this.isLoading = true
      this.pendingShippings = await ShippingManagement.getShippings(this.fileImportProperty.customerId, {
        creationDate: this.$dayjs().add(-14, 'days').format('YYYY-MM-DD'),
        enduserId: null,
        process: null
      })
      if (this.pendingShippings.length) {
        this.hasWarning = true
      }
      this.isLoading = false
    },
    filterChanged () {
      localStorage.setItem(`ediFilters-${this.fileImportProperty.customerId}`, JSON.stringify(this.gridOptions.api.getFilterModel()))
    },
    firstDataRendered: function () {
      const instance = this.gridOptions.api.getFilterInstance('process_status')
      instance.resetFilterValues()
      instance.unselectValue('Deleted')
      instance.unselectValue('Integrated')
      instance.applyModel()
      this.gridOptions.api.onFilterChanged()
    },
    rowClicked: function (params) {
      this.$router.push({ name: 'export-details', params: { fih_id: params.data.id, fip_id: params.data.fip_id } })
    },
    async uploadFiles () {
      try {
        this.upload.isLoading = true
        await FileImport.upload(this.fipId, this.upload.dropFiles)
        this.upload.isOpen = false
        this.upload.dropFiles = []
        this.$buefy.notification.open({ message: `File(s) uploaded, it will be procesed in few seconds`, position: 'is-top', type: 'is-success' })
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
      this.upload.isLoading = false
    },
    deleteDropFile (index) {
      this.upload.dropFiles.splice(index, 1)
    }
  }
}
</script>
