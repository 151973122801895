
<!--
 eslint-disable vue/no-use-v-if-with-v-for */
eslint-disable vue/no-template-shadow
-->
<template>
  <div>
    <v-tour name="collectCalendarTour" :steps="tourSteps" :callbacks="customCallbacks">
      <template slot-scope="tour">
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template>
            <div slot="actions">
              <div>
                <b-button v-if="!tour.isFirst" class="tourBtn" type="is-small" @click="tour.previousStep">
                  Previous step
                </b-button>
                <b-button v-if="tour.currentStep < tourSteps.length -1" class="tourBtn" type="is-small" @click="tour.nextStep">
                  Next step
                </b-button>
                <b-button class="tourBtn" type="is-small" @click="tour.stop">
                  Finish tour
                </b-button>
              </div>
            </div>
          </template>
        </v-step>
      </template>
    </v-tour>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      customCallbacks: {
        onPreviousStep: this.previousStepCallback,
        onNextStep: this.nextStepCallback,
        onStop: this.stop
      },
      tour: null,
      btnsStepToHide: [],
      tourSteps: [
        {
          target: '#calendarTourContainer',
          header: {
            title: 'Helper'
          },
          content: `Hello, I'm going to teach you how to use this page. </br> If this appears : <span style="color: orange"><b>(ACTION)</b></span> </br> it means that you must perform the requested action to automatically proceed to the next step. However, you can always click on "next step" to continue.`
        },
        {
          target: '#calendarTourContainer',
          header: {
            title: 'Choose a group'
          },
          content: `First of all, you have to choose a collection group.`
        },
        {
          target: '#calendarTourContainer',
          content: `On this screen, you can see all the collections made and to be made. They are grouped by enduser, with one line for each material collected at plant.`
        },
        {
          target: '#calendar-step-status-color',
          header: {
            title: 'Collects status'
          },
          params: {
            placement: 'top'
          },
          content: `They are characterized by a color code, representing the status of each one.`
        },
        {
          target: '#calendar-step-grid',
          content: `The green part is there to indicate the information area. On the right is the weekly calendar, where all collections appear.`

        },
        {
          target: '.calendar-step-last-inv',
          header: {
            title: 'Columns'
          },
          content: `Here is the last inventory declared by the enduser.`

        },
        {
          target: '.calendar-step-theorical-stock',
          header: {
            title: 'Columns'
          },
          content: `Here you will find theorical stock, calculated from the last inventory and the last collections made after this inventory.`

        },
        {
          target: '#calendar-step-grid',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> To get information about a collection, right-click on a cell containing a number and then click on "details". This number corresponds to the number of crates collected per collection and per material, in a specified week.`
          // 7
        },
        {
          target: '#calendar-step-coll-screen',
          content: `On this screen, you should see one "card" per collection. All collections of the selected week are displayed here. If a collection is shown in green with dotted lines, it is a grouped collection.`
        },
        {
          target: '#calendar-step-coll-screen-back',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Now, let's see how to create a collection. Please go back.`
          // 9
        },
        {
          target: '#calendar-step-grid',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Right-click on a line (it doesn't need to contain a collection) and click on "create collect".`
          // 10
        },
        {
          target: '#calendar-step-cc-load-date',
          content: `First, fill in a loading date, and eventually some comments.`
        },
        {
          target: '#calendar-step-cc-materials',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Then you have to add from 1 to 5 materials. To do so, click here.`
          // 12
        },
        {
          target: '#calendar-step-cc-material-choose',
          content: `Choose a material`
        },
        {
          target: '#calendar-step-cc-qty',
          content: `A quantity`
        },
        {
          target: '#calendar-step-cc-add',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> And click on add`
          // 15
        },
        {
          target: '#calendar-step-cc-materials',
          content: `Repeat this process for each material to be added.`
        },
        {
          target: '#calendar-step-cc-submit',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Once all these fields have been filled in, you can validate (or go back).`
          // 17
        },
        {
          target: '#calendar-step-grid',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> You can also add closing dates for an enduser. This will have the effect of blocking the sending of automatic collection request emails. To do this, right-click on a line, then click on "closure".`
          // 18
        },
        {
          target: '#calendar-step-cd-screen',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> You will see here all the upcoming closing dates of the enduser, as well as a form to add them. You can go back.`
          // 19
        },
        {
          target: '#calendar-step-grid',
          content: `The closures are also displayed on this screen, in the form of a door. If a red door is displayed on a cell, it means that the Enduser will be closed one or more days of this week.`
        },
        {
          target: '#calendarTourContainer',
          content: `This tutorial is now over.`
        }
      ]
    }
  },
  mounted: function () {
    this.tour = this.$tours['collectCalendarTour']
  },
  methods: {
    stop (currentStep) {
      currentStep = -1
      this.$emit('tourStoped')
    },
    previousStepCallback (currentStep) {
      currentStep--
    },
    nextStepCallback (currentStep) {
      currentStep++
    },
    nextTourStep (action) {
      if (this.tour.currentStep === 7 && action === 'collectDetails') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 9 && action === 'collectDetailsBack') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 10 && action === 'createCollect') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 12 && action === 'addMaterial') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 15 && action === 'confirmMaterial') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 17 && action === 'submitCollect') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 18 && action === 'createClosure') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 19 && action === 'closureBack') { setTimeout(() => { this.tour.nextStep() }, 200) }
    }
  }
}
</script>

<style scoped>
.tourBtn {
    border: solid 1px white;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
}
.tourBtn:hover {
    color: white;
}

.orange {
  color: orange;
}
</style>
