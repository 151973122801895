<template>
  <div class="section">
    <div class="container is-fluid">
      <div class="columns is-multiline" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      passwordModalVisible: false,
      isSubmitting: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  }
}
</script>

<style>

</style>
