import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/devicesAdmin'

const devices = axios.create({
  baseURL
})

interceptorsSetup(devices)

export default class Device {
  static async getAll () {
    const { data: result } = await devices.get('/')
    return result
  }

  static async createByRigstrationCode (newDevice) {
    const { data: result } = await devices.post('/', newDevice)
    return result
  }

  static async delete (deviceUuid) {
    const { data: result } = await devices.delete(`/${deviceUuid}`)
    return result
  }

  static async edit ({ deviceUuid, serialNumber, plantId, customerId, producerId }) {
    const { data: result } = await devices.put(`/${deviceUuid}`, {
      plantId: plantId,
      serialNumber: serialNumber,
      customerId: customerId,
      producerId: producerId
    })
    return result
  }
}
