import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/customers'

const customers = axios.create({
  baseURL
})

interceptorsSetup(customers)

export default class Customer {
  static async getAll ({ additionalData, limit, offset }) {
    const result = await customers.get('/', {
      params: {
        additionalData, limit, offset
      }
    })
    return result.data
  }
  static async findOne ({ additionalData, customerId }) {
    const result = await customers.get(`/${customerId}/`, {
      params: {
        additionalData
      }
    })
    return result.data
  }
  static async getEndusers ({ customerId, additionalData, fromDate, toDate, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers`, {
      params: {
        additionalData, limit, offset, fromDate, toDate
      }
    })
    return result.data
  }

  static async downloadEndusers ({ customerId, additionalData, fromDate, toDate, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      params: {
        additionalData, limit, offset, fromDate, toDate
      }
    })
    return result.data
  }

  static async getMaterialsMovements ({ customerId, fromDate, toDate, enduserId, requirement, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers/${enduserId}/movements`, {
      params: {
        fromDate,
        toDate,
        requirement,
        limit,
        offset
      }
    })
    return result.data
  }

  static async downloadMaterialsMovements ({ customerId, fromDate, toDate, enduserId, requirement, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers/${enduserId}/movements`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      params: {
        fromDate, toDate, requirement
      }
    })
    return result.data
  }

  static async getStock ({ customerId, limit, offset }) {
    const result = await customers.get(`/${customerId}/stock`, {
      params: {
        limit, offset
      }
    })
    return result.data
  }

  static async downloadStock ({ customerId, limit, offset }) {
    const result = await customers.get(`/${customerId}/stock`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    return result.data
  }

  static async getEnduserStock ({ customerId, enduserId, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers/${enduserId}/stock`, {
      params: {
        limit, offset
      }
    })
    return result.data
  }

  static async downloadEnduserStock ({ customerId, enduserId }) {
    const result = await customers.get(`/${customerId}/endusers/${enduserId}/stock`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    return result.data
  }

  static async getTemplateForecast () {
    const result = await customers.get('/template-forecast', {
      responseType: 'blob'
    })
    return result
  }
}
