import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/actionsHistory'

const actionsHistory = axios.create({
  baseURL
})

interceptorsSetup(actionsHistory)

export default class ActionsHistory {
  static async getAll () {
    const result = await actionsHistory.get('/')
    return result.data
  }

  static async get (filters) {
    const result = await actionsHistory.get('/', { params: filters })
    return result.data
  }

  static async insert (data) {
    const result = await actionsHistory.post('/', data)
    return result.data
  }

  static async delete (id) {
    const result = await actionsHistory.delete(`/${id}`)
    return result.data
  }
}
