<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns">
        <div class="column is-3 has-text-centered">
          <b-field>
            <b-input v-model="search" placeholder="Search..." type="search" icon="search" />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-table
            :per-page="15"
            :data="fileteredEndusers"
            :selected.sync="selectedEnduser"
            :loading="isLoading"
            narrowed
            striped
            paginated
            @contextmenu="showMenu"
          >
            <template slot="header" slot-scope="props">
              <span>{{ props.column.label }}</span>
            </template>
            <template slot-scope="props">
              <b-table-column field="name" label="Customer name" width="100px" sortable>
                {{ props.row.name }}
              </b-table-column>
              <b-table-column field="country" label="Country" sortable>
                {{ props.row.country }}
              </b-table-column>
              <b-table-column field="city" label="City" sortable>
                {{ props.row.city }}
              </b-table-column>
              <b-table-column
                field="stock"
                label="Stock"
                sortable
                numeric
              >
                {{ props.row.stock }}
              </b-table-column>
              <b-table-column
                field="deemedLost"
                label="Lost"
                meta="Deemed as lost"
                sortable
                numeric
              >
                <span class="has-text-danger">
                  {{ props.row.deemedLost }}
                </span>
              </b-table-column>
              <b-table-column
                field="averageRentalDays"
                label="Average rental days"
                sortable
                numeric
              >
                {{ props.row.averageRentalDays }}
              </b-table-column>
            </template>
            <template slot="empty">
              <nothing-here />
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <context-menu ref="loadContextMenu">
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <a class="dropdown-item">
            <b-icon icon="box" type="is-warning" size="is-small" />
            <span>Stock</span>
          </a>
        </div>
      </div>
    </context-menu>
  </section>
</template>

<script>
import Customer from '@/services/v2/customer'
import contextMenu from '@/components/common/context-menu'

export default {
  components: {
    contextMenu
  },
  data: function () {
    return {
      Endusers: [],
      selectedEnduser: null,
      isLoading: false,
      search: ''
    }
  },
  computed: {
    formatedAttachFormDateField: function () {
      return this.$dayjs(this.attachForm.dateField).format('YYYY-MM-DD')
    },
    fileteredEndusers: function () {
      return this.Endusers.filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key]).toLowerCase().indexOf(this.search.toLowerCase()) > -1
        })
      })
    }
  },
  mounted: function () {
    try {
      this.getData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  methods: {
    getData: async function () {
      this.isLoading = true
      this.Endusers = await Customer.getEndusers({ customerId: this.$route.params.customer_id, additionalData: 'stock' })
      this.isLoading = false
    },
    showMenu: function (item, event) {
      this.selectedEnduser = item
      this.$refs.loadContextMenu.show(event)
    }
  }

}
</script>
