<template>
  <section class="section">
    <b-field :label="$t('pages.customer_forecast.customer')">
      <b-autocomplete
        v-model="selectedCustomerString"
        :data="filteredCustomer"
        open-on-focus
        field="displayName"
        icon="magnifying-glass"
        @select="selectCustomer"
      />
    </b-field>

    <b-field label="Plant" grouped>
      <b-autocomplete
        v-model="selectedPlant"
        expanded
        :data="filteredPlants"
        open-on-focus
        field="id"
        placeholder="plantId"
        :disabled="isEditing"
        @typing="getFilteredPlants"
      />
    </b-field>

    <b-field label="Select a month">
      <b-datepicker
        v-model="selectedMonth"
        type="month"
        placeholder="Click to select..."
        icon="calendar"
        trap-focus
      />
    </b-field>

    <div class="buttons level">
      <div class="level-left">
        <b-button type="is-info" icon-left="magnifying-glass" @click="search">
          Search
        </b-button>
        <b-button icon-left="xmark" @click="clearFilter">
          Clear
        </b-button>
        <b-button
          :loading="loading"
          icon-left="sync"
          :disabled="loading"
          @click="refresh"
        >
          {{ $t('pages.pickup_management.pickup_management.refresh') }}
        </b-button>
        <b-button icon-left="arrow-up-from-bracket" @click="download">
          Export
        </b-button>
      </div>
      <b-button
        class="level-right"
        type="is-primary"
        icon-left="plus"
        :disabled="!selectedCustomerString"
        @click="addForecast = true"
      >
        Add forecast
      </b-button>
    </div>
    <div class="columns">
      <div class="column is-full">
        <ag-grid-vue
          style="width: 100%; height: 600px;"
          class="ag-theme-balham"
          :row-data="forecast"
          :pagination="true"
          :pagination-auto-page-size="true"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="multiple"
          :grid-options="gridOptions"
          :get-context-menu-items="getContextMenuItems"
          :overlay-no-rows-template="overlayNoRowsTemplate"
        />
      </div>
    </div>

    <b-modal :active.sync="addForecast" scroll="keep" has-modal-card>
      <ForecastForm :plants="plants" :customer="selectedCustomer.id" :cur-forecasts="curForcasts" @on-close="close" />
    </b-modal>

    <b-modal :active.sync="isEditing" scroll="keep" has-modal-card>
      <ForecastForm
        :customer="editForm.customer"
        :plants="plants"
        :edit-form="editForm"
        :is-editing="isEditing"
        @on-close="close"
      />
    </b-modal>
  </section>
</template>

<script>
import Forecast from '@/services/v2/forecast'
import Customer from '@/services/v2/customer'
import Plant from '@/services/v2/plant'
import ForecastForm from './forecast-form'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { saveAs } from 'file-saver'

export default {
  components: {
    ForecastForm
  },
  data () {
    return {
      modules: AllModules,
      timeLineChange: '',
      selectedCustomerString: '',
      selectedCustomer: {},
      selectedMonth: null,
      selectedPlant: null,
      filteredPlants: [],
      addForecast: false,
      isEditing: false,
      editForm: {},
      customers: [],
      plants: [],
      forecast: [],
      curForcasts: [],
      loading: false,
      overlayNoRowsTemplate: '<div class="level"><p class="level-item has-text-centered"> Please choose criteria to start with, to consult all click Search </p></div>',
      gridOptions: {
        defaultColDef: {
          flex: 1,
          sortable: true,
          filter: true,
          floatingFilter: true,
          resizable: true
        },
        columnDefs: [
          {
            headerName: 'Date',
            valueGetter: (params) => {
              return params.data && params.data.date ? this.$dayjs(params.data.date.toString()).format('MM/YYYY') : ''
            },
            maxWidth: 180,
            filter: 'agSetColumnFilter',
            filterParams: {
              treeList: true
            }
          },
          {
            headerName: 'Customer code',
            field: 'customer',
            filter: 'agSetColumnFilter',
            filterParams: {
              treeList: true
            }
          },
          {
            headerName: 'Customer name',
            filter: 'agSetColumnFilter',
            valueGetter: (params) => {
              const customer = this.customers.find(el => el.id === params.data.customer)
              return customer.name
            },
            filterParams: {
              treeList: true
            }
          },
          {
            headerName: 'Plant',
            field: 'plant',
            filter: 'agSetColumnFilter',
            filterParams: {
              treeList: true
            }
          },
          {
            headerName: 'Material',
            field: 'material',
            filter: 'agSetColumnFilter',
            filterParams: {
              treeList: true
            }
          },
          {
            headerName: 'Quantity',
            field: 'sum',
            floatingFilter: false
          }
        ]
      }
    }
  },
  computed: {
    filteredCustomer () {
      return this.customers.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.selectedCustomerString.toLowerCase()) >= 0 ||
          option.id
            .toString()
            .toLowerCase()
            .indexOf(this.selectedCustomerString.toLowerCase()) >= 0
        )
      })
    }
  },
  watch: {
    selectedCustomer: async function () {
      localStorage.removeItem('filter')
      this.selectedPlant = null
      this.selectedMonth = null
      this.forecast = await Forecast.getForecast({ customerId: this.selectedCustomer.id })
      this.setCurForecasts()
    }
  },
  mounted: async function () {
    this.customers = await Customer.getAll({})
    this.customers.map(el => (el.displayName = el.id + ' - ' + el.name))
    this.plants = await Plant.getAll()
  },
  methods: {
    selectCustomer: function (el) {
      if (el === null) {
        this.selectedCustomer = {}
        return
      }
      this.selectedCustomer = el
    },
    getFilteredPlants: function (text) {
      this.filteredPlants = this.plants.filter(option => {
        return option.id
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    setCurForecasts: async function () {
      this.curForcasts = await Forecast.getForecast({ customerId: this.selectedCustomer.id })
    },
    loadForecast: async function () {
      this.loading = true
      const query = JSON.parse(localStorage.getItem('filter'))
      if (query) this.forecast = await Forecast.getForecast(query)
      if (!query) this.forecast = await Forecast.getForecast({ customerId: this.selectedCustomer.id })
      this.loading = false
    },
    async refresh () {
      await this.loadForecast()
      if (this.selectedCustomerString) this.setCurForecasts()
      this.gridOptions.api.refreshCells()
    },
    async close () {
      this.addForecast = false
      if (this.isEditing) this.isEditing = false
      await this.refresh()
    },
    async search () {
      this.loading = true
      const query = {
        plant: this.selectedPlant || '',
        customerId: this.selectedCustomer.id || '',
        date: this.selectedMonth ? this.$dayjs(this.selectedMonth.toString()).format('YYYY-MM') : ''
      }
      localStorage.setItem('filter', JSON.stringify(query))
      const response = await Forecast.getForecast(query)
      this.forecast = response
      this.loading = false
    },
    async download () {
      const result = await Forecast.getRawData()
      if (!result.length) return
      const columns = Object.keys(result[0]).join(';')
      const values = result.map(e => Object.values(e).join(';'))
      const final = [columns, ...values]
      const blob = new Blob([final.join('\n')], { type: 'text/csv' })
      saveAs(blob, 'forecastExport.csv')
    },
    clearFilter () {
      this.selectedCustomer = []
      this.selectedCustomerString = ''
      this.selectedMonth = null
      this.selectedPlant = null
      localStorage.removeItem('filter')
    },
    getContextMenuItems (params) {
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      const customMenu = []
      customMenu.push({
        name: 'Edit',
        action: async () => {
          this.editForm.customer = params.node.data.customer
          this.editForm.material = params.node.data.material
          this.editForm.quantity = +params.node.data.sum
          this.editForm.plant = params.node.data.plant
          this.editForm.date = this.$dayjs(params.node.data.date)
          this.isEditing = true
        },
        icon: '<i class="fa-solid fa-pen-to-square"></i>'
      })

      customMenu.push({
        name: 'Delete',
        action: async () => {
          await Forecast.deleteForecastByMaterial(params.node.data.customer, new Date(params.node.data.date).getMonth() + 1, params.node.data.plant, params.node.data.material)
          this.$buefy.toast.open({
            message: 'Successfully deleted',
            type: 'is-primary' })
          await this.refresh()
        },
        icon: '<i class="fa-solid fa-trash"></i>'
      })

      return [...customMenu, ...genericMenu]
    }
  }
}

</script>
