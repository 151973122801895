<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import Vue from 'vue'

import Navbar from './components/layout/navbar'
import Header from './components/layout/header'
import Footer from './components/layout/footer'
import NothingHere from './components/common/nothing-here'
import SideBar from './components/layout/sidebar'
import { AgGridVue } from '@ag-grid-community/vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import LightTimeline from 'vue-light-timeline'

Vue.component('sidebar', SideBar)
Vue.component('navbar', Navbar)
Vue.component('Header', Header)
Vue.component('dash-footer', Footer)
Vue.component('nothing-here', NothingHere)
Vue.component('ag-grid-vue', AgGridVue)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('multiselect', Multiselect)
Vue.use(LightTimeline)

export default {
  mounted: function () {
    this.sockets.subscribe('processed::report', (data) => {
      const notif = this.$store.state.generateReportNotification
      if (notif) this.$store.state.generateReportNotification.close()

      this.$buefy.notification.open({
        message: 'The report was successfully generated 🎉',
        closable: true,
        indefinite: true,
        type: 'is-success'
      })
    })

    this.sockets.subscribe('exception::report', (data) => {
      const notif = this.$store.state.generateReportNotification
      if (notif) this.$store.state.generateReportNotification.close()

      this.$buefy.notification.open({
        message: `An error occured while generating the report 😟`,
        closable: true,
        indefinite: true,
        type: 'is-danger'
      })
    })
  }
}
</script>
