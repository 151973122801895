import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/shipments'

const shipments = axios.create({
  baseURL
})

interceptorsSetup(shipments)

export default class Plant {
  static async getMovements (vakey) {
    const result = await shipments.get(`/${vakey}/movements`)
    return result.data
  }

  static async postMovements (vakey, movements, ignoreProcess) {
    const result = await shipments.post(`/${vakey}/movements`, {
      movements: movements,
      ignoreProcess: ignoreProcess
    })
    return result.data
  }

  static async updateStatus (vakey, status) {
    const result = await shipments.put(`/${vakey}/`, {
      status
    })
    return result.data
  }

  static async notify (vakey) {
    const result = await shipments.post(`/${vakey}/notify`, {
      status
    })
    return result.data
  }
}
