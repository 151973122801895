<template>
  <div class="modal-card" :style="{minHeight: 80 + 'vh', minWidth: 50 + 'vw'}">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Attach material by quantity
      </p>
    </header>
    <section class="modal-card-body">
      <form id="qtyForm" @submit.prevent="submitQtyForm">
        <b-field :label="$t('pages.loads.receipt_date')">
          <b-datetimepicker
            v-model="matForm.date"
            placeholder="Click to select..."
            icon="calendar"
            :max-datetime="maxDatetime"
            :min-datetime="minDatetime"
            horizontal-time-picker
            required
            :disabled="disbaleCalendar"
          >
            <template #left>
              <b-button
                label="Now"
                type="is-primary"
                icon-left="clock"
                @click="matForm.date = new Date()"
              />
            </template>
          </b-datetimepicker>
        </b-field>
        <b-field label="Material">
          <b-select v-model="qtyForm.material" placeholder="select a material">
            <option
              v-for="material in availableMaterials"
              :key="material.ref"
              :value="material.ref"
            >
              {{ material.ref }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Quantity">
          <div class="is-flex is-align-content-center">
            <b-input v-model="qtyForm.qty" type="number" min="0" />
            <b-button type="is-info" class="ml-1" :disabled="!qtyForm.material" @click="calMaxQty(qtyForm.material)">
              max
            </b-button>
          </div>
        </b-field>
      </form>

      <b-table v-if="showMatForm" :data="matForm.data" class="mt-5">
        <b-table-column field="dateField" label="Date time">
          {{ $dayjs(matForm.date).format('YYYY-MM-DD HH:mm:ss') }}
        </b-table-column>
        <b-table-column v-slot="props" field="material" label="Material">
          {{ props.row.material }}
        </b-table-column>
        <b-table-column v-slot="props" field="qty" label="Quantity">
          {{ props.row.qty }}
        </b-table-column>
        <b-table-column v-slot="props" label="Action">
          <b-button icon-left="trash-can" @click="deleteRow(props.row.material)" />
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <b-field class="ml-auto buttons">
        <b-button :disabled="disableAddButton" @click="add">
          Add
        </b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          form="qtyForm"
          :loading="isSubmitting"
          :disabled="disabledSubmit"
        >
          {{ $t('global.submit') }}
        </b-button>
      </b-field>
    </footer>
  </div>
</template>

<script>
import Material from '@/services/v1/material'
// eslint-disable-next-line no-unused-vars
import Shipment from '@/services/v2/shipment'

export default {
  props: {
    shipment: {
      default: () => {},
      type: Object
    },
    maxDatetime: {
      default: () => {},
      type: Date
    },
    minDatetime: {
      default: () => {},
      type: Date
    }
  },
  data: function () {
    return {
      isSubmitting: false,
      showMatForm: false,
      qtyForm: {
        material: '',
        qty: null
      },
      materials: [],
      availableMaterials: [],
      matForm: {
        date: null,
        data: []
      }
    }
  },
  computed: {
    disableAddButton () {
      if (!this.matForm.date || !this.qtyForm.material || !this.qtyForm.qty) {
        return true
      }
      return false
    },
    disbaleCalendar () {
      if (this.matForm.data.length) {
        return true
      }
      return false
    },
    disabledSubmit () {
      if (this.matForm.data.length) {
        return false
      }
      return true
    }

  },
  async mounted () {
    await this.getMaterials()
    this.calAvailableMat()
  },
  methods: {
    async getMaterials () {
      this.isLoading = true
      const result = await Material.getMaterials()
      this.materials = result
      this.isLoading = false
    },
    calAvailableMat () {
      if (!this.shipment) return
      const { loads } = this.shipment
      this.availableMaterials = this.materials.filter(m => loads.some(load => load.material === m.ref && !this.matForm.data.some(mat => mat.material === m.ref)))
    },
    calMaxQty (material) {
      const { loads } = this.shipment
      const load = loads.find(load => load.material === material)
      this.qtyForm.qty = load.quantity
    },
    add () {
      this.matForm.data.push({ ...this.qtyForm })
      this.showMatForm = true
      this.calAvailableMat()
      this.resetQtyForm()
    },
    resetQtyForm () {
      this.qtyForm.material = ''
      this.qtyForm.qty = null
    },
    deleteRow (material) {
      const index = this.matForm.data.findIndex(m => m.material === material)
      this.matForm.data.splice(index, 1)
      this.calAvailableMat()
      if (!this.matForm.data.length) {
        this.matForm.date = null
        this.showMatForm = false
      }
    },
    isTimeValid (selectedDate) {
      return !(this.$dayjs(selectedDate).format('HHmmss') === '000000')
    },
    isQtyValid () {
      const { loads } = this.shipment
      const { data } = this.matForm
      return loads.every(l => {
        const matching = data.find(d => d.material === l.material)
        return matching && matching.qty === l.quantity
      })
    },
    async submitQtyForm () {
      this.isSubmitting = true
      try {
        if (!this.matForm.date || !this.isTimeValid(this.matForm.date)) {
          throw Error('Date missing or invalid')
        }

        if (['DEL', 'TRFO'].includes(this.shipment.requirement) &&
          (!this.isQtyValid() && !this.shipment.quantityControl)) {
          throw Error(this.$t('pages.loads.invalid_quantity'))
        }

        const movements = this.matForm.data.map(e => {
          return {
            'GPS_QTY_MASS': e.qty.toString(),
            'GPS_MATERIAL': e.material,
            'GPS_DELIVERY_NUMBER': this.shipment.deliveryNumber || '',
            'ST_TIMESTAMP': this.$dayjs(this.matForm.date).utc().format('YYYYMMDDHHmmss'),
            'GPS_TIMESTAMP': this.$dayjs(this.matForm.date).utc().format('YYYYMMDDHHmmss'),
            'GPS_PLANT': this.shipment.plantId,
            'GPS_STORLOC_IN': this.shipment.storageLocation || '',
            'GPS_REQUIREMENT': this.shipment.requirement === 'COL' ? 'COLW' : this.shipment.requirement,
            'GPS_COLLECT_ID': this.shipment.collectNumber || '',
            'GPS_BARCODE': '',
            'GPS_TYP_NC': ''
          }
        })
        await Shipment.postMovements(this.shipment.vakey, movements, false)
        await Shipment.updateStatus(this.shipment.vakey, 'T')
        this.$emit('on-close')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
      this.isSubmitting = false
    }
  }
}
</script>
