<template>
  <div class="modal-card" style="width: auto">
    <div class="header">
      <h1 class="title">
        <img :src="`https://flagcdn.com/w80/${mails.countryCode.toLowerCase()}.png`" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">
        {{ mails.enduserCode + ' - ' + mails.enduserName }}
      </h1>
    </div>
    <div class="infos">
      <div class="flex-column">
        <span>{{ $t('pages.pickup_management.stats.interval') }}</span>
        <span>{{ mailsInterval }}</span>
      </div>
    </div>
    <div style="margin: 50px">
      <button class="button is-warning is-outlined is-small" @click="close">
        <b-icon icon="chevron-left" />
        <span>{{ $t('global.back') }}</span>
      </button>
    </div>
    <div class="flex-column">
      <i>
        {{ $t('pages.pickup_management.stats.period') }} {{ $dayjs(rangeDates[0]).format('DD/MM/YYYY') }} - {{ $dayjs(rangeDates[1]).format('DD/MM/YYYY') }}
      </i>
    </div>
    <div class="container body">
      <div class="flex-column margin">
        <span class="subtitle">{{ $t('pages.pickup_management.stats.response_rate') }}</span>
        <span class="stat" :style="{color: getResponseRateClass()}">{{ responseRate.value + responseRate.suffix }}</span>
      </div>
      <div class="flex-column margin">
        <span class="subtitle">{{ $t('pages.pickup_management.stats.response_time') }}</span>
        <span class="stat" :style="{color: getResponseTimeClass()}">{{ responseTime.value + responseTime.suffix }}</span>
      </div>
      <div class="flex-column margin">
        <span class="subtitle">{{ $t('pages.pickup_management.stats.declared_quantity') }}</span>
        <span class="stat blue">{{ declaredQty }}</span>
      </div>
      <div class="flex-column margin">
        <span class="subtitle">{{ $t('pages.pickup_management.stats.mail_sent') }}</span>
        <span class="stat blue">{{ mailsCount }}</span>
      </div>
      <div class="flex-column margin">
        <span class="subtitle">{{ $t('pages.pickup_management.stats.reminder_sent') }}</span>
        <span class="stat" :style="{color: getRemindersClass()}">{{ remindersCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    mails: {
      required: true,
      type: Object
    },
    rangeDates: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      responseRate: { value: null },
      responseTime: { value: null },
      declaredQty: null,
      remindersCount: null,
      mailsCount: null,
      mailsInterval: this.$t('global.not_specified'),
      colorsArray:
['#C23616', '#C74016', '#CC4B17', '#D15618', '#D76019', '#DC6B1A', '#E1761B', '#E7801C', '#EC8B1D', '#F1961E', '#F7A11F', '#E5A320', '#D3A622', '#C1A924', '#AFAC26', '#9DAF28', '#8BB12A', '#79B42C', '#67B72E', '#55BA30', '#44BD32']
    }
  },
  mounted: function () {
    try {
      this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
    }
  },
  methods: {
    loadData: async function () {
      if (this.mails.interval) {
        this.mailsInterval = this.mails.interval + ' days'
      }
      this.responseRate = this.getResponseRate()
      this.responseTime = this.getResponseTime()
      this.declaredQty = this.getAverageQuantities()
      this.remindersCount = this.getRemindersCount()
      this.mailsCount = this.getMailsCount()
    },
    getResponseRate () {
      if (!this.mails.mails.find(m => m.declarationId)) {
        return {
          value: '',
          suffix: this.$t('pages.pickup_management.stats.no_data_yet')
        }
      }
      let i = 0
      let totalReminders = 0
      let totalDeclaration = 0

      let stop = false
      while (!stop) {
        const mail = this.mails.mails[i]
        totalReminders += parseInt(mail.relaunchCount, 10)
        totalDeclaration += parseInt(mail.declarationId ? 1 : 0, 10)
        if (i >= 180 || i === this.mails.mails.length - 1) {
          stop = true
        }
        i++
      }
      return {
        value: Math.trunc((totalDeclaration / totalReminders) * 100),
        suffix: '%'
      }
    },
    getMailsCount () {
      return this.mails.mails.filter(m => !m.manualDeclaration).length
    },
    getResponseTime () {
      let sum = 0
      if (!this.mails.mails.find(m => m.declarationId)) {
        return {
          value: '',
          suffix: this.$t('pages.pickup_management.stats.no_data_yet')
        }
      }

      for (let i = this.mails.mails.length - 1; i >= 0; i--) {
        const m = this.mails.mails[i]
        if (m.firstSendingDate) {
          if (m.declarationDate) {
            sum += this.$dayjs(m.declarationDate).diff(this.$dayjs(m.firstSendingDate))
          } else {
            if (i === 0) {
              sum += this.$dayjs().diff(this.$dayjs(m.firstSendingDate))
            } else {
              sum += this.$dayjs(this.mails.mails[i - 1].firstSendingDate).diff(this.$dayjs(m.firstSendingDate))
            }
          }
        }
      }
      const hours = Math.trunc(sum / 1000 / 60 / 60 / this.mails.mails.length)
      const days = Math.trunc(sum / 1000 / 60 / 60 / 24 / this.mails.mails.length)
      return hours <= 24 ? {
        value: hours,
        suffix: ' ' + hours === 1 ? this.$t('global.hour') : this.$t('global.hours')
      } : {
        value: days,
        suffix: ' ' + days === 1 ? this.$t('global.day') : this.$t('global.days')
      }
    },
    getAverageQuantities () {
      if (!this.mails.mails.find(m => m.declarationId)) {
        return this.$t('pages.pickup_management.stats.no_data_yet')
      }
      let sum = 0
      if (this.mails.mails.length > 0) {
        this.mails.mails.map(m => {
          if (m.declarationQuantityTotal) {
            sum += parseInt(m.declarationQuantityTotal)
          }
        })
        return Math.trunc(sum / this.mails.mails.length)
      } else {
        return 0
      }
    },
    getRemindersCount () {
      let sum = 0
      if (!this.mails.mails.find(m => m.declarationId)) {
        return this.$t('pages.pickup_management.stats.no_data_yet')
      }
      this.mails.mails.map(m => {
        if (m.relaunchCount > 1) {
          sum += parseInt(m.relaunchCount)
        }
      })
      return sum
    },
    close () {
      this.$emit('close')
    },
    getResponseRateClass () {
      if (this.remindersCount === this.$t('pages.pickup_management.stats.no_data_yet')) {
        return '#1B1464'
      }
      return this.colorsArray[Math.round(this.responseRate.value / 5)]
    },
    getResponseTimeClass () {
      if (this.remindersCount === this.$t('pages.pickup_management.stats.no_data_yet')) {
        return '#1B1464'
      }
      if (this.responseTime.suffix === (' ' + this.$t('global.days'))) {
        let interval = this.mails.interval || 7
        let c = Math.round(this.responseTime.value / interval * 100 / 5)
        if (c > this.colorsArray.length - 1) {
          c = this.colorsArray.length - 1
        }
        /*
        *   colors array : 0 = red -- 10 = orange -- 20 = green
        *   in some cases, we want 0 = green and 20 = red
        *   this formula inverts the number to half the size of the colors array (10)
        *   so 11 becomes 9, 16 becomes 4 ...
        */
        const reverse = c - ((c * 2) - (this.colorsArray.length - 1))
        return this.colorsArray[reverse]
      } else {
        return this.colorsArray[20]
      }
    },
    getRemindersClass () {
      if (this.remindersCount === this.$t('pages.pickup_management.stats.no_data_yet')) {
        return '#1B1464'
      }
      /*
      *   x = reminders count
      *   C1 = max reminders count possible (2 reminders max for one mail => mails.length * 2)
      *   C2 = perntage factor of colors array (colors array length = 20 => 100(%) / 20 = 5(C2))
      *   formula : x / C1 * C2
      *   => gives a number between 0 and 20 (colors array length) which is associated to a color (percentage coloration)
      */
      const c = Math.round(this.remindersCount / (this.mails.mails.length * 2) * 100 / 5)
      /*
      *   colors array : 0 = red -- 10 = orange -- 20 = green
      *   in some cases, we want 0 = green and 20 = red
      *   this formula inverts the number to half the size of the colors array (10)
      *   so 11 becomes 9, 16 becomes 4 ...
      */
      const reverse = c - ((c * 2) - (this.colorsArray.length - 1))
      return this.colorsArray[reverse]
    }
  }

}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: 100px;
  text-align: center;
}

.body {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat {
  /* color: #6ABF9E; */
  font-size: 50px;
  font-weight: 600;
}

.infos {
  /* background-color: rgba(106, 191, 158, 0.623); */
  color: white;
  font-size: 20px;
  padding: 10px;
  background: linear-gradient(90deg, rgba(9,121,112,1) 0%, rgba(106,191,158,1) 100%);

}

.margin {
  margin-bottom: 30px;
  margin-right: 60px;
  margin-left: 60px;
}

.blue {
  color: #1B1464
}
/*
.green {
  color: #6ABF9E;
}

.red {
  color: #d63031;
}

.orange {
 color: #fdcb6e;
} */
</style>
