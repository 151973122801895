<template>
  <section id="shipping-instructions" class="section">
    <!-- <div class="columns">
      <div class="column is-narrow">
        <b-button type="is-info" icon-left="folder-plus" @click="creating.isOpened = true">
          Create new SI
        </b-button>
      </div>
    </div> -->

    <ag-grid-vue
      style="width: 100%; height: 700px;"
      class="ag-theme-alpine"
      :row-data="formattedShippingInstructions"
      :grid-options="gridOptions"
      :get-context-menu-items="getContextMenuItems"
      :pagination="true"
      :animate-rows="true"
      :row-selection="'multiple'"
      :pagination-auto-page-size="true"
      :side-bar="['columns']"
      :multi-sort-key="'ctrl'"
      :modules="modules"
      @grid-ready="gridReady"
    />

    <b-modal scroll="keep" has-modal-card :active.sync="creating.isOpened">
      <div class="modal-card">
        <SiForm
          :is-submitting="false"
          :populate-with="creating.shippingInstruction"
          @submit="editShippingInstruction"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
import { columns } from './columns.js'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import SiForm from './form'

import {
  ShippingInstructionDelete,
  ShippingInstructionCreateOrder,
  ShippingInstructionEdit,
  ShippingInstructionUpdateDB
} from '@/graphql/shippingInstruction'

import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'

export default {
  components: {
    SiForm
  },
  mixins: [sortAndFilterMixins],
  data: () => {
    return {
      modules: AllModules,
      gridOptions: {
        cacheBlockSize: 12,
        rowModelType: 'serverSide',
        serverSideStoreType: 'partial',
        paginationPageSize: 12,
        enableBrowserTooltips: true,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true
        },
        autoGroupColumnDef: {
          sort: 'desc'
        },
        columnDefs: columns
      },
      creating: {
        isLoading: false,
        isOpened: false,
        selectedSI: null
      },
      shippingInstructions: []
    }
  },

  computed: {
    formattedShippingInstructions () {
      return this.shippingInstructions.map(item => {
        return {
          formattedEtd: item.feeder_etd
            ? this.$dayjs(item.feeder_etd).format('DD/MM/YYYY')
            : null,
          formattedSiDate: this.$dayjs(item.si_date).format('DD/MM/YYYY'),
          historyDateFormatted: item.history_created
            ? this.$dayjs(item.history_created).format('DD/MM/YYYY')
            : null,
          showDate: item.history_created
            ? this.$dayjs(item.history_created).format('DD/MM/YYYY')
            : this.$dayjs(item.si_date).format('DD/MM/YYYY'),
          ...item
        }
      })
    }
  },
  async mounted () {
    await this.$apollo.mutate({
      mutation: ShippingInstructionUpdateDB
    })
  },
  methods: {
    gridReady: async function (params) {
      const dataSource = {
        getRows: async getRowParams => {
          const groupSummary = this.getGroups(getRowParams.request)

          let allcolumns = groupSummary
            .filter(e => e.isHere === true)
            .map(e => e.column.toString())
          if (!allcolumns.length) {
            allcolumns = this.gridOptions.columnDefs.map(colDef => colDef.field)
            allcolumns.push('id')
          }

          const builder = this.$gql.query({
            operation: 'shippingInstruction',
            fields: [
              'totalCount',
              {
                edges: [
                  {
                    node: [...allcolumns]
                  }
                ]
              }
            ],
            variables: {
              skip: { type: 'Int', value: getRowParams.request.startRow },
              limit: { type: 'Int', value: getRowParams.request.endRow },
              sort: {
                type: '[sortInputshippingInstruction]',
                value: this.getSorts(getRowParams.request)
              },
              filter: {
                type: 'filterInputshippingInstruction',
                value: this.getFilters(getRowParams.request)
              },
              group: {
                type: 'Boolean',
                value: !!groupSummary.find(e => e.isHere === true)
              }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`
              ${builder.query}
            `,
            variables: builder.variables
          })

          const shippingInstructionsResult = result.data.shippingInstruction
          this.totalCount = shippingInstructionsResult.totalCount
          this.shippingInstructions = shippingInstructionsResult.edges.map(
            e => e.node
          )

          getRowParams.successCallback(
            this.shippingInstructions,
            this.totalCount
          )
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getContextMenuItems (params) {
      const selected = params?.node?.data

      // const genericMenu = ['copy', 'copyWithHeaders', 'separator', 'export']
      const genericMenu = []

      if (selected) {
        if (selected.status !== 'C' && selected.status !== 'V') {
          genericMenu.push(
            // 'separator',
            {
              name: 'Create order',
              action: async () => {
                await this.createOrder(selected)
              },
              icon: '<i class="fas fa-fw fa-check" ></i>'
            },
            // {
            //   name: 'New version',
            //   action: async () => {
            //     await this.newVersion(selected)
            //   },
            //   icon: '<i class="fas fa-fw fa-plus" ></i>'
            // }
            {
              name: 'Edit',
              action: () => {
                this.edit(selected)
              },
              icon: '<i class="fas fa-fw fa-edit" ></i>'
            }
          )
          // if (selected.edi_import === null) {
          //   genericMenu.push({
          //     name: 'Delete',
          //     action: async () => {
          //       await this.delete(selected)
          //     },
          //     icon: '<i class="fas fa-fw fa-trash-alt" ></i>'
          //   })
          // }
        }
      }

      return genericMenu
    },
    async editShippingInstruction (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      await this.$apollo.mutate({
        mutation: ShippingInstructionEdit,
        variables: {
          id: shippingInstruction.id,
          departurePlantId: shippingInstruction.departurePlantId
        }
      })
      this.gridOptions.api.refreshServerSideStore()
      this.gridOptions.api.hideOverlay()
      this.creating.isOpened = false
    },
    async createOrder (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      this.$apollo
        .mutate({
          mutation: ShippingInstructionCreateOrder,
          variables: {
            id: shippingInstruction.id,
            departurePlantId: shippingInstruction.departurePlantId
          }
        })
        .then(res => {
          if (res.data.shippingInstructionCreateOrder === true) {
            this.$buefy.notification.open({
              type: 'is-success',
              message: 'Order created successfully',
              duration: 2500,
              hasIcon: true
            })
            this.gridOptions.api.refreshServerSideStore()
          }
        })
      this.gridOptions.api.hideOverlay()
    },
    // async newVersion (shippingInstruction) {
    //   this.gridOptions.api.showLoadingOverlay()
    //   await Customer.createShippingInstructionsVersion(shippingInstruction.id)
    //   this.gridOptions.api.hideOverlay()
    // },
    async delete (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      this.$apollo.mutate({
        mutation: ShippingInstructionDelete,
        variables: {
          id: shippingInstruction.id
        }
      })
      this.gridOptions.api.hideOverlay()
    },
    async edit (shippingInstruction) {
      this.creating.isOpened = true
      this.creating.shippingInstruction = shippingInstruction
    }
  }
}
</script>
