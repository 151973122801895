<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ title }}
        </p>
        <button
          type="button"
          class="delete"
          @click="$emit('on-close')"
        />
      </header>
      <section class="modal-card-body">
        <b-field label="Select a month">
          <b-datepicker
            v-model="selectedDate"
            type="month"
            placeholder="Click to select..."
            icon="calendar"
            trap-focus
            :disabled="isEditing"
          />
        </b-field>

        <b-field label="Plant" grouped>
          <b-autocomplete
            v-model="selectedPlant"
            :data="filteredPlants"
            open-on-focus
            field="id"
            placeholder="plantId"
            :disabled="isEditing || !selectedDate"
            @typing="getFilteredPlants"
          />
          <p class="control">
            <b-button class="is-info" :disabled="isDisabledCheck" :loading="isLoading" @click="checkQuantity">
              check stats
            </b-button>
          </p>
        </b-field>

        <div v-if="showQty" class="container mb-5">
          <p v-for=" (item, index) in totalForecasts" :key="index" class="forecast-info notification ">
            {{ item.material }}: {{ item.sum }}
          </p>
        </div>

        <div v-if="showMessage" class="container mb-5">
          <p class="notification">
            There is no forecast yet.
          </p>
        </div>

        <div>
          <p class="label">
            Material
          </p>
          <b-field>
            <p class="control">
              <span class="button is-static"> GPS1</span>
            </p>
            <b-input v-model="inputGPS1" :disabled="gps1Disabled" placeholder="enter a number" />
          </b-field>
          <b-field>
            <p class="control">
              <span class="button is-static"> GPS51</span>
            </p>
            <b-input v-model="inputGPS51" :disabled="gps51Disabled" placeholder="enter a number" />
          </b-field>
          <b-field>
            <p class="control">
              <span class="button is-static"> GPS52</span>
            </p>
            <b-input v-model="inputGPS52" :disabled="gps52Disabled" placeholder="enter a number" />
          </b-field>
          <b-field>
            <p class="control">
              <span class="button is-static"> GPS55</span>
            </p>
            <b-input v-model="inputGPS55" :disabled="gps55Disabled" placeholder="enter a number" />
          </b-field>
        </div>
        <b-field class="mt-5">
          <b-checkbox v-model="nothing">
            I want to declare 0 as forecast.
          </b-checkbox>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div class="level-right">
          <b-tooltip class="level-item" position="is-right" label="Split the quantity between GPS52 and GPS55">
            <b-button
              label="Split"
              :disabled="isDisabledSplit"
              :loading="isLoading"
              @click="split"
            />
          </b-tooltip>

          <b-button
            label="Save"
            type="submit"
            class="button is-primary "
            :disabled="isDisabsledSubmit"
            :loading="isLoading"
            @click.prevent="submit"
          />
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import Forecast from '@/services/v2/forecast'

export default {
  props: {
    plants: {
      default: () => [],
      type: Array
    },
    customer: {
      default: '',
      type: String
    },
    isEditing: Boolean,
    editForm: {
      default: () => {},
      type: Object
    },
    curForecasts: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      title: this.isEditing ? 'Modify forecast' : 'Add new forecast',
      isLoading: false,
      selectedPlant: null,
      selectedDate: null,
      inputGPS1: '',
      inputGPS51: '',
      inputGPS52: '',
      inputGPS55: '',
      showQty: false,
      showMessage: false,
      nothing: false,
      totalForecasts: [],
      filteredPlants: [],
      forecast: [],
      gps1Disabled: false,
      gps51Disabled: false,
      gps52Disabled: false,
      gps55Disabled: false,
      existingForecasts: null
    }
  },
  computed: {
    isDisabledSplit () {
      if (this.inputGPS52 || this.inputGPS55) {
        if (!this.plantsId.includes(this.selectedPlant)) return true
        return false
      }
      return true
    },

    isDisabledCheck () {
      if (!this.plantsId.includes(this.selectedPlant) || !this.selectedDate) return true
      return false
    },

    isDisabsledSubmit () {
      if (this.isLoading) return true
      if (this.plantsId.includes(this.selectedPlant) && this.selectedDate) {
        if (!this.inputGPS1 && !this.inputGPS51 && !this.inputGPS52 && !this.inputGPS55) {
          if (this.nothing) return false
        } else {
          return false
        }
      }
      return true
    },

    plantsId () {
      return this.plants.map(e => e.id)
    }
  },
  watch: {
    async selectedPlant () {
      this.resetParam()
      this.curForecasts.forEach(f => {
        if (new Date(f.date).getMonth() === this.selectedDate.getMonth() && f.plant === this.selectedPlant) {
          switch (f.material) {
            case 'GPS1':
              this.gps1Disabled = true
              this.inputGPS1 = +f.sum
              break
            case 'GPS51':
              this.gps51Disabled = true
              this.inputGPS51 = +f.sum
              break
            case 'GPS52':
              this.gps52Disabled = true
              this.inputGPS52 = +f.sum
              break
            case 'GPS55':
              this.gps55Disabled = true
              this.inputGPS55 = +f.sum
              break
          }
        }
      })
    },
    async selectedDate () {
      this.resetParam()
      this.curForecasts.forEach(f => {
        if (new Date(f.date).getMonth() === this.selectedDate.getMonth() && f.plant === this.selectedPlant) {
          switch (f.material) {
            case 'GPS1':
              this.gps1Disabled = true
              this.inputGPS1 = +f.sum
              break
            case 'GPS51':
              this.gps51Disabled = true
              this.inputGPS51 = +f.sum
              break
            case 'GPS52':
              this.gps52Disabled = true
              this.inputGPS52 = +f.sum
              break
            case 'GPS55':
              this.gps55Disabled = true
              this.inputGPS55 = +f.sum
              break
          }
        }
      })
    },
    nothing () {
      if (this.nothing) {
        this.inputGPS1 = 0
        this.inputGPS51 = 0
        this.inputGPS52 = 0
        this.inputGPS55 = 0
        this.gps1Disabled = true
        this.gps51Disabled = true
        this.gps52Disabled = true
        this.gps55Disabled = true
      } else {
        this.inputGPS1 = ''
        this.inputGPS51 = ''
        this.inputGPS52 = ''
        this.inputGPS55 = ''
        this.gps1Disabled = false
        this.gps51Disabled = false
        this.gps52Disabled = false
        this.gps55Disabled = false
      }
    },
    selectedMaterial () {
      this.resetParam()
    }
  },
  mounted: async function () {
    if (this.isEditing) {
      this.selectedPlant = this.editForm.plant
      this.selectedDate = new Date(this.editForm.date)
      this.existingForecasts = await Forecast.getForecast({ customerId: this.editForm.customer, date: this.$dayjs(this.editForm.date.toString()).format('YYYY-MM'), plant: this.selectedPlant })
      this.existingForecasts.forEach(f => {
        switch (f.material) {
          case 'GPS1':
            this.inputGPS1 = +f.sum
            break
          case 'GPS51':
            this.inputGPS51 = +f.sum
            break
          case 'GPS52':
            this.inputGPS52 = +f.sum
            break
          case 'GPS55':
            this.inputGPS55 = +f.sum
            break
        }
      })
    }
  },
  methods: {
    getFilteredPlants: function (text) {
      this.filteredPlants = this.plants.filter(option => {
        return option.id
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    async checkQuantity () {
      this.isloading = true
      const response = await Forecast.getForecastQuantitybyPlant({ plantId: this.selectedPlant, month: this.selectedDate.getMonth() + 1 })
      if (!response.length) this.showMessage = true
      this.totalForecasts = response
      this.showQty = true
      this.isloading = false
    },
    async split () {
      this.isloading = true
      const data = {
        plantId: this.selectedPlant,
        quantity: this.inputGPS52 || this.inputGPS55
      }
      const res = await Forecast.getSplitData(data)
      if (res) {
        this.inputGPS52 = res.gps52
        this.inputGPS55 = res.gps55
      }
      this.isloading = false
    },
    resetParam () {
      this.showQty = false
      this.showMessage = false
      if (!this.isEditing) {
        this.inputGPS1 = ''
        this.inputGPS51 = ''
        this.inputGPS52 = ''
        this.inputGPS55 = ''
        this.gps1Disabled = false
        this.gps51Disabled = false
        this.gps52Disabled = false
        this.gps55Disabled = false
        this.nothing = false
      }
    },
    getMondaysInMonth (curMonth, curYear) {
      const date = new Date(curYear, curMonth, 1)
      let mondays = 0
      while (date.getMonth() === curMonth) {
        if (date.getDay() === 1) {
          mondays++
        }
        date.setDate(date.getDate() + 1)
      }

      return mondays
    },
    getMondaysArray (curMonth, curYear) {
      const date = new Date(curYear, curMonth, 1)
      const mondays = []
      while (date.getMonth() === curMonth) {
        if (date.getDay() === 1) {
          mondays.push(new Date(date))
        }
        date.setDate(date.getDate() + 1)
      }

      return mondays
    },
    async submit () {
      this.isLoading = true
      const month = this.selectedDate.getMonth()
      const year = this.selectedDate.getFullYear()
      const numberOfMondays = this.getMondaysInMonth(month, year)
      const mondayDates = this.getMondaysArray(month, year)
      const inputs = [
        { type: 'GPS1', qty: +this.inputGPS1 },
        { type: 'GPS51', qty: +this.inputGPS51 },
        { type: 'GPS52', qty: +this.inputGPS52 },
        { type: 'GPS55', qty: +this.inputGPS55 }
      ]

      const newInputs = inputs.filter(input => !this.curForecasts.some(curForcast => curForcast.material === input.type && curForcast.plant === this.selectedPlant && curForcast.date === this.$dayjs(this.selectedDate).format('YYYY-MM')))

      newInputs.forEach(input => {
        if (this.nothing) {
          mondayDates.forEach(d => {
            this.forecast.push({
              customer: this.customer,
              date: d,
              plant: this.selectedPlant,
              material: input.type,
              quantity: 0
            })
          })
        } else if (input.qty) {
          mondayDates.forEach(d => {
            this.forecast.push({
              customer: this.customer,
              date: this.$dayjs(d).format('YYYY-MM-DD'),
              plant: this.selectedPlant,
              material: input.type,
              quantity: Math.ceil(input.qty / numberOfMondays)
            })
          })
        }
      })

      try {
        if (this.isEditing) {
          await Forecast.deleteForecast(this.customer, new Date(this.selectedDate).getMonth() + 1, this.editForm.plant)
        }
        await Forecast.setForecast(this.customer, [...this.forecast])
        this.$buefy.toast.open({
          message: 'Successfully deleted',
          type: 'is-primary' })
        this.$emit('on-close')
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger' })
      }
      this.isLoading = false
    }
  }

}
</script>

<style scoped>
.forecast-info {
  color: #45aaf2
}
</style>
