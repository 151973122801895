<template>
  <section class="section">
    <div class="container is-fluid">
      <Header v-bind="$route.meta" />
      <div class="columns is-centered">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-auto-page-size="true"
            :side-bar="sideBar"
            :animate-rows="true"
            :multi-sort-key="'ctrl'"
            :get-child-count="getChildCount"
            :modules="modules"
            @grid-ready="gridReady"
          />
          <div id="myChart" class="ag-theme-balham-dark prod-chart" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'

export default {
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      production: [],
      productionCheckupDates: [],
      totalCount: 0,
      isLoading: false,
      modules: AllModules,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true
            }
          }
        ],
        defaultToolPanel: 'columns'
      },
      gridOptions: {
        rowClass: 'is-size-6',
        rowHeight: 33,
        getContextMenuItems: this.getContextMenuItems,
        enableCharts: true,
        enableRangeSelection: true,
        purgeClosedRowNodes: true,
        // rowBuffer: 0,
        rowModelType: 'serverSide',
        serverSideStoreType: 'partial',
        cacheBlockSize: 20,
        paginationPageSize: 20,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        autoGroupColumnDef: {
          minWidth: 350
        },
        getRowStyle: params => {
          if (this.productionCheckupDates.includes(params.node?.data?.scannedAt)) {
            return { background: '#C8E6C9' }
          }
        },
        columnDefs: [
          { headerName: this.$t('pages.production.grid.barcode'),
            field: 'barcode',
            filter: 'agTextColumnFilter',
            width: 120,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: 'UUID',
            field: 'uuid',
            filter: 'agTextColumnFilter',
            width: 120,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.production.grid.scanned_at'),
            field: 'scannedAt',
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString(undefined, { timeZone: 'UTC' })
            },
            filter: 'agDateColumnFilter',
            sort: 'desc',
            width: 200,
            sortable: true,
            rowGroup: true,
            hide: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.production.grid.scanned_hour'),
            field: 'scannedAtHour',
            filter: 'agTextColumnFilter',
            cellRenderer: (data) => { return data.value && new Date(data.value).toLocaleTimeString() },
            hide: true,
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.previous_storage'),
            field: 'previousStorageId',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.previous_requirement'),
            field: 'previousRequirement',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.storage'),
            field: 'storageId',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.operation'),
            field: 'operation',
            rowGroup: true,
            cellRenderer: (data) => {
              switch (data.value) {
                case 'REPAIRED':
                  return '<i class="fas fa-wrench has-text-warning"></i> ' + data.value
                case 'WASHED':
                  return '<i class="fas fa-tint has-text-info"></i> ' + data.value
                case 'RECEIVED':
                  return '<i class="fas fa-truck-loading has-text-success"></i> ' + data.value
                case 'DELIVERED':
                  return '<i class="fas fa-truck has-text-primary"></i> ' + data.value
                case 'REJECTED':
                  return '<i class="fas fa-wrench has-text-danger"></i> ' + data.value
                case 'SCRAPPED':
                  return '<i class="fas fa-ban has-text-danger"></i> ' + data.value
                case 'REPAIRED_BC':
                  return '<i class="fas fa-barcode has-text-warning"></i> ' + data.value
                case 'REJECTED_BC':
                  return '<i class="fas fa-barcode has-text-danger"></i> ' + data.value
                case 'UNKNOWN':
                  return '<i class="fas fa-question-circle"></i> ' + data.value
              }
              return data.value
            },
            hide: true,
            filter: 'agTextColumnFilter',
            sort: 'desc',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.material'),
            field: 'material',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            rowGroup: true,
            sort: 'desc',
            hide: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.production.grid.requirement'),
            field: 'requirement',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } }
        ]
      }
    }
  },
  methods: {
    gridReady: async function (params) {
      const dataSource = {
        getRows: async (getRowParams) => {
          const groupSummary = this.getGroups(getRowParams.request)

          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'production',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }, ...this.getAggregate(getRowParams.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: getRowParams.request.startRow },
              limit: { type: 'Int', value: getRowParams.request.endRow },
              sort: { type: '[sortInputProduction]', value: this.getSorts(getRowParams.request) },
              filter: { type: 'filterInputProduction', value: this.getFilters(getRowParams.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              plantId: { type: 'ID!', value: this.$route.params.plant_id }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })

          const productionResult = result.data.production
          this.totalCount = productionResult.totalCount
          this.production = productionResult.edges.map(e => e.node)

          getRowParams.successCallback(this.production, this.totalCount)
        }
      }
      await this.getCheckouts()
      params.api.setServerSideDatasource(dataSource)
    },
    getCheckouts: async function () {
      const builder = this.$gql.query({
        operation: 'productionCheckup',
        fields: [{ edges: [{ node: ['productionDate'] }] }],
        variables: {
          lastVersionOnly: { type: 'Boolean', value: true },
          plantId: { type: 'ID!', value: this.$route.params.plant_id },
          sort: { type: '[sortInputProductionCheckup]', value: { column: 'productionDate', order: 'asc' } },
          filter: { type: 'filterInputProductionCheckup', value: { type: { equals: 'checkup' } } }
        }
      })
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })
      this.productionCheckupDates = result.data.productionCheckup.edges.map(e => e.node.productionDate)
    },
    getContextMenuItems: function (params) {
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (params.node.level === 0 && params.node.field === 'scannedAt') {
        genericMenu.push({
          name: 'Checkup',
          action: async () => {
            try {
              const selected = params.node.data
              this.$router.push({ name: 'plant-production-checkup', params: { productionDate: selected.scannedAt } })
            } catch (error) {
              this.$buefy.toast.open({ message: error.message || error, type: 'is-danger', duration: 5000 })
            }
          },
          icon: '<i class="fas fa-fw fa-check" ></i>'
        })
      }

      return genericMenu
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.barcode
      }
    }
  }
}
</script>

<style scoped>
.ag-row {
  font-size: 35px;
}
</style>
