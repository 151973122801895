import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/materials'

const materials = axios.create({
  baseURL
})

interceptorsSetup(materials)

export default class Material {
  static async getMaterials () {
    const { data } = await materials.get('/')
    return data
  }
}
