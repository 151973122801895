import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/roles'

const roles = axios.create({
  baseURL
})

interceptorsSetup(roles)

export default class Role {
  static async getRoles () {
    const { data: result } = await roles.get(`/`)
    return result
  }

  static async getRole (roleId) {
    const { data: result } = await roles.get(`/${roleId}`)
    return result
  }

  static async deleteRole (roleId) {
    const { data: result } = await roles.delete(`/${roleId}`)
    return result
  }

  static async createRole (role) {
    const { data: result } = await roles.post(`/`, role)
    return result
  }

  static async getRoleRights (roleId) {
    const { data: result } = await roles.get(`/${roleId}/rights`)
    return result
  }

  static async updateRoleRights (roleId, roleRights, role) {
    const { data: result } = await roles.put(`/${roleId}/rights`, { roleRights: roleRights, role: role })
    return result
  }
}
