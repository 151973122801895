<template>
  <section>
    <b-loading v-model="isLoading" :can-cancel="true" />
    <div class="modal-card" :style="{minHeight: 40 + 'vh'}">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Manage email templates
        </p>
        <button
          type="button"
          class="delete"
          @click="$emit('on-close')"
        />
      </header>
      <section class="modal-card-body">
        <div v-if="!addNewActive && !editActive" class="block">
          <b-button icon-left="plus" type="is-primary" @click="addNewActive = true">
            New template
          </b-button>
        </div>
        <div v-if="!addNewActive && !editActive" class="block">
          <b-field label="Template language">
            <b-select v-model="selectedLang" placeholder="select a language">
              <option
                v-for="lang in languages"
                :key="lang"
                :value="lang"
              >
                {{ lang }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Model">
            <b-select v-model="selectedTemplate" placeholder="Select a modal">
              <option v-for="template in mailTemplates" :key="template.id" :value="template">
                {{ template.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="addNewActive">
          <templateForm :template="newTemplate" :langs="languages" @on-close="closeModal" />
        </div>

        <div v-if="editActive">
          <templateForm :template="selectedTemplate" :langs="languages" @on-close="closeModal" />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Distribution from '../../services/v2/distribution'
import templateForm from './template-form.vue'

export default {
  components: {
    templateForm
  },
  data () {
    return {
      addNewActive: false,
      editActive: false,
      newTemplate: {},
      mailTemplates: null,
      isLoading: false,
      selectedTemplate: null,
      languages: [],
      selectedLang: null
    }
  },
  watch: {
    selectedTemplate () {
      this.editActive = true
    },
    async selectedLang () {
      this.mailTemplates = await Distribution.getTemplates({ lang: this.selectedLang })
    }
  },
  async mounted () {
    this.isLoading = true
    await this.loadData()
    this.isLoading = false
  },
  methods: {
    async loadData () {
      // this.mailTemplates = await Distribution.getTemplates()
      this.languages = this.$i18n.availableLocales
    },
    closeModal () {
      this.addNewActive = false
      this.editActive = false
    }
  }

}

</script>
