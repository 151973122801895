
export default {
  methods: {
    getLocalFlagString (lang) {
      const providedLang = lang || this.$i18n.locale
      switch (providedLang) {
        case 'en':
          return 'us'
        case 'fr':
          return 'fr'
        case 'ru':
          return 'ru'
        case 'es':
          return 'es'
        case 'pt':
          return 'pt'
        case 'kr':
          return 'kr'
        case 'cn':
          return 'cn'
        default:
          break
      }
    },
    getLangName (lang) {
      switch (lang) {
        case 'en':
          return 'English'
        case 'fr':
          return 'French'
        case 'ru':
          return 'Russian'
        case 'es':
          return 'Spanish'
        case 'pt':
          return 'Portuguese'
        case 'kt':
          return 'Korean'
        case 'cn':
          return 'Chinese'
        default:
          return 'Unknown'
      }
    },
    getAvailableUserTypes () {
      const types = ['end user']
      if (this.$store.getters.hasSuperAdminAccess) {
        types.push('customer')
      }
      return types
    }
  }
}
