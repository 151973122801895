import * as types from './mutation-types'

import * as Customer from '@/services/v1/customer'
import * as Table from '@/services/v1/table'
import ActionsHistory from '@/services/v2/actionsHistory'
import { NotificationProgrammatic as Notification } from 'buefy'

// ACTIONS
const actions = {

  setCustomer ({ commit }, customer) {
    commit(types.SET_SELECTED_CUSTOMER, customer)
  },
  switchSideBar ({ commit }) {
    commit(types.SWITCH_SIDE_BAR)
  },
  hideSideBar ({ commit }) {
    commit(types.HIDE_SIDE_BAR)
  },
  generateReportNotification ({ commit }) {
    const notif = Notification.open({
      message: 'Your file is being processed, this might take a few minutes...',
      type: 'is-info',
      closable: true,
      hasIcon: true,
      indefinite: true
    })
    commit(types.SHOW_GENERATE_REPORT_NOTIFICATION, notif)
  },
  generateSqliteDbFilesNotification ({ commit }) {
    const notif = Notification.open({
      message: 'Files are being processed, this might take a few minutes...',
      type: 'is-info',
      closable: true,
      hasIcon: true,
      indefinite: true
    })
    commit(types.SHOW_GENERATE_SQLITEDBFILES_NOTIFICATION, notif)
  },
  async deleteCustomerFileSettings ({ commit }, obj = {}) {
    commit(types.CUSTOMER_FILE_SETTINGS_REQUEST)
    return Customer.deleteCustomerFileSettings(obj.customer, obj.fip).then(data => {
      commit(types.CUSTOMER_FILE_SETTINGS_SUCCESS, data)
    }).catch(error => {
      const formatted = {
        message: error.message || error
      }
      commit(types.CUSTOMER_FILE_SETTINGS_FAILURE, formatted)
    })
  },
  async loadMovementFields ({ commit }, obj = {}) {
    commit(types.TABLES_MOVEMENT_FIELDS_REQUEST)
    return Table.getMovementFields(obj.table).then(data => {
      commit(types.TABLES_MOVEMENT_FIELDS_SUCCESS, data)
    }).catch(error => {
      commit(types.TABLES_MOVEMENT_FIELDS_FAILURE, error)
    })
  },
  async loadMovementTables ({ commit }, obj = {}) {
    commit(types.TABLES_MOVEMENT_TABLES_REQUEST)
    return Table.getMovementTables(obj.table).then(data => {
      commit(types.TABLES_MOVEMENT_TABLES_SUCCESS, data)
    }).catch(error => {
      commit(types.TABLES_MOVEMENT_TABLES_FAILURE, error)
    })
  },
  async duplicateCustomerFileSettings ({ commit }, { fromCustomer, fromId, toCustomer }) {
    return Customer.duplicateCustomerFileSettings(fromCustomer, fromId, toCustomer)
  },
  async archiveAction (store, action, misc) {
    try {
      ActionsHistory.insert({
        user: store.state.auth.user.displayName,
        action: action,
        misc: misc || null
      })
    } catch (error) {
    }
  }
}

export default actions
