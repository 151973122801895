import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import Auth from '@/services/v1/auth'

const state = {
  user: Auth.isLoggedIn() ? Auth.getUser() : null,
  token: Auth.getAccessToken() || ''
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
