<template>
  <div class="modal-card" style=" height: 100%; width: auto">
    <div class="modal-card-head centered-items">
      <div class="centered-items">
        <h2 class="title">
          {{ collect.enduserCode + ' - ' + collect.enduserName }}
        </h2>
        <h2 class="subtitle">
          {{ $t('pages.pickup_management.calendar.collect') }} {{ collect.collectId }}
        </h2>
      </div>
    </div>
    <section class="modal-card-body">
      <div class="centered-items">
        <b-icon icon="exclamation-triangle" type="is-warning" size="is-large" />
        <span class="sub-title">{{ $t('pages.pickup_management.collect_confirmation.disclaimer') }}</span>
      </div>
      <div v-if="!showPreview">
        <b-loading :active.sync="loading" is-full-page />
        <div class="card-content custom-content">
          <div class="d-flex-row d-flex-space-between">
            <div style="width: 75%">
              <b>{{ $t('pages.pickup_management.collect_confirmation.emails') }}</b>
              <div class="d-flex-justify-center d-flex-row emails">
                <b-tag
                  v-for="email of emailsArray"
                  :key="email"
                  close-type="is-danger"
                  class="email"
                  closable
                  attached
                  @close="removeEmail(email)"
                >
                  {{ email }}
                </b-tag>
                <br>
              </div>
              <div>
                <b>{{ $t('pages.pickup_management.collect_confirmation.additional_email') }}</b>
                <span>
                  <ValidationObserver
                    ref="observer"
                    v-slot="{ valid }"
                    tag="form"
                    class="d-flex-row"
                    @submit.prevent="addEmail"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="email"
                      rules="email"
                      class="additional-email "
                    >
                      <b-field
                        :type="!valid ? 'is-danger': ''"
                      >
                        <b-input
                          v-model="additionalMail"
                          name="email"
                        />
                      </b-field>
                    </ValidationProvider>
                    <b-button class="is-info additional-email-button" icon-left="plus" :disabled="!valid" @click="addEmail" />
                  </ValidationObserver>
                </span>
              </div>
              <div class="d-flex-row d-flex-space-between">
                <div>
                  <b>{{ $t('pages.pickup_management.collect_confirmation.templates.use_template') }}</b>
                  <b-select
                    v-model="selectedTemplate"
                    :placeholder="$t('pages.pickup_management.mail_templates.select_placeholder')"
                  >
                    <option
                      v-for="t in templates"
                      :key="t.id"
                      :value="t"
                    >
                      {{ t.templateName }}
                    </option>
                  </b-select>
                  <b-button v-if="selectedTemplate" type="is-primary is-small" @click="useTemplate">
                    {{ $t('global.use') }}
                  </b-button>
                </div>
              </div>
              <div class="separator" />
              <div>
                <b-field :label="$t('pages.pickup_management.collect_confirmation.mail_subject_title')">
                  <b-input
                    v-model="mailSubject"
                    name="subject"
                  />
                </b-field>
              </div>
              <div>
                <ValidationObserver
                  ref="observer2"
                  v-slot="{ valid }"
                  tag="form"
                  class="d-flex-row"
                  @submit.prevent=""
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="sender"
                    rules="email"
                    class="additional-email "
                  >
                    <b-tooltip
                      :label="$t('pages.pickup_management.collect_confirmation.sender_hint')"
                      position="is-right"
                      style="margin-top: 1rem;"
                    >
                      <b>
                        {{ $t('pages.pickup_management.collect_confirmation.sender') }}
                      </b>
                      <b-icon icon="info" type="is-info" class="is-small" />
                    </b-tooltip>
                    <b-field
                      :type="!valid ? 'is-danger': ''"
                    >
                      <b-input
                        v-model="mailSender"
                        name="sender"
                      />
                    </b-field>
                  </ValidationProvider>
                </ValidationObserver>
              </div>
              <b-field :label="$t('pages.pickup_management.collect_confirmation.attachment')">
                <b-upload
                  v-model="attachments"
                  multiple
                  accept="application/pdf"
                  drag-drop
                  required
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="file-csv" size="is-large" />
                      </p>
                      <p>
                        {{ $t('pages.loads.drop_file_here') }}
                      </p>
                    </div>
                  </section>
                </b-upload>

                <div v-if="attachments && attachments.length">
                  <div v-for="at in attachments" :key="at.name">
                    <b-field>
                      <span class="tag is-info">
                        {{ at.name }}
                        <button class="delete is-small" type="button" @click="removeAttachment(at)" />
                      </span>
                    </b-field>
                  </div>
                </div>
              </b-field>
            </div>

            <div>
              <collectInfoCard :informative="true" :collect="collect" />
            </div>
          </div>
          <div>
            <b>{{ $t('pages.pickup_management.collect_confirmation.mail_content_title') }}</b>
            <div>
              <vue-editor v-model="mailContent" />
            </div>
          </div>
          <div>
            <b-checkbox v-model="includeSignature">
              {{ $t('pages.pickup_management.collect_confirmation.include_signature') }}
            </b-checkbox>
            <b-field v-if="signature && includeSignature" :label="$t('pages.pickup_management.collect_confirmation.signature')">
              <signaturePreview id="signatureHTML" :signature="signature" />
            </b-field>
          </div>
          <div class="d-flex-justify-center buttons">
            <b-button
              class="is-warning"
              @click="() => {
                $emit('close')
              }
              "
            >
              {{ $t("global.cancel") }}
            </b-button>
            <b-button
              class="is-info"
              @click="loadPreview()"
            >
              {{ $t("pages.pickup_management.signature.preview") }}
            </b-button>
            <b-button class="is-primary" @click="submit">
              {{ $t("global.submit") }}
            </b-button>
          </div>
        </div>
      </div>
      <div :style="!showPreview ? 'display: none;' : 'display: block'">
        <b-button
          class="is-warning"
          @click="() => {showPreview = !showPreview}"
        >
          {{ $t("global.close") }}
        </b-button>
        <div id="confirmationPreview" class="container" />
      </div>
    </section>
  </div>
</template>

<script>

import CollectConfirmation from '@/services/v2/enduserCollectConfirmation'
import { VueEditor } from 'vue2-editor'
import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import signaturePreview from './signaturePreview'
import CollectInfoCard from './collectInfoCard'

export default {
  components: {
    VueEditor,
    signaturePreview,
    CollectInfoCard
  },
  props: {
    collect: {
      required: true,
      type: Object
    },
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mailEnd: this.$t('pages.pickup_management.collect_confirmation.mail_end_default'),
      loading: false,
      savedLanguage: null,
      additionalMail: '',
      mailContent: '',
      mailSubject: '',
      mailSender: '',
      emailsArray: [],
      signature: null,
      includeSignature: false,
      showPreview: false,
      templates: [],
      selectedTemplate: null,
      attachments: []
    }
  },
  computed: {
  },
  mounted: function () {
    this.savedLanguage = this.$i18n.locale
    this.emailsArray = this.collect.emails.toString().split(',')
    this.loadTemplates()
    this.loadSignature()
  },
  methods: {
    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    removeAttachment (at) {
      const index = this.attachments.indexOf(at)
      if (index !== -1) { this.attachments.splice(index, 1) }
    },
    submit: async function () {
      const base64AttachmentsArray = []
      if (this.attachments && this.attachments.length) {
        const init = this.attachments[0].size
        const sum = this.attachments.reduce((acc, value, i) => {
          return acc + value.size
        }, init)
        if (sum > 7340032) {
          this.$buefy.toast.open({ message: this.$t('pages.pickup_management.collect_confirmation.errors.attachments_size_limit'), type: 'is-danger', duration: 5000 })
          return
        }
        try {
          for (let i = 0; i < this.attachments.length; i++) {
            const at = this.attachments[i]
            const base64 = await this.toBase64(at)
            const content = (base64 && base64.length) ? base64.split(',')[1] : ''
            base64AttachmentsArray.push({
              content: content,
              type: at.type,
              filename: at.name,
              disposition: 'attachment'
            })
          }
        } catch (error) {
          this.$buefy.toast.open({ message: this.$t('pages.pickup_management.collect_confirmation.errors.attachment_internal_error'), type: 'is-danger', duration: 5000 })
        }
      }

      if (await this.$refs.observer2.validate()) {
        this.$buefy.dialog.confirm({
          title: this.$t('pages.pickup_management.collect_confirmation.confirm.title'),
          message: this.$t('pages.pickup_management.collect_confirmation.confirm.message'),
          confirmText: this.$t('global.yes'),
          cancelText: this.$t('global.cancel'),
          type: 'is-link',
          hasIcon: true,
          onConfirm: async () => {
            try {
              this.loading = true
              await CollectConfirmation.insert({
                enduserCode: this.collect.enduserCode,
                createdAt: this.$dayjs(),
                loadingDate: this.collect.loadingDate,
                collectId: this.collect.collectId,
                materials: this.collect.materials,
                mailSubject: this.mailSubject,
                mailSender: this.mailSender,
                mailContent: this.getMailContent(),
                // mailCustomContent: this.mailEnd,
                emails: this.getEmailsString(),
                attachments: base64AttachmentsArray
              })
              this.collect.confirmation = true
              this.$buefy.toast.open({ message: this.$t('global.success'), type: 'is-primary', duration: 8000 })
              this.loading = false
              this.$i18n.locale = this.savedLanguage
              this.$emit('close')
            } catch (error) {
              this.$buefy.toast.open({ message: error.message || error, type: 'is-danger', duration: 5000 })
              this.loading = false
            }
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('pages.pickup_management.collect_confirmation.invalid_email'), type: 'is-danger', duration: 5000 })
      }
    },
    removeEmail: function (email) {
      const emailIndex = this.emailsArray.indexOf(email)
      if (emailIndex !== -1) {
        this.emailsArray.splice(emailIndex, 1)
      }
    },
    addEmail: function () {
      if (this.additionalMail.length > 0) {
        this.emailsArray.push(this.additionalMail)
        this.additionalMail = ''
      }
    },
    loadTemplates: async function () {
      this.templates = await EnduserDeclarationAdmin.getConfirmationTemplates(this.selectedGroup.collectGroup)
    },
    loadSignature: async function () {
      this.signature = await EnduserDeclarationAdmin.getSignature(this.selectedGroup.collectGroup)
    },
    getPreview: function () {
      const content = this.getMailContent()
      document.getElementById('confirmationPreview').innerHTML = content
    },
    useTemplate: function () {
      this.mailContent = this.selectedTemplate.content
      this.mailSubject = this.selectedTemplate.subject
      this.mailSender = this.selectedTemplate.sender
      this.autoFill()
    },
    loadPreview: function () {
      this.showPreview = true
      this.getPreview()
    },
    getEmailsString: function () {
      return this.emailsArray.reduce((emails, newEmail) => {
        return emails + ',' + newEmail
      })
    },
    getMailContent: function () {
      let content = this.mailContent
      if (this.includeSignature) {
        content += '<br/>' + document.getElementById('signatureHTML').innerHTML
      }
      return content
    },
    autoFill: function () {
      this.mailContent = this.mailContent.replace('enduser_field', this.collect.enduserName)
      this.mailContent = this.mailContent.replace('loading_date_field', this.$dayjs(this.collect.loadingDate).format('DD-MM-YYYY'))
      this.mailContent = this.mailContent.replace('arrival_date_field', this.$dayjs(this.collect.arrivalDate).format('DD-MM-YYYY'))
      this.mailContent = this.mailContent.replace('forwarder_name_field', this.collect.forwarderName)
      this.mailContent = this.mailContent.replace('shipment_nr_field', this.collect.shipmentNr)
      this.mailContent = this.mailContent.replace('dest_code_field', this.collect.destCode)
      this.mailContent = this.mailContent.replace('dest_name_field', this.collect.destName)
      this.mailContent = this.mailContent.replace('dest_address_field', this.collect.destAdress)
      this.mailContent = this.mailContent.replace('ob_nr_field', this.collect.obNr)
      this.collect.materials.forEach((m, index) => {
        this.mailContent = this.mailContent.replace('materials_field', `${m.qty} ${m.mat} -- materials_field`)
        if (index === this.collect.materials.length - 1) {
          this.mailContent = this.mailContent.replace('-- materials_field', '')
        }
      })
    }
  }

}
</script>

<style scoped>
.centered-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail_content_icon {
  position: absolute;
  left: -22px;
}

.email {
  margin-right: 1rem;
  margin-right: 1rem;
}

.additional-email {
  width: 100%;
}

.custom-content > div {
  margin-top: 1rem;
}

.separator {
  margin-top: 1rem;
  border: solid 1px rgba(0, 0, 0, 0.2);
  height: 1px;
  width: 100%;
}

</style>
