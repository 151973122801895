<template>
  <div>
    <div v-for="(d, i) of data" :key="i" :style="indent">
      <div>
        <b-checkbox v-model="d.value" :disabled="d.isDefault">
          {{ d.label }}
        </b-checkbox>
      </div>
      <div v-if="d.value === true && d.data !== null">
        <ReportFormColumn :depth="depth + 1" :data="d.data" />
      </div>
    </div>
  </div>
</template>

<script>
import ReportFormColumn from './report-form-column.vue'

export default {
  name: 'ReportFormColumn',
  components: {
    ReportFormColumn
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    depth: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },
  computed: {
    indent () {
      // return { transform: `translate(${this.depth * 50}px)` }
      return { 'margin-left': `${this.depth * 20}px` }
    }
  },
  methods: {
  }
}
</script>

<style type="css" scoped>

</style>
