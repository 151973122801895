<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <h2 class="subtitle">
        {{ $t('global.total') }} : {{ totalCount }} {{ $t('global.crates') }}
      </h2>
      <div class="columns is-multiline">
        <div id="collected-crates" class="column">
          <div class="box">
            <nav class="level is-mobile">
              <div class="level-item has-text-centered">
                <b-icon type="is-primary" size="is-medium" icon="cube" />
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">
                    {{ $t('pages.stock.available') }}
                  </p>
                  <p class="subtitle">
                    {{ storages.find(e => e.type === '1AVL').totalCount }}
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div id="crate-collections" class="column">
          <div class="box">
            <nav class="level is-mobile">
              <div class="level-item has-text-centered">
                <b-icon size="is-medium" icon="cube" />
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">
                    {{ $t('pages.stock.dirty') }}
                  </p>
                  <p class="subtitle">
                    {{ storages.find(e => e.type === '2DIR').totalCount }}
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div id="crate-stock" class="column">
          <div class="box">
            <nav class="level is-mobile">
              <div class="level-item has-text-centered">
                <b-icon type="is-warning" size="is-medium" icon="cube" />
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">
                    {{ $t('pages.stock.to_repair') }}
                  </p>
                  <p class="subtitle">
                    {{ storages.find(e => e.type === '3REP').totalCount }}
                  </p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <p class="control">
                  <button class="button is-warning" @click="fetchToReplaceBarcodes">
                    <b-icon icon="print" />
                    <span>
                      <template>{{ $t('global.barcode') }}</template>
                    </span>
                  </button>
                </p>
              </div>
            </nav>
          </div>
        </div>
        <div id="enduser-count" class="column">
          <div class="box">
            <nav class="level is-mobile">
              <div class="level-item has-text-centered">
                <b-icon type="is-danger" size="is-medium" icon="cube" />
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">
                    {{ $t('pages.stock.scrap') }}
                  </p>
                  <p class="subtitle">
                    {{ storages.find(e => e.type === '5SCR').totalCount }}
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-auto-page-size="true"
            :animate-rows="true"
            :side-bar="['columns']"
            :cache-overflow-size="0"
            :multi-sort-key="'ctrl'"
            :get-child-count="getChildCount"
            :modules="modules"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
    <b-modal :active.sync="barcodesToReplace.isModalActive" scroll="keep" has-modal-card @close="barcodesToReplace.checked = []; fetchAll()">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('pages.stock.to_replace') }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field grouped>
            <b-field>
              <b-input v-model="barcodesToReplace.inputSearch" placeholder="Search..." type="search" icon="search" />
            </b-field>
            <b-field>
              <b-tooltip :label="$t('global.export')" position="is-bottom" type="is-dark">
                <b-button
                  type="is-info"
                  icon-left="file-csv"
                  :disabled="barcodesToReplace.checked.length === 0"
                  @click="exportToReplaceBarcodes"
                />
              </b-tooltip>
            </b-field>
            <b-field>
              <b-tooltip :label="$t('pages.stock.print')" position="is-bottom" type="is-dark">
                <b-button
                  type="is-link"
                  icon-left="print"
                  :disabled="barcodesToReplace.checked.length === 0"
                  :loading="barcodesToReplace.isCreatingPDF"
                  @click="getPdfToReplaceBarcodes"
                />
              </b-tooltip>
            </b-field>
            <b-field>
              <b-tooltip :label="$t('pages.stock.storage_dirty')" position="is-bottom" type="is-dark">
                <b-button
                  type="is-brown"
                  icon-left="dolly"
                  :loading="barcodesToReplace.isPosting"
                  :disabled="barcodesToReplace.checked.length === 0"
                  @click="setStorage('2DIR')"
                />
              </b-tooltip>
            </b-field>
            <b-field>
              <b-tooltip :label="$t('pages.stock.storage_clean')" position="is-bottom" type="is-dark">
                <b-button
                  type="is-white"
                  icon-left="dolly"
                  :loading="barcodesToReplace.isPosting"
                  :disabled="barcodesToReplace.checked.length === 0"
                  @click="setStorage('1AVL')"
                />
              </b-tooltip>
            </b-field>
            <b-field>
              <b-tooltip :label="$t('pages.stock.storage_repair')" position="is-bottom" type="is-dark">
                <b-button
                  type="is-warning"
                  icon-left="dolly"
                  :loading="barcodesToReplace.isPosting"
                  :disabled="barcodesToReplace.checked.length === 0"
                  @click="setStorage('3REP')"
                />
              </b-tooltip>
            </b-field>
          </b-field>
          <b-field>
            <b-button icon-left="check" @click="checkAll">
              {{ $t('pages.stock.check_all') }}
            </b-button>
          </b-field>
          <b-table
            :per-page="15"
            :data="filteredReplaceBarcodes"
            :checked-rows.sync="barcodesToReplace.checked"
            :header-checkable="false"
            :loading="barcodesToReplace.isLoading"
            checkable
            narrowed
            striped
            paginated
            @check-all="checkAll"
            @check="row => barcodesToReplace.checked = row"
          >
            <template slot="header" slot-scope="props">
              <span>{{ props.column.label }}</span>
            </template>
            <b-table-column v-slot="props" field="barcode" label="Barcode">
              {{ props.row.barcode }}
            </b-table-column>
            <b-table-column v-slot="props" field="material" label="Material">
              {{ props.row.material }}
            </b-table-column>
            <b-table-column v-slot="props" field="quantity" label="Quantity">
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column v-slot="props" field="scannedAt" label="Scanned at">
              {{ new Date(props.row.scannedAt).toLocaleString() }}
            </b-table-column>
            <template slot="bottom-left">
              <b>{{ $t('global.total') }}</b>: {{ filteredReplaceBarcodes.length }}
            </template>
            <template slot="empty">
              <nothing-here />
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot" />
      </div>
    </b-modal>
  </section>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'

import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import { jsPDF as JSPDF } from 'jspdf'
import bwipjs from 'bwip-js'

export default {
  name: 'PlantStock',
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      isLoading: false,
      modules: AllModules,
      gridOptions: {
        rowModelType: 'serverSide',
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: false,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('global.barcode'),
            field: 'barcode',
            filter: 'agTextColumnFilter',
            width: 120,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.stock.grid.scanned_at'),
            field: 'scannedAt',
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleString()
            },
            filter: 'agDateColumnFilter',
            sort: 'desc',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('global.storage'),
            field: 'storageId',
            filter: 'agTextColumnFilter',
            width: 150,
            rowGroup: true,
            hide: true,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('global.material'),
            field: 'material',
            filter: 'agTextColumnFilter',
            width: 130,
            rowGroup: true,
            hide: true,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.stock.grid.requirement'),
            field: 'requirement',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } }
        ]
      },
      barcodesToReplace: {
        isModalActive: false,
        isLoading: false,
        isCreatingPDF: false,
        data: [],
        checked: [],
        isPosting: false,
        inputSearch: ''
      },
      totalCount: 0,
      storages: [
        { type: '2DIR', totalCount: 0 },
        { type: '5SCR', totalCount: 0 },
        { type: '1AVL', totalCount: 0 },
        { type: '3REP', totalCount: 0 }]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    filteredReplaceBarcodes: function () {
      return this.barcodesToReplace.data.map(e => { return { ...e, quantity: e.typNc.split('.', 2)[1] || 1 } }).filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key]).toLowerCase().indexOf(this.barcodesToReplace.inputSearch.toLowerCase()) > -1
        })
      })
    },
    barcodesExportFormat: function () {
      return this.barcodesToReplace.checked
        .reduce((acc, current) => {
          if (current.quantity > 1) {
            return [...acc, current.barcode, current.barcode]
          } else {
            return [...acc, current.barcode]
          }
        }, [])
    }
  },
  mounted: async function () {
    this.fetchAll()
  },
  methods: {
    fetchAll: async function () {
      try {
        const builder = this.$gql.query({
          operation: 'plantStock',
          fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }] }, 'barcode'] }] }],
          variables: {
            limit: { type: 'Int', value: 0 },
            plantId: { type: 'ID!', value: this.$route.params.plant_id }
          }
        })

        const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })

        this.totalCount = result.data.plantStock.totalCount

        const promises = this.storages.map(e => {
          return new Promise(async (resolve, reject) => {
            try {
              const builder = this.$gql.query({
                operation: 'plantStock',
                fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }] }, 'barcode'] }] }],
                variables: {
                  filter: { type: 'filterInputPlantStock', value: { storageId: { equals: e.type } } },
                  limit: { type: 'Int', value: 0 },
                  plantId: { type: 'ID!', value: this.$route.params.plant_id }
                }
              })

              const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })

              e.totalCount = result.data.plantStock.totalCount
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        })

        await Promise.all(promises)
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    checkAll: function () {
      if (this.barcodesToReplace.checked.length) {
        this.barcodesToReplace.checked = []
      } else {
        this.barcodesToReplace.checked = this.filteredReplaceBarcodes
      }
    },
    setStorage: async function (storageId) {
      this.barcodesToReplace.isPosting = true
      await this.$apollo.mutate({
        mutation: gql`mutation ($movements: [MovementInput]) {
        movementsCreate(movements: $movements) {
          vakey
        }
      }`,
        variables: {
          movements: this.barcodesToReplace.checked.map(e => {
            return { barcode: e.barcode,
              requirement: 'MVTI',
              plantId: this.$route.params.plant_id,
              storageId: storageId,
              user: this.user.username,
              createdAt: this.$dayjs.utc().format('YYYYMMDDHHmmss') }
          })
        }
      })
      this.barcodesToReplace.isPosting = false
      this.fetchToReplaceBarcodes()
    },
    gridReady: async function (params) {
      const dataSource = {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)

          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'plantStock',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputPlantStock]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputPlantStock', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              plantId: { type: 'ID!', value: this.$route.params.plant_id }
            }
          })

          const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })

          const stockResult = result.data.plantStock
          const stock = stockResult.edges.map(e => e.node)

          params.successCallback(stock, stockResult.totalCount)
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    fetchToReplaceBarcodes: async function () {
      this.barcodesToReplace.isLoading = true
      this.barcodesToReplace.isModalActive = true

      const builder = this.$gql.query({
        operation: 'plantStock',
        fields: ['totalCount', { edges: [{ node: ['barcode', 'material', 'scannedAt', 'typNc'] }] }],
        variables: {
          sort: { type: '[sortInputPlantStock]', value: { column: 'scannedAt', order: 'desc' } },
          filter: { type: 'filterInputPlantStock', value: { storageId: { equals: '3REP' }, typNc: { startsWith: '1' } } },
          plantId: { type: 'ID!', value: this.$route.params.plant_id }
        }
      })

      const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })

      this.barcodesToReplace.data = result.data.plantStock.edges.map(e => e.node)
      this.barcodesToReplace.isLoading = false
    },
    exportToReplaceBarcodes: function () {
      const result = this.barcodesExportFormat.join('\n')
      const csvBlob = new Blob([result], { type: 'text/csv' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')
      anchorElement.href = blobUrl
      anchorElement.download = new Date().toLocaleDateString() + '-toreplace_export.csv'
      anchorElement.click()
    },
    getPdfToReplaceBarcodes: async function () {
      this.barcodesToReplace.isCreatingPDF = true

      const imgHeight = 30
      const imgWidth = 85

      const doc = new JSPDF({ orientation: 'landscape', format: [imgHeight, imgWidth] })

      doc.deletePage(1)

      this.barcodesExportFormat.forEach(barcode => {
        const params = {
          bcid: 'code39',
          text: barcode,
          includetext: true,
          textsize: 20,
          textgaps: 4,
          scale: 3,
          height: imgHeight,
          width: imgWidth,
          textyoffset: 5,
          textxalign: 'center'
        }
        doc.addPage()

        const canvas = document.createElement('canvas')

        const imgData = bwipjs.toCanvas(canvas, params).toDataURL()
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, null, 'MEDIUM')
      })
      this.barcodesToReplace.isCreatingPDF = false
      doc.save('replacebc_' + this.$dayjs().format('YYYYMMDD_HHmmss') + '.pdf')
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.barcode
      }
    }
  }
}
</script>
