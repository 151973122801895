<template>
  <div class="modal-card" style=" height: auto; width: auto">
    <div class="modal-card-head d-flex-justify-center">
      <div class="d-flex-align-center d-flex-column">
        <h2 class="title">
          {{ $t('pages.pickup_management.collect_confirmation.templates.title') }}
        </h2>
        <h2 class="subtitle">
          {{ selectedGroup.collectGroup }}
        </h2>
      </div>
    </div>
    <section class="modal-card-body">
      <b-button icon-left="arrow-left" class="is-warning is-small" style="margin-bottom: 30px" @click="close">
        {{ $t('global.back') }}
      </b-button>
      <b-loading :active.sync="loading" is-full-page />
      <div class="card-content">
        <div>
          <div class="d-flex-row d-flex-space-between">
            <div class="first-fields">
              <div>
                <div class="column">
                  <b-select
                    v-model="selectedTemplate"
                    :placeholder="$t('pages.pickup_management.mail_templates.select_placeholder')"
                  >
                    <option
                      v-for="t in templates"
                      :key="t.id"
                      :value="t"
                    >
                      {{ t.templateName }}
                    </option>
                  </b-select>
                </div>
                <div class="column right-align buttons">
                  <button
                    id="pickup-step-5"
                    class="button is-info"
                    @click="newTemplate"
                  >
                    <b-icon icon="plus" />
                  </button>
                  <button v-if="selectedTemplate && selectedTemplate.id" class="button is-danger" @click="deleteTemplate">
                    {{ $t('global.delete') }}
                  </button>
                </div>
              </div>
              <div v-if="selectedTemplate" class="edit-section">
                <div>
                  <b-field :label="$t('pages.pickup_management.collect_confirmation.templates.template_name')">
                    <b-input v-model="selectedTemplate.templateName" />
                  </b-field>
                </div>
                <div>
                  <b-field :label="$t('pages.pickup_management.collect_confirmation.templates.subject')">
                    <b-input v-model="selectedTemplate.subject" />
                  </b-field>
                </div>
                <div>
                  <b-field :label="$t('pages.pickup_management.collect_confirmation.templates.sender')">
                    <b-input v-model="selectedTemplate.sender" />
                  </b-field>
                </div>
              </div>
            </div>
            <div>
              <collectInfoCard />
            </div>
          </div>
          <div v-if="selectedTemplate">
            <div>
              <b-field :label="$t('pages.pickup_management.collect_confirmation.templates.mail_content')">
                <vue-editor v-model="selectedTemplate.content" />
              </b-field>
            </div>
            <div>
              <button
                :class=" {'is-loading' : isSubmitting}"
                type="submit"
                class="button is-primary is-fullwidth"
                @click="submit"
              >
                {{ $t('global.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { VueEditor } from 'vue2-editor'
import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import CollectInfoCard from './collectInfoCard'

export default {
  components: {
    VueEditor,
    CollectInfoCard
  },
  props: {
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      templates: [],
      selectedTemplate: null,
      isSubmitting: false
    }
  },
  computed: {
  },
  mounted: function () {
    this.loadData()
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    submit: async function () {
      let action = this.selectedTemplate.id ? this.$t('global.update') : this.$t('global.create')
      this.isSubmitting = true
      this.$buefy.dialog.confirm({
        title: action,
        message: this.$t('global.are_you_sure'),
        confirmText: this.$t('global.yes'),
        type: 'is-link',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.selectedTemplate.collectGroup = this.selectedGroup.collectGroup
            await EnduserDeclarationAdmin.postConfirmationTemplates(this.selectedTemplate)
            this.$buefy.toast.open({ type: 'is-primary', message: this.$t('global.success'), duration: 3000 })
            this.loadData()
            this.selectedTemplate = null
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
      this.isSubmitting = false
    },
    deleteTemplate: function () {
      if (this.selectedTemplate.id) {
        this.$buefy.dialog.confirm({
          title: this.$t('global.delete'),
          message: this.$t('global.are_you_sure'),
          confirmText: this.$t('global.yes'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              this.selectedTemplate.collectGroup = this.selectedGroup.collectGroup
              await EnduserDeclarationAdmin.deleteConfirmationTemplates(this.selectedTemplate.id)
              this.$buefy.toast.open({ type: 'is-primary', message: this.$t('global.success'), duration: 3000 })
              this.loadData()
              this.selectedTemplate = null
            } catch (error) {
              this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
            }
          }
        })
      }
    },
    newTemplate: function () {
      if (!this.templates.find(t => !t.id)) {
        this.selectedTemplate = {
          templateName: 'New template',
          content: '',
          subject: '',
          collectGroup: '',
          sender: ''
        }
        this.templates.push(this.selectedTemplate)
      }
    },
    loadData: async function () {
      this.templates = await EnduserDeclarationAdmin.getConfirmationTemplates(this.selectedGroup.collectGroup)
    }
  }

}
</script>

<style scoped>
.edit-section > div{
  margin-bottom: 1rem;
}

.first-fields {
  flex: 2;
  margin-right: 2rem;
}

</style>
