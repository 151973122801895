import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/plants'

const plants = axios.create({
  baseURL
})

interceptorsSetup(plants)

export default class Plant {
  static async getAll () {
    const result = await plants.get('/')
    return result.data
  }

  static async getCurSimulation (query) {
    const queryString = constructQueryString(query)
    const result = await plants.get(`/simulation?${queryString}`)
    return result.data
  }

  static async insertSimulation (data) {
    const result = await plants.post('/simulation/insert', data)
    return result.data
  }

  static async updateSimulation (data) {
    const result = await plants.put('/simulation/modify', data)
    return result.data
  }

  static async updateIntuiflow (data) {
    const result = await plants.put('/simulation/intuiflow', data)
    return result.data
  }

  static async find (plantId) {
    const result = await plants.get(`/${plantId}`)
    return result.data
  }

  static async getScans (plantId) {
    const result = await plants.get(`/${plantId}/scans/`)
    return result.data
  }

  static async getShipments (plantId, query) {
    const queryString = constructQueryString(query)
    const result = await plants.get(`/${plantId}/shipments?${queryString}`)
    return result.data
  }
  static async getStock (plantId) {
    const result = await plants.get(`/${plantId}/stock/`)
    return result.data
  }
  static async getProducerStock (plantId) {
    const result = await plants.get(`/${plantId}/producer-stock/`)
    return result.data
  }
  static async getStockByStorage (plantId, storageId, typNc) {
    const result = await plants.get(`/${plantId}/stock/${storageId}/`, {
      params: {
        typNc
      }
    })
    return result.data
  }

  static async getStockSummary (plantId) {
    const result = await plants.get(`/${plantId}/stock/summary`)
    return result.data
  }

  static async getProduction (plantId, requirement) {
    const result = await plants.get(`/${plantId}/production`, {
      params: {
        requirement
      }
    })
    return result.data
  }

  static async getForecastParameters (plantId) {
    const result = await plants.get(`/${plantId}/forecastParameter`)
    return result.data
  }

  static async setForecastParameters (plantId, data) {
    const { data: result } = await plants.post(`/${plantId}/forecastParameter`, data)
    return result
  }
}
