<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit">
    <div class="modal-card" style="min-height: 750px">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ editableUser ? 'Edit' : 'Create' }} user
        </p>
      </header>
      <section class="modal-card-body">
        <ValidationProvider v-slot="{ errors, valid }" rules="required" name="Username" slim>
          <b-field label="Username" :type="{ 'is-danger': errors[0], 'is-success': valid }">
            <b-input v-model="newUser.username" name="username" placeholder="endusername.city" />
          </b-field>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
          <b-field label="Display Name" :type="{ 'is-danger': errors[0], 'is-success': valid }">
            <b-input v-model="newUser.displayName" name="displayName" type="text" placeholder="enduser name" />
          </b-field>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required" slim>
          <b-field label="Type">
            <b-radio-button
              v-model="newUser.type"
              native-value="HUB"
              type="is-primary"
              :disabled="!isAdmin"
            >
              <b-icon icon="home" />
              <span>HUB</span>
            </b-radio-button>

            <b-radio-button
              v-model="newUser.type"
              native-value="CUSTOMER"
              type="is-info"
              :disabled="!isAdmin"
            >
              <b-icon icon="handshake" />
              <span>CUSTOMER</span>
            </b-radio-button>

            <b-radio-button
              v-model="newUser.type"
              native-value="END USER"
              type="is-purple"
            >
              <b-icon icon="user" />
              <span>END USER</span>
            </b-radio-button>
            <b-radio-button
              v-model="newUser.type"
              native-value="PFT"
              type="is-orange"
            >
              <b-icon icon="mobile-alt" />
              <span>PFT</span>
            </b-radio-button>
          </b-field>
        </ValidationProvider>
        <div v-if="newUser.type !== 'PFT'">
          <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
            <b-field label="Role" :addons="false" :type="{ 'is-danger': errors[0], 'is-success': valid }">
              <b-autocomplete
                v-model="selectedRole"
                :data="filteredRoles"
                open-on-focus
                field="name"
                icon="search"
                clearable
                @select="option => (newUser.role = option)"
              >
                <template slot="empty">
                  No results found
                </template>
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
        </div>
        <div v-if="newUser.type !== 'PFT'">
          <ValidationProvider v-slot="{ errors, valid }" name="Group" slim>
            <b-field label="Group" :addons="false" :type="{ 'is-danger': errors[0], 'is-success': valid }">
              <b-autocomplete
                v-model="selectedGroup"
                :data="filteredGroups"
                open-on-focus
                field="name"
                icon="search"
                clearable
                @select="option => (newUser.group = option)"
              >
                <template slot="empty">
                  No results found
                </template>
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
        </div>
        <ValidationProvider v-slot="{ errors, valid }" rules="required|email" slim>
          <b-field label="Email" :type="{ 'is-danger': errors[0], 'is-success': valid }">
            <b-input v-model="newUser.email" name="email" type="email" />
          </b-field>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors, valid }" slim>
          <b-field v-if="editableUser" label="Password" :type="{ 'is-danger': errors[0], 'is-success': valid }">
            <b-input v-model="newUser.password" name="password" type="password" password-reveal />
          </b-field>
        </ValidationProvider>
      </section>
      <footer class="modal-card-foot">
        <button :class=" {'is-loading' : isSubmitting}" :disabled="invalid" type="submit" class="button is-info is-fullwidth">
          Submit
        </button>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import miscMixins from '@/mixins/misc'
import Role from '@/services/v2/role'
export default {
  mixins: [miscMixins],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    editableUser: {
      type: Object,
      default: null
    },
    groups: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedRole: '',
      selectedGroup: '',
      roles: [],
      isAdmin: false,
      newUser: {
        id: '',
        displayName: '',
        username: '',
        password: '',
        group: '',
        email: '',
        role: '',
        type: ''
      }
    }
  },
  computed: {
    filteredGroups () {
      return this.groups.filter(g => g.type === this.newUser.type).filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.selectedGroup?.toLowerCase()) >= 0
        )
      })
    },
    filteredRoles () {
      return this.roles.filter(option => {
        return (option.name.toString().toLowerCase().indexOf(this.selectedRole.toLowerCase()) >= 0)
      })
    }
  },
  mounted: async function () {
    this.roles = await Role.getRoles()
    this.isAdmin = this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED'
    this.userTypes = this.getAvailableUserTypes()
    if (!this.isAdmin) {
      // for non admin users, only allow the creation of pickup management users => intented for collections pilots
      this.newUser.role = { 'id': 9, 'name': this.roles.find(r => r.id === 9)?.name }
      this.roles = [this.newUser.role]
      this.selectedRole = this.newUser.role.name
    }
    if (this.editableUser) {
      this.newUser.id = this.editableUser.id
      this.newUser.displayName = this.editableUser.displayName
      this.newUser.username = this.editableUser.username
      this.newUser.email = this.editableUser.email
      this.newUser.type = this.editableUser.type
      if (this.editableUser.group) {
        this.selectedGroup = this.editableUser.group.name
        this.newUser.group = this.editableUser.group
      }
      if (this.editableUser.role) {
        this.newUser.role = this.editableUser.role
        this.selectedRole = this.editableUser.role.name
      }
    }
  },
  methods: {
    async submit () {
      try {
        let isValid = await this.$refs.observer.validate()
        if (this.newUser.group && this.newUser.type !== this.newUser.group.type) {
          isValid = false
          this.$refs.observer.setErrors({ Group: 'This field is invalid' })
        }
        if (!isValid) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Invalid form', duration: 3000 })
          return
        }
        this.$emit('submit', this.newUser)
        return
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
    }
  }
}
</script>

<style>

</style>
