import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/tables'

const tables = axios.create({
  baseURL
})

interceptorsSetup(tables)

export async function getMovementTables () {
  const result = await tables.get('/')
  return result.data
}

export async function getMovementFields (table) {
  const result = await tables.get('/fields')
  return result.data
}
