<template>
  <div v-if="collect.status !== 'D'" :class="{'associated-collect' : associated, 'confirmed': collect.confirmation}" class="card">
    <div class="card-header centered-items">
      <b-tooltip
        v-if="collect.fake"
        :label="$t('pages.pickup_management.collect_confirmation.templates.hint')"
        position="is-left"
        style="margin-top: 1rem;"
      >
        <b-icon icon="info" type="is-info" class="is-small" />
      </b-tooltip>
      <div v-if="!collect.fake">
        <h2 class="subtitle centered-items">
          {{ $t('pages.pickup_management.calendar.collect') }}
        </h2>
        <span v-if="!associated" class="d-flex-justify-center">{{ collect.collectId }}</span>
        <span v-else class="d-flex-justify-center"> {{ collect.enduserCode + ' - ' + collect.enduserName }}</span>
      </div>
      <span v-if="!informative" class="top-right-button">
        <b-tooltip
          :label="$t('pages.pickup_management.calendar.send_collect_confirmation')"
          position="is-top"
        >
          <b-button v-if="(collect.newStatus === 'T1' || collect.newStatus === 'T2') && !collect.confirmation" icon-left="paper-plane" class="is-primary is-small" @click="collectConfirmation(collect)" />
        </b-tooltip>
      </span>
    </div>
    <div class="card-content">
      <span v-if="collect.fake">
        <span class="row-label">
          {{ $t('global.enduser') }} :
        </span>
        enduser_field
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.loading_date') }} :
        </span>
        {{ !collect.fake ? collect.loadingDate ? $dayjs(collect.loadingDate).format('DD-MM-YYYY') : '' : collect.loadingDate }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.arrival_date') }} :
        </span>
        {{ !collect.fake ? collect.arrivalDate && !collect.arrivalDate.includes('0000') ? $dayjs(collect.arrivalDate).format('DD-MM-YYYY') : '' : collect.arrivalDate }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.creation_date') }} :
        </span>
        {{ !collect.fake ? collect.creationDate && !collect.creationDate.includes('0000') ? $dayjs(collect.creationDate).format('DD-MM-YYYY') : '' : collect.creationDate }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.notification_date') }} :
        </span>
        {{ !collect.fake ? collect.notificationDate && !collect.notificationDate.includes('0000') ? $dayjs(collect.notificationDate).format('DD-MM-YYYY') : '' : collect.notificationDate }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.forwarder') }} :
        </span>
        {{ collect.forwarderName }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.shipment_nr') }} :
        </span>
        {{ collect.shipmentNr }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.destination_code') }} :
        </span>
        {{ collect.destCode }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.destination') }} :
        </span>
        {{ collect.destName }}
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.destination_address') }} :
        </span>
        {{ collect.destAdress }}
      </span>
      <span v-if="!collect.fake">
        <span class="row-label">
          {{ $t('global.status') }} :
        </span>
        <span :class="'S'+collect.newStatus">{{ collect.newStatus }}</span>
      </span>
      <span>
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.ob_nr') }} :
        </span>
        {{ collect.obNr }}
      </span>
      <span v-if="collect.confirmation">
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.confirmation') }} :
        </span>
        {{ this.$dayjs(collect.confirmation.createdAt).format('DD/MM/YYYY') }}
      </span>
      <span v-if="!collect.fake">
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.comment1') }} :
        </span>
        {{ collect.text1 }}
      </span>
      <span v-if="!collect.fake">
        <span class="row-label">
          {{ $t('pages.pickup_management.calendar.infos.comment2') }} :
        </span>
        {{ collect.text2 }}
      </span>
      <div class="separator" />
      <div v-if="collect.fake">
        <b>
          {{ $t('global.materials') }} :
        </b>
        <span>materials_field</span>
      </div>
      <div v-else>
        <div v-for="m in collect.materials" :key="m.collectId">
          <span>
            {{ m.mat }} :
            {{ parseInt(m.qty) || m.qty }}
          </span>
        </div>
      </div>
      <div>
        <b-button v-if="collect.newStatus === 'C'" type="is-danger is-small is-fullwidth" style="margin-top: 1rem;" @click="deleteCollect(collect.collectId)">
          {{ $t('global.delete') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    collect: {
      type: Object,
      default: () => {
        return {
          fake: true,
          enduser: 'enduser_field',
          loadingDate: 'loading_date_field',
          arrivalDate: 'arrival_date_field',
          forwarderName: 'forwarder_name_field',
          shipmentNr: 'shipment_nr_field',
          destCode: 'dest_code_field',
          destName: 'dest_name_field',
          destAdress: 'dest_address_field',
          newStatus: null,
          obNr: 'ob_nr_field',
          text1: null,
          text2: null,
          materials: [
            {
              'mat': 'mat_field',
              'qty': 'qty_field'
            }
          ]
        }
      }
    },
    associated: {
      type: Boolean,
      default: false
    },
    informative: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    collectConfirmation: function (collect) {
      this.$emit('collectConfirmation', collect)
    },
    deleteCollect: function (id) {
      this.$emit('deleteCollect', id)
    }
  }
}
</script>

<style scoped>
.card {
  margin-top: 2rem;
}
.centered-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-content > span {
  max-width: 350px;
}

.separator{
  height: 2px;
  background-color: #ced6e0;
  margin: 5px;
}

.custom-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.row-label {
  font-weight: bold;
}

.custom-column {
  margin: 1rem;
}

.associated-collects {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.line {
  width: 100px;
  height: 2px;
  border: solid 1px black;
  border-style: dashed;
}

.line-vert {
  width: 2px;
  height: 100px;
  border: solid 1px black;
  border-style: dashed;
}

.associated-columns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right-button {
  position: absolute;
  right: 0px;
}

.confirmed {
  /* background-color: rgba(180, 180, 180, .4);
  backgr */
  background: repeating-linear-gradient(
  65deg,
  rgba(76, 210, 228, 0.39),
  rgba(76, 210, 228, 0.329) 10px,
  rgba(76, 210, 228, 0.178) 10px,
  rgba(76, 210, 228, 0.178) 20px
);
}

.associated-collect {
  /* background-color: rgba(180, 180, 180, .4);
  backgr */
  background: repeating-linear-gradient(
  65deg,
  rgba(106, 191, 158, 0.39),
  rgba(106, 191, 158, 0.329) 10px,
  rgba(106, 191, 158, 0.178) 10px,
  rgba(106, 191, 158, 0.178) 20px
);
}
</style>
