<template>
  <div class="section">
    <div class="buttons">
      <b-button icon-left="arrow-left" class="is-warning" @click="$router.go(-1)">
        Back
      </b-button>
      <b-button v-if="!creating" icon-left="plus" type="is-info" @click="() => {creating = true}">
        Create
      </b-button>
    </div>
    <div v-if="creating">
      <div class="section">
        <h1 class="title">
          Create report translation
        </h1>

        <div class="flex-column">
          <div class="flex-row space-between">
            <div class="flex-row">
              <b-field class="field" label="Country">
                <multiselect
                  v-model="newReportTranslation.country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Pick country..."
                  label="name"
                  track-by="name"
                />
              </b-field>
              <b-field class="field" label="Sheet number">
                <b-input
                  v-model="newReportTranslation.sheetNbr"
                  name="sheetNbr"
                  type="number"
                  icon="file"
                />
              </b-field>
            </div>
            <div class="buttons">
              <b-button class="is-warning" @click="() => {creating = false}">
                Cancel
              </b-button>
              <b-button class="is-primary" @click="submit">
                Submit
              </b-button>
            </div>
          </div>
          <div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th />
                  <th>index</th>
                  <th>text</th>
                  <th>translation</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(translation, index) in newReportTranslation.translations" :key="index">
                  <td>
                    <div>
                      <b-button class="is-danger" icon-left="minus" @click="removeRow(index)" />
                    </div>
                  </td>
                  <td
                    style="width: 100px;"
                  >
                    <b-input
                      v-model="translation.index"
                      type="number"
                    />
                  </td>
                  <td>
                    <b-input
                      v-model="translation.text"
                      type="text"
                    />
                  </td>
                  <td>
                    <b-input
                      v-model="translation.trad"
                      type="text"
                    />
                  </td>
                </tr>
                <div>
                  <b-button class="is-info" icon-left="plus" @click="addRow" />
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 5px;">
      <ag-grid-vue
        style="width: 100%; height: 1000px;"
        class="ag-theme-balham"
        :row-data="reportTranslations"
        :grid-options="gridOptions"
        :pagination="true"
        :pagination-auto-page-size="true"
        :multi-sort-key="'ctrl'"
        :modules="modules"
        row-selection="multiple"
      />
    </div>
  </div>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
import reportTranslation from '@/services/v2/reportTranslation'
import axios from 'axios'

export default {
  data: function () {
    return {
      creating: false,
      modules: AllModules,
      countries: [],

      newReportTranslation: {
        sheetNbr: null,
        country: '',
        translations: [{
          index: null,
          text: '',
          trad: ''
        } ]
      },
      reportTranslations: [],

      gridOptions: {
        masterDetail: true,
        suppressAggFuncInHeader: true,
        getContextMenuItems: this.gridActions,
        groupMultiAutoColumn: true,
        autoGroupColumnDef: {
          pinned: 'left' // force pinned left. Does not work in columnDef
        },
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [
          {
            headerName: 'Country',
            field: 'country',
            rowGroup: true,
            hide: true
          },
          {
            headerName: 'Sheet nbr',
            field: 'sheetNbr',
            rowGroup: true,
            hide: true
          },
          {
            headerName: 'Index',
            field: 'index'
          },
          {
            headerName: 'Text',
            field: 'text'
          },
          {
            headerName: 'Translation',
            field: 'trad'
          }
        ]
      }
    }
  },
  mounted: function () {
    this.loadData()
  },
  methods: {
    loadData: async function () {
      this.reportTranslations = await reportTranslation.getAll()
      this.countries = (await axios.get('https://cors-anywhere.herokuapp.com/https://restcountries.eu/rest/v2/all')).data
    },
    addRow () {
      this.newReportTranslation.translations.push({
        index: null,
        text: '',
        trad: ''
      })
    },
    removeRow (index) {
      this.newReportTranslation.translations.splice(index, 1)
    },
    submit: async function () {
      try {
        let data = []
        this.newReportTranslation.translations.map(r => {
          data.push({
            sheet_nbr: parseInt(this.newReportTranslation.sheetNbr),
            country: this.newReportTranslation.country.name,
            index_: parseInt(r.index),
            text: r.text,
            translation_: r.trad
          })
        })
        await reportTranslation.insert(data)
        this.creating = false
        this.loadData()
        this.$buefy.toast.open({ message: 'Success', type: 'is-primary' })
      } catch (error) {
        if (error.message === 'ER_DUP_ENTRY') {
          this.$buefy.toast.open({ message: 'An entry already exist for the combination : sheet nbr - index', type: 'is-danger' })
        } else {
          this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        }
      }
    },
    gridActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      const selectedRows = this.gridOptions.api.getSelectedRows()
      if (selectedRows.length > 0) {
        customMenu.push({
          name: 'Delete',
          action: () => {
            this.deleteTranslation(selectedRows)
          },
          icon: '<i class="fas fa-fw fa-trash" ></i>'
        })
      }

      const result = [...customMenu, ...genericMenu]
      return result
    },
    deleteTranslation: async function (selectedRows) {
      this.$buefy.dialog.confirm({
        title: 'Delete',
        message: 'Are you sure you want to <b>delete</b> this entry? This action cannot be undone.',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            for (let i = 0; i < selectedRows.length; i++) {
              await reportTranslation.delete(selectedRows[i])
            }
            this.loadData()
            this.$buefy.toast.open({ message: 'Deleted', type: 'is-primary' })
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.field {
  margin: 1rem;
}

.space-between {
  align-content: space-between;
  justify-content: space-between
}
</style>
