<template>
  <div>
    <h2>{{ plant }}</h2>
    <light-timeline :items="items">
      <template slot="tag" slot-scope="{ item }">
        {{ getTimeLabel(item.week) }}
      </template>
      <template slot="content" slot-scope="{ item }">
        <TimelineCell
          :data="item"
          @to-save="$emit('to-save')"
        />
      </template>
    </light-timeline>
  </div>
</template>

<script>
import TimelineCell from './timelineCell'

export default {
  components: {
    TimelineCell
  },
  props: {
    material: {
      type: String,
      default: ''
    },
    plant: {
      type: String,
      default: ''
    },
    allData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      items: [],
      data: []
    }
  },
  watch: {
    'material' () {
      this.parsData()
    },
    'plant' () {
      this.parsData()
    },
    'allData' () {
      this.parsData()
    }
  },
  mounted: async function () {
    this.parsData()
  },
  methods: {
    getTimeLabel: function (date) {
      const stringDate = date.toString()
      return stringDate.slice(6) + '/' + stringDate.slice(4, 6) + '/' + stringDate.slice(0, 4)
    },
    parsData () {
      for (var i = 0; i !== this.allData.length; i++) {
        if (this.allData[i][0].plant === this.plant && this.allData[i][0].material === this.material) {
          this.items = this.allData[i]
        }
      }
    }
  }
}
</script>

<style>

</style>
