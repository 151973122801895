<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field :label="$t('pages.plants.forecast.range_date')">
          <b-datepicker
            :value="rangeDates"
            inline
            placeholder="Click to select..."
            range
            @input="$emit('rangeDates', $event)"
          />
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$t('pages.plants.forecast.working_days')">
          <b-checkbox :value="allowSaturday" @input="$emit('allowSaturday', $event)">
            {{ $t('global.saturday') }}
          </b-checkbox>
          <b-checkbox :value="allowSunday" @input="$emit('allowSunday', $event)">
            {{ $t('global.sunday') }}
          </b-checkbox>
        </b-field>
        <b-button
          icon-left="save"
          :label="$t('pages.plants.forecast.save_working_days')"
          type="is-primary"
          @click="$emit('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Parameters',
  props: {
    allowSunday: {
      default: false,
      type: Boolean
    },
    allowSaturday: {
      default: false,
      type: Boolean
    },
    rangeDates: {
      default: () => [],
      type: Array
    }
  }
}
</script>

<style scoped>
  h1 {
    font-size: 30px;
    font-weight: bold;
  }

</style>
