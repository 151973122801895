<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns is-mobile">
        <div class="column">
          <div class="box level">
            <div class="level-left">
              <p class="level-item">
                Empty crates
              </p>
            </div>
            <div class="level-right">
              <p class="level-item is-size-2 pr-6">
                {{ formattedStock['7EMP']?.length || 0 }}
              </p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box level">
            <div class="level-left">
              <p class="level-item">
                Full crates
              </p>
            </div>
            <div class="level-right">
              <p class="level-item is-size-2 pr-6">
                {{ formattedStock['8FUL']?.length || 0 }}
              </p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box level">
            <div class="level-left">
              <p class="level-item">
                Damaged crates
              </p>
            </div>
            <div class="level-right">
              <p class="level-item is-size-2 pr-6">
                {{ formattedStock['3REP']?.length || 0 }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ag-grid-vue
          class="ag-theme-alpine"
          style="height: 500px"
          :modules="modules"
          :pagination="true"
          :pagination-auto-page-size="true"
          :group-display-type="groupDisplayType"
          :grid-options="gridOptions"
          :row-data="stock"
          :column-defs="columnDefs"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Plant from '@/services/v2/plant'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  data () {
    return {
      modules: AllModules,
      groupDisplayType: 'custom',
      isLoading: false,
      formattedStock: {},
      stock: [],
      gridOptions: {
        autoGroupColumnDef: {
          headerName: 'Type'
        } },
      columnDefs: [
        { field: 'storageId',
          rowGroup: true,
          hide: true
        },
        { headerName: 'Barcode',
          field: 'barcode'
        },
        { headerName: 'Scanned on',
          field: 'scannedAt',
          minWidth: 250
        },
        { headerName: 'Movement type',
          field: 'stockType'
        },
        { headerName: 'Scanning device',
          field: 'deviceUuid'
        },
        { headerName: 'Synced on',
          field: 'syncedAt',
          minWidth: 250
        }
      ]

    }
  },
  async mounted () {
    this.isLoading = true
    this.formattedStock = await this.loadData()
    this.isLoading = false
  },
  methods: {
    async loadData () {
      this.stock = await Plant.getProducerStock(this.$route.params.plant_id)
      const comuptedStock = {}
      if (this.stock.length) {
        this.stock.forEach(s => {
          if (!comuptedStock[s.storageId]) {
            comuptedStock[s.storageId] = [s]
          } else {
            comuptedStock[s.storageId].push(s)
          }
        })
      }
      return comuptedStock
    }
  }
}
</script>
