import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/download'

const download = axios.create({
  baseURL
})

interceptorsSetup(download)

export default class Download {
  static async downloadSqliteFile () {
    const { data: file } = await download('/initDeviceDbSqlite', {
      responseType: 'blob'
    })
    return file
  }
  static async downloadInitDbCSV () {
    const { data: file } = await download('/initDeviceDb', {
      responseType: 'blob'
    })
    return file
  }
  static async generateDbFiles () {
    const { data: file } = await download('/generateSeed')
    return file
  }
}
