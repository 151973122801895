<template>
  <div v-if="showCollectConfirmation">
    <b-modal :active.sync="showCollectConfirmation" has-modal-card full-screen>
      <collectConfirmation :selected-group="selectedGroup" :collect="selectedCollect" @close="showCollectConfirmation = false" />
    </b-modal>
  </div>
  <div v-else class="modal-card" style="width: auto">
    <div class="modal-card-head centered-items">
      <div class="centered-items">
        <h2 class="title">
          {{ enduserCode + ' - ' + enduserName }}
        </h2>
        <h2 class="subtitle">
          {{ $t('pages.pickup_management.calendar.infos.week') }} {{ week }}
        </h2>
      </div>
    </div>
    <section id="calendar-step-coll-screen" class="modal-card-body">
      <div>
        <button id="calendar-step-coll-screen-back" class="button is-warning is-outlined is-small" @click="closeInfos">
          <b-icon icon="chevron-left" />
          <span>{{ $t('global.back') }}</span>
        </button>
      </div>
      <div class="custom-columns">
        <div v-for="collect in computedCollects" :key="collect.id" class="custom-column">
          <div class="associated-collects">
            <collectInfoCard
              :associated="false"
              :collect="collect"
              @deleteCollect="deleteCollect"
              @collectConfirmation="collectConfirmation"
            />
            <div v-for="ac in collect.associated" :key="ac.enduserCode" class="associated-columns">
              <div class="line" />
              <collectInfoCard
                :associated="true"
                :collect="ac"
                class="associated-collect"
                @deleteCollect="deleteCollect"
                @collectConfirmation="collectConfirmation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

// p,c,t

import Collect from '@/services/v2/collect'
import collectConfirmation from './collectConfirmation'
import CollectInfoCard from './collectInfoCard'

export default {
  components: {
    collectConfirmation,
    CollectInfoCard
  },
  props: {
    collects: {
      required: true,
      type: Array
    },
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      enduserName: '',
      enduserCode: '',
      week: '',
      showCollectConfirmation: false,
      associatedCollects: []
    }
  },
  computed: {
    computedCollects: function () {
      let collects = []
      this.associatedCollects.map(sc => {
        const same = this.collects.find(c => c.collectId === sc.collectId)
        if (!same.associated) {
          same.associated = []
        }
        let existingCollect = same.associated.find(ac => ac.collectId === sc.collectId && ac.enduserCode === sc.enduserCode)

        if (existingCollect) {
          existingCollect.materials.push({
            mat: sc.mat,
            qty: sc.qty
          })
        } else {
          sc.materials = [
            {
              mat: sc.mat,
              qty: sc.qty
            }
          ]
          same.associated.push(sc)
        }
      })
      this.collects.forEach(c => {
        this.collectPivot(collects, c)
      })
      return collects
    }
  },
  mounted: async function () {
    if (this.collects.length > 0) {
      this.enduserName = this.collects[0].enduserName
      this.enduserCode = this.collects[0].enduserCode
      this.week = this.collects[0].loadingWeek || this.collects[0].week
      // collects is a referenced array, so the next actions will affect the parent array and we do not want it to duplicate entries for each component displays
      if (!this.collects.find(c => c.associated)) {
        let promises = []
        const removedDoublon = []
        this.collects.forEach(c => {
          if (!removedDoublon.find(cc => cc.collectId === c.collectId)) {
            removedDoublon.push(c)
          }
        })
        removedDoublon.forEach(c => {
          promises.push(Collect.getAssociatedCollects(c.collectId, c.enduserCode))
        })

        const data = await Promise.all(promises)

        data.forEach(d => {
          this.associatedCollects = this.associatedCollects.concat(d)
        })

        // this.associatedCollects.forEach(ac => {
        //   ac.emails = this.tableData?.find(ce => ce.enduserCode.toString() === ac.enduserCode.toString())?.emails
        // })
      }
    }
  },
  methods: {
    collectPivot: function (collects, c) {
      let existingCollect = collects.find(e => e.collectId === c.collectId)
      if (existingCollect) {
        existingCollect.materials.push({
          mat: c.mat,
          qty: c.qty
        })
      } else {
        c.materials = [{
          mat: c.mat,
          qty: c.qty
        }]
        collects.push(c)
      }
    },
    closeInfos () {
      this.$emit('nextTourStep', 'collectDetailsBack')
      this.$emit('close')
    },
    async deleteCollect (id) {
      this.$buefy.dialog.confirm({
        title: this.$t('pages.pickup_management.calendar.infos.delete_collect.title'),
        message: this.$t('pages.pickup_management.calendar.infos.delete_collect.message'),
        confirmText: this.$t('pages.pickup_management.calendar.infos.delete_collect.confirm'),
        cancelText: this.$t('pages.pickup_management.calendar.infos.delete_collect.cancel'),
        type: 'is-link',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Collect.deleteCollect(id)
            this.$buefy.toast.open({ message: this.$t('pages.pickup_management.calendar.infos.collect_deleted'), type: 'is-primary' })
            this.collects.find(c => c.collectId === id).status = 'D'
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    },
    collectConfirmation: function (collect) {
      this.selectedCollect = collect
      this.showCollectConfirmation = !this.showCollectConfirmation
    }
  }

}
</script>

<style scoped>
.card {
  margin-top: 2rem;
}
.centered-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.separator{
  height: 2px;
  background-color: #ced6e0;
  margin: 5px;
}

.custom-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.row-label {
  font-weight: bold;
}

.custom-column {
  margin: 1rem;
}

.associated-collects {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.line {
  width: 100px;
  height: 2px;
  border: solid 1px black;
  border-style: dashed;
}

.line-vert {
  width: 2px;
  height: 100px;
  border: solid 1px black;
  border-style: dashed;
}

.associated-columns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right-button {
  position: absolute;
  right: 0px;
}

.associated-collect {
  /* background-color: rgba(180, 180, 180, .4);
  backgr */
  background: repeating-linear-gradient(
  65deg,
  rgba(106, 191, 158, 0.39),
  rgba(106, 191, 158, 0.329) 10px,
  rgba(106, 191, 158, 0.178) 10px,
  rgba(106, 191, 158, 0.178) 20px
);
}
</style>
