<template>
  <div class="padding">
    <span class="m-10">
      <b-select v-model="selectedGroup" :placeholder="$t('pages.pickup_management.select')">
        <option v-for="grp in collectGroups" :key="grp.collectGroup" :value="grp">
          {{ grp.collectGroup + ' (' + grp.description + ')' }}
        </option>
        <option :value="{ collectGroup: 'ALL' }">All (Warning: Lot of data)</option>
      </b-select>
    </span>
    <b-tabs type="is-toggle">
      <b-tab-item :label="$t('pages.pickup_management.pickup_management.title')">
        <pickupManagement :endusers="endusers" :selected-group="selectedGroup" />
      </b-tab-item>
      <b-tab-item :label="$t('pages.pickup_management.calendar.title')">
        <calendar :calendar-data="calendarData" :selected-group="selectedGroup || {}" />
      </b-tab-item>
      <b-tab-item :label="$t('pages.pickup_management.mail_observer.title')">
        <mailObserver :selected-group="selectedGroup || {}" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>

import Calendar from './calendar'
import pickupManagement from './pickupManagement'
import mailObserver from './mailObserver'
import Collect from '@/services/v2/collect'

export default {
  components: {
    Calendar,
    pickupManagement,
    mailObserver
  },
  data: function () {
    return {
      collectGroups: [],
      selectedGroup: null,
      endusers: null,
      pickupData: null,
      calendarData: null,
      quantities: null
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: async function () {
    const result = await Collect.getGroups()
    // sort the result into ascending order
    result.sort((a, b) => {
      const ga = a.collectGroup.toLowerCase()
      const gb = b.collectGroup.toLowerCase()

      if (ga < gb) {
        return -1
      }

      if (ga > gb) {
        return 1
      }

      return 0
    })
    this.collectGroups = result
  },
  methods: {
  }
}
</script>

<style>
.padding {
  padding: 1rem;
}

.caption {
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
  border: dashed;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.caption .caption-title {
  text-decoration: underline;
}

.pickup-actions-container {
  display: inline-block;
  position: absolute;
  top: -2rem;
  right: 1rem;
}

.pickup-actions-container span {
  margin-left: 5px;
}
</style>
