<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field :label="$t('pages.plants.forecast.range_date')">
          <b-datepicker
            v-model="editionParams.rangeDates"
            inline
            :min-date="new Date($dayjs().subtract('1', 'day'))"
            placeholder="Click to select..."
            range
          />
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$t('pages.plants.forecast.working_days')">
          <b-checkbox v-model="allowSaturday">
            {{ $t('global.saturday') }}
          </b-checkbox>
          <b-checkbox v-model="allowSunday">
            {{ $t('global.sunday') }}
          </b-checkbox>
        </b-field>

        <b-field>
          <b-select v-model="editionParams.material" :placeholder="$t('global.material')">
            <option v-for="option in materials" :key="option" :value="option">
              {{ option }}
            </option>
          </b-select>
          <b-select v-model="editionParams.operation" :placeholder="$t('global.operation')">
            <option
              v-for="option in operations.filter(e => e.type === 'operation')"
              :key="option.operation"
              :value="option.operation"
            >
              {{ option.operation }}
            </option>
          </b-select>
        </b-field>
        <b-field>
          <b-input v-model="editionParams.quantity" type="number" />
          <p class="control">
            <b-button
              type="is-info"
              :label="$t('global.edit')"
              icon-left="edit"
              :disabled="!editionParams.material || !editionParams.operation || editionParams.quantity === '0'"
              @click="updateOperations"
            />
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsertByRange',
  props: {
    propsAllowSunday: {
      default: false,
      type: Boolean
    },
    propsAllowSaturday: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      materials: ['GPS1', 'GPS51', 'GPS52', 'GPS55', 'RHODIA', 'ZEON', 'GPSLID'],
      operations: [
        { operation: 'DIRTY', type: 'stock' },
        { operation: 'TO_REPAIR', type: 'stock' },
        { operation: 'AVAILABLE', type: 'stock' },
        { operation: 'RECEIVED', type: 'operation' },
        { operation: 'DELIVERED', type: 'operation' },
        { operation: 'WASHED', type: 'operation' },
        { operation: 'REPAIRED', type: 'operation' },
        { operation: 'REJECTED', type: 'operation' }
      ],
      editionParams: {
        material: null,
        operation: null,
        quantity: '0',
        rangeDates: [this.$dayjs().toDate(), this.$dayjs().add('1', 'w').toDate()]
      },
      allowSunday: false,
      allowSaturday: false
    }
  },
  watch: {
    propsAllowSunday: function (val) {
      this.allowSunday = val
    },
    propsAllowSaturday: function (val) {
      this.allowSaturday = val
    }
  },
  methods: {
    updateOperations () {
      let newDatas = []

      for (let m = this.$dayjs(this.editionParams.rangeDates[0]); m.isSameOrBefore(this.$dayjs(this.editionParams.rangeDates[1])); m = m.add(1, 'days')) {
        if ((m.day() === 6 && this.editionParams.allowSaturday) ||
          (m.day() === 0 && this.editionParams.allowSunday) ||
          (m.day() !== 6 && m.day() !== 0)) {
          newDatas.push({
            material: this.editionParams.material,
            quantity: +this.editionParams.quantity,
            updatedAt: new Date(),
            updatedBy: this.$store.state.auth.user.id,
            operation: this.editionParams.operation,
            productionDate: new Date(m.format('YYYY-MM-DD'))
          })
        }
      }
      this.$emit('updateOperations', newDatas)
    }
  }

}
</script>

<style scoped>
  h1 {
    font-size: 30px;
    font-weight: bold;
  }

</style>
