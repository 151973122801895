<template>
  <div id="gps-map">
    <b-loading :active.sync="isLoading" :is-full-page="false" />
    <div class="columns is-gapless">
      <!-- <div class="column is-2"> -->
      <div>
        <b-icon
          v-if="isFetchingMarkerData"
          type="is-info"
          size="is-large"
          icon="spinner"
          custom-class="fa-spin"
        />
        {{ markerData }}
      </div>
      <!-- </div> -->
      <div class="column">
        <VueLeafletMap v-if="!isLoading" :markers="filteredMarkers" name="leaflet" :no-coords="noCoords">
          <map-filter :markers="markers" @filter-changed="(markers) => filteredMarkers = markers" />
        </VueLeafletMap>
      </div>
    </div>
  </div>
</template>

<script>

import VueLeafletMap from '@/components/map/vue-leafletmap'
import Customer from '@/services/v2/customer'
import Enduser from '@/services/v2/enduser'
import Plant from '@/services/v2/plant'
import mapFilter from '@/components/map/map-filter'
import { markerTypes } from '@/components/map/mTypes'
import mapMethods from '@/mixins/mapMethods'
import { mapState } from 'vuex'

export default {
  components: {
    VueLeafletMap,
    mapFilter
  },
  mixins: [mapMethods],
  data: function () {
    return {
      isLoading: true,
      isFetchingMarkerData: false,
      markerData: null,
      markers: [],
      filteredMarkers: [],
      noCoords: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
    try {
      const result = await Promise.all([Customer.getAll({}), Enduser.getAll({}), Plant.getAll({})])
      let customers = result[0]
      let endusers = result[1]
      let plants = result[2]
      const gpsPlantsPattern = ['C', 'D', 'E']

      let gpsPlants = plants.filter(p => !gpsPlantsPattern.includes(p.id[2]))
      let subPlants = plants.filter(p => gpsPlantsPattern.includes(p.id[2]))

      this.noCoords.push(customers.filter(e => !e.lat), endusers.filter(e => !e.lat))

      let enduserMarkerType = markerTypes.get('enduser')
      let customerMarkerType = markerTypes.get('customer')
      let plantMarkerType = markerTypes.get('plant')
      let subcontractorMarkerType = markerTypes.get('subcontractor')

      this.markers.push(...this.generateMarkers('enduser', endusers, enduserMarkerType.markerIcon))
      this.markers.push(...this.generateMarkers('customer', customers, customerMarkerType.markerIcon))
      this.markers.push(...this.generateMarkers('plant', gpsPlants, plantMarkerType.markerIcon))
      this.markers.push(...this.generateMarkers('plant', subPlants, subcontractorMarkerType.markerIcon))
    } catch (error) {
      this.$buefy.toast.open({ message: error.message, duration: 3000 })
    }

    this.isLoading = false
  },
  methods: {
  }
}
</script>
