<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Distributior setting
      </p>
      <button
        type="button"
        class="delete"
        @click="$emit('on-close')"
      />
    </header>
    <section class="modal-card-body">
      <div class="mb-5">
        <b-tag type="is-info" size="is-medium" class="mb-2" rounded>
          {{ distributor.id }} - {{ distributor.name }}
        </b-tag>
        <b-tag type="is-info" size="is-medium" rounded>
          {{ distributor.address || '' }} {{ distributor.city }}, {{ distributor.country }}
        </b-tag>
      </div>
      <b-loading v-model="isLoading" :is-full-page="false" />
      <b-field label="Group">
        <b-select v-model="selectedGroup" placeholder="Select a group...">
          <option v-for="group in groups" :key="group.collectGroup" :value="group">
            {{ group.collectGroup }} - {{ group.description }}
          </option>
        </b-select>
      </b-field>
      <div class="mt level-right">
        <b-button type="is-primary" :loading="isLoading" @click="save">
          Save
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import Collect from '../../services/v2/collect'
import Distribution from '../../services/v2/distribution'

export default {
  props: {
    distributor: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      isLoading: false,
      groups: null,
      selectedGroup: null
    }
  },
  async mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.isLoading = true
      const result = await Collect.getGroups()
      this.groups = result

      if (this.distributor.group.length) {
        const curGroup = result.filter(e => e.collectGroup === this.distributor.group[0].group)
        this.selectedGroup = curGroup[0]
      }
      this.isLoading = false
    },
    async save () {
      this.isLoading = true
      try {
        await Distribution.setGroup({
          id: this.distributor.group.length ? this.distributor.group[0].id : null,
          distributorCode: this.distributor.id,
          group: this.selectedGroup.collectGroup
        })
        this.$buefy.toast.open({
          message: 'Successful',
          type: 'is-primary' })
        this.$emit('on-close')
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger' })
      }
      this.isLoading = false
    }
  }

}
</script>
