<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <b-loading :active.sync="loading" is-full-page />
      <i>Please try to download your files before generating files</i>
      <div class="columns">
        <div class="column is-one-third">
          <b-button icon-left="database" class="button is-link" @click="downloadSqliteFile">
            {{ $t('pages.admin_panel.download_sqlite_file') }}
          </b-button>
        </div>
        <div class="column is-one-third">
          <b-button icon-left="file-csv" class="button is-link" @click="downloadInitDbCSV">
            {{ $t('pages.admin_panel.download_initdb_file') }}
          </b-button>
        </div>
        <div class="column is-one-third">
          <b-button icon-left="compact-disc" class="button is-dark" @click="generateDbFiles">
            {{ $t('pages.admin_panel.generate_db_files') }}
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Download from '@/services/v2/download'
import { saveAs } from 'file-saver'

export default {
  name: 'AdminPanel',
  data: function () {
    return {
      loading: false,
      sqliteFileExist: true
    }
  },
  mounted: function () {
    this.sockets.subscribe('process::sqliteDbFiles', (data) => {
      const notif = this.$store.state.generateSqliteDbFilesNotification
      if (notif) this.$store.state.generateSqliteDbFilesNotification.close()

      this.$buefy.notification.open({
        message: `SQlite database files successfully generated 😁`,
        closable: true,
        indefinite: true,
        type: 'is-primary'
      })
    })
    this.sockets.subscribe('exception::sqliteDbFiles', (data) => {
      const notif = this.$store.state.generateSqliteDbFilesNotification
      if (notif) this.$store.state.generateSqliteDbFilesNotification.close()

      this.$buefy.notification.open({
        message: `An error occured while generating files 😟`,
        closable: true,
        indefinite: true,
        type: 'is-danger'
      })
    })
  },
  beforeRouteLeave: function (to, from, next) {
    this.sockets.unsubscribe('process::sqliteDbFiles')
    this.sockets.unsubscribe('exception::sqliteDbFiles')
    next()
  },
  methods: {
    async downloadSqliteFile () {
      try {
        this.loading = true
        const result = await Download.downloadSqliteFile()
        saveAs(result, 'scan.sqlite3')
        this.loading = false
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        this.loading = false
      }
    },
    async downloadInitDbCSV () {
      try {
        this.loading = true
        const result = await Download.downloadInitDbCSV()
        saveAs(result, 'initDb.csv')
        this.loading = false
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        this.loading = false
      }
    },
    async generateDbFiles () {
      try {
        this.loading = true
        this.$socket.emit('process::sqliteDbFiles')
        this.$store.dispatch('generateSqliteDbFilesNotification')
        this.loading = false
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        this.loading = false
      }
    }
  }

}
</script>

<style>

</style>
