import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/newCrates'

const newCrates = axios.create({
  baseURL
})
interceptorsSetup(newCrates)

export default class NewCrates {
  static async setNewCratesData (data) {
    const res = await newCrates.post('/', data)
    return res.data
  }

  static async getNewCratesData () {
    const result = await newCrates.get(`/`)
    return result.data
  }

  static async getTemplate () {
    const result = await newCrates.get('/template-newcrates', {
      responseType: 'blob'
    })
    return result
  }
}
