import Vue from 'vue'
import store from './store'
import App from './App.vue'
import './vee-validate'
import './registerServiceWorker'
import router from './router'

import Buefy, { NotificationProgrammatic as Notification } from 'buefy'

// import * as Sentry from '@sentry/browser'
import VueApollo from 'vue-apollo'
import dayjs from 'dayjs'
// import dayjsPluginUTC from 'dayjs-plugin-utc'

import axios from 'axios'
import lodash from 'lodash'
import i18n from './i18n'
import VueTour from 'vue-tour'

import { version } from '../package.json'

import * as gql from 'gql-query-builder'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/assets/css/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import './assets/sass/main.scss'
import './assets/main.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css'

import { LicenseManager } from '@ag-grid-enterprise/core'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import apolloClient from './plugins/apolloClient'

import { io } from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'

const weekOfYear = require('dayjs/plugin/weekOfYear')
const customParseFormat = require('dayjs/plugin/customParseFormat')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
var isoWeeksInYear = require('dayjs/plugin/isoWeeksInYear')
var isLeapYear = require('dayjs/plugin/isLeapYear') // dependent on isLeapYear plugin
var isoWeek = require('dayjs/plugin/isoWeek')

const utc = require('dayjs/plugin/utc')

dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)
dayjs.extend(isoWeek)

// dayjs.extend(dayjsPluginUTC)
dayjs.extend(utc)

Vue.prototype.appVersion = version
Vue.prototype.$dayjs = dayjs
Vue.prototype.$http = axios
Vue.prototype.$lodash = lodash
Vue.prototype.$gql = gql

Vue.config.productionTip = false

require('vue-tour/dist/vue-tour.css')

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: process.env.VUE_APP_INSIGHTS,
  enableDebug: process.env.NODE_ENV === 'development',
  enableAutoRouteTracking: true
} })

if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights()
}

Vue.use(VueApollo)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

const globalErrorHandler = (err) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  appInsights.trackException({
    error: err
  })

  Notification.open({
    duration: 5000,
    message: 'Oups, something went wrong 😕',
    position: 'is-bottom-right',
    type: 'is-danger',
    hasIcon: true
  })
}

Vue.config.errorHandler = (err) => {
  globalErrorHandler(err)
}

window.onerror = function (message, source, lineno, colno, err) {
  globalErrorHandler(err)
}

Vue.use(VueTour)

LicenseManager.setLicenseKey('CompanyName=Groupe GPS SAS,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-010642,ExpiryDate=17_October_2021_[v2]_MTYzNDQyNTIwMDAwMA==36fc418a328fe21f0f6521e1763f6e1b')

const socket = io(process.env.VUE_APP_API_BASE_URL, {
  transports: [ 'websocket', 'polling' ]
})

Vue.use(new VueSocketIO({
  debug: process.env.NODE_ENV === 'development',
  connection: socket,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
})
)

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

axios.defaults.headers.common.User = store.state.auth.user?.displayName

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')
