import interceptorsSetup from '@/helpers/interceptors'
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserDeclarationAdmin'

const enduserDeclarationAdmin = axios.create({
  baseURL
})

interceptorsSetup(enduserDeclarationAdmin)

export default class EnduserDeclarationAdmin {
  static async getAll (date) {
    const { data: result } = await enduserDeclarationAdmin.get(`/mail`, { params: { 'date': date } })
    return result
  }

  static async getPickupData (params) {
    const { data: result } = await enduserDeclarationAdmin.get(`/pickupData`, { params: params })
    return result
  }

  static async getCalendarData (params) {
    const { data: result } = await enduserDeclarationAdmin.get(`/calendarData`, { params: params })
    return result
  }

  static async getByEnduser (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.get(`/mail/byEnduser/${enduserCode}`)
    return result
  }

  static async getQuantities (id) {
    const { data: result } = await enduserDeclarationAdmin.get(`/declaration/${id}/quantities`)
    return result
  }

  static async getEndusersQuantities (endusers) {
    const { data: result } = await enduserDeclarationAdmin.post(`/declaration/quantities`, endusers)
    return result
  }

  static async getDeclaration (id) {
    const { data: result } = await enduserDeclarationAdmin.get(`/declaration/${id}`)
    return result
  }

  static async getDeclarationWithFilters (filters) {
    const { data: result } = await enduserDeclarationAdmin.get(`/declaration/`, { params: filters })
    return result
  }

  static async getReminders (mailId) {
    const { data: result } = await enduserDeclarationAdmin.get(`/mail/${mailId}/reminders`)
    return result
  }

  static async getMailTemplates (collectGroup) {
    const { data: result } = await enduserDeclarationAdmin.get(`/mailTemplates/${collectGroup}`)
    return result
  }

  static async getMailTemplatesMap () {
    const { data: result } = await enduserDeclarationAdmin.get(`/mailTemplatesMap/`)
    return result
  }

  static async postMailTemplate (template) {
    const { data: result } = await enduserDeclarationAdmin.post(`/mailTemplates/`, template)
    return result
  }

  static async setMailTemplate (map) {
    const { data: result } = await enduserDeclarationAdmin.post(`/mailTemplatesMap/`, map)
    return result
  }

  static async deleteMailTemplate (templateId) {
    const { data: result } = await enduserDeclarationAdmin.delete(`/mailTemplates/${templateId}`)
    return result
  }

  static async getCoords (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.get(`/coords/${enduserCode}`)
    return result
  }

  static async setCoords (enduserCode, lat, lng) {
    const { data: result } = await enduserDeclarationAdmin.post(`/coords/`, { enduserCode: enduserCode, lat: lat, lng: lng })
    return result
  }

  static async deleteCoords (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.delete(`/coords/${enduserCode}`)
    return result
  }

  static async manualDeclaration (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.get(`/manualDeclaration/${enduserCode}`)
    return result
  }

  static async getMaterials (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.get(`/materials/`, { params: { enduserCode: enduserCode } })
    return result
  }

  static async deleteMail (mailId) {
    const { data: result } = await enduserDeclarationAdmin.delete(`/deleteMail/${mailId}`)
    return result
  }

  static async getPdfReport (token) {
    var FileSaver = require('file-saver')
    FileSaver.saveAs(baseURL + `/report/${token}/`, 'report.pdf')
  }

  static async getInventories (filters) {
    const { data: result } = await enduserDeclarationAdmin.get(`/inventory/`, { params: filters })
    return result
  }

  static async getSignature (collectGroup) {
    const { data: result } = await enduserDeclarationAdmin.get(`/signatures/${collectGroup}`)
    return result
  }

  static async insertSignature (signature) {
    const { data: result } = await enduserDeclarationAdmin.post(`/signatures`, { signature: signature })
    return result
  }

  static async deleteSignature (group) {
    const { data: result } = await enduserDeclarationAdmin.delete(`/signatures/${group}`)
    return result
  }

  static async updateSeeDeclaration (declarationId) {
    const { data: result } = await enduserDeclarationAdmin.get(`/declaration/${declarationId}/seen`)
    return result
  }

  static async sendMail (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.post(`/sendMail`, { enduserCode: enduserCode })
    return result
  }

  static async getEndusersInfos () {
    const { data: result } = await enduserDeclarationAdmin.get(`/enduserInfos/`)
    return result
  }

  static async getEnduserInfos (enduserCode) {
    const { data: result } = await enduserDeclarationAdmin.get(`/enduserInfos/${enduserCode}`)
    return result
  }

  static async setEnduserInfos (data) {
    const { data: result } = await enduserDeclarationAdmin.post(`/enduserInfos/`, data)
    return result
  }

  static async getConfirmationTemplates (collectGroup) {
    const { data: result } = await enduserDeclarationAdmin.get(`/confirmationTemplates/byGroup/${collectGroup}`)
    return result
  }

  static async postConfirmationTemplates (data) {
    const { data: result } = await enduserDeclarationAdmin.post(`/confirmationTemplates/`, data)
    return result
  }

  static async deleteConfirmationTemplates (id) {
    const { data: result } = await enduserDeclarationAdmin.delete(`/confirmationTemplates/${id}`)
    return result
  }

  static async getNotRecentlyCollected (collectGroup) {
    const { data: result } = await enduserDeclarationAdmin.get(`/notRecentlyCollected/${collectGroup}`)
    return result
  }

  static async getBlockedEmails (group) {
    const { data: result } = await enduserDeclarationAdmin.get(`/mailObserver/blocked/${group}`)
    return result
  }
}
