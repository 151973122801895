const markerUrl = 'https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-star&background='

const pickupMTypes = new Map()
pickupMTypes.set('enduser', {
  type: 'enduser',
  color: '90A4AE',
  label: 'Enduser'
})

pickupMTypes.set('stock', {
  type: 'stock',
  color: '42a5f5',
  label: 'Not yet collected'
})

pickupMTypes.forEach(element => {
  element.markerIcon = markerUrl + element.color
})

export {
  markerUrl,
  pickupMTypes
}
