<template>
  <div class="section">
    <div class="container">
      <div class="level">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button type="is-info" icon-left="plus" @click="creatingReport = true">
              New
            </b-button>
            <b-button type="is-info" icon-left="plus" @click="verifReport = true">
              Stock validation
            </b-button>
          </div>
          <div v-if="creatingReport" />
        </div>

        <div class="level-right">
          <div class="level-item">
            <b-button icon-left="refresh" :loading="isLoading" :disabled="isLoading" @click="() => refresh()">
              Refresh
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <b-loading v-model="isLoading" :is-full-page="false" />
          <ag-grid-vue
            style="width: 100%; height: 600px;"
            class="ag-theme-balham"
            :row-data="reports"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-auto-page-size="true"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            row-selection="multiple"
          />
        </div>
      </div>

      <b-modal :active.sync="creatingReport" scroll="keep" has-modal-card>
        <ReportForm :reports="fullReports" :extended-reports="reports" :customers="customers" @submit="generateReport" />
      </b-modal>

      <b-modal :active.sync="verifReport" scroll="keep" has-modal-card>
        <ReturnData :customers="customers" @submit="sendData" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

import Customer from '@/services/v2/customer'
import Report from '@/services/v2/report'
import ReportForm from './report-form'
import ReturnData from './return-data'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'

export default {
  components: {
    ReportForm,
    ReturnData
  },
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      modules: AllModules,
      reports: [],
      fullReports: [],
      customers: [],
      creatingReport: false,
      verifReport: false,
      isLoading: false,
      gridOptions: {
        masterDetail: true,
        getContextMenuItems: this.mainActions,

        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [
          {
            headerName: 'Report ID',
            field: 'id',
            sort: 'desc'
          },
          {
            headerName: 'Ship to',
            valueGetter: (params) => {
              if (params.data.customerCode === '-1' || params.data.customerCode === null) {
                return ''
              } else {
                return params.data.customerCode + ' - ' + this.customers.find(c => c.id === params.data.customerCode)?.name
              }
            }
          },
          {
            headerName: 'Sold to',
            valueGetter: (params) => {
              if (params.data.customerSoldTo === '-1' || params.data.customerSoldTo === null) {
                return ''
              } else {
                return params.data.customerSoldTo + ' - ' + this.customers.find(c => c.id === params.data.customerSoldTo)?.name
              }
            }
          },
          {
            headerName: 'Generation time',
            valueGetter: (params) => {
              return params.data && params.data.generationDate ? this.$dayjs(params.data.generationDate).format('DD/MM/YYYY') : ''
            },
            comparator: this.dateSort
          },
          {
            headerName: 'Period',
            valueGetter: (params) => {
              if (params.data.validate) return this.$dayjs(params.data.dateEnd, 'DDMMYYYY').format('DD/MM/YYYY')
              return params.data ? this.$dayjs(params.data.dateStart, 'DDMMYYYY').format('DD/MM/YYYY') + ' - ' + this.$dayjs(params.data.dateEnd, 'DDMMYYYY').format('DD/MM/YYYY') : ''
            },
            comparator: this.dateSort
          },
          {
            headerName: 'Stock validation',
            field: 'validate',
            cellRenderer: (params) => {
              return params.value ? '<i class="fa fa-check has-text-primary" />' : '<i class="fa fa-triangle-exclamation has-text-warning" />'
            },
            tooltipValueGetter: (params) => {
              return params.value ? '' : 'Please validate the most recent stock.'
            }
          },
          {
            headerName: 'Report',
            valueGetter: (params) => {
              if (!params.data.validate) return params.data.hasReport ? 'Ready to download' : 'Processing'
            }
          }
        ]
      }

    }
  },
  created: async function () {
    await this.loadData()
  },
  methods: {
    sendData: function (data) {
      Report.returnData(data)
      this.verifReport = false
      this.refresh()
    },
    async loadAllReports () {
      this.fullReports = await Report.getAll()
      const allReports = await Report.getRecentReport()
      allReports.forEach(async report => {
        const result = await this.hasReport(report.customerCode, report.customerSoldTo, report.dateEnd)
        report.hasReport = result.data.hasReport
        this.reports.push(report)
      })
    },
    loadData: async function () {
      this.isLoading = true
      this.customers = await Customer.getAll({})
      await this.loadAllReports()
      this.isLoading = false
    },
    generateReport: async function (data) {
      this.$buefy.notification.open({
        message: 'Your file is being processed, this might take a few minutes...',
        type: 'is-info',
        closable: true,
        hasIcon: true,
        indefinite: false,
        autoClose: true,
        duration: 600
      })
      Report.generateReport(data)
      this.refresh()
    },

    mainActions (params) {
      if (params.node.data.hasReport) {
        return [
          {
            name: 'Get report',
            icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
            action: () => {
              this.getReport(params.node.data)
            }
          }
        ]
      }
    },

    getReport: async function (data) {
      try {
        const result = await Report.getReport(data.customerCode, data.customerSoldTo, data.dateEnd)
        saveAs(result.data, 'Report_' + data.customerCode + '_' + data.customerSoldTo + '_' + data.dateEnd + '.zip')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },

    hasReport: async function (customerCode, customerSoldTo, dateEnd) {
      try {
        const result = await Report.hasReport(customerCode, customerSoldTo, dateEnd)
        return result
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },

    async refresh () {
      this.isLoading = true
      this.reports = []
      await this.loadAllReports()
      this.gridOptions.api.refreshCells()
      this.isLoading = false
    }

  }
}
</script>
