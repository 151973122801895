<template>
  <section id="customer-page" class="section">
    <!-- SEARCH BOX -->
    <div class="columns">
      <div class="column is-7">
        <b-field>
          <b-input v-model="search" icon="search" placeholder="Search" @input="changeQuery" />
        </b-field>
      </div>
      <div class="column is-5">
        <div class="buttons">
          <b-button tag="router-link" :to="{ name: 'export-recap-all' }" icon-left="history" type="is-link">
            File import history
          </b-button>
          <b-button type="is-primary" icon-left="folder-plus" @click="createModal()">
            Add new file setting
          </b-button>
        </div>
      </div>
    </div>

    <!-- DUPLICATE FILE SETTING MODAL -->
    <b-modal scroll="keep" has-modal-card :active.sync="isDuplicating">
      <div class="modal-card" style="min-height: 560px">
        <header class="modal-card-head">
          Duplicate file setting
        </header>
        <section class="modal-card-body">
          <div>Select which customer you want this file setting to be copied for</div>
          <multiselect
            v-model="duplicateTo"
            :options="customers"
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            :close-on-select="true"
            placeholder="Select customer..."
          />
        </section>
        <footer class="modal-card-foot">
          <div class="buttons">
            <button class="button is-danger is-outlined" @click="isDuplicating = false">
              Cancel
            </button>
            <button class="button is-info" :class="{ 'is-loading': duplicateLoading }" :disabled="duplicateTo === null" @click="duplicateFileSetting">
              Duplicate
            </button>
          </div>
        </footer>
      </div>
    </b-modal>
    <!-- ADD FILE SETTING MODAL -->
    <b-modal scroll="keep" has-modal-card :active.sync="isCreating">
      <div class="modal-card" style="min-height: 560px">
        <header class="modal-card-head">
          Create file setting
        </header>
        <section class="modal-card-body">
          <div>Select which customer you want a file setting to be created for</div>
          <multiselect
            v-model="createFor"
            :options="customers"
            :custom-label="(obj) => obj.id + ' - ' + obj.name"
            :close-on-select="true"
            placeholder="Select customer..."
          />
        </section>
        <footer class="modal-card-foot">
          <div class="buttons">
            <button class="button is-danger is-outlined" @click="isCreating = false">
              Cancel
            </button>
            <button class="button is-info" :class="{ 'is-loading': duplicateLoading }" :disabled="createFor === null" @click="createFileSetting">
              Create
            </button>
          </div>
        </footer>
      </div>
    </b-modal>

    <!-- FILE SETTINGS TABLE -->
    <b-table
      class="mt-10"
      :data="filteredCustomerFileSettings"
      :loading="isLoading"
      default-sort="customer.name"
      paginated
      striped
      narrowed
    >
      <b-table-column v-slot="props" field="customer.id" label="Customer id" sortable>
        {{ props.row.customer.id }}
      </b-table-column>
      <b-table-column v-slot="props" field="customer.name" label="Customer name" sortable>
        {{ props.row.customer.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="table" label="Type" sortable>
        {{ $lodash.upperFirst(props.row.table) }}
      </b-table-column>
      <b-table-column v-slot="props" field="parser" label="Actions" width="170">
        <b-tooltip v-if="props.row.parser === 'edi'" label="View natural rubber page" type="is-link">
          <b-button
            size="is-small"
            type="is-link"
            icon-left="tree"
            tag="router-link"
            :to="{ name: 'shipping-instructions' }"
          />
        </b-tooltip>
        <b-tooltip v-else label="View file history" type="is-link">
          <b-button
            size="is-small"
            type="is-link"
            icon-left="align-center"
            tag="router-link"
            :to="{ name: 'export-recap', params: {fip_id: props.row.id } }"
          />
        </b-tooltip>
        <b-tooltip label="Duplicate" type="is-info">
          <b-button
            size="is-small"
            type="is-info"
            icon-left="plus"
            @click="duplicateModal(props.row.customer.id, props.row.fipId)"
          />
        </b-tooltip>
        <b-tooltip label="Edit" type="is-primary">
          <b-button
            size="is-small"
            type="is-primary"
            icon-left="edit"
            @click="setEditing(props.row.customer.id, props.row.fipId)"
          />
        </b-tooltip>
        <b-tooltip label="Delete" type="is-danger">
          <b-button
            size="is-small"
            type="is-danger"
            icon-left="times"
            @click="deleteFileSettings(props.row.customer.id, props.row.fipId)"
          />
        </b-tooltip>
        <b-tooltip label="Test validity" type="is-warning is-white">
          <label>
            <input
              v-show="false"
              class="file-input"
              type="file"
              name="export"
              required
              @input="exportFile($event, props.row.fipId, props.row.table)"
            >
            <span class="is-warning is-small button" :class="{ 'is-loading': testLoading[props.row.fipId] }">
              <b-icon icon="upload" type="is-white" size="is-small" />
            </span>
          </label>
        </b-tooltip>
      </b-table-column>
      <template slot="empty">
        <nothing-here content="No file setting matching your criteria was found" />
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { addExport } from '@/services/v1/file'
import Vue from 'vue'
import * as Customer from '@/services/v1/customer'
import Customerv2 from '@/services/v2/customer'

export default {
  data () {
    return {
      fileSettings: [],
      customers: [],
      testFileSettingError: '',
      showTestFileSettingError: false,
      search: '',
      isLoading: true,
      testLoading: {},
      isDuplicating: false,
      duplicateTo: null,
      isCreating: false,
      createFor: null,
      duplicateFromCustomer: null,
      duplicateFromId: null,
      duplicateLoading: false,
      duplicateError: null
    }
  },

  computed: {
    filteredCustomerFileSettings () {
      const search = this.search.toLowerCase()
      return this.fileSettings.filter(row => {
        return row.customer.name.toLowerCase().indexOf(search) > -1 ||
        row.customer.id.toLowerCase().indexOf(search) > -1
      })
    }
  },
  async mounted () {
    const [fileSettings, customers] = await Promise.all([
      Customer.loadCustomerFileSettings('all'),
      Customerv2.getAll({})
    ])
    this.fileSettings = fileSettings.filter(e => e.customer)
    this.customers = customers
    this.isLoading = false
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
  },
  methods: {
    ...mapActions(['deleteCustomerFileSettings', 'duplicateCustomerFileSettings']),
    changeQuery (e) {
      this.$router.replace({ query: { search: e } })
    },
    async duplicateFileSetting () {
      this.duplicateLoading = true
      try {
        await this.duplicateCustomerFileSettings({ fromCustomer: this.duplicateFromCustomer, fromId: this.duplicateFromId, toCustomer: this.duplicateTo.id })
        this.fileSettings = await Customer.loadCustomerFileSettings('all')
        this.isDuplicating = false
      } catch (e) {
        this.duplicateError = e.message || e
      }
      this.duplicateLoading = false
    },
    duplicateModal (customer, id) {
      this.duplicateError = null
      this.isDuplicating = true
      this.duplicateFromCustomer = customer
      this.duplicateFromId = id
    },
    async createFileSetting () {
      if (!this.createFor || !this.createFor.id) {
        return this.$buefy.toast.open({
          message: 'You need to select a customer',
          type: 'is-danger',
          duration: 5000
        })
      }
      this.setEditing(this.createFor.id, 'new')
    },
    createModal (customer, id) {
      this.isCreating = true
      this.createFor = null
    },
    setEditing (customer, id) {
      this.$router.push({ name: 'edit-file-settings', params: { selectedCustomerId: customer + '', fileSettingsId: id + '' } })
    },
    uploadExport: async function (file, id, table) {
      let formData = new FormData()
      formData.append('filename', file.name)
      formData.append('file', file)
      formData.append('formatId', id)
      formData.append('table', table)
      formData.append('source', 'WebPortal - ' + this.displayName)
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      await addExport(formData, config)
    },
    exportFile: async function (event, id, table) {
      Vue.set(this.testLoading, id, true)
      let files = event.target.files || event.dataTransfer.files
      try {
        await this.uploadExport(files[0], id, table)
        this.$buefy.toast.open({ message: 'File test was successful', type: 'is-primary', duration: 5000 })
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
      Vue.set(this.testLoading, id, false)
    },
    async deleteFileSettings (customerId, fipId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting file import config',
        message: 'Are you sure you want to <b>delete</b> this import config ? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.deleteCustomerFileSettings({ customer: customerId, fip: fipId })
            this.fileSettings = await Customer.loadCustomerFileSettings('all')
          } catch (e) {
            this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
          }
        }
      })
    }
  }
}
</script>
