import * as types from './mutation-types'
import Vue from 'vue'
// MUTATIONS
const mutations = {

  [types.SET_CUSTOMERS] (state, customers) {
    state.customers = customers
  },
  [types.SET_EDI_CUSTOMERS] (state, customers) {
    state.ediCustomers = customers
  },
  [types.SET_SELECTED_CUSTOMER] (state, customer) {
    state.selectedCustomer = customer
  },

  [types.SET_END_USERS] (state, endUsers) {
    state.endUsers = endUsers
  },
  [types.SWITCH_SIDE_BAR] (state) {
    state.visibleSidebar = !state.visibleSidebar
  },
  [types.HIDE_SIDE_BAR] (state) {
    state.visibleSidebar = false
  },
  [types.CUSTOMER_FILE_SETTINGS_REQUEST] (state) {
    Vue.set(state, 'groupFileSettings', {
      data: [],
      loading: true,
      error: null
    })
  },
  [types.CUSTOMER_FILE_SETTINGS_SUCCESS] (state, data) {
    Vue.set(state, 'groupFileSettings', {
      data,
      loading: false,
      error: null
    })
  },
  [types.CUSTOMER_FILE_SETTINGS_FAILURE] (state, error) {
    Vue.set(state, 'groupFileSettings', {
      data: {},
      loading: false,
      error: error.message || error
    })
  },
  [types.TABLES_MOVEMENT_FIELDS_REQUEST] (state) {
    Vue.set(state, 'movementFields', {
      data: {},
      loading: true,
      error: null
    })
  },
  [types.TABLES_MOVEMENT_FIELDS_SUCCESS] (state, data) {
    Vue.set(state, 'movementFields', {
      data,
      loading: false,
      error: null
    })
  },
  [types.TABLES_MOVEMENT_FIELDS_FAILURE] (state, error) {
    Vue.set(state, 'movementFields', {
      data: {},
      loading: false,
      error: error.message || error
    })
  },
  [types.TABLES_MOVEMENT_TABLES_REQUEST] (state) {
    Vue.set(state, 'movementTables', {
      data: [],
      loading: true,
      error: null
    })
  },
  [types.TABLES_MOVEMENT_TABLES_SUCCESS] (state, data) {
    Vue.set(state, 'movementTables', {
      data,
      loading: false,
      error: null
    })
  },
  [types.TABLES_MOVEMENT_TABLES_FAILURE] (state, error) {
    Vue.set(state, 'movementTables', {
      data: [],
      loading: false,
      error: error.message || error
    })
  },
  [types.SHOW_GENERATE_REPORT_NOTIFICATION] (state, notif) {
    state.generateReportNotification = notif
  },
  [types.SHOW_GENERATE_SQLITEDBFILES_NOTIFICATION] (state, notif) {
    state.generateSqliteDbFilesNotification = notif
  }
}

export default mutations
