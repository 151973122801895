import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/shippingManagement'

const shippingManagement = axios.create({
  baseURL
})

interceptorsSetup(shippingManagement)

export default class ShippingManagement {
  static async getShippings (customerId, query) {
    const queryString = constructQueryString(query)
    const { data: result } = await shippingManagement.get(`/shippings/${customerId}?${queryString}`)
    return result
  }

  static async getCrateMovements (shippingId) {
    const { data: result } = await shippingManagement.get(`/crateMovements/${shippingId}`)
    return result
  }

  static async getCrates () {
    const { data: result } = await shippingManagement.get(`/crates`)
    return result
  }

  static async updateShipping (shipping, crateMovements) {
    let data = {
      'shipping': shipping,
      'crateMovements': crateMovements
    }
    const result = await shippingManagement.put(`/shippings`, data)
    return result.data
  }

  static async uploadEndusers (customerId, category, file) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('customerId', customerId)
    formData.append('fileCategoryId', category)
    const result = await shippingManagement.post(`/upload/endusers`, formData, { headers: {
      'Content-Type': 'multipart/form-data'
    } })
    return result.data
  }

  static async createShipping (customerId, { crateMovements, enduserId, reference, date }) {
    let data = {
      'customerId': customerId,
      'date': date,
      'enduserId': enduserId,
      'reference': reference,
      'crateMovements': crateMovements
    }
    const result = await shippingManagement.post(`/shippings`, data)
    return result.data
  }

  static async deleteShipping (shippingId) {
    const { data: result } = await shippingManagement.delete(`/shippings/${shippingId}`)
    return result
  }

  static async createPendingEnduser ({ customerId, enduser, endusers }) {
    const result = await shippingManagement.post(`/pending_enduser`, { 'customerId': customerId, 'enduser': enduser, 'endusers': endusers })
    return result.data
  }

  static async getPendingEnduser (customerId) {
    const result = await shippingManagement.get(`/pending_enduser/${customerId}`)
    return result.data
  }
}
