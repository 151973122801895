<template>
  <keep-alive include="plants">
    <router-view :key="$route.fullPath" />
  </keep-alive>
</template>

<script>

export default {

}
</script>

<style>

</style>
