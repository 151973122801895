<template>
  <div id="preview" class="container">
    <div style="margin: 10px; display: inline-block; padding: 10px;">
      <div style="margin-right: 2rem; display: inline;">
        <div>
          <span>
            {{ signature.firstname }} <b style="color: rgb(106, 191, 158);">{{ signature.lastname }}</b>
          </span>
          <br>
          <span>{{ signature.job }}</span>
        </div>
        <br>
        <div>
          <span>
            {{ signature.phone }}
          </span>
          <br>
          <span>
            {{ signature.address }} <span v-if="signature.city || signature.postalCode"> - {{ signature.postalCode }}, {{ signature.city }}</span>
            <br>
            {{ signature.country }}
            <br>
            <a href="https://www.fr-gps.com" style="text-decoration: underline; color: black">www.fr-gps.com</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    signature: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted: async function () {
  }
}
</script>

<style scoped>
.f-column {
  display: flex;
  flex-direction: column;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.m-left {
  margin-left: 1rem;
}
.m-right {
  margin-right: 2rem;
}

.f-vspace {
  justify-content: space-between;
}

.m-top {
  margin-top: 2rem;
}

.f-center {
  display: flex;
  justify-content: center;
}

</style>
