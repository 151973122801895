import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/forecast'

const forecast = axios.create({
  baseURL
})

interceptorsSetup(forecast)

export default class Customer {
  static async getForecast (query) {
    const queryString = constructQueryString(query)
    const result = await forecast.get(`/?${queryString}`)
    return result.data
  }

  static async getRawData (query) {
    const result = await forecast.get(`/export`)
    return result.data
  }

  static async getForecastQuantitybyPlant (data) {
    const result = await forecast.post('/quantity', data)
    return result.data
  }

  static async getSplitData (data) {
    const result = await forecast.post('/split', data)
    return result.data
  }

  static async setForecast (customerId, data) {
    const result = await forecast.post(`/newForecast/${customerId}`, data)
    return result.data
  }

  static async deleteForecast (customerId, month, plant) {
    const result = await forecast.delete(`/customer/${customerId}/${month}/${plant}`)
    return result.data
  }

  static async deleteForecastByMaterial (customerId, month, plant, material) {
    const result = await forecast.delete(`/customer/${customerId}/${month}/${plant}/${material}`)
    return result.data
  }
}
