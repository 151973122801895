<template>
  <b-progress
    v-if="isShowing"
    type="is-info"
    size="is-small"
    :value="progressValue"
    format="percent"
    show-value
    class="center-progress"
  />
</template>

<script>
export default {
  data () {
    return {
      isShowing: false,
      progressValue: null
    }
  },
  mounted () {
    if (this.params.value !== null) {
      this.isShowing = true
      this.progressValue = +this.params.value
    }
  }
}
</script>

<style scooped>
.center-progress {
  margin-top: 14px;
}
</style>
