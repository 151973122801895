import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/scans'

const scans = axios.create({
  baseURL
})

interceptorsSetup(scans)

export async function getAllDevices () {
  const { data: devices } = await scans('/devices')
  return devices
}

export async function deleteDevice (deviceUuid) {
  const { data: response } = await scans.delete(`/devices/${deviceUuid}`)
  return response
}

export async function getMvtForScanner () {
}

export async function editDevicePlant (deviceUuid, plantId) {
  const { data: response } = await scans.put(`/devices/${deviceUuid}`, {
    plantId: plantId
  })
  return response
}

export async function downloadFreeScanMovements (uuid) {
  const result = await scans.get(`/${uuid}/movements`)
  return result.data
}
