const editable = ({ data: { status } }) => status !== 'SENT'

export const columns = [
  { headerName: 'GPS_CONSIGNEE',
    filter: 'agTextColumnFilter',
    field: 'GPS_CONSIGNEE',
    width: 150,
    editable: editable
  },
  { headerName: 'GPS_BARCODE',
    filter: 'agTextColumnFilter',
    field: 'GPS_BARCODE',
    width: 150,
    editable: editable },
  { headerName: 'ST_TIMESTAMP',
    filter: 'agDateColumnFilter',
    field: 'ST_TIMESTAMP',
    width: 190,
    editable: editable,
    cellRenderer: (data) => {
      return data.value && new Date(data.value).toLocaleDateString()
    }
    // cellEditor: 'agDateCellEditor'
  },
  { headerName: 'CS_BATCH_NUMBER',
    filter: 'agTextColumnFilter',
    field: 'CS_BATCH_NUMBER',
    width: 150,
    editable: editable },
  { headerName: 'CS_DELIVERY_NUMBER',
    filter: 'agTextColumnFilter',
    field: 'CS_DELIVERY_NUMBER',
    width: 150,
    editable: editable },
  { headerName: 'CS_END_USER_NAME',
    filter: 'agTextColumnFilter',
    field: 'CS_END_USER_NAME',
    width: 150,
    editable: editable },
  { headerName: 'CS_INFO1',
    filter: 'agTextColumnFilter',
    field: 'CS_INFO1',
    width: 150,
    editable: editable },
  { headerName: 'CS_INFO2',
    filter: 'agTextColumnFilter',
    field: 'CS_INFO2',
    width: 150,
    editable: editable },
  { headerName: 'GPS_MATERIAL',
    filter: 'agTextColumnFilter',
    field: 'GPS_MATERIAL',
    width: 150,
    editable: editable,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['GPS52', 'GPS1']
    }
  },
  { headerName: 'GPS_SHIP_FROM',
    filter: 'agTextColumnFilter',
    field: 'GPS_SHIP_FROM',
    width: 150,
    editable: editable },
  { headerName: 'GPS_QTY_MASS',
    filter: 'agNumberColumnFilter',
    field: 'GPS_QTY_MASS',
    width: 150,
    editable: editable }
]
