<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
    tag="form"
    @submit.prevent="submit"
  >
    <div class="modal-card" style="min-height: 750px">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ editableGroup ? 'Edit' : 'Create' }} group
        </p>
      </header>
      <section class="modal-card-body">
        <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
          <b-field
            label="Name"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <b-input v-model="newGroup.name" name="name" type="text" required />
          </b-field>
        </ValidationProvider>

        <b-field label="Type">
          <b-radio-button
            v-model="newGroup.type"
            native-value="HUB"
            type="is-primary"
            :disabled="!isAdmin"
          >
            <b-icon icon="home" />
            <span>HUB</span>
          </b-radio-button>

          <b-radio-button
            v-model="newGroup.type"
            native-value="CUSTOMER"
            type="is-info"
            :disabled="!isAdmin"
          >
            <b-icon icon="handshake" />
            <span>CUSTOMER</span>
          </b-radio-button>

          <b-radio-button
            v-model="newGroup.type"
            native-value="END USER"
            type="is-purple"
          >
            <b-icon icon="user" />
            <span>END USER</span>
          </b-radio-button>
        </b-field>
        <b-field v-if="newGroup.type === 'CUSTOMER'" label="Customers">
          <b-taginput
            v-model="newGroup.customers"
            :data="filteredCustomers"
            field="name"
            autocomplete
            open-on-focus
            :disabled="newGroup.type !== 'CUSTOMER'"
            icon="search"
            @typing="getFilteredCustomersTags"
            @input="() => {
              filteredCustomers = rmIfExiste(customers, newGroup.customers, 'id')
            }"
          >
            <template slot-scope="props">
              <strong>{{ props.option.id }}</strong> - {{ props.option.name }}
            </template>
            <template slot="tag" slot-scope="props">
              {{ props.tag.id }} - {{ props.tag.name }}
            </template>
          </b-taginput>
        </b-field>
        <b-field v-if="newGroup.type === 'END USER'" label="End users">
          <b-taginput
            v-model="newGroup.endusers"
            :data="filteredEndusers"
            field="name"
            autocomplete
            open-on-focus
            :disabled="newGroup.type !== 'END USER'"
            icon="search"
            @typing="getFilteredEndusersTags"
            @input="() => {
              filteredEndusers = rmIfExiste(endusers, newGroup.endusers, 'name')
            }"
          >
            <template slot-scope="props">
              <strong>{{ props.option.id }}</strong> - {{ props.option.name }}
            </template>
            <template slot="tag" slot-scope="props">
              {{ props.tag.id }} - {{ props.tag.name }}
            </template>
          </b-taginput>
        </b-field>
        <b-field v-if="newGroup.type === 'HUB'" label="Plants">
          <b-taginput
            v-model="newGroup.plants"
            :data="filteredPlants"
            :disabled="newGroup.type !== 'HUB'"
            field="name"
            autocomplete
            open-on-focus
            icon="search"
            @typing="getFilteredPlantsTags"
            @input="() => {
              filteredPlants = rmIfExiste(plants, newGroup.plants, 'name')
            }"
          >
            <template slot-scope="props">
              <strong>{{ props.option.id }}</strong> - {{ props.option.name }}
            </template>
            <template slot="tag" slot-scope="props">
              {{ props.tag.id }} - {{ props.tag.name }}
            </template>
          </b-taginput>
        </b-field>
        <b-field label="PowerBI">
          <b-taginput
            v-model="newGroup.powerBis"
            :data="filteredPowerBis"
            autocomplete
            open-on-focus
            field="name"
            icon="search"
            @typing="getFilteredPowerBis"
            @add="addAllRole"
            @input="() => {
              filteredPowerBis = rmIfExiste(allPowerBis, newGroup.powerBis, 'id')
            }"
          />
        </b-field>
        <b-field v-for="item in newGroup.powerBis.filter(el => el.allRoles.length !== 0)" :key="item.id" :label="item.name">
          <b-taginput
            v-model="item.roles"
            :data="rmIfExiste(item.allRoles, item.roles, 'id')"
            autocomplete
            open-on-focus
            readonly
            field="role"
            icon="search"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': isSubmitting }"
          :disabled="invalid"
          ype="submit"
          class="button is-info is-fullwidth"
        >
          Submit
        </button>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
// import Customer from '@/services/v2/customer'
import miscMixins from '@/mixins/misc'
import Plant from '@/services/v2/plant'
import Powerbi from '@/services/v2/powerbi'

export default {
  mixins: [miscMixins],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    editableGroup: {
      type: Object,
      default: null
    },
    customers: {
      type: Array,
      default: () => []
    },
    endusers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      plants: [],
      filteredPlants: [],
      filteredCustomers: [],
      filteredEndusers: [],
      filteredPowerBis: [],
      backupallPowerBis: [],
      newGroup: {
        name: '',
        type:
          this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED'
            ? 'HUB'
            : 'END USER',
        customers: [],
        plants: [],
        endusers: [],
        powerBis: []
      },
      isAdmin: this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED'
    }
  },
  mounted: async function () {
    this.plants = await Plant.getAll()
    const tmp = await Powerbi.getPowerBIReports()
    this.allPowerBis = tmp.value
    this.allPowerBis.forEach(el => {
      el.name = el.name.replaceAll('_', ' ')
      el.roles = []
      el.allRoles = []
    })
    if (this.editableGroup) {
      this.newGroup.id = this.editableGroup.id
      this.newGroup.name = this.editableGroup.name
      this.newGroup.customers = [
        ...this.editableGroup.customers,
        ...this.editableGroup.endusers
      ]
      this.newGroup.type = this.editableGroup.type
      this.newGroup.customers = this.editableGroup.customers
        ? [...this.editableGroup.customers]
        : []
      this.newGroup.endusers = this.editableGroup.endusers
        ? [...this.editableGroup.endusers]
        : []
      this.newGroup.plants = this.editableGroup.plants
        ? [...this.editableGroup.plants]
        : []
      this.getPowerBIName()
    }
    this.filteredPowerBis = this.rmIfExiste(this.allPowerBis, this.newGroup.powerBis, 'id')
    this.filteredCustomers = this.rmIfExiste(this.customers, this.newGroup.customers, 'name')
    this.filteredPlants = this.rmIfExiste(this.plants, this.newGroup.plants, 'name')
    this.filteredEndusers = this.rmIfExiste(this.endusers, this.newGroup.endusers, 'name')
  },
  methods: {
    rmIfExiste (allData, userDatas, field) {
      return allData.filter(el => !userDatas.find(userData => userData[field] === el[field]))
    },
    getPowerBiIfRoles (powerBis) {
      return powerBis.filter(el => el.allRoles.length !== 0)
    },
    async addAllRole (obj) {
      const roles = await Powerbi.getRoles(obj.id)
      obj.allRoles = roles
    },
    async getPowerBIName () {
      await this.editableGroup.powerBis.forEach(async powerBi => {
        this.newGroup.powerBis.push({ ...this.allPowerBis.find(el => el.id === powerBi.id), roles: (powerBi.roles ? powerBi.roles : []) })
      })
      await this.newGroup.powerBis.forEach(async el => {
        await this.addAllRole(el)
        el.roles = el.roles.map(role => {
          return el.allRoles.find(allRole => allRole.id === parseInt(role))
        })
      })
    },
    getFilteredCustomersTags (text) {
      this.filteredCustomers = this.rmIfExiste(this.customers, this.newGroup.customers, 'id').filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 ||
          option.id
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    getFilteredPlantsTags (text) {
      this.filteredPlants = this.rmIfExiste(this.plants, this.newGroup.plants, 'name').filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 ||
          option.id
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    getFilteredEndusersTags (text) {
      this.filteredEndusers = this.rmIfExiste(this.endusers, this.newGroup.endusers, 'name').filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 ||
          option.id
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    getFilteredPowerBis (text) {
      this.filteredPowerBis = this.rmIfExiste(this.allPowerBis, this.newGroup.powerBis, 'id').filter(option => {
        return (option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0)
      })
    },
    async submit () {
      try {
        const submitable = {
          ...this.newGroup,
          customers:
            this.newGroup.type === 'CUSTOMER'
              ? this.newGroup.customers.map(e => e.id)
              : [],
          plants:
            this.newGroup.type === 'HUB'
              ? this.newGroup.plants.map(e => e.id)
              : [],
          endusers:
            this.newGroup.type === 'END USER'
              ? this.newGroup.endusers.map(e => e.id)
              : [],
          powerBis: this.newGroup.powerBis.map(e => ({
            id: e.id,
            roles: (e.roles ? e.roles?.map(r => r.id) : [])
          }))
        }
        this.$emit('submit', submitable)
        return
      } catch (error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.message,
          duration: 3000
        })
      }
    }
  }
}
</script>
