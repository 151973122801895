<template>
  <div class="section">
    <div class="section filters">
      <multiselect
        v-model="selectedCustomer"
        :options="customers"
        :multiple="false"
        :close-on-select="true"
        placeholder="Pick customer..."
        label="name"
        track-by="name"
      />
    </div>
    <div style="padding: 2px;">
      <ag-grid-vue
        style="width: 100%; height: 1000px;"
        class="ag-theme-balham"
        :grid-options="gridOptions"
        :pagination="true"
        :pagination-auto-page-size="true"
        :animate-rows="true"
        :multi-sort-key="'ctrl'"
        row-selection="multiple"
        :get-child-count="getChildCount"
        :modules="modules"
        @grid-ready="gridReady"
      />
    </div>
  </div>
</template>

<script>

import { AllModules } from '@ag-grid-enterprise/all-modules'
import Customer from '@/services/v2/customer'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'
import XLSX from 'xlsx'

export default {
  components: {
  },
  mixins: [sortAndFilterMixins],
  data () {
    return {
      modules: AllModules,
      selectedCustomer: null,
      customers: [],
      params: null,
      gridApi: null,
      gridColumnApi: null,

      gridOptions: {
        rowModelType: 'serverSide',
        serverSideStoreType: 'partial',
        getContextMenuItems: this.mainActions,
        purgeClosedRowNodes: true,
        enableCharts: true,

        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [
          {
            headerName: this.$t('pages.barcode_reminder.grid.name'),
            field: 'NAME',
            hide: true,
            rowGroup: true

          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.enduser_code'),
            field: 'ZSHIPTO_SHI1',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
            // hide: true
            // valueGetter: (params) => {
            //   if (params.data) {
            //     if (params.data.NAME) {
            //       return params.data.ZSHIPTO_SHI1 + ' - ' + params.data.NAME
            //     }
            //     return params.data.ZSHIPTO_SHI1
            //   } else {
            //     return ''
            //   }
            // }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.barcode'),
            field: 'barcode',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.shipping_date'),
            field: 'LAST_SHIPTO_DATE',
            valueGetter: (params) => {
              if (params.data && params.data.LAST_SHIPTO_DATE) {
                return this.$dayjs(params.data.LAST_SHIPTO_DATE).format('DD/MM/YYYY')
              }
              return ''
            },
            filter: 'agDateColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.delivery_date'),
            field: 'delivery_date',
            valueGetter: (params) => {
              if (params.data && params.data.delivery_date) {
                return this.$dayjs(params.data.delivery_date).format('DD/MM/YYYY')
              }
              return ''
            },
            filter: 'agDateColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.eta_date'),
            field: 'ETA_date',
            valueGetter: (params) => {
              if (params.data && params.data.ETA_date) {
                return this.$dayjs(params.data.ETA_date).format('DD/MM/YYYY')
              }
              return ''
            },
            filter: 'agDateColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.transit_time'),
            field: 'TRANSIT_TIME',
            valueGetter: (params) => {
              return params.data && params.data.TRANSIT_TIME ? parseInt(params.data.TRANSIT_TIME) + ' days' : ''
            },
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.usage_days'),
            field: 'USAGE_DAYS',
            valueGetter: (params) => {
              return params.data && params.data.USAGE_DAYS ? parseInt(params.data.USAGE_DAYS) + ' days' : ''
            },
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.days_at_enduser'),
            field: 'nbDaysAtEnduser',
            valueGetter: (params) => {
              return params.data && params.data.nbDaysAtEnduser ? parseInt(params.data.nbDaysAtEnduser) + ' days' : ''
            },
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.expiration_date'),
            field: 'expirationDate',
            valueGetter: (params) => {
              return params.data && params.data.expirationDate ? this.$dayjs(params.data.expirationDate).format('DD/MM/YYYY') : ''
            },
            filter: 'agDateColumnFilter',
            filterParams: { suppressAndOrCondition: true }

          },
          {
            headerName: this.$t('pages.barcode_reminder.grid.nb_days_expired'),
            field: 'daysExpired',
            valueGetter: (params) => {
              return params.data && params.data.daysExpired ? params.data.daysExpired + ' days' : ''
            },
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true }
          }
        ],
        detailRowHeight: 350

      }

    }
  },
  computed: {
  },
  watch: {
    selectedCustomer: async function (selectedCustomer) {
      this.loadData()
    }
  },
  mounted: async function () {
    try {
      this.loading = true
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi
      await this.getCustomers()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }

    this.loading = false
  },
  methods: {
    async getCustomers () {
      this.customers = await Customer.getAll({})
    },
    loadData: async function () {
      this.gridOptions.api.showLoadingOverlay()
      // this.data = await CratePeremption.get({ customerCode: this.selectedCustomer.id })
      const dataSource = {
        getRows: async (params) => {
          // const columns = this.gridOptions.columnDefs.map(colDef => colDef.field).filter(e => e)
          const groupSummary = this.getGroups(params.request)

          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'cratePeremption',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputCratePeremption]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputCratePeremption', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              customerCode: { type: 'ID!', value: this.selectedCustomer.id }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })

          params.successCallback(result.data.cratePeremption.edges.map(e => e.node), result.data.cratePeremption.totalCount)
          if (result.data.cratePeremption.totalCount === 0) {
            this.gridOptions.api.showNoRowsOverlay()
          }
        }
      }
      this.params.api.setServerSideDatasource(dataSource)
      this.gridOptions.api.hideOverlay()
    },
    async refresh () {
      await this.loadData()
      this.gridOptions.api.refreshCells()
    },
    gridReady: async function (params) {
      this.params = params
    },
    mainActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: this.$t('global.export'),
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          subMenu: [
            {
              name: 'CSV Export',
              action: () => {
                this.csvDownload()
              }
            },
            {
              name: 'Excel Export (.xlsx)',
              action: () => {
                this.xlsxDownload()
              }
            }
          ]
        }
      ]
      const result = [...customMenu, ...genericMenu]
      return result
    },
    workbook: async function () {
      // const request = this.gridOptions.api.getModel().cacheParams

      const columns = this.gridOptions.columnDefs.map(colDef => colDef.field).filter(e => e)
      const builder = this.$gql.query({
        operation: 'cratePeremption',
        fields: ['totalCount', { edges: [{ node: [...columns] }] }],
        variables: {
          customerCode: { type: 'ID!', value: this.selectedCustomer.id }
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const columnsDefs = this.gridOptions.columnApi.getAllDisplayedColumns().map(col => col.colDef)
      const csvFields = columnsDefs.map(e => e.field)
      const csvColumns = columnsDefs.map(e => e.headerName).filter(e => e)
      const csvContent = result.data.cratePeremption.edges.map(e => {
        const data = csvFields.filter(f => f).map(field => e.node[field])
        const groupColumn = csvColumns.find(c => c === 'Group')
        const groupColumnIndex = csvColumns.indexOf('Group')
        if (groupColumn) {
          csvColumns.splice(groupColumnIndex, 1)
        }
        return data
      }
      )

      const data = [csvColumns, ...csvContent]
      const worksheet = XLSX.utils.aoa_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS')
      return workbook
    },
    csvDownload: async function () {
      try {
        this.gridOptions.api.showLoadingOverlay()
        XLSX.writeFile(await this.workbook(), 'export.csv')
        this.gridOptions.api.hideOverlay()
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    xlsxDownload: async function () {
      try {
        this.gridOptions.api.showLoadingOverlay()
        XLSX.writeFile(await this.workbook(), 'export.xlsx')
        this.gridOptions.api.hideOverlay()
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.barcode
      }
    }
  }
}
</script>

<style scoped>

.filters * {
  margin-bottom: 1rem;
}

</style>
