<template>
  <keep-alive include="customers">
    <router-view />
  </keep-alive>
</template>

<script>

export default {

}
</script>

<style>

</style>
