<template>
  <section class="section">
    <div class="container is-fluid">
      <Header v-bind="$route.meta" />
      <h2 v-if="currentPlant" class="subtitle">
        {{ currentPlant.name }} - {{ $t('pages.plants.forecast.delay') }}: {{ +currentPlant.shipmentDelay || 0 }} {{ $t('global.days').toLowerCase() }}
      </h2>
      <div class="columns">
        <div class="column card my-card">
          <b-tabs type="is-toggle" position="is-centered">
            <b-tab-item :label="$t('pages.plants.forecast.parameters')">
              <Parameters
                :allow-sunday="forecastParams.allowSunday"
                :allow-saturday="forecastParams.allowSaturday"
                :range-dates="forecastParams.rangeDates"
                @allowSunday="(event) => {
                  forecastParams.allowSunday = event
                  refreshForecast({})
                }"
                @allowSaturday="(event) => {
                  forecastParams.allowSaturday = event
                  refreshForecast({})
                }"
                @rangeDates="(event) => {
                  forecastParams.rangeDates = event
                  getAll()
                }"
                @save="saveForecastParameters"
              />
            </b-tab-item>
          </b-tabs>
        </div>
        <div class="column card my-card">
          <b-tabs type="is-toggle" position="is-centered">
            <b-tab-item :label="$t('pages.plants.forecast.insert_by_range')">
              <InsertByRange
                :props-allow-sunday="forecastParams.allowSunday"
                :props-allow-saturday="forecastParams.allowSaturday"
                @updateOperations="updateOperations"
              />
            </b-tab-item>
            <b-tab-item :label="$t('pages.plants.forecast.delete_by_range')">
              <DeleteByRange
                :props-allow-sunday="forecastParams.allowSunday"
                :props-allow-saturday="forecastParams.allowSaturday"
                @deleteOperations="deleteOperations"
              />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <b-field>
        <b-button icon-left="save" @click="saveForecast">
          {{ $t('pages.plants.forecast.save_forecast') }}
        </b-button>
        <b-button icon-left="redo" @click="getAll">
          {{ $t('pages.plants.forecast.reset_forecast') }}
        </b-button>
      </b-field>
      <div style="position: relative">
        <b-loading style="position: absolute; right: 20px; top: 20px;" :active="loading" :is-full-page="false" :can-cancel="false" />
        <ag-grid-vue
          style="width: 100%; height: 900px;"
          class="ag-theme-balham"
          :grid-options="gridOptions"
          :modules="modules"
          :row-data="productionForecast"
          :animated-rows="true"
          @firstDataRendered="firstDataRendered"
        />
      </div>
    </div>
  </section>
</template>
<script>
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import Plant from '@/services/v2/plant'

import Parameters from './parameters'
import InsertByRange from './insertByRange'
import DeleteByRange from './deleteByRange'

export default {
  components: {
    Parameters,
    InsertByRange,
    DeleteByRange
  },
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      newInputForecast: [],
      rmInputForecast: [],
      modules: AllModules,
      loading: true,
      currentPlant: null,
      materials: ['GPS1', 'GPS51', 'GPS52', 'GPS55', 'RHODIA', 'ZEON', 'GPSLID'],
      operations: [
        { operation: 'DIRTY', type: 'stock' },
        { operation: 'TO_REPAIR', type: 'stock' },
        { operation: 'AVAILABLE', type: 'stock' },
        { operation: 'RECEIVED', type: 'operation' },
        { operation: 'DELIVERED', type: 'operation' },
        { operation: 'WASHED', type: 'operation' },
        { operation: 'REPAIRED', type: 'operation' },
        { operation: 'REJECTED', type: 'operation' }
      ],
      productionForecast: [],
      totalCount: 0,
      forecastParams: {
        allowSunday: false,
        allowSaturday: false,
        rangeDates: [this.$dayjs().subtract(2, 'week').toDate(), this.$dayjs().add('3', 'M').toDate()]
      },
      gridOptions: {
        getRowStyle: this.gridRowStyle,
        immutableData: true,
        enableCellChangeFlash: true,
        getRowNodeId: function (data) {
          return data.version + '-' + data.productionDate + '-' + data.plantId
        },

        columnDefs: [
          {
            field: 'productionDate',
            cellRenderer: 'agGroupCellRenderer',
            headerName: this.$t('pages.plants.forecast.grid.production_date'),
            width: 200,
            sortable: true,
            filter: 'agDateColumnFilter',
            valueGetter: (params) => {
              const day = this.$dayjs(params.data.productionDate).day()
              if (day === 0 || day === 6) { return params.data.productionDate + ' (Weekend)' }
              return params.data.productionDate
            }
          },
          {
            field: 'type',
            headerName: this.$t('pages.plants.forecast.grid.type'),
            width: 150,
            cellStyle: function (params) {
              if (params.value === 'checkup') {
                return { color: 'black', backgroundColor: '#a5d6a7' }
              } else if (params.value === 'forecast') {
                return { color: 'black', backgroundColor: '#64b5f6' }
              } else if (params.value === 'scan') {
                return { backgroundColor: '#e0e0e0' }
              } else if (params.value === 'No data') {
                return { backgroundColor: '#d16243' }
              } else if (params.value === 'Today') {
                return { backgroundColor: '#cc359a' }
              }
              return null
            }
          },
          {
            field: 'comment',
            headerName: this.$t('pages.plants.forecast.grid.plant_comment'),
            flex: 1,
            wrapText: true
          },
          {
            headerName: this.$t('pages.plants.forecast.grid.admin_comment'),
            field: 'adminComment',
            flex: 1,
            onCellValueChanged: async (params) => {
              await this.updateAdminComment(params.data.id, params.newValue)
            },
            editable: true,
            wrapText: true
          }
        ],
        defaultColDef: {
          autoHeight: true,
          resizable: true

        },
        masterDetail: true,
        detailRowAutoHeight: true,
        detailCellRendererParams: params => {
          const res = { refreshStrategy: 'rows' }

          const parentData = params.data

          res.getDetailRowData = function (params) {
            params.successCallback(params.data.groupedOperations)
          }

          const columns = []
          const uniqueKeys = [...this.materials]

          uniqueKeys.forEach(e => {
            if (e !== 'type') {
              columns.push({ field: e,
                editable: function (editableRowParams) {
                  return editableRowParams.data.type === 'operation' && e !== 'operation' && (params.data.type === 'forecast' || params.data.type === 'Today')
                },
                cellStyle: (params) => {
                  let found
                  if (params.data.type === 'stock') {
                    found = parentData.stock.find(e => (e.storage === params.data.operation && e.material === params.colDef.field))
                  } else if (params.data.type === 'operation') {
                    found = parentData.operations.find(e => (e.operation === params.data.operation && e.material === params.colDef.field))
                  }
                  if (found && found.updatedAt !== undefined && found.updatedAt !== null) {
                    // mark police cells as red
                    return { color: 'red' }
                  }
                  return null
                } })
            }
          })

          res.detailGridOptions = {
            columnDefs: columns,
            rowHeight: 25,
            enableRangeSelection: true,
            enableCellChangeFlash: true,
            immutableData: true,
            statusBar: {
              statusPanels: [
                {
                  statusPanel: 'agAggregationComponent',
                  statusPanelParams: {
                    aggFuncs: ['sum']
                  }
                }
              ]
            },
            defaultColDef: {
              flex: 1
            },
            getRowNodeId: function (data) {
              return data.productionCheckupId + '-' + data.operation + '-' + data.type
            },
            onCellValueChanged: async (params) => {
              if (params.data.type === 'operation') {
                let newData = {
                  material: params.colDef.field,
                  quantity: +params.newValue,
                  updatedAt: new Date(),
                  updatedBy: this.$store.state.auth.user.id,
                  operation: params.data.operation,
                  productionDate: new Date(this.$dayjs(parentData.productionDate).format('YYYY-MM-DD'))
                }
                if (newData.quantity === '' || newData.quantity === undefined) {
                  /**
                   * add data to rmInput
                   */
                  const found = await this.rmInputForecast.find(e => (e.operation === params.data.operation && e.material === params.colDef.field && e.productionDate === parentData.productionDate))
                  if (found) {
                    found.quantity = +newData.quantity
                    found.updatedAt = newData.updatedAt
                    found.updatedBy = newData.updatedBy
                  } else {
                    this.rmInputForecast.push(newData)
                  }

                  /**
                   * rm from newInput
                   */
                  const index = await this.newInputForecast.findIndex(e => (e.operation === params.data.operation && e.material === params.colDef.field && e.productionDate === parentData.productionDate))
                  if (index) {
                    this.newInputForecast.splice(index, 1)
                  }
                  this.refreshForecast({ rmDatas: [newData] })
                } else {
                  /**
                   * add data add to newInput
                   */
                  const found = await this.newInputForecast.find(e => (e.operation === params.data.operation && e.material === params.colDef.field && e.productionDate === parentData.productionDate))
                  if (found) {
                    found.quantity = +newData.quantity
                    found.updatedAt = newData.updatedAt
                    found.updatedBy = newData.updatedBy
                  } else {
                    this.newInputForecast.push(newData)
                  }

                  /**
                   * rm from rmInput
                   */
                  const index = await this.rmInputForecast.findIndex(e => (e.operation === params.data.operation && e.material === params.colDef.field && e.productionDate === parentData.productionDate))
                  if (index) {
                    this.rmInputForecast.splice(index, 1)
                  }

                  /**
                   * Change the color for the new Input
                   */
                  let column = params.column.colDef.field
                  params.column.colDef.cellStyle = { color: 'red' }
                  params.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [params.node]
                  })
                  this.refreshForecast({ newDatas: [newData] })
                }
              }
            },
            getRowStyle: (params) => {
              if (params.data.type === 'stock') {
                return { background: '#9DD1F1' }
              } else {
                return { background: '#DAF7DC' }
              }
            }

          }

          res.template = (params) => {
            const shipments = params.data.shipments
            return (
              `<div style="width: 100%; display: grid; grid-template-columns: 60% 40%; padding: 10px; background-color: #EDF6FF; grid-gap: 20px;">
                  <div ref="eDetailGrid" style="width: 99%;" ></div>
                  <div style="white-space: normal!important; font-size: smaller;">
                    ${shipments.map(e => `<span class="${e.status === 'T' && 'has-text-primary'} style="vertical-align: middle;">
                      <span style="font-size: 18px"> ${e.requirement} ${e.collectId || e.deliveryId} - ${e.shipmentNumber || 'N/A'} </span> - ${e.type} - ETA : ${e.dateAtPlant} - Scanned at : ${e.scanDate || 'N/A'} <br>
                        ${e.loads.map(l => `<span style="font-size: 18px"> - ${l.quantity} ${l.material} ${l.storageLocation} </span>, ${l.consigneeName}`).join('<br>')}
                        </span>
                      `).join('<br><br>')}
                  </div>
                </div>`
            )
          }
          return res
        }
      }
    }
  },
  mounted: async function () {
    this.loading = true

    Plant.find(this.$route.params.plant_id).then(res => {
      this.currentPlant = res
    })
    Plant.getForecastParameters(this.$route.params.plant_id).then((res) => {
      this.forecastParams.allowSunday = res.allowSunday
      this.forecastParams.allowSaturday = res.allowSaturday
    })

    // to save new inputforecast
    this.newInputForecast = []

    /**
     * Init sockets for forecast
     */
    this.sockets.subscribe('process::forecast', (data) => {
      this.loading = false
      this.productionForecast = this.formatForecast(data)
    })
    this.sockets.subscribe('exception::forecast', (data) => {
      this.$buefy.notification.open({ message: `An error while refreshing the forecast 😕`, position: 'is-top', type: 'is-danger', hasIcon: true, duration: 500 })
    })

    /**
     * get all data
     */
    await this.addScan()
    this.getAll()
  },
  beforeRouteLeave: function (to, from, next) {
    this.sockets.unsubscribe('process::forecast')
    this.sockets.unsubscribe('exception::forecast')
    next()
  },
  methods: {
    scrollToday: function () {
      this.gridOptions.api.forEachNode(node => {
        if (node.data && this.$dayjs().isSame(node.data.productionDate, 'day')) {
          this.gridOptions.api.ensureIndexVisible(node.rowIndex, 'top')
        }
      })
    },
    firstDataRendered: function (params) {
      this.gridOptions.api.forEachNode(node => {
        node.setExpanded(false)
      })
    },
    formatForecast: function (productionForecast) {
      return productionForecast.map(el => {
        const groupedData = [
          ...el.stock.map(e => ({ ...e, operation: e.storage, type: 'stock' })),
          ...el.operations.map(e => ({ ...e, type: 'operation' }))
        ]
        let newData = []

        for (const line of this.operations) {
          newData.push({
            productionCheckupId: el.version + '-' + el.productionDate + '-' + el.plantId,
            operation: line.operation,
            type: line.type
          })
        }

        for (const line of groupedData) {
          const found = newData.find(e => e.operation === line.operation)
          if (found) {
            found[line.material] = line.quantity
            found.productionCheckupId = el.version + '-' + el.productionDate + '-' + el.plantId
          }
        }

        return { ...el, groupedOperations: newData }
      })
    },
    getData: async function () {
      const columns = [
        'id', 'userId', 'plantId', 'productionDate', 'version', 'comment', 'adminComment', 'type',
        { operations: ['productionCheckupId', 'operation', 'quantity', 'material', 'updatedAt', 'updatedBy'] },
        { 'stock: previousStock': ['productionCheckupId', 'storage', 'quantity', 'material', 'updatedAt', 'updatedBy'] },
        { shipments: [
          'dateAtPlant',
          'shipmentNumber',
          'collectId',
          'deliveryId',
          'orderReference',
          'requirement',
          'information',
          'type',
          'status',
          'scanDate',
          { loads: ['material', 'quantity', 'consigneeId', 'consigneeName', 'storageLocation'] }]
        }
      ]
      const builder = this.$gql.query({
        operation: 'productionCheckup',
        fields: ['totalCount', { edges: [{ node: [...columns] }] }],
        variables: {
          plantId: { type: 'ID!', value: this.$route.params.plant_id },
          lastVersionOnly: { type: 'Boolean', value: true },
          filter: { type: 'filterInputProductionCheckup', value: { productionDate: { inRange: [this.$dayjs(this.forecastParams.rangeDates[0]).format('YYYY-MM-DD'), this.$dayjs(this.forecastParams.rangeDates[1]).format('YYYY-MM-DD')] } } },
          sort: { type: '[sortInputProductionCheckup]', value: { column: 'productionDate', order: 'asc' } }
        }
      })
      const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })
      const productionResult = result.data.productionCheckup
      const productionResultData = this.formatForecast(productionResult.edges.map(e => e.node))
      this.totalCount = productionResult.totalCount
      this.productionForecast = [...productionResultData]
    },
    refreshForecast: async function ({ newDatas, rmDatas }) {
      this.loading = true
      this.$socket.emit('process::forecast', {
        data: this.productionForecast,
        plantId: this.$route.params.plant_id,
        allowSunday: this.forecastParams.allowSunday,
        allowSaturday: this.forecastParams.allowSaturday,
        rangeDates: this.forecastParams.rangeDates.map(e => this.$dayjs(e).format('YYYY-MM-DD')),
        newDatas: newDatas,
        rmDatas: rmDatas
      })
    },
    async updateAdminComment (id, newComment) {
      await this.$apollo.mutate({
        mutation: gql`mutation ($id: ID!, $adminComment: String) {
        productionCheckupUpdateComment(id: $id, adminComment: $adminComment)
      }`,
        variables: {
          id: id,
          adminComment: newComment }
      })
    },
    async updateOperations (newDatas) {
      for (const i in newDatas) {
        /**
         * add data to newInput
         */
        const found = await this.newInputForecast.find(e => (e.operation === newDatas[i].operation && e.material === newDatas[i].material && e.productionDate === newDatas[i].productionDate))
        if (found) {
          found.quantity = +newDatas[i].quantity
          found.updatedAt = newDatas[i].updatedAt
          found.updatedBy = newDatas[i].updatedBy
        } else {
          this.newInputForecast.push(newDatas[i])
        }

        /**
         * rm data from rmInput
         */
        const index = await this.rmInputForecast.findIndex(e => (e.operation === newDatas[i].operation && e.material === newDatas[i].material && e.productionDate === newDatas[i].productionDate))
        if (index !== -1) {
          this.rmInputForecast.splice(index, 1)
        }
      }
      this.refreshForecast({ newDatas })
    },
    async deleteOperations (rmDatas) {
      for (const i in rmDatas) {
        /**
         * add data to rmInput
         */
        const found = await this.rmInputForecast.find(e => (e.operation === rmDatas[i].operation && e.material === rmDatas[i].material && e.productionDate === rmDatas[i].productionDate))
        if (found) {
          found.quantity = +rmDatas[i].quantity
          found.updatedAt = rmDatas[i].updatedAt
          found.updatedBy = rmDatas[i].updatedBy
        } else {
          this.rmInputForecast.push(rmDatas[i])
        }

        /**
         * rm data from newInput
         */
        const index = await this.newInputForecast.findIndex(e => (e.operation === rmDatas[i].operation && e.material === rmDatas[i].material && e.productionDate === rmDatas[i].productionDate))
        if (index !== -1) {
          this.newInputForecast.splice(index, 1)
        }
      }
      this.refreshForecast({ rmDatas: rmDatas })
    },
    gridRowStyle (params) {
      const pDate = params.data.productionDate
      if (pDate && (this.$dayjs(pDate).day() === 0 || this.$dayjs(pDate).day() === 6)) {
        return { 'background-color': '#FFB563' }
      }
    },
    async addScan () {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($plantId: ID!, $rangeDates: [Date!]) {
          productionCheckupAddScanData(plantId: $plantId, rangeDates: $rangeDates)
        }`,
          variables: {
            plantId: this.$route.params.plant_id,
            rangeDates: this.forecastParams.rangeDates.map(e => this.$dayjs(e).format('YYYY-MM-DD'))
          }
        })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger', duration: 5000 })
      }
    },
    async saveForecast () {
      // save inputs forecast by array
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($plantId: ID!, $userId: ID!, $newDatas: [UpdateForecastsListInput], $rmDatas: [UpdateForecastsListInput]) {
        productionCheckupUpdateForecasts(plantId: $plantId, userId: $userId, newDatas: $newDatas, rmDatas: $rmDatas)
      }`,
          variables: {
            plantId: this.$route.params.plant_id,
            userId: this.$store.state.auth.user.id,
            newDatas: this.newInputForecast,
            rmDatas: this.rmInputForecast
          }
        })
        this.newInputForecast = []
        this.rmInputForecast = []
        await this.getData()
        this.refreshForecast({})
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger', duration: 5000 })
      }
    },
    async getAll () {
      this.loading = true
      this.productionForecast = []
      this.newInputForecast = []
      this.rmInputForecast = []
      await this.getData()
      this.refreshForecast({})
    },
    saveForecastParameters () {
      Plant.setForecastParameters(this.$route.params.plant_id, {
        allowSunday: this.forecastParams.allowSunday,
        allowSaturday: this.forecastParams.allowSaturday
      })
    }
  }
}

</script>

<style>
  .my-card {
    padding: 20px;
    margin: 20px;
  }
</style>
