<template>
  <section class="section">
    <div class="container is-fluid">
      <Header v-bind="$route.meta" />
      <h2>{{ new Date($route.params.productionDate).toLocaleDateString() }}</h2>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-loading v-model="isLoading" is-full-page />

          <b-steps v-if="!isLoading" v-model="activeStep" :has-navigation="false" :animated="false">
            <b-step-item step="1" :label="$t('pages.plants.checkup.operations')">
              <section class="section">
                <operationsCheckup
                  :operations="formatedOperations"
                  @operationsApiGride="getOperationsApiGride"
                  @dataChanged="productionChanged"
                />
              </section>
            </b-step-item>

            <b-step-item step="2" :label="$t('pages.plants.checkup.stock')">
              <section class="section">
                <stockCheckup
                  :stock="formatedStock"
                  @stockApiGride="getStockApiGride"
                  @dataChanged="stockChanged"
                />
              </section>
            </b-step-item>
            <b-step-item step="3" :label="$t('pages.plants.checkup.comment')">
              <section class="section">
                <b-field label="Message">
                  <b-input
                    v-model="productionCheckup.comment"
                    maxlength="600"
                    rows="5"
                    type="textarea"
                    size="is-medium"
                  />
                </b-field>
              </section>
            </b-step-item>
            <b-step-item step="3" :label="$t('pages.plants.checkup.finish')" disabled>
              <section class="section">
                <div class="content has-text-success has-text-centered">
                  <p>
                    <b-icon
                      icon="check"
                      custom-size="fa-9x"
                    />
                  </p>
                  <p>
                    {{ $t('pages.plants.checkup.success_message') }}
                  </p>
                </div>
              </section>
            </b-step-item>

            <template
              v-if="activeStep !== 3"
              slot="navigation"
              slot-scope="{previous, next}"
            >
              <div class="buttons is-centered">
                <b-button
                  outlined
                  type="is-info"
                  icon-left="backward"
                  :disabled="previous.disabled"
                  @click.prevent="stopEdition('previous', previous)"
                >
                  {{ $t('pages.plants.checkup.previous_step') }}
                </b-button>
                <b-button
                  v-if="activeStep === 2"
                  icon-left="save"
                  type="is-primary"
                  :loading="isCreating"
                  @click="createProduction"
                >
                  {{ $t('pages.plants.checkup.submit') }}
                </b-button>
                <b-button
                  v-else
                  outlined
                  type="is-info"
                  icon-right="forward"
                  :disabled="next.disabled"
                  @click.prevent="stopEdition('next', next)"
                >
                  {{ $t('pages.plants.checkup.next_step') }}
                </b-button>
              </div>
            </template>
          </b-steps>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'
import operationsCheckup from './operations'
import stockCheckup from './stock'

export default {
  components: {
    operationsCheckup,
    stockCheckup
  },
  data: function () {
    return {
      operamtionApiGride: null,
      stockApiGride: null,
      activeStep: 0,
      newVersion: 0,
      previousProduction: {},
      scannedProduction: [],
      productionCheckup: {},
      isLoading: true,
      isCreating: false,
      operations: [
        { operation: 'DIRTY', type: 'stock' },
        { operation: 'TO_REPAIR', type: 'stock' },
        { operation: 'AVAILABLE', type: 'stock' },
        { operation: 'RECEIVED', type: 'operation' },
        { operation: 'TRANSFERRED_IN:1AVL', type: 'operation' },
        { operation: 'TRANSFERRED_IN:2DIR', type: 'operation' },
        { operation: 'TRANSFERRED_IN:3REP', type: 'operation' },
        { operation: 'DELIVERED', type: 'operation' },
        { operation: 'TRANSFERRED_OUT:1AVL', type: 'operation' },
        { operation: 'TRANSFERRED_OUT:2DIR', type: 'operation' },
        { operation: 'TRANSFERRED_OUT:3REP', type: 'operation' },
        { operation: 'WASHED', type: 'operation' },
        { operation: 'REPAIRED', type: 'operation' },
        // { operation: 'REPAIRED_BC', type: 'operation' },
        { operation: 'REJECTED', type: 'operation' }
        // { operation: 'REJECTED_BC', type: 'operation' }

      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    formatedStock: function () {
      let formatedStock = []
      for (const line of this.operations.filter(e => e.type === 'stock')) {
        let newLine = { storage: line.operation }
        formatedStock.push(newLine)
      }

      for (const line of this.productionCheckup.stock) {
        const found = formatedStock.find(e => e.storage === line.storage)
        if (found) {
          found[line.material] = line.quantity
        }
      }
      return formatedStock
    },
    formatedOperations: function () {
      let formatedOperations = []
      for (const line of this.operations.filter(e => e.type === 'operation')) {
        let newLine = { operation: line.operation }
        formatedOperations.push(newLine)
      }
      for (const line of this.productionCheckup.operations) {
        const found = formatedOperations.find(e => e.operation === line.operation)
        if (found) {
          found[line.material] = line.quantity
        }
      }
      return formatedOperations
    }
  },
  mounted: async function () {
    await this.getData()

    this.$set(this.productionCheckup, 'stock', this.previousProduction?.stock.length ? [...this.previousProduction.stock] : [])
    this.isLoading = false
  },
  methods: {
    getOperationsApiGride (e) {
      this.operamtionApiGride = e
    },
    getStockApiGride (e) {
      this.stockApiGride = e
    },
    stopEdition (actionName, action) {
      if (this.operamtionApiGride.getEditingCells().length) {
        this.operamtionApiGride.stopEditing()
        return
      }
      if (this.stockApiGride.getEditingCells().length) {
        this.stockApiGride.stopEditing()
        return
      }
      if (actionName === 'next') {
        if (this.activeStep === 0) {
          for (let i = 0; this.productionCheckup.operations.length !== i; i++) {
            this.calculateStockOperation(this.productionCheckup.operations[i])
          }
        }
      }
      if (actionName === 'previous') {
        if (this.activeStep === 1) {
          this.$set(this.productionCheckup, 'stock', this.previousProduction?.stock.length ? [...this.previousProduction.stock] : [])
        }
      }
      action.action()
    },
    getData: async function () {
      await Promise.all([
        this.getScannedProduction(),
        this.getLastVersion(),
        this.getPreviousProduction()
      ])

      if (!Object.keys(this.productionCheckup).length) {
        this.productionCheckup = {
          plantId: this.$route.params.plant_id,
          version: this.newVersion,
          userId: this.user.id,
          productionDate: this.$route.params.productionDate,
          operations: [...this.scannedProduction]
        }
      }
    },
    addStock: function (storage, material, quantity) {
      const i = this.productionCheckup.stock.findIndex(e => e.storage === storage && e.material === material)
      const tmp = this.productionCheckup.stock[i]
      if (!tmp) {
        this.$set(this.productionCheckup, 'stock', [...this.productionCheckup.stock, { storage: storage, material: material, quantity: quantity }])
      } else {
        this.$set(this.productionCheckup.stock, i, { ...tmp, quantity: tmp.quantity + quantity })
      }
    },
    substractStock: function (storage, material, quantity) {
      const i = this.productionCheckup.stock.findIndex(e => e.storage === storage && e.material === material)
      const tmp = this.productionCheckup.stock[i]
      if (!tmp) {
        this.$set(this.productionCheckup, 'stock', [...this.productionCheckup.stock, { storage: storage, material: material, quantity: -quantity }])
      } else {
        this.$set(this.productionCheckup.stock, i, { ...tmp, quantity: tmp.quantity - quantity })
      }
    },
    calculateStockOperation: function (ock) {
      if (!ock.material || !ock.quantity || !ock.operation) return
      switch (ock.operation) {
        case 'RECEIVED':
          this.addStock('DIRTY', ock.material, ock.quantity)
          break
        case 'REJECTED':
          this.addStock('TO_REPAIR', ock.material, ock.quantity)
          this.substractStock('DIRTY', ock.material, ock.quantity)
          break
        case 'WASHED':
          this.addStock('AVAILABLE', ock.material, ock.quantity)
          this.substractStock('DIRTY', ock.material, ock.quantity)
          break
        case 'DELIVERED':
          this.substractStock('AVAILABLE', ock.material, ock.quantity)
          break
        case 'REPAIRED':
          this.addStock('DIRTY', ock.material, ock.quantity)
          this.substractStock('TO_REPAIR', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_IN:1AVL':
          this.addStock('AVAILABLE', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_IN:2DIR':
          this.addStock('DIRTY', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_IN:3REP':
          this.addStock('TO_REPAIR', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_OUT:1AVL':
          this.substractStock('AVAILABLE', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_OUT:2DIR':
          this.substractStock('DIRTY', ock.material, ock.quantity)
          break
        case 'TRANSFERRED_OUT:3REP':
          this.substractStock('TO_REPAIR', ock.material, ock.quantity)
          break
      }
    },
    productionChanged: function (newRow, oldRow) {
      const i = this.productionCheckup.operations.findIndex(e => e.material === newRow.material && e.operation === newRow.operation)
      if (isNaN(newRow.quantity)) {
        this.productionCheckup.operations[i].quantity = 0
        return
      }
      if (i === -1) {
        this.productionCheckup.operations.push(newRow)
      } else {
        this.productionCheckup.operations[i].quantity = newRow.quantity
      }
    },
    stockChanged: async function (newRow, oldRow) {
      const i = this.productionCheckup.stock.findIndex(e => e.material === newRow.material && e.storage === newRow.storage)
      if (isNaN(newRow.quantity)) {
        this.productionCheckup.stock[i].quantity = 0
        return
      }
      if (i === -1) {
        this.productionCheckup.stock.push(newRow)
      } else {
        this.productionCheckup.stock[i].quantity = newRow.quantity
      }
    },
    getScannedProduction: async function () {
      const builder = this.$gql.query({
        operation: 'production',
        fields: [{ edges: [{ node: [{ aggregate: [{ count: ['barcode'] }] }, 'material', 'operation'] }] }],
        variables: {
          filter: { type: 'filterInputProduction', value: { scannedAt: { equals: this.$route.params.productionDate } } },
          plantId: { type: 'ID!', value: this.$route.params.plant_id }
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const productionResult = result.data.production
      this.scannedProduction = productionResult.edges.map(e => {
        return {
          quantity: e.node.aggregate.count.barcode,
          material: e.node.material,
          operation: e.node.operation }
      })
    },
    getLastVersion: async function () {
      const columns = [
        'id',
        'userId',
        'plantId',
        'productionDate',
        'version'
      ]

      const builder = this.$gql.query({
        operation: 'productionCheckup',
        fields: ['totalCount', { edges: [{ node: [...columns] }] }],
        variables: {
          plantId: { type: 'ID!', value: this.$route.params.plant_id },
          productionDate: { type: 'Date', value: this.$route.params.productionDate },
          lastVersionOnly: { type: 'Boolean', value: true }
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const lastTodayVersionCheckup = result.data.productionCheckup?.edges.map(e => e.node)[0]
      if (lastTodayVersionCheckup) {
        this.newVersion = lastTodayVersionCheckup.version
      }
    },
    getPreviousProduction: async function () {
      const columns = [
        'id',
        'userId',
        'plantId',
        'productionDate',
        'version',
        'type',
        'comment',
        { stock: ['storage', 'quantity', 'material'] }
      ]

      const previousProdBuilder =
      this.$gql.query({
        operation: 'productionCheckup',
        fields: ['totalCount', { edges: [{ node: [...columns] }] }],
        variables: {
          plantId: { type: 'ID!', value: this.$route.params.plant_id },
          lastVersionOnly: { type: 'Boolean', value: true },
          filter: { type: 'filterInputProductionCheckup', value: { type: { equals: 'checkup' }, productionDate: { lessThan: this.$route.params.productionDate } } },
          limit: { type: 'Int', value: 1 },
          sort: { type: '[sortInputProductionCheckup]', value: { column: 'productionDate', order: 'desc' } }
        }
      })
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${previousProdBuilder.query}`,
        variables: previousProdBuilder.variables })
      this.previousProduction = result.data.productionCheckup?.edges.map(e => e.node)[0]
    },
    createProduction: async function () {
      this.isCreating = true
      await this.$apollo.mutate({
        mutation: gql`mutation ($plantId: String!, $version: Int!, $userId: ID, $type: String, $comment: String, $productionDate: Date!, $operations: [ProductionCheckupOperationInput], $stock: [ProductionCheckupStockInput]) {
        productionCheckupCreate(plantId: $plantId, version: $version, userId: $userId, type: $type, comment: $comment, productionDate: $productionDate, operations: $operations, stock: $stock)
      }`,
        variables: this.productionCheckup
      })
      this.isCreating = false
      this.activeStep = 3
    }
  }
}
</script>
