<template>
  <div>
    <template>
      <div v-for="[key, item] of mTypes" :key="'legend-' + item.type">
        <img :src="item.markerIcon">
        <span>{{ item.label }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { markerTypes } from './mTypes'
import { pickupMTypes } from '@/components/map/pickupMTypes'

export default {
  name: 'MapLegend',
  props: {
    mapType: {
      type: String,
      default: 'default'
    }
  },
  data: function () {
    return {
      markerTypes: Array.from(markerTypes),
      pickupMTypes: Array.from(pickupMTypes)
    }
  },
  computed: {
    mTypes: function () {
      switch (this.mapType) {
        case 'pickup':
          return this.pickupMTypes
        case 'default':
          return this.markerTypes

        default:
          return markerTypes
      }
    }
  }
}
</script>
