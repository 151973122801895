<template>
  <div class="columns is-mobile is-gapless is-fullheight">
    <transition name="sidebar">
      <aside v-show="$store.state.visibleSidebar" class="column is-narrow aside hero sidebar">
        <sidebar />
      </aside>
    </transition>
    <transition name="sidebar-replace">
      <aside v-show="$store.state.visibleSidebar" />
    </transition>
    <div id="site-wrapper" class="column overflow-hidden">
      <main class="site-content">
        <navbar />
        <router-view />
      </main>
      <dash-footer />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style>
.sidebar-enter,
.sidebar-leave-to {
  transform: translateX(-100%);
  position: absolute;
  opacity: 0;
}

.sidebar-leave,
.sidebar-enter-to {
  transform: translateX(0);
  position: absolute;
  opacity: 1;
}

.sidebar-enter-active,
.sidebar-leave-active { transition: all 300ms }

.sidebar-replace-enter,
.sidebar-replace-leave-to {
  width: 0px;
}

.sidebar-replace-leave,
.sidebar-replace-enter-to {
  width: 280px;
}
.sidebar-replace-enter-active,
.sidebar-replace-leave-active { transition: all 300ms }

.overflow-hidden {
  overflow: hidden;
}
</style>
