<template>
  <section id="edit-file-settings" class="section">
    <b-field>
      <b-button icon-left="arrow-left" @click="$router.go(-1)">
        Back
      </b-button>
    </b-field>
    <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
    <div v-if="selectedCustomer">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ selectedCustomer.name }} - {{ fileSettingsId === 'new' ? 'New' : 'Edit' }} file settings
        </p>
      </header>
      <section v-if="editing" class="modal-card-body">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="saveEdit">
            <b-field label="File path :">
              <b-input
                v-model="editing.filePath"
                name="filepath"
                placeholder="Enter a filepath"
                disabled
              />
            </b-field>

            <b-field label="Auto import :">
              <b-checkbox v-model="editing.active">
                Import file automatically
              </b-checkbox>
            </b-field>

            <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
              <b-field label="Destination table :" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-select v-model="editing.table" name="table" placeholder="Select a table" expanded>
                  <option v-for="table in movementTables" :key="'table-' + table" :value="table">
                    {{ table }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
              <b-field label="Date format (e.g. DD/MM/YYYY):" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input v-model="editing.dateFormat" name="dateformat" placeholder="Enter a date format" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
              <b-field label="Format :" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-select v-model="editing.parser" name="parser" placeholder="Parser" expanded>
                  <option value="csv">
                    csv / txt
                  </option>
                  <option value="edi">
                    EDI GoodYear
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
              <b-field v-if="editing.parser === 'csv'" label="Delimiter" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input v-model="editing.delimiter" name="delimiter" placeholder="CSV Delimiter" />
              </b-field>
            </ValidationProvider>

            <b-field label="Header :">
              <b-checkbox v-model="editing.header">
                File has a header (the first line isn't data)
              </b-checkbox>
            </b-field>

            <hr>

            <b-button size="is-small" type="is-primary" @click.prevent="editing.fields.push({ field: '', fields: '' })">
              Add field
            </b-button>

            <b-table v-if="editing.table" :data="editing.fields">
              <b-table-column v-slot="props" label="Field name">
                <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
                  <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                    <b-select
                      v-model="props.row.name"
                      :name="'field ' + (props.row.index + 1) + ' in table ' + editing.table"
                      placeholder="Select a field"
                      expanded
                    >
                      <option v-for="field in currentMovementFields" :key="'sap-field-' + field" :value="field">
                        {{ field }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column v-slot="props" label="Field position">
                <ValidationProvider v-slot="{ errors, valid }" :rules="props.row.isForced ? '' : 'required'" slim>
                  <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                    <b-input
                      v-model="props.row.position"
                      :name="'column fields ' + (props.row.index + 1) + ' in table ' + editing.table"
                      :disabled="props.row.isForced"
                      placeholder="Column fields"
                      type="number"
                      min="1"
                    />
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column v-if="editing.parser === 'edi'" v-slot="props" label="Field length">
                <ValidationProvider v-slot="{ errors, valid }" rules="required" slim>
                  <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                    <b-input
                      v-model="props.row.length"
                      :name="'field length ' + (props.row.index + 1) + ' in table ' + editing.table"
                      placeholder="Field Length"
                      type="number"
                      min="1"
                      :disabled="props.row.isForced"
                    />
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column v-slot="props" label="Operator">
                <b-field>
                  <b-select
                    v-model="props.row.whereClauseOperator"
                    :disabled="!props.row.hasWhereClause"
                    :name="'where clause operator ' + (props.row.index + 1) + ' in table ' + editing.table"
                    placeholder="Choose one"
                    type="text"
                  >
                    <option>=</option>
                    <option>!=</option>
                  </b-select>
                  <span>
                    <button v-if="props.row.whereClauseOperator" class="button is-danger" @click="props.row.whereClauseOperator = null">
                      <b-icon icon="ban" />
                    </button>
                  </span>
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="Field value">
                <b-field>
                  <b-input
                    v-model="props.row.whereClause"
                    :name="'where clause ' + (props.row.index + 1) + ' in table ' + editing.table"
                    :disabled="!props.row.hasWhereClause"
                    placeholder=""
                    type="text"
                  />
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="Action">
                <b-field>
                  <b-select
                    v-model="props.row.whereClauseAction"
                    :disabled="!props.row.hasWhereClause"
                    :name="'Where clause action ' + (props.row.index + 1) + ' in table ' + editing.table"
                    placeholder="Choose one"
                  >
                    <option value="ignore">
                      Ignore
                    </option>
                  </b-select>
                  <span>
                    <button v-if="props.row.whereClauseAction" class="button is-danger" @click="props.row.whereClauseAction = null">
                      <b-icon icon="ban" />
                    </button>
                  </span>
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="Forced value">
                <ValidationProvider v-slot="{ errors, valid }" :rules="props.row.isForced ? 'required' : ''" slim>
                  <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                    <b-input
                      v-model="props.row.force"
                      :name="'field forced value ' + (props.row.index + 1) + ' in table ' + editing.table"
                      :disabled="!props.row.isForced"
                      placeholder="Forced Value"
                      min="1"
                    />
                  </b-field>
                </ValidationProvider>
              </b-table-column>

              <b-table-column v-slot="props" label="Force">
                <b-field>
                  <b-checkbox v-model="props.row.isForced" />
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="Integration criteria">
                <b-field>
                  <b-checkbox v-model="props.row.hasWhereClause" />
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="Delete">
                <b-button
                  type="is-danger"
                  icon-left="trash-alt"
                  size="is-small"
                  rounded
                  outlined
                  @click="editing.fields.splice(props.index, 1)"
                />
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                    <p>Please note that if one of 'Operator' or 'Action' fields is empty, the integration criteria will be disabled</p>
                  </div>
                </section>
              </template>
            </b-table>

            <hr>

            <b-field grouped position="is-centered">
              <p class="control">
                <b-button
                  icon-left="save"
                  type="is-primary"
                  :loading="isSaving"
                  :disabled="invalid"
                  native-type="submit"
                >
                  Save
                </b-button>
              </p>
            </b-field>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Customerv2 from '@/services/v2/customer'
import * as Customer from '@/services/v1/customer'

export default {
  data () {
    return {
      isLoading: false,
      isSaving: false,
      customerId: this.$route.params.selectedCustomerId,
      fileSettingsId: this.$route.params.fileSettingsId,
      selectedCustomer: null,
      currentIndex: 0,
      editing: null
    }
  },
  computed: {
    ...mapGetters(['getMovementTables', 'getMovementFields']),
    movementFields () {
      return this.getMovementFields.data || {}
    },
    currentMovementFields () {
      return this.movementFields[this.editing.table] || []
    },
    movementTables () {
      return this.getMovementTables.data || []
    }
  },
  async mounted () {
    this.isLoading = true
    try {
      this.selectedCustomer = await Customerv2.findOne({ customerId: this.customerId })

      await this.loadMovementFields()
      await this.loadMovementTables()

      if (this.fileSettingsId === 'new') {
        this.editing = {
          dateFormat: 'YYYYMMDD',
          delimiter: ';',
          filePath: `/exports/${this.customerId}/`,
          parser: 'csv',
          fields: [],
          id: null
        }
      } else {
        const fileSettings = await Customer.loadCustomerFileSettings('all')

        this.editing = fileSettings.find(item => item.id === +this.fileSettingsId)
        this.editing.active = !!this.editing.active
      }
    } catch (e) {
      this.$buefy.toast.open({ type: 'is-danger', message: e.message || e })
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions(['loadMovementFields', 'loadMovementTables']),
    async saveEdit () {
      this.editing.dateFormat = this.editing.dateFormat.toUpperCase()
      this.isSaving = true
      try {
        await Customer.editCustomerFileSettings(this.customerId, this.editing)
        this.$router.push('/import-settings')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
      this.isSaving = false
    }
  }
}
</script>
