<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body section">
      <div>
        <b-loading :active.sync="loading" is-full-page />
        <div class="flex">
          <div>
            <button id="pickup-step-12" class="button is-warning is-outlined is-small" @click="() => {$emit('close'); $emit('nextTourStep', 'mailTemplatesBack')}">
              <b-icon icon="chevron-left" />
              <span>{{ $t('global.back') }}</span>
            </button>
          </div>
        </div>
        <div v-if="selectedGroup" class="section">
          <div class="container">
            <div class="column">
              <b-select
                v-model="selectedTemplate"
                :placeholder="$t('pages.pickup_management.mail_templates.select_placeholder')"
              >
                <option
                  v-for="template in mailTemplates"
                  :key="template.id"
                  :value="template"
                >
                  {{ template.templateName }}
                </option>
              </b-select>
            </div>
            <div class="column right-align buttons">
              <button
                id="pickup-step-5"
                class="button is-info"
                @click="newTemplate"
              >
                <b-icon icon="plus" />
              </button>
              <button v-if="selectedTemplate" class="button is-danger" @click="deleteTemplate">
                {{ $t('global.delete') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="selectedTemplate" class="container">
          <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="submit">
            <b-field :label="$t('pages.pickup_management.mail_templates.template_name')">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  id="pickup-step-6"
                  v-model="selectedTemplate.templateName"
                  name="templateName"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.mail_templates.mail_content')">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <textarea
                  id="pickup-step-7"
                  v-model="selectedTemplate.content"
                  class="textarea"
                  name="content"
                  type="text"
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.mail_templates.subject')">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  id="pickup-step-8"
                  v-model="selectedTemplate.subject"
                  name="subject"
                  type="text"
                />
              </ValidationProvider>
            </b-field>
            <b-tooltip
              :label="$t('pages.pickup_management.collect_confirmation.sender_hint')"
              position="is-right"
              style="margin-top: 1rem;"
            >
              <b>
                {{ $t('pages.pickup_management.collect_confirmation.sender') }}
              </b>
              <b-icon icon="info" type="is-info" class="is-small" />
            </b-tooltip>
            <ValidationProvider
              v-slot="{ errors }"
              rules="email"
            >
              <b-field
                :type="errors.length > 0 && selectedTemplate.sender ? 'is-danger' : ''"
                style="margin-bottom: 1rem"
              >
                <b-input
                  v-model="selectedTemplate.sender"
                  name="subject"
                  type="text"
                />
              </b-field>
            </ValidationProvider>
            <div>
              <b
                id="pickup-step-9"
                class="sub-titl9"
              >
                {{ $t('pages.pickup_management.mail_templates.language') }}
              </b>
              <b-tooltip
                :label="$t('pages.pickup_management.mail_templates.language_tooltip')"
                position="is-right"
              >
                <b-icon icon="info" type="is-info" class="is-small" />
              </b-tooltip>
            </div>
            <div>
              <b-field style="display: inline-block">
                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link">
                    <img :src="`https://flagcdn.com/w20/${getLocalFlagString(selectedTemplate.lang).toLowerCase()}.png`" alt="flag">
                    <span class="ml-2">{{ getLangName(selectedTemplate.lang) }}</span>
                  </div>

                  <div class="navbar-dropdown">
                    <div
                      v-for="lang in langs"
                      :key="lang"
                      :value="lang"
                      class="navbar-item hoverable"
                      @click.prevent="selectLang(lang)"
                    >
                      <img :src="`https://flagcdn.com/w20/${getLocalFlagString(lang).toLowerCase()}.png`" alt="flag">
                      <span>{{ getLangName(lang) }}</span>
                    </div>
                  </div>
                </div>
              </b-field>
            </div>
            <b-field :label="$t('pages.pickup_management.mail_templates.misc')">
              <ValidationProvider v-slot="{ errors }">
                <textarea
                  id="pickup-step-10"
                  v-model="selectedTemplate.misc"
                  class="textarea"
                  name="misc"
                  type="text"
                />
              </ValidationProvider>
            </b-field>
            <button
              id="pickup-step-11"
              :class=" {'is-loading' : isSubmitting}"
              :disabled="!valid"
              type="submit"
              class="button is-info is-fullwidth"
            >
              {{ $t('global.submit') }}
            </button>
          </ValidationObserver>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

// import Collect from '@/services/v2/collect'

import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import mixinMethods from '@/mixins/misc'

export default {
  mixins: [mixinMethods],
  props: {
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      mailTemplates: [],
      selectedTemplate: null,
      isSubmitting: false,
      langs: []
    }
  },
  mounted: async function () {
    try {
      this.loading = true
      await this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
    this.loading = false
  },
  methods: {
    loadData: async function () {
      this.mailTemplates = await EnduserDeclarationAdmin.getMailTemplates(this.selectedGroup.collectGroup)
      this.langs = this.$i18n.availableLocales
    },
    newTemplate: function () {
      this.selectedTemplate = {
        templateName: 'New template',
        content: '',
        subject: '',
        misc: '',
        lang: 'en',
        collectGroup: ''
      }
      this.mailTemplates.push(this.selectedTemplate)
      this.$emit('nextTourStep', 'mailTemplatesAdd')
    },
    submit: async function () {
      let action = this.selectedTemplate.id ? 'Update' : 'Create'
      this.isSubmitting = true
      this.$buefy.dialog.confirm({
        title: action + ' template',
        message: `Are you sure you want to <b>${action.toLowerCase()}</b> this template?`,
        confirmText: 'Yes',
        type: 'is-link',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.selectedTemplate.collectGroup = this.selectedGroup.collectGroup
            await EnduserDeclarationAdmin.postMailTemplate(this.selectedTemplate)
            this.$buefy.toast.open({ type: 'is-primary', message: this.selectedTemplate.id ? 'Template updated' : 'Template created', duration: 3000 })
            this.loadData()
            this.selectedTemplate = null
            this.$emit('nextTourStep', 'mailTemplatesSubmit')
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
      this.isSubmitting = false
    },
    deleteTemplate: async function () {
      this.$buefy.dialog.confirm({
        title: 'Delete template',
        message: `Are you sure you want to <b>delete</b> this template?`,
        confirmText: 'Delete',
        type: 'is-link',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await EnduserDeclarationAdmin.deleteMailTemplate(this.selectedTemplate.id)
            this.$buefy.toast.open({ type: 'is-primary', message: 'Template deleted', duration: 3000 })
            this.selectedTemplate = null
            this.loadData()
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    },
    selectLang: function (lang) {
      this.selectedTemplate.lang = lang
    }
  }
}
</script>

<style>
.navbar-item > span {
  margin-left: 5px;
}

.navbar-item {
  cursor: pointer;
  margin-right: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

</style>
