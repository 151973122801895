
<!--
 eslint-disable vue/no-use-v-if-with-v-for */
eslint-disable vue/no-template-shadow
-->
<template>
  <div class="div">
    <v-tour name="pickupManagementTour" :steps="tourSteps" :callbacks="customCallbacks">
      <template slot-scope="tour" class="test">
        <!-- <transition name="fade"> -->
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template>
            <div slot="actions">
              <div>
                <b-button v-if="!tour.isFirst" class="tourBtn" type="is-small" @click="tour.previousStep">
                  Previous step
                </b-button>
                <b-button v-if="currentStep < tourSteps.length -1" class="tourBtn" type="is-small" @click="tour.nextStep">
                  Next step
                </b-button>
                <b-button class="tourBtn" type="is-small" @click="tour.stop">
                  Finish tour
                </b-button>
              </div>
            </div>
          </template>
        </v-step>
        <!-- </transition> -->
      </template>
    </v-tour>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      customCallbacks: {
        onPreviousStep: this.previousStepCallback,
        onNextStep: this.nextStepCallback,
        onStop: this.stop
      },
      tour: null,
      currentStep: 0,
      tourSteps: [
        {
          target: '#pickupTourContainer',
          header: {
            title: 'Helper'
          },

          content: `Hello, I'm going to teach you how to use this page. </br> If this appears : <span style="color: orange"><b>(ACTION)</b></span> </br> it means that you must perform the requested action to automatically proceed to the next step. However, you can always click on "next step" to continue.`
        },
        {
          target: '#pickupTourContainer',
          header: {
            title: 'Choose a group'
          },
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> First of all, you have to choose a collection group.`
        },
        {
          target: '#pickupTourContainer',
          content: `On this screen you can see all your endusers who can be assigned to the direct mailing process to retrieve their inventory.`
        },
        {
          target: '.pickup-step-3',
          header: {
            title: 'Mailing process'
          },
          content: `When an enduser is correctly set to SAP, this column will change to "YES". So you can see all endusers included in the process here. Also, they will not receive any mail yet. For this, you have to assign them a mail template.`
        },
        {
          target: '#pickup-step-4',
          header: {
            title: 'Mail template'
          },
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> To create a mail template, click here`

        },
        {
          target: '#pickup-step-5',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Click on the "+" button`
        },
        {
          target: '#pickup-step-6',
          content: `this field corresponds to the name of the template, in order to find it easily later. This will not appear in the mail.`
        },
        {
          target: '#pickup-step-7',
          content: `This one, corresponds to the content of the email, without the final politeness formula.`
        },
        {
          target: '#pickup-step-8',
          content: `This one, is the mail subject`
        },
        {
          target: '#pickup-step-9',
          content: `This one, will set the language of the inventory window, the one the user will see (and not the language of the mail).`
        },
        {
          target: '#pickup-step-10',
          content: `This one, is used for the closing greeting, as well as any other field that may appear at the end of the mail.`
        },
        {
          target: '#pickup-step-11',
          content: `Once finished, you can validate the template by clicking here.`
        },
        {
          target: '#pickup-step-12',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Now you can go back to the previous screen.`
        },
        {
          target: '#pickupTourContainer',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> In order for the enduser to receive an email, you have to set up a template. To do this, right-click on the desired enduser (it has to be in the auto mailing process for it to work), then click on "set mail template".`
        },
        {
          target: '#pickup-step-14',
          content: `Select the desired template`
        },
        {
          target: '#pickup-step-15',
          content: `<span style="color: orange"><b>(ACTION)</b></span> </br> Then validate your choice`
        },
        {
          target: '.pickup-step-16',
          content: `A template is now normally attached to an enduser. This one should receive an automatic email the next day.`
        },
        {
          target: '#pickup-step-17',
          content: `Here you can add a signature to your automatic email, in order to give your contact information to the endusers. It will appear on all emails sent, regardless of the template. (Not part of the tutorial)`
        },
        {
          target: '.pickup-step-18',
          content: `Here is the column concerning the number of reminders sent to the Enduser on the last inventory request. An inventory request is sent every 7/15/30 days (depending on the SAP setting), and a reminder is sent at 1/3 and then 2/3 of the time if he does not give his inventory within the time limit.`
        },
        {
          target: '.pickup-step-18',
          content: `When the enduser has received an email, this column will contain an arrow icon. By clicking on it, a table will appear showing all the inventory requests sent, with their respective reminders.`
        },
        {
          target: '.pickup-step-19',
          content: `Here you will find the column specifying whether the Enduser made a declaration at the last request. An exclamation mark will appear if there is a comment associated with it.`
        },
        {
          target: '.pickup-step-20',
          content: `In this column you will see if the last declaration was opened (by you), so you don't miss any.`
        },
        {
          target: '#pickupTourContainer',
          content: `To see this same declaration, right-click on a line containing one, then click on "See declaration".`
        },
        {
          target: '#pickupTourContainer',
          content: `The main tutorial on the enduser's reminders part is now over.`
        }
      ]
    }
  },
  mounted: function () {
    this.tour = this.$tours['pickupManagementTour']
  },
  methods: {
    stop (currentStep) {
      currentStep = -1
      this.currentStep = currentStep
      this.$emit('tourStoped')
    },
    previousStepCallback (currentStep) {
      currentStep--
      this.currentStep = currentStep
    },
    nextStepCallback (currentStep) {
      currentStep++
      this.currentStep = currentStep
    },
    nextTourStep (action) {
      if (this.tour.currentStep === 1 && action === 'selectGroup') { this.tour.nextStep() }
      if (this.tour.currentStep === 4 && action === 'createMailTemplates') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 5 && action === 'mailTemplatesAdd') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 11 && action === 'mailTemplatesSubmit') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 12 && action === 'mailTemplatesBack') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 13 && action === 'setMailTemplate') { setTimeout(() => { this.tour.nextStep() }, 200) }
      if (this.tour.currentStep === 15 && action === 'closeSettingMailTemplate') { setTimeout(() => { this.tour.nextStep() }, 200) }
    }
  }
}
</script>

<style scoped>
.tourBtn {
    border: solid 1px white;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
}
.tourBtn:hover {
    color: white;
}

.orange {
  color: orange;
}
</style>
