// import * as roles from '../utils/rolesTypes'

const getters = {
  getCustomerFileSettings: state => state.groupFileSettings || {},
  getMovementFields: state => state.movementFields || {},
  getMovementTables: state => state.movementTables || {},
  getCustomerExportRecap: state => state.customerExportRecap || {},
  getCustomerExportRecapAll: state => state.customerExportRecap || {},
  getExportDetails: state => state.exportDetails || {},
  getEdiCustomers: state => state.ediCustomers || []
}

export default getters
