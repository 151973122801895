<template>
  <div>
    <calendarTour id="calendarTour" ref="calendarTour" @tourStoped="stopTour" />
    <div id="calendarTourContainer" />
    <div>
      <div class="pickup-actions-container">
        <span>
          <b-tooltip
            :label="$t('pages.pickup_management.clear_filters')"
            position="is-top"
          >
            <b-button icon-left="filter" type="is-danger" rounded @click="clearFilters" />
          </b-tooltip>
        </span>
        <span>
          <b-tooltip
            id="calendarTourBtn"
            :label="$t('pages.pickup_management.page_tutorial')"
            position="is-top"
          >
            <b-button icon-left="question" type="is-info" rounded @click="startTour" />
          </b-tooltip>
        </span>
      </div>
      <div class="custom-container">
        <div class="column is-one-fifth">
          <b-field :label="$t('pages.pickup_management.calendar.range_dates_label')">
            <b-datepicker
              v-model="startDate"
              placeholder="Click to select..."
            />
          </b-field>
        </div>

        <div class="left-box panel-block column is-one-fifth">
          <b-field
            label="Find a collect"
            @keyup.native.enter="filterByRo"
          >
            <b-input v-model="filterByRoValue" placeholder="RO" />
            <p class="control">
              <b-button
                :disabled="loading"
                icon-right="arrow-right"
                class="button is-warning"
                @click="filterByRo"
              />
            </p>
          </b-field>
        </div>
        <span v-if="selectedGroup.collectGroup" class="buttons">
          <b-button
            :disabled="loading"
            :class="!showNotRecentlyCollected ? 'is-purple' : 'is-danger'"
            icon-left="history"
            @click="switchShowNotRecentlyCollected"
          > {{ $t('pages.pickup_management.calendar.not_recently_collected_btn') }}
            <span class="count">
              {{ notRecentlyCollected.length }}
            </span></b-button>
          <b-button :disabled="loading" icon-left="envelope" class="button is-link" @click="switchOpenConfirmationTemplates"> {{ $t('pages.pickup_management.collect_confirmation.confirmation_templates') }} </b-button>
          <b-button :disabled="loading" icon-left="door-closed" class="button is-info" @click="openClosuresGrid"> {{ $t('pages.pickup_management.calendar.show_closures_grid') }} </b-button>
          <b-button
            :disabled="loading"
            :loading="loading"
            icon-left="sync"
            class="button is-warning"
            @click="() => refresh()"
          > {{ $t('global.refresh') }} </b-button>
        </span>
      </div>
      <div class="caption d-flex-space-between">
        <div>
          <span class="subtitle caption-title">{{ $t('pages.pickup_management.calendar.caption') }} :</span>
          <br>
          <span><i class="fas fa-door-closed has-text-danger" /> : {{ $t('pages.pickup_management.calendar.contains_closing_date') }}</span>
        </div>
        <div id="status" class="relative">
          <span id="calendar-step-status-color" class="subtitle caption-title"> {{ $t('global.status') }}:</span><br>
          <div class="d-flex-space-around d-flex-justify-center">
            <span id="SC-caption" class="SC status">C: {{ $t('pages.pickup_management.calendar.status_c') }}</span>
            <span id="SP-caption" class="SP status">P: {{ $t('pages.pickup_management.calendar.status_p') }}</span>
          </div>
          <div class="d-flex-space-around d-flex-justify-center">
            <span id="ST1-caption" class="ST1 status">T1: {{ $t('pages.pickup_management.calendar.status_t1') }}</span>
            <span id="ST1b-caption" class="ST1b status">T1b: {{ $t('pages.pickup_management.calendar.status_t1b') }}</span>
            <span id="ST2-caption" class="ST2 status">T2: {{ $t('pages.pickup_management.calendar.status_t2') }}</span>
            <span id="ST3-caption" class="ST3 status">T3: {{ $t('pages.pickup_management.calendar.status_t3') }}</span>
          </div>
          <div class="flex f-center">
            <span id="SMultiple-caption" class="SMultiple">{{ $t('pages.pickup_management.calendar.mixed_status') }}</span>
          </div>
          <b-button id="pin-button" class="is-small is-primary is-outlined is-rounded" @click="pinCaption">
            <b-icon icon="thumbtack" />
          </b-button>
        </div>
      </div>
      <div v-if="!showNotRecentlyCollected">
        <ag-grid-vue
          id="calendar-step-grid"
          style="width: 100%; height: 1000px;"
          class="ag-theme-balham"
          :row-data="gridData"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="multiple"
          :group-hide-open-parents="true"
          @grid-ready="onGridReady"
        />
      </div>
      <div v-else>
        <ag-grid-vue
          id="calendar-step-grid"
          style="width: 100%; height: 1000px;"
          class="ag-theme-balham"
          :row-data="notRecentlyCollected"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="multiple"
          :group-hide-open-parents="true"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
    <b-modal :active.sync="showCollectCreation" scroll="keep" has-modal-card full-screen>
      <collectCreation :endusers="selectedEndusers" :selected-group="selectedGroup.collectGroup" @close="closeCollectCreation" @nextTourStep="nextTourStep" />
    </b-modal>
    <b-modal :active.sync="showCollectInfo" scroll="keep" has-modal-card full-screen>
      <collectInfo
        :selected-group="selectedGroup"
        :collects="selectedCollects"
        :associated-collects="associatedCollects"
        @nextTourStep="nextTourStep"
        @close="closeCollectInfos"
      />
    </b-modal>
    <b-modal
      v-if="selectedEnduser"
      :active.sync="showClosure"
      scroll="keep"
      has-modal-card
      full-screen
    >
      <closure :enduser="selectedEnduser" @close="closeClosure" @nextTourStep="nextTourStep" />
    </b-modal>
    <b-modal :active.sync="showClosuresGrid" scroll="keep" has-modal-card full-screen>
      <closuresGrid :endusers-data="gridData" @close="closeClosuresGrid" />
    </b-modal>
    <b-modal :active.sync="showConfirmationTemplate" scroll="keep" has-modal-card full-screen>
      <confirmationTemplate :selected-group="selectedGroup" @close="switchOpenConfirmationTemplates" />
    </b-modal>
  </div>
</template>

<script>

import { AllModules } from '@ag-grid-enterprise/all-modules'
import collectInfo from './collectInfo'
import collectCreation from './collectCreation'
import closure from './closure'
import calendarTour from './calendarTour'
import closuresGrid from './closuresGrid'
import confirmationTemplate from './collectConfirmationTemplates'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'

export default {
  components: {
    collectInfo,
    collectCreation,
    closure,
    calendarTour,
    closuresGrid,
    confirmationTemplate
  },
  mixins: [sortAndFilterMixins],
  props: {
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      collects: [],
      inventories: [],
      materials: [],
      modules: AllModules,
      year: new Date().getFullYear(),
      showCollectInfo: false,
      showCollectCreation: false,
      showClosure: false, // show one enduser closures and create new
      showClosuresGrid: false, // grid showing all endusers closures on the same screen
      selectedCollects: [],
      selectedEndusers: null,
      selectedEnduser: null,
      associatedCollects: [],
      closures: [],
      pinnedRow: null,
      weekOfTheYear: parseInt(this.getWeekOfYear(new Date())),
      collectEndusers: null,
      statusFilters: [],
      collectConfirmations: null,
      showConfirmationTemplate: false,
      calendarData: null,
      startDateDefault: new Date(this.$dayjs().subtract(3, 'week').toString()),
      startDate: null,
      gridData: null,
      savedData: null,
      filterByRoValue: null,
      notRecentlyCollected: [],
      showNotRecentlyCollected: false,
      weeksToShow: null,
      notRecentlyCollectedGridColumns: [
        {
          headerName: this.$t('pages.pickup_management.calendar.grid.enduser'),
          width: 400,
          // rowGroup: true,
          comparator: (a, b) => {
            if (a) {
              let sa = a.split('-')
              if (sa.length > 1) {
                a = sa[1]
              }
            }
            if (b) {
              let sb = b.split('-')
              if (sb.length > 1) {
                b = sb[1]
              }
            }
            return this.basicStringComparator(a, b)
          },
          aggFunc: this.defaultStringAgg,
          filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
          filterValueGetter: params => {
            return params.data ? params.data.enduserCode + (params.data.enduserName ? ' - ' + params.data.enduserName : '') : ''
          },
          valueGetter: (params) => {
            return params.data ? params.data.enduserCode + (params.data.enduserName ? ' - ' + params.data.enduserName : '') : ''
          },
          cellClass: 'rag-pale-background'

        },
        {
          headerName: this.$t('pages.pickup_management.calendar.grid.last_collected'),
          width: 150,
          filter: 'agDateColumnFilter',
          aggFunc: this.lastDateAgg,
          sort: 'asc',
          valueGetter: (params) => {
            if (params.data && params.data.lastCollect) {
              return this.$dayjs(params.data.lastCollect, 'YYYYMMDD').format('DD/MM/YYYY')
            }
            return ''
          },
          cellClass: 'rag-pale-background',
          comparator: this.dateSort
        }
      ],
      masterDetail: true,

      gridOptions: {
        masterDetail: this.masterDetail,
        suppressAggFuncInHeader: true,
        getContextMenuItems: this.gridActions,
        onCellMouseOver: this.cellMouseOver,
        onCellMouseOut: this.cellMouseOver,
        onFilterChanged: this.filterChanged,
        autoGroupColumnDef: {
          pinned: 'left', // force pinned left. Does not work in columnDef
          comparator: (a, b) => {
            if (a) {
              let sa = a.split('-')
              if (sa.length > 1) {
                a = sa[1]
              }
            }
            if (b) {
              let sb = b.split('-')
              if (sb.length > 1) {
                b = sb[1]
              }
            }
            return this.basicStringComparator(a, b)
          }
        },
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [],
        detailRowHeight: 350,
        groupDefaultExpanded: 1,
        enableBrowserTooltips: true

      }

    }
  },
  computed: {
    weekColumns: function () {
      let months = [
        this.$t('pages.pickup_management.calendar.grid.january'),
        this.$t('pages.pickup_management.calendar.grid.february'),
        this.$t('pages.pickup_management.calendar.grid.march'),
        this.$t('pages.pickup_management.calendar.grid.april'),
        this.$t('pages.pickup_management.calendar.grid.may'),
        this.$t('pages.pickup_management.calendar.grid.june'),
        this.$t('pages.pickup_management.calendar.grid.july'),
        this.$t('pages.pickup_management.calendar.grid.august'),
        this.$t('pages.pickup_management.calendar.grid.september'),
        this.$t('pages.pickup_management.calendar.grid.october'),
        this.$t('pages.pickup_management.calendar.grid.november'),
        this.$t('pages.pickup_management.calendar.grid.december')
      ]
      let weekCount = 1

      let columns = {}
      for (let i = 0; i < 12; i++) {
        const month = months[i]
        const nbWeeks = this.getWeeks(this.year, i)
        let monthWeeks = []
        for (let j = 0; j < nbWeeks; j++) {
          monthWeeks.push('W' + weekCount)
          weekCount++
        }
        columns[month] = monthWeeks
      }
      return columns
    },
    buildColumns: function () {
      let columns = []
      columns.push(
        {
          headerName: 'Infos',
          marryChildren: true,
          children: [
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.country'),
              width: 130,
              filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
              comparator: this.basicStringComparator,
              aggFunc: this.defaultStringAgg,
              valueGetter: (params) => {
                return params.data ? params.data.country : ''
              },
              cellRenderer: this.flagRender,
              cellClass: 'rag-pale-background'
            },
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.enduser'),
              width: 400,
              rowGroup: true,
              comparator: (a, b) => {
                if (a) {
                  let sa = a.split('-')
                  if (sa.length > 1) {
                    a = sa[1]
                  }
                }
                if (b) {
                  let sb = b.split('-')
                  if (sb.length > 1) {
                    b = sb[1]
                  }
                }
                return this.basicStringComparator(a, b)
              },
              aggFunc: this.defaultStringAgg,
              filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
              filterValueGetter: params => {
                return params.data ? params.data.enduserCode + (params.data.enduserName ? ' - ' + params.data.enduserName : '') : ''
              },
              valueGetter: (params) => {
                return params.data ? params.data.enduserCode + (params.data.enduserName ? ' - ' + params.data.enduserName : '') : ''
              },
              cellClass: 'rag-pale-background'

            },
            {
              headerName: 'Type',
              field: 'enduserType',
              width: 120,
              cellClass: 'rag-pale-background'
            },
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.inventory'),
              width: 100,
              filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
              headerClass: 'calendar-step-last-inv',
              aggFunc: this.intAgg,
              comparator: (a, b) => {
                return (a || 0) > (b || 0)
              },
              valueGetter: (params) => {
                if (params.data && params.data.inventory) {
                  return params.data.inventory.inventory
                }
                return ''
              },
              cellClass: 'rag-pale-background'
            },
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.inventory_date'),
              width: 150,
              filter: 'agDateColumnFilter',
              aggFunc: this.lastDateAgg,
              valueGetter: (params) => {
                if (params.data && params.data.inventory) {
                  return this.$dayjs(params.data.inventory.date).format('DD/MM/YYYY')
                }
                return ''
              },
              cellClass: 'rag-pale-background',
              comparator: this.dateSort
            },
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.theorical_stock'),
              field: 'theoricalStock',
              width: 120,
              headerClass: 'calendar-step-theorical-stock',
              aggFunc: this.intAgg,
              filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
              comparator: (a, b) => {
                return (a || 0) > (b || 0)
              },
              valueGetter: (params) => {
                return this.calcTheoryStock(params)
              },
              cellClass: 'rag-pale-background'
            },
            {
              headerName: this.$t('pages.pickup_management.calendar.grid.material'),
              width: 120,
              filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
              aggFunc: this.stringAgg,
              valueGetter: (params) => {
                return params.data ? params.data.material : ''
              },
              cellClass: 'rag-pale-background'
            }
          ]
        }
      )
      for (const element in this.weekColumns) {
        columns.push(
          {
            headerName: element,
            marryChildren: true,
            children:
              this.weekColumns[element].map(week => {
                return {
                  headerName: week + ' (' + this.$t('pages.pickup_management.calendar.grid.collect') + ')',
                  width: 130,
                  aggFunc: this.stringToIntAgg,
                  hide: parseInt(week.split('W')[1]) < this.weeksToShow[0] || parseInt(week.split('W')[1]) > this.weeksToShow[1],
                  filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
                  valueGetter: (params) => {
                    let agr = ''
                    if (params.data && params.data.collects) {
                      params.data.collects.filter(c => ('W' + (parseInt(c.week)) === week && c.status !== 'D')).map(c => {
                        agr.length === 0 ? agr += parseInt(c.qty) : agr += ', ' + parseInt((c.qty))
                      })
                    }
                    return agr
                  },
                  cellRenderer: (params) => {
                    if (params.data && params.data.closures && params.data.closures.length > 0) {
                      if (params.data.closures.find(c => {
                        return (this.year.toString() === this.$dayjs(c.startDate).year().toString() || this.year.toString() === this.$dayjs(c.endDate).year().toString()) && this.getWeekOfYear(new Date(c.startDate)) <= week.split('W')[1] && this.getWeekOfYear(new Date(c.endDate)) >= week.split('W')[1]
                      })) { return '<i class="fas fa-door-closed has-text-danger"></i>' + params.value }
                    } return params.value
                  },
                  cellStyle: params => {
                    // if (params.data && params.data.collects) {
                    //   const weekCollects = params.data.collects.filter(c => {
                    //     return 'W' + (parseInt(c.week)) === week
                    //   })
                    //   console.log(weekCollects)
                    //   if (weekCollects.find(c => c.year !== this.$dayjs(this.startDate || this.startDateDefault).year())) {
                    //     return { backgroundColor: 'yellow' }
                    //   }
                    //   return null
                    // }
                  },
                  cellClass: function (params) {
                    if (params.data && params.data.collects) {
                      const weekCollects = params.data.collects.filter(c => {
                        return 'W' + (parseInt(c.week)) === week
                      })
                      const hasC = weekCollects.filter(c => {
                        return c.newStatus === 'C'
                      }).length > 0 ? 1 : 0
                      const hasP = weekCollects.filter(c => {
                        return c.newStatus === 'P'
                      }).length > 0 ? 1 : 0
                      const hasT1 = weekCollects.filter(c => {
                        return c.newStatus === 'T1'
                      }).length > 0 ? 1 : 0
                      const hasT1b = weekCollects.filter(c => {
                        return c.newStatus === 'T1b'
                      }).length > 0 ? 1 : 0
                      const hasT2 = weekCollects.filter(c => {
                        return c.newStatus === 'T2'
                      }).length > 0 ? 1 : 0
                      const hasT3 = weekCollects.filter(c => {
                        return c.newStatus === 'T3'
                      }).length > 0 ? 1 : 0
                      if (hasP + hasC + hasT1 + hasT2 + hasT3 + hasT1b > 1) {
                        return 'SMultiple'
                      } else {
                        if (hasC) return 'SC'
                        if (hasP) return 'SP'
                        if (hasT1) return 'ST1'
                        if (hasT1b) return 'ST1b'
                        if (hasT2) return 'ST2'
                        if (hasT3) return 'ST3'
                      }
                    }
                    return ''
                  },
                  tooltipValueGetter: params => {
                    if (params.data) {
                      let shipmentNrs = []
                      let status = []
                      if (params.data && params.data.collects) {
                        params.data.collects.map(e => {
                          if ('W' + (parseInt(e.week)) === week) {
                            if (e.shipmentNr) { shipmentNrs.push(e.shipmentNr) }
                            if (e.newStatus) { status.push(e.newStatus) }
                          }
                        })
                        let text = ''
                        if (shipmentNrs.length > 0) {
                          text = this.$t('pages.pickup_management.calendar.grid.shipment_nr') + ': ' + shipmentNrs
                        }
                        if (status.length > 0) {
                          text += '\n'
                          text += this.$t('pages.pickup_management.calendar.grid.status') + ': ' + status
                        }
                        return text
                      }
                      return ''
                    }
                    return ''
                  }
                }
              })
          }
        )
      }
      return columns
    }
    // }
  },
  watch: {
    selectedGroup: async function (selectedGroup) {
      this.loadData()
    },
    startDate: function () {
      this.loadData()
    }
  },
  mounted: async function () {
    try {
      this.startDate = this.startDateDefault
      this.loading = true
      this.getWeeksToDisplay()
      this.handleStatusFiltersDom()
      await this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  methods: {
    startTour: function () {
      if (this.$tours['collectCalendarTour']) { this.$tours['collectCalendarTour'].start() }
    },
    stopTour () {

    },
    nextTourStep (action) {
      if (this.$refs.calendarTour) {
        this.$refs.calendarTour.nextTourStep(action)
      }
    },
    onGridReady: async function (params) {
      this.gridApi = this.gridOptions.api
      this.gridApi.setPinnedBottomRowData([this.pinnedRow])

      if (this.gridApi) { this.gridApi.setColumnDefs([...this.buildColumns]) }
    },
    stringToIntAgg: function ({ values }) {
      let sum = 0
      if (values) {
        values.map(v => {
          if (v && v.length > 0) {
            const strings = v.split(',')
            strings.map(s => {
              sum += parseInt(s)
            })
          }
        })
      }
      return sum
    },
    refresh: function () {
      this.loadData()
    },
    loadData: async function () {
      this.loading = true
      try {
        if (this.selectedGroup && this.selectedGroup.collectGroup) {
          this.notRecentlyCollected = await EnduserDeclarationAdmin.getNotRecentlyCollected(this.selectedGroup.collectGroup)
          const data = await EnduserDeclarationAdmin.getCalendarData({ 'collectGroup': this.selectedGroup.collectGroup,
            startDate: this.$dayjs(this.startDate).format('YYYYMMDD'),
            endDate: this.$dayjs().add(1, 'month').format('YYYYMMDD'),
            year: this.year })
          this.gridData = this.savedData = data.computed
          this.collects = data.collects

          this.calendarData = {
            closures: data[0],
            allCollects: data[1],
            collectConfirmations: data[2]
          }
          this.loading = false
          this.getSavedFilters()
          this.filterByStatus()
          this.filterByRo()
          this.calcTotal()
          this.toggleHideMonths()
        }
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        this.loading = false
      }
    },
    getSavedFilters: function () {
      if (this.selectedGroup) {
        if (this.gridOptions.api) {
          setTimeout(() => {
            this.gridOptions.api.setFilterModel(JSON.parse(localStorage.getItem('calendarGridFilters')) || null)
            this.gridOptions.api.refreshCells()
          }, 500)
        }
      }
    },
    flagRender: function (params) {
      if (params.data && params.data.countryCode) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px; height: 20px; width: 30px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    calcTotal: function () {
      // add qty total row
      this.pinnedRow = { enduserCode: 'Total', collects: [] }
      let collects = []
      this.gridData.forEach(t => {
        if (t.collects && t.collects.length) {
          collects = collects.concat(t.collects)
        }
      })
      collects.forEach(collect => {
        const existingWeek = this.pinnedRow.collects.find(c => (parseInt(c.week).toString()) === (parseInt(collect.week).toString()))
        if (existingWeek) {
          existingWeek.qty += parseInt(collect.qty)
        } else {
          this.pinnedRow.collects.push({ week: (parseInt(collect.week).toString()), qty: parseInt(collect.qty) })
        }
      })
      if (this.gridApi) {
        this.gridApi.setPinnedBottomRowData([this.pinnedRow])
      }
    },
    modulo (n, modulo) {
      return ((n % modulo) + modulo) % modulo
    },
    getWeeks: function (year, month) {
      // first DAY of the month (0-6 = monday-sunday)
      const firstDay = this.modulo(parseInt((new Date(year, month, 1)).getDay() - 1), 7)
      // last DATE of the month (ex: January 31)
      const lastDate = (new Date(year, month + 1, 0)).getDate()

      let firstMondayDate
      if (month === 0) {
        if (firstDay > 3) {
          firstMondayDate = (new Date(year, month, firstDay !== 0 ? 1 + (7 - firstDay) : 1)).getDate()
        } else {
          firstMondayDate = 1
        }
      } else {
        // first monday
        firstMondayDate = (new Date(year, month, firstDay !== 0 ? 1 + (7 - firstDay) : 1)).getDate()
      }

      return Math.ceil((lastDate - firstMondayDate + 1) / 7)
    },
    gridActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      const colName = params.column && params.column.userProvidedColDef ? params.column.userProvidedColDef.headerName : ''
      const selectedRows = this.gridOptions.api.getSelectedRows()
      if (selectedRows.length > 1) {
        customMenu.push({
          name: this.$t('pages.pickup_management.calendar.grid.create_collect'),
          action: () => {
            this.collectCreation(selectedRows)
            this.nextTourStep('createCollect')
          },
          icon: '<i class="fas fa-fw fa-truck-pickup" ></i>'
        })
      } else {
        if (params.node.data) {
          if (colName.includes(this.$t('pages.pickup_management.calendar.grid.collect')) && params.value) {
            const nodeData = params.node.data
            const weekName = colName.split(' ')[1]
            const week = colName.split(' ')[0].slice(1, weekName.length)
            const collectsSelected = this.collects.filter(c => (parseInt(c.week)).toString() === week.toString() && c.enduserCode === nodeData.enduserCode)
            collectsSelected.forEach(c => {
              c.emails = this.gridData?.find(ce => ce.enduserCode.toString() === c.enduserCode.toString())?.emails
            })

            customMenu.push({
              name: this.$t('pages.pickup_management.calendar.grid.see_details'),
              action: () => {
                this.collectInfo(collectsSelected)
                this.nextTourStep('collectDetails')
              },
              icon: '<i class="fas fa-fw fa-info" ></i>'
            })
          }

          if (params.node.data.enduserType === 'EU') {
            customMenu.push({
              name: this.$t('pages.pickup_management.calendar.grid.create_collect'),
              action: () => {
                this.collectCreation([params.node.data])
                this.nextTourStep('createCollect')
              },
              icon: '<i class="fas fa-fw fa-truck-pickup" ></i>'
            })
          }
        }
      }

      const result = [...customMenu, ...genericMenu]
      return result
    },
    collectInfo: async function (collects) {
      this.associatedCollects = []
      this.selectedCollects = collects

      this.showCollectInfo = true
    },
    closeCollectInfos: function () {
      this.showCollectInfo = false
      this.loadData()
    },
    collectCreation (selectedRows) {
      this.selectedEndusers = []
      if (selectedRows.length > 0) {
        selectedRows.map(r => {
          if (!this.selectedEndusers.find(e => e.enduserCode === r.enduserCode)) {
            this.selectedEndusers.push(r)
          }
        })
      }
      this.showCollectCreation = true
    },
    closeCollectCreation: function () {
      this.showCollectCreation = false
    },
    openClosure (enduser) {
      this.selectedEnduser = enduser
      this.showClosure = true
    },
    closeClosure () {
      this.showClosure = false
      this.selectedEnduser = null
    },
    openClosuresGrid (enduser) {
      this.showClosuresGrid = true
    },
    async closeClosuresGrid (updated) {
      this.showClosuresGrid = false
      await this.loadData()
    },
    cellMouseOver (e) {
      if (e.node.data && e.node.data.collects) {
        const alreadyHighlighted = []
        e.node.data.collects.forEach(c => {
          const weekNr = e.colDef.headerName.split(' ')[0]
          if (weekNr.slice(1, weekNr.length).toString() === (parseInt(c.week)).toString()) {
            if (!alreadyHighlighted.includes(c.newStatus)) {
              this.highlightStatus(c.newStatus)
              alreadyHighlighted.push(c.newStatus)
            }
          }
        })
        if (alreadyHighlighted.length > 1) { this.highlightStatus('Multiple') }
      }
    },
    highlightStatus (status) {
      const statusCaption = document.getElementById('S' + status + '-caption')
      if (statusCaption) {
        statusCaption.classList.toggle('S' + status + 'R')
      }
    },
    pinCaption () {
      const status = document.getElementById('status')
      status.classList.toggle('fade-out')
      setTimeout(() => {
        status.classList.toggle('sticked-helper')
        status.classList.toggle('relative')
        status.classList.remove('fade-out')
        status.classList.toggle('fade-in')
        setTimeout(() => {
          status.classList.remove('fade-in')
        }, 500)
      }, 500)
    },
    getWeeksToDisplay () {
      const start = this.$dayjs(this.startDate || this.startDateDefault).isoWeek()
      const end = this.$dayjs().add(1, 'month').isoWeek()
      this.weeksToShow = [start, end]
    },
    toggleHideMonths () {
      this.loading = true
      try {
        const weeksInYear = this.$dayjs().isoWeeksInYear()
        this.getWeeksToDisplay()
        const overlaps = (this.weeksToShow[0] > this.weeksToShow[1])
        this.gridOptions.columnApi.getAllColumns().map(c => {
          if (c.userProvidedColDef.headerName.includes(this.$t('pages.pickup_management.calendar.grid.collect'))) {
            let visible = false
            const week = c.userProvidedColDef.headerName.slice(1, 3)
            if (overlaps) {
              if ((parseInt(week) >= this.weeksToShow[0] && parseInt(week) <= weeksInYear) || (parseInt(week) <= this.weeksToShow[1] && parseInt(week) >= 0)) {
                visible = true
              }
            } else {
              if (parseInt(week) >= this.weeksToShow[0] && parseInt(week) <= this.weeksToShow[1]) {
                visible = true
              }
            }
            this.gridOptions.columnApi.setColumnVisible(c.colId, visible)
          }
        })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
      this.loading = false
    },
    filterChanged: function (e) {
      if (e.afterFloatingFilter != null) {
        localStorage.setItem('calendarGridFilters', JSON.stringify(this.gridOptions.api.getFilterModel()))
      }
    },
    clearFilters: function () {
      localStorage.setItem('calendarGridFilters', JSON.stringify({}))
      this.gridOptions.api.setFilterModel(JSON.parse(localStorage.getItem('calendarGridFilters')))
      this.startDate = this.startDateDefault
      this.filterByRoValue = null
      this.filterByRo()
      const allStatus = document.getElementsByClassName('status')
      allStatus.forEach(s => {
        const status = s.innerHTML.includes(':') ? s.innerHTML?.split(':')[0] : 'Multiple'
        s.classList.remove('S' + status + 'R')
      })
      this.filterByStatus()
    },
    handleStatusFiltersDom () {
      const allStatus = document.getElementsByClassName('status')
      allStatus.forEach(s => {
        const status = s.innerHTML.includes(':') ? s.innerHTML?.split(':')[0] : 'Multiple'
        s.addEventListener('mouseup', (e) => {
          e.target.classList.toggle('S' + status + 'R')
          this.filterByStatus(status)
        })
      })
    },
    getWeekOfYear (d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
      // Return array of year and week number
      return weekNo
    },
    filterByStatus: function () {
      const allStatus = document.getElementsByClassName('status')
      this.statusFilters = []
      allStatus.forEach(s => {
        const status = s.innerHTML.includes(':') ? s.innerHTML?.split(':')[0] : 'Multiple'
        if (s.classList.contains('S' + status + 'R')) {
          this.statusFilters.push(status)
        }
        if (this.statusFilters.length) {
          this.gridData = this.savedData.map(e => {
            const copy = { ...e }
            copy.collects = e.collects?.filter(c => this.statusFilters.includes(c.newStatus)) ?? []
            return copy
          })
          this.gridData = this.gridData.filter(t => t.collects?.length)
        } else {
          this.gridData = this.savedData
        }
      })
      // this.gridApi.refreshCells()
    },
    filterByRo () {
      if (this.filterByRoValue) {
        this.gridData = this.savedData.map(e => {
          const copy = { ...e }
          copy.collects = e.collects?.filter(c => c.shipmentNr === this.filterByRoValue)
          return copy
        })
        this.gridData = this.gridData.filter(t => t.collects?.length)
      } else {
        this.gridData = this.savedData
      }
    },
    switchOpenConfirmationTemplates: function () {
      this.showConfirmationTemplate = !this.showConfirmationTemplate
    },
    switchShowNotRecentlyCollected: function () {
      this.showNotRecentlyCollected = !this.showNotRecentlyCollected
      if (this.showNotRecentlyCollected) {
        if (this.gridApi) { this.gridApi.setColumnDefs([...this.notRecentlyCollectedGridColumns]) }
        this.masterDetail = false
      } else {
        if (this.gridApi) { this.gridApi.setColumnDefs([...this.buildColumns]) }
        this.masterDetail = true
      }
    },
    calcTheoryStock (params) {
      if (params?.data && params.data.inventory) {
        let stock = params.data.inventory.inventory
        if (params.data.collects) {
          params.data.collects.map(c => {
            if (this.$dayjs(c.loadingDate).isAfter(this.$dayjs(params.data.inventory.date))) {
              stock -= c.qty
            }
          })
        }
        return stock
      }
      return ''
    }
  }
}
</script>

<style scoped>
/* #mapid { height: 250px; } */
.custom-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.f-center {
  justify-content: center;
}

#calendarTourContainer {
  height: 0px;
  position: absolute;
  right: 0px;
  top: -8rem;
  z-index: 60;
  margin-right: 1rem;
  min-width: 120px;
}

#status > div > span {
  border-radius: 15px;
  border: dashed 1px;
  padding: 4px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.relative {
  position: relative;
}

.sticked-helper {
  position: fixed;
  z-index: 100;
  right: 30px;
  top: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  border: 7px solid #6abf9e;
}

#pin-button {
  position: absolute;
  right: -6px;
  top: -6px;
}

.status {
  cursor: pointer;
}

.count {
  padding: 2px;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
  background-color: red;
  border-radius: 15px;
  min-width: 30px;
  min-height: 30px;
}

</style>
