import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/users'

const users = axios.create({
  baseURL
})

interceptorsSetup(users)

export async function create (
  username,
  displayName,
  groupId,
  password,
  email,
  roleId,
  type
) {
  return axios
    .post(process.env.VUE_APP_API_URL + `/auth/register`, {
      displayname: displayName,
      username: username,
      password: password,
      groupId: groupId,
      email: email,
      roleId: roleId,
      type: type
    })
    .then(response => response.data)
}

export async function getAll ({ pickupUsers }) {
  let { data: allUsers } = await users('/', { params: { pickupUsers: pickupUsers } })
  return allUsers
}

export async function deleteUser (id) {
  let { data: response } = await users.delete(`/${id}/`)
  return response
}

export async function activateUser (userinfo) {
  const { data: response } = await users.post('/activate-user', userinfo)
  return response
}

export async function updateUser (
  id,
  username,
  displayName,
  groupId,
  email,
  password,
  roleId,
  type
) {
  let { data: response } = await users.put(`/${id}/`, {
    displayname: displayName,
    groupId: groupId,
    username: username,
    email: email,
    password: password,
    roleId: roleId,
    type: type
  })
  return response
}
