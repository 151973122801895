<template>
  <section class="section">
    <div class="container is-fluid">
      <b-field>
        <b-button icon-left="arrow-left" @click="$router.go(-1)">
          Back
        </b-button>
      </b-field>

      <div class="columns">
        <div class="column is-7">
          <div v-if="file">
            <p class="title is-4 is-inline">
              {{ file.filename }}
            </p>
            <div class="buttons is-inline">
              <b-tooltip label="Delete shipping file" type="is-danger">
                <b-button
                  size="is-small"
                  type="is-danger"
                  rounded
                  outlined
                  icon-left="trash-alt"
                  :disabled="file.process_status === 1"
                  @click="deleteAll"
                />
              </b-tooltip>
              <b-tooltip label="Download file" type="is-info">
                <b-button
                  size="is-small"
                  type="is-info"
                  rounded
                  outlined
                  icon-left="download"
                  :loading="isDownloading"
                  @click="downloadFile"
                />
              </b-tooltip>
              <b-tooltip label="Check lines validity" type="is-link">
                <b-button
                  size="is-small"
                  type="is-link"
                  rounded
                  outlined
                  icon-left="clipboard-check"
                  :loading="isFetching.checkup"
                  @click="checkup"
                />
              </b-tooltip>
              <b-tooltip label="Integrate all valid lines">
                <b-button
                  size="is-small"
                  type="is-primary"
                  rounded
                  outlined
                  icon-left="check"
                  :disabled="file.process_status === 1"
                  :loading="isFetching.integrate"
                  @click="integrate"
                />
              </b-tooltip>
            </div>
            <b-message v-if="allInvalidConsinee.length !== 0" type="is-warning" title="All Invalid Consinee" :closable="false">
              <h5 v-for="(el, i) in allInvalidConsinee" :key="i">
                {{ el }}
              </h5>
            </b-message>

            <p class="subtitle">
              {{ file.customerId }} - {{ file.table }}
            </p>
          </div>
          <div v-else class="has-text-centered">
            <b-icon
              pack="fas"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin"
            />
          </div>
        </div>
        <div class="column is-offset-1 is-3">
          <nav class="level is-mobile has-text-centered">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">
                  Valid
                </p>
                <p class="title">
                  {{ validTotalCount }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading has-text-primary">
                  Sent
                </p>
                <p class="title">
                  {{ sentTotalCount }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading has-text-warning">
                  Warnings
                </p>
                <p class="title">
                  {{ warningTotalCount }}
                </p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading has-text-danger">
                  Errors
                </p>
                <p class="title">
                  {{ errorTotalCount }}
                </p>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="buttons">
            <b-button type="is-danger" icon-left="minus" @click="deleteSelectedRows">
              Delete selected rows
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-balham"
            :row-data="lines"
            :grid-options="gridOptions"
            :pagination="true"
            :animate-rows="true"
            :row-selection="'multiple'"
            :pagination-auto-page-size="true"
            :suppress-row-click-selection="true"
            :side-bar="['columns']"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            @cellValueChanged="cellValueChanged"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
import * as Customer from '@/services/v1/customer'
import FileImport from '@/services/v2/fileImport'
import { columns } from './columns.js'
import { saveAs } from 'file-saver'

export default {
  data: function () {
    return {
      modules: AllModules,
      id: this.$route.params.fih_id,
      file: null,
      lines: [],
      checkedRows: [],
      fields: [],
      isDownloading: false,
      isFetching: {
        integrate: false,
        checkup: false
      },
      gridOptions: {

        getRowStyle: function (params) {
          if (params.node.data.status === 'ERROR') {
            return { background: '#ffcdd2' }
          } else if (params.node.data.status === 'WARNING') {
            return { background: '#FFF9C4' }
          } else if (params.node.data.status === 'SENT') {
            return { background: '#C8E6C9' }
          }
        },
        enableBrowserTooltips: true,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true,
          headerCheckboxSelection: this.hasCheckbox,
          checkboxSelection: this.hasCheckbox,
          headerCheckboxSelectionFilteredOnly: this.hasCheckbox
        },
        autoGroupColumnDef: {
          sort: 'desc'
        }
      },
      allInvalidConsinee: []
    }
  },
  computed: {
    gridColumns: function () {
      return [

        { headerName: 'STATUS',
          filter: 'agTextColumnFilter',
          field: 'status',
          width: 150,
          comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
            if (valueA === 'ERROR' || valueB === 'ERROR') {
              return 1
            } else if (valueA === 'WARNING' || valueB === 'WARNING') {
              return -1
            } else return 0
          }
        },
        { headerName: 'ERRORS',
          filter: 'agSetColumnFilter',
          width: 150,
          field: 'errors',
          keyCreator: params => { if (Array.isArray(params.value)) return params.value.map(e => e.message) },
          valueFormatter: params => { if (Array.isArray(params.value)) return params.value.map(e => e.message).join(', ') }
        },
        // return the specified column in file or create a generic new one
        ...this.fields.map(field =>
          columns.find(column => column.field === field.name) ||
        { headerName: field.name, filter: 'agTextColumnFilter', field: field.name, width: 150 })
      ]
    },

    validTotalCount: function () {
      return this.lines.reduce((acc, cur) => cur.status === 'VALID' ? ++acc : acc, 0)
    },
    errorTotalCount: function () {
      return this.lines.reduce((acc, cur) => cur.status === 'ERROR' ? ++acc : acc, 0)
    },
    warningTotalCount: function () {
      return this.lines.reduce((acc, cur) => cur.status === 'WARNING' ? ++acc : acc, 0)
    },
    sentTotalCount: function () {
      return this.lines.reduce((acc, cur) => cur.status === 'SENT' ? ++acc : acc, 0)
    }
  },
  methods: {
    gridReady: async function () {
      await this.getData()
    },
    hasCheckbox: function (params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns()
      const isFirstColumn = displayedColumns[0] === params.column
      const isNotSent = params.data ? params.data.status !== 'SENT' : true
      return isFirstColumn && isNotSent
    },
    cellValueChanged: async function (row) {
      const column = row.column.colId
      const value = row.newValue
      const rowId = row.data.id
      try {
        if ((value !== row.oldValue) && row.data.status !== 'SENT') {
          await Customer.submitEdiChange(this.id, this.file.table, rowId, column, value)
        }
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },
    getData: async function () {
      this.gridOptions.api.showLoadingOverlay()
      this.file = await FileImport.findHistory(this.$route.params.fip_id, this.id)
      const result = await Customer.getExportDetails(this.file.table, this.id)
      this.fields = result.fields
      this.lines = result.details
      this.allInvalidConsinee = result.allInvalidConsinee
      this.gridOptions.api.setColumnDefs([])
      this.gridOptions.api.setColumnDefs(this.gridColumns)
    },
    integrate: async function () {
      try {
        this.isFetching.integrate = true
        await Customer.integrateEdiLines(this.file.table, this.lines.map(e => e.id), this.id)
        this.isFetching.integrate = false
        await this.getData()
      } catch (e) {
        this.isFetching.integrate = false
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },
    checkup: async function () {
      try {
        this.isFetching.checkup = true
        await Customer.checkEdiLines(this.file.table, this.lines.map(e => e.id), this.id)
        this.isFetching.checkup = false
        await this.getData()
      } catch (e) {
        this.isFetching.checkup = false
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },
    deleteAll: async function () {
      this.$buefy.dialog.confirm({
        title: 'Deleting shipping',
        message: 'Are you sure you want to <b>delete</b> this shipping ? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Customer.deleteExportDetails(this.id, this.file.table)
            this.$router.go(-1)
            this.$buefy.toast.open('Shipping batch deleted!')
          } catch (e) {
            this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
          }
        }
      })
    },
    deleteSelectedRows: async function () {
      const selectedLines = this.gridOptions.api.getSelectedRows()
      if (selectedLines.length === 0) return
      this.$buefy.dialog.confirm({
        title: 'Deleting rows',
        message: 'Are you sure you want to <b>delete</b> ' + selectedLines.length + ' rows ? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Customer.deleteEdiLines(this.file.table, selectedLines.filter(e => e.status !== 'SENT').map(e => e.id), this.id)
            await this.getData()
          } catch (e) {
            this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
          }
        }
      })
    },
    downloadFile: async function () {
      const { fip_id: fipId, fih_id: fihId } = this.$route.params
      try {
        this.isDownloading = true
        const result = await FileImport.download(fipId, fihId) // var file = new File([result.data], 'test.csv', { type: 'text/csv;charset=utf-8' })

        saveAs(result.data, this.file.filename)
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
      this.isDownloading = false
    }
  }
}
</script>
<style scoped>
  .buttons>.b-tooltip {
    margin-right: .5rem;
    margin-bottom: .5rem;
  }
</style>
