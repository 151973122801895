import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserCollectConfirmation'

const enduserCollectConfirmation = axios.create({
  baseURL
})

interceptorsSetup(enduserCollectConfirmation)

export default class EnduserCollectConfirmation {
  static async getAll () {
    const result = await enduserCollectConfirmation.get('/')
    return result.data
  }

  static async get (enduserCode) {
    const result = await enduserCollectConfirmation.get('/' + enduserCode)
    return result.data
  }

  static async insert (data) {
    const result = await enduserCollectConfirmation.post('/', data)
    return result.data
  }
}
