import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { NotificationProgrammatic as Notification } from 'buefy'

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          window.location = '/login'
      }
    }
    graphQLErrors.map(({ message, locations, path }) => {
      Notification.open({
        duration: 5000,
        message: message + ' 😕',
        position: 'is-bottom-right',
        type: 'is-danger',
        hasIcon: true
      })
    })
  }
})

const httpLink = createHttpLink({ uri: process.env.VUE_APP_GRAPH_URL })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const link = ApolloLink.from([
  errorLink,
  authLink,
  httpLink
])

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  })
})

export default apolloClient
