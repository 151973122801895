import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/files'

const files = axios.create({
  baseURL
})

interceptorsSetup(files)

export async function addOne (formData, config) {
  const result = await files.post('/', formData, config)
  return result.data
}

export async function postSapShipping (formData) {
  const result = await files.post('/sap-shipping', formData, { headers: { 'content-type': 'multipart/form-data' } }
  )
  return result.data
}

export async function getCategories () {
  let { data: categories } = await files('/categories')
  return categories
}

export async function addExport (formData, config) {
  const result = await files.post('/test-file-settings', formData, config)
  return result.data
}

export async function downloadXlsx (content) {
  const result = await files.post('/xlsx',
    { content },
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    }
  )
  return result.data
}
