import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/groups'

const groups = axios.create({
  baseURL
})

interceptorsSetup(groups)

export async function getAll ({ pickupGroups }) {
  let { data: allGroups } = await groups('/', { params: { pickupGroups: pickupGroups } })
  return allGroups
}

export async function deleteGroup (id) {
  let { data: response } = await groups.delete(`/${id}/`)
  return response
}

export async function createGroup (group) {
  let { data: response } = await groups.post('', group)
  return response
}

export async function updateGroup (group) {
  let { data: response } = await groups.put('/' + group.id, group)
  return response
}

export async function getFiles (groupId) {
  let { data: files } = await groups(`/${groupId}/files/`)
  return files
}

export async function deleteFile (groupId, fileId) {
  const result = await groups.delete(`${groupId}/files/${fileId}`)
  return result.data
}
