<template>
  <div>
    <h1 class="title">
      {{ $t('pages.pickup_management.mail_observer.blocked_mails') }}
    </h1>
    <span class="mb-2">
      <b-button
        icon-left="sync"
        class="button is-warning"
        :disabled="loading || !selectedGroup.collectGroup"
        @click="loadData"
      >
        {{ $t('global.refresh') }}
      </b-button>
    </span>
    <div v-if="result" class="mt-2 box">
      <div v-for="(obj, index) in result" :key="index" class="mb-4">
        <article class="message is-danger">
          <div class="message-header is-size-4">
            {{ obj.email }}
            <b-tag size="is-medium">
              {{ $dayjs(obj.created).format('DD/MM/YYYY HH:mm') }}
            </b-tag>
          </div>
          <div class="message-body">
            {{ obj.reason }}
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>

import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'

export default {
  props: {
    selectedGroup: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      result: null,
      loading: false
    }
  },
  watch: {
    selectedGroup: async function (selectedGroup) {
      this.loadData()
    }
  },
  methods: {
    loadData: async function () {
      if (this.selectedGroup?.collectGroup) {
        this.loading = true
        this.result = await EnduserDeclarationAdmin.getBlockedEmails(this.selectedGroup.collectGroup)
        this.loading = false
      }
    }
  }

}
</script>

<style>
</style>
