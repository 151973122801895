<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns">
        <div class="column is-9">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-alpine"
            :row-data="scans"
            :grid-options="gridOptions"
            :pagination="true"
            :animate-rows="true"
            :get-child-count="getChildCount"
            :pagination-auto-page-size="true"
            :side-bar="['columns']"
            :get-context-menu-items="getContextMenuItems"
            :detail-row-height="200"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
    <b-modal :active.sync="isMaterialModalActive" scroll="keep" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('global.materials') }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field grouped>
            <b-field>
              <b-input v-model="searchMaterial" placeholder="Search..." type="search" icon="search" />
            </b-field>
            <div class="field">
              <b-button type="is-info" icon-left="file-csv" @click="exportMaterials">
                {{ $t('global.export') }}
              </b-button>
            </div>
          </b-field>
          <b-table
            :per-page="15"
            :data="filteredMaterials"
            :loading="isLoading"
            narrowed
            striped
            paginated
          >
            <template slot="header" slot-scope="props">
              <span>{{ props.column.label }}</span>
            </template>
            <b-table-column v-slot="props" field="barcode" :label="$t('global.barcode')" sortable>
              {{ props.row.barcode }}
            </b-table-column>
            <b-table-column v-slot="props" field="scannedAt" :label="$t('pages.scans.scanned_at')" sortable>
              {{ new Date(props.row.scannedAt).toLocaleString() }}
            </b-table-column>
            <template slot="bottom-left">
              <b>{{ $t('global.total') }}</b>: {{ filteredMaterials.length }}
            </template>
            <template slot="empty">
              <nothing-here />
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot" />
      </div>
    </b-modal>
  </section>
</template>

<script>
import { downloadFreeScanMovements } from '@/services/v1/scan'
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'

export default {
  mixins: [sortAndFilterMixins],

  data () {
    return {
      modules: AllModules,
      gridOptions: {
        rowModelType: 'serverSide',
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('pages.scans.grid.uuid'),
            field: 'uuid',
            filter: 'agTextColumnFilter',
            width: 250,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.scans.grid.name'),
            field: 'name',
            filter: 'agTextColumnFilter',
            width: 250,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.scans.grid.created_at'),
            field: 'createdAt',
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleString()
            },
            sort: 'desc',
            width: 160,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.scans.grid.archived'),
            field: 'isArchived',
            cellRenderer: (data) => {
              return data.value === true ? '<i class="fas fa-fw fa-check has-text-primary" ></i>' : '<i class="fas fa-fw fa-times has-text-danger" ></i>'
            },
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          }
        ]
      },
      totalCount: 0,
      scans: [],
      isLoading: false,
      selectedScan: null,
      searchMaterial: '',
      isMaterialModalActive: false,
      selectedScanMaterials: []
    }
  },
  computed: {
    filteredMaterials: function () {
      return this.selectedScanMaterials.filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key]).toLowerCase().indexOf(this.searchMaterial.toLowerCase()) > -1
        })
      })
    }
  },
  methods: {
    gridReady: async function (params) {
      const dataSource = {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)

          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'scans',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['uuid'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputScan]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputScan', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              plantId: { type: 'ID!', value: this.$route.params.plant_id }
            }
          })

          const result = await this.$apollo.query({ fetchPolicy: 'no-cache', query: gql`${builder.query}`, variables: builder.variables })

          const scansResult = result.data.scans
          const scans = scansResult.edges.map(e => e.node)

          params.successCallback(scans, scansResult.totalCount)
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getContextMenuItems (params) {
      this.selectedScan = params.node.data
      return [
        {
          name: this.$t('pages.scans.grid.attached_materials'),
          action: () => {
            this.openMaterialModal()
          },
          icon: '<i class="fas fa-fw fa-barcode" ></i>'
        },
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
    },
    openMaterialModal: async function () {
      try {
        const movements = await downloadFreeScanMovements(this.selectedScan.uuid)

        this.selectedScanMaterials = movements.filter(e => e.barcode !== null)
        this.isMaterialModalActive = true
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    exportMaterials: function () {
      const result = this.filteredMaterials.map(e => Object.values(e).join(';')).join('\n')
      const csvBlob = new Blob([result], { type: 'text/csv' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')
      anchorElement.href = blobUrl
      anchorElement.download = this.selectedScan.name + '-export.csv'
      anchorElement.click()
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.uuid
      }
    }
  }
}
</script>
