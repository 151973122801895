// import store from '@/store'

export default function setup (axiosInstance) {
  axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (token) {
      // Do something before request is sent
      config.headers['Authorization'] =
   'Bearer ' + localStorage.getItem('token')
    }

    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })
  axiosInstance.interceptors.response.use(undefined, function (err) {
    if (err.response && err.response.status === 401) {
      // console.log('Unauthorized')
      // if you ever get an unauthorized, logout the user
      window.location = '/login'
    } else if (err.response && err.response.status === 404) {
      return Promise.reject(new Error('Resource not found.'))
    } else {
      if (err && err.response && err.response.data) {
        return Promise.reject(new Error(err.response.data.message))
      }
      return Promise.reject(new Error(err))
    }
  })
}
