<template>
  <div
    v-show="isActive"
    id="context-menu"
    ref="contextMenu"
    v-on-clickaway="hide"
    class="dropdown is-active"
  >
    <slot />
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  data: function () {
    return {
      isActive: false,
      contextMenuWidth: null,
      contextMenuHeight: null
    }
  },
  methods: {
    show: function (event) {
      event.preventDefault()
      this.isActive = true
      const menu = this.$refs.contextMenu

      if (!this.contextMenuWidth || !this.contextMenuHeight) {
        this.contextMenuWidth = menu.firstChild.offsetWidth
        this.contextMenuHeight = menu.firstChild.offsetHeight
      }

      if ((this.contextMenuWidth + event.pageX) >= window.innerWidth) {
        menu.style.left = (event.pageX - this.contextMenuWidth) + 'px'
      } else {
        menu.style.left = event.pageX + 'px'
      }

      if ((this.contextMenuHeight + event.pageY) >= window.innerHeight) {
        menu.style.top = (event.pageY - this.contextMenuHeight) + 'px'
      } else {
        menu.style.top = event.pageY + 'px'
      }
    },
    hide: function () {
      this.isActive = false
    }
  }
}
</script>

<style>

</style>
