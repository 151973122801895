<template>
  <div>
    <ag-grid-vue
      style="height: 200px;"
      class="ag-theme-balham"
      :grid-options="gridOptions"
      :animate-rows="true"
      :modules="modules"
      :row-data="stock"
      @cellValueChanged="cellValueChanged"
      @grid-ready="onGridReady"
    />
  </div>
</template>
<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
export default {
  props: { stock: { name: 'stock', type: Array, default: () => [] } },
  data: function () {
    return {
      modules: AllModules,
      gridOptions: {
        defaultColDef: {
          autoHeight: true,
          editable: true,
          resizable: false,
          flex: 1
        },
        columnDefs: [
          { field: 'storage', editable: false },
          { field: 'GPS1',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'GPS51',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'GPS52',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'GPS55',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'RHODIA',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'ZEON',
            cellEditorParams: {
              parseValue: value => +value
            }
          },
          { field: 'GPSLID',
            cellEditorParams: {
              parseValue: value => +value
            }
          }
        ]
      }
    }
  },
  methods: {
    onGridReady: async function (params) {
      this.gridApi = this.gridOptions.api
      this.$emit('stockApiGride', this.gridApi)
    },
    cellValueChanged: function (e) {
      const newRow = { storage: e.data.storage, material: e.colDef.field, quantity: e.value }
      const oldRow = { storage: e.data.storage, material: e.colDef.field, quantity: e.oldValue }

      this.$emit('dataChanged', newRow, oldRow)
    }
  }
}
</script>
