<template>
  <section class="section">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-3">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="https://picsum.photos/400?random" alt="Placeholder image">
              </figure>
            </div>
            <div v-if="user" class="card-content">
              <div class="media">
                <div class="media-left">
                  <b-icon icon="user" size="is-small" />
                </div>
                <div class="media-content">
                  <p class="title is-4">
                    {{ user.displayName }}
                  </p>
                </div>
              </div>

              <div class="content">
                <div>
                  <ul>
                    <li>
                      Username : {{ user.username }}
                    </li>
                    <li>
                      Mail : {{ user.mail || 'N/A' }}
                    </li>
                    <li>
                      Description : {{ user.description || 'N/A' }}
                    </li>
                    <li>
                      Created at : {{ $dayjs(user.whenCreated, 'YYYYMMDDhhmmss').toDate().toLocaleDateString('fr-FR') }}
                    </li>
                    <li>
                      Groups : {{ user.group.name }}
                    </li>
                    <li>
                      Plants(s) :
                      <b-taglist>
                        <b-tag v-for="plant in user.group.plants" :key="plant.id" type="is-dark">
                          {{ plant.name }}
                        </b-tag>
                      </b-taglist>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
  },
  methods: {}
}
</script>

<style>

</style>
