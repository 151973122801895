import * as types from './mutation-types'

const mutations = {
  [types.LOGIN_USER] (state, token) {
    state.token = token
  },
  [types.LOGOUT_USER] (state) {
    state.token = ''
    state.user = null
  },
  [types.SET_USER] (state, user) {
    state.user = user
  }
}

export default mutations
