<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns mt-6">
        <div class="column">
          <div class="box">
            <b-field label="Plant">
              <b-autocomplete
                v-model="selectedPlantId"
                placeholder="Select a plant..."
                :data="filteredPlants"
                open-on-focus
                field="id"
                icon="magnifying-glass"
                @typing="getFilteredPlants"
                @select="selectPlant"
              />
            </b-field>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <b-field label="Material">
              <b-select v-model="selectedMaterial" placeholder="Select material..." expanded>
                <option v-for="option in materials" :key="option" :value="option">
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <b-field label="Operation">
              <b-select v-model="selectedOperation" placeholder="Select a operation..." expanded>
                <option v-for="option in operations" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <b-field label="Dates">
              <b-datepicker
                v-model="selectedDates"
                placeholder="Click to select..."
                :first-day-of-week="1"
                icon="calendar"
                multiple
                @change-month="changeMonth"
                @change-year="changeYear"
              >
                <b-button label="Clear" icon-left="close" outlined @click="selectedDates = []" />
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <b-field label="Quantity">
              <b-input v-model="inputQty" placeholder="Enter quantity..." />
            </b-field>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <div v-if="!values.length">
          <p>No simulation found, select some criterias to see more data...</p>
          <b-skeleton width="20%" :animated="true" />
          <b-skeleton width="40%" :animated="true" />
          <b-skeleton width="60%" :animated="true" />
        </div>
        <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="true" />
        <la-cartesian
          v-if="values.length"
          :bound="[0, n => n + 50]"
          narrow
          autoresize
          :colors="colors"
          :text-color="textColor"
          :data="values"
        >
          <la-bar animated prop="qty" />
          <la-line
            dot
            curve
            animated
            :show-value="true"
            label="quantity"
            prop="qty"
          />
          <la-x-axis :font-size="10" :format="date => date.slice(0, 5)" prop="requestDate" />
          <la-y-axis :font-size="10" />
        </la-cartesian>
      </div>

      <div class="buttons level mt-6">
        <div class="level-left">
          <b-button :disabled="diableRefresh" @click="refresh">
            Refresh
          </b-button>
          <b-button
            icon-left="floppy-disk"
            type="is-primary"
            :disabled="disableSave"
            :loading="buttonLoading"
            @click="save"
          >
            Save
          </b-button>
        </div>
        <div class="level-right">
          <b-button icon-left="backward" :disabled="disableReset" @click="reset">
            Reset
          </b-button>
          <b-button
            icon-left="play"
            :disabled="disableButtons"
            :loading="buttonLoading"
            type="is-info"
            @click="simulate"
          >
            Simulate
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Plant from '@/services/v2/plant'
import { Cartesian, Line, Bar, XAxis, YAxis } from 'laue'

export default {
  components: {
    LaCartesian: Cartesian,
    LaLine: Line,
    LaBar: Bar,
    LaXAxis: XAxis,
    LaYAxis: YAxis
  },
  data () {
    return {
      thisMonth: new Date().getMonth(),
      thisYear: new Date().getFullYear(),
      selectedPlantId: '',
      plants: [],
      filteredPlants: [],
      selectedDates: [],
      inputQty: '',
      materials: ['GPS1', 'GPS51', 'GPS52', 'GPS55'],
      operations: [{ text: 'washing', value: 'S' }, { text: 'repair', value: 'R' }],
      selectedMaterial: '',
      selectedOperation: '',
      colors: ['#6abf9e', '#45aaf2'],
      textColor: '#999',
      values: [],
      isLoading: false,
      buttonLoading: false,
      isSimulated: false
    }
  },
  computed: {
    disableButtons () {
      if (this.selectedDates.length && this.selectedPlantId && this.selectedMaterial && this.selectedOperation && this.inputQty && !this.buttonLoading) return false
      return true
    },
    disableSave () {
      if (!this.disableButtons && this.isSimulated) return false
      return true
    },
    disableReset () {
      if (this.selectedDates.length && this.selectedPlantId && this.selectedMaterial && this.selectedOperation && this.isSimulated && !this.buttonLoading) return false
      return true
    },
    diableRefresh () {
      if (this.selectedPlantId && this.selectedMaterial && this.selectedOperation && !this.buttonLoading) return false
      return true
    }
  },
  watch: {
    async selectedPlantId () {
      if (!this.selectedMaterial || !this.selectedOperation) return
      if (!this.plants.map(el => el.id).includes(this.selectedPlantId)) return
      await this.loadSimulation()
    },
    async selectedMaterial () {
      if (!this.selectedPlantId || !this.selectedOperation) return
      await this.loadSimulation()
    },
    async selectedOperation () {
      if (!this.selectedMaterial || !this.selectedPlantId) return
      await this.loadSimulation()
    },
    async thisMonth () {
      if (!this.selectedMaterial || !this.selectedPlantId) return
      await this.loadSimulation()
    },
    async thisYear () {
      if (!this.selectedMaterial || !this.selectedPlantId) return
      await this.loadSimulation()
    }
  },
  mounted: async function () {
    this.plants = await Plant.getAll()
    this.filteredPlants = await Plant.getAll()
  },
  methods: {
    async loadSimulation () {
      this.isLoading = true
      const res = await Plant.getCurSimulation({ month: `${this.formatMonth(this.thisMonth + 1)}/${this.thisYear}`, plant: this.selectedPlantId, material: this.workOrderGenerator() })
      this.values = res
      this.isLoading = false
    },
    getFilteredPlants (text) {
      this.filteredPlants = this.plants.filter(option => option.id.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0)
    },
    selectPlant (el) {
      if (el === null) {
        this.selectedPlantId = ''
      } else {
        this.selectedPlantId = el.id
      }
    },
    changeMonth (month) {
      this.thisMonth = month
    },
    formatMonth (month) {
      return month < 10 ? `0${month}` : month
    },
    changeYear (year) {
      this.thisYear = year
    },
    workOrderGenerator () {
      switch (this.selectedOperation) {
        case 'S':
          return `${this.selectedMaterial}`
        case 'R':
          return `${this.selectedMaterial}-S`
      }
    },
    demandekOrderGenerator () {
      switch (this.selectedOperation) {
        case 'S':
          return `${this.selectedMaterial}-S`
        case 'R':
          return `${this.selectedMaterial}-R`
      }
    },
    async save () {
      const formattedDates = this.selectedDates.map(day => this.$dayjs(day).format('YYYY/MM/DD'))
      this.buttonLoading = true
      this.$buefy.dialog.confirm({
        title: 'Continue to modify?',
        message: `Plant: ${this.selectedPlantId} <br> Operation: ${this.selectedMaterial} - ${this.selectedOperation} <br> Dates: ${formattedDates} <br> Modifiy quantity to: ${this.inputQty} <br><br> <b>This operation cannot be undone. </b><br><br> All set ? `,
        type: 'is-primary',
        onConfirm: async () => {
          try {
            const datas = this.selectedDates.map(date => {
              const formattedDate = this.$dayjs(date).format('YYYYMMDD')
              const supplyMaterial = this.workOrderGenerator()
              const demandMaterial = this.demandekOrderGenerator()
              return {
                quantity: this.inputQty,
                supplyMaterial,
                demandMaterial,
                plantId: this.selectedPlantId,
                selectedDate: this.$dayjs(date).format('DD/MM/YYYY'),
                workOrderKey: `${this.selectedPlantId}_${supplyMaterial}_${formattedDate}_WO`,
                demandOrderKey: `${this.selectedPlantId}_${demandMaterial}_${formattedDate}_WO`
              }
            })

            datas.forEach(async data => {
              // CHECK WORKORDER already exisits in sap
              const result = await Plant.getCurSimulation({ orderNumber: data.workOrderKey })
              if (!result.length) {
                // post it as new entry
                await Plant.insertSimulation(data)
              } else {
                // update it
                await Plant.updateSimulation(data)
              }
            })
            this.isSimulated = false
            this.$buefy.toast.open({ message: 'Success' + '<br> Modifications have been saved', duration: 4000, type: 'is-primary' })
          } catch (e) {
            this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
          }
        }
      })

      this.buttonLoading = false
    },
    async simulate () {
      const datas = {
        plantId: this.selectedPlantId,
        requestedDates: this.selectedDates.map(date => this.$dayjs(date).format('YYYY-MM-DD')),
        supplyMaterial: this.workOrderGenerator(),
        demandMaterial: this.demandekOrderGenerator(),
        quantity: +this.inputQty
      }

      this.buttonLoading = true
      try {
        await Plant.updateIntuiflow(datas)
        this.isSimulated = true
        this.$buefy.toast.open({ message: 'Success' + '<br> Sent to Intuiflow', duration: 4000, type: 'is-primary' })
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
      this.buttonLoading = false
    },

    async reset () {
      this.buttonLoading = true
      const supplyMaterial = this.workOrderGenerator()
      const demandMaterial = this.demandekOrderGenerator()
      try {
        const resetDatas = await Promise.all(this.selectedDates.map(async date => {
          const res = await Plant.getCurSimulation({ orderNumber: `${this.selectedPlantId}_${supplyMaterial}_${this.$dayjs(date).format('YYYYMMDD')}_WO` })
          return {
            plantId: this.selectedPlantId,
            requestedDate: this.$dayjs(date).format('YYYY-MM-DD'),
            quantity: +res[0]?.qty || 0
          }
        }))
        const groupedDatas = {}
        resetDatas.forEach(data => {
          if (!groupedDatas[data.quantity]) {
            groupedDatas[data.quantity] = {
              plantId: this.selectedPlantId,
              requestedDates: [data.requestedDate],
              supplyMaterial,
              demandMaterial,
              quantity: data.quantity
            }
          } else {
            groupedDatas[data.quantity].requestedDates.push(data.requestedDate)
          }
        })
        Object.values(groupedDatas).forEach(async data => {
          await Plant.updateIntuiflow(data)
        })
        this.isSimulated = false
        this.$buefy.toast.open({ message: 'Resets successfully', duration: 4000, type: 'is-primary' })
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
      this.buttonLoading = false
    },
    async refresh () {
      await this.loadSimulation()
    }
  }
}
</script>

<style scoped></style>
