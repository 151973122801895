<template>
  <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Edit device.
        </p>
      </header>
      <section class="modal-card-body" style="min-height: 70vh">
        <b-field label="Plant">
          <multiselect
            v-model="selectedPlant"
            :options="$lodash.orderBy(plants, ['name'])"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick plant..."
            :custom-label="getLabel"
            track-by="id"
          />
        </b-field>
        <b-field label="Customer">
          <multiselect
            v-model="selectedCustomer"
            :options="customers"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick customer..."
            :custom-label="getLabel"
            track-by="id"
          />
        </b-field>
        <b-field label="Producer">
          <multiselect
            v-model="selectedProducer"
            :options="$lodash.orderBy(plants, ['name'])"
            :multiple="false"
            :close-on-select="true"
            placeholder="Pick producer..."
            :custom-label="getLabel"
            track-by="id"
          />
        </b-field>

        <b-field label="Serial Number">
          <ValidationProvider v-slot="{ errors }">
            <b-input
              v-model="newDevice.serialNumber"
              name="serialnumber"
              type="text"
              required
            />
          </ValidationProvider>
        </b-field>
        <div v-if="newDevice.registrationCode">
          <b-field label="Registration code">
            <b-input
              v-model="newDevice.registrationCode"
              name="registrationCode"
              type="text"
              disabled
            />
          </b-field>
          <canvas id="qrcode" class="block" />
          <b-button icon-left="download" @click="downloadQRCode" />
          <p>Device registration code: <strong>{{ newDevice.registrationCode }}</strong></p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button :class=" {'is-loading' : isSubmitting}" type="submit" class="button is-info is-fullwidth">
          Submit
        </button>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import QRCode from 'qrcode'
import { saveAs } from 'file-saver'

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    editableDevice: {
      type: Object,
      required: true
    },
    plants: {
      type: Array,
      required: true
    },
    customers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      newDevice: {
        uuid: '',
        plantId: '',
        customerId: '',
        producerId: '',
        serialNumber: ''
      },
      selectedPlant: null,
      selectedCustomer: null,
      selectedProducer: null
    }
  },
  mounted: function () {
    this.newDevice.uuid = this.editableDevice.uuid
    this.newDevice.plantId = this.editableDevice.plantId
    this.newDevice.customerId = this.editableDevice.customerId
    this.newDevice.producerId = this.editableDevice.producerId
    this.newDevice.serialNumber = this.editableDevice.serialNumber
    this.newDevice.registrationCode = this.editableDevice.registrationCode
    this.selectedPlant = this.plants.find(e => e.id === this.newDevice.plantId)
    this.selectedCustomer = this.customers.find(e => e.id === this.newDevice.customerId)
    this.selectedProducer = this.plants.find(e => e.id === this.newDevice.producerId)

    if (this.editableDevice.registrationCode) {
      this.$nextTick(() => {
        this.generateQRCode(this.editableDevice.registrationCode)
      })
    }
  },
  methods: {
    getLabel ({ id, name }) {
      return `${id} - ${name}`
    },
    async submit () {
      try {
        const isValid = await this.$refs.observer.validate()
        if (!isValid) return

        this.newDevice.plantId = this.selectedPlant ? this.selectedPlant.id : null
        this.newDevice.customerId = this.selectedCustomer ? this.selectedCustomer.id : null
        this.newDevice.producerId = this.selectedProducer ? this.selectedProducer.id : null

        this.$emit('submit', this.newDevice)
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
    },
    generateQRCode (text) {
      const canvas = document.getElementById('qrcode')
      QRCode.toCanvas(canvas, text, (error) => {
        if (error) this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      })
    },
    downloadQRCode () {
      const canvas = document.getElementById('qrcode')
      canvas.toBlob(blob => saveAs(blob, `${this.editableDevice.registrationCode}.png`))
    }
  }
}
</script>
