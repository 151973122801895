<template>
  <div
    class="modal-card"
  >
    <div class="modal-card-head centered-items">
      <h2 class="title">
        {{ enduser.enduserCode + ' - ' + enduser.enduserName }}
      </h2>
    </div>

    <section class="modal-card-body">
      <span class="buttons">
        <button class="button is-warning is-outlined is-small" @click="close">
          <b-icon icon="chevron-left" />
          <span>{{ $t('global.back') }}</span>
        </button>
      </span>
      <div>
        <div v-if="enduser.closures && enduser.closures.length > 0">
          <h2 class="subtitle">
            {{ $t('pages.pickup_management.closures.closing_dates') }}
          </h2>
          <div id="calendar-step-cd-screen" class="centered-items cards">
            <div v-for="closure in enduser.closures" :key="closure.id" class="card m-10">
              <b-button icon-left="trash" class="is-small is-danger p-right" @click="deleteClosure(closure.id)" />
              <div class="card-body p-10">
                <div class="flex-column-center">
                  <span>
                    {{ $t('pages.pickup_management.closures.closed') }}
                  </span>
                  <b-icon icon="calendar" type="is-dark" />
                </div>
                <div>
                  <span class="tag is-medium is-link">
                    {{ $dayjs(closure.startDate).local().format('DD/MM/YYYY') }}
                  </span>
                  <b-icon icon="arrow-right" class="m-10" type="is-primary" />
                  <span class="tag is-medium is-link">
                    {{ $dayjs(closure.endDate).local().format('DD/MM/YYYY') }}
                  </span>
                </div>
                <span class="">
                  {{ closure.comment }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
          tag="form"
          @submit.prevent="submit"
        >
          <div id="form">
            <h2 class="subtitle">
              {{ $t('pages.pickup_management.closures.add_closing_date') }} :
            </h2>
            <b-field :label="$t('pages.pickup_management.closures.start_date')">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="start date"
              >
                <b-datepicker
                  v-model="startDate"
                  name="start date"
                  icon="calendar-alt"
                  :placeholder="$t('global.click_to_select')"
                  :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                  editable
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.closures.end_date')">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="end date"
              >
                <b-datepicker
                  v-model="endDate"
                  name="end date"
                  icon="calendar-alt"
                  placeholder="Click to select..."
                  :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                  editable
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.closures.comments')">
              <ValidationProvider>
                <textarea v-model="comment" class="input" />
              </ValidationProvider>
            </b-field>
            <div class="centered-items buttons">
              <b-button
                class="button "
                @click="close"
              >
                {{ $t('global.cancel') }}
              </b-button>
              <button
                class="is-primary button"
                type="submit"
              >
                {{ $t('global.submit') }}
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </section>
  </div>
</template>

<script>

// p,c,t
import Closure from '@/services/v2/enduserClosure'
import { mapActions } from 'vuex'

export default {
  props: {
    enduser: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      comment: null,
      closures: []
    }
  },
  mounted: function () {
  },
  methods: {
    ...mapActions(['archiveAction']),
    async refresh () {
      this.closures = await Closure.get(this.enduser.enduserCode)
      this.enduser.closures = this.closures
    },
    close () {
      this.$emit('close')
      this.$emit('nextTourStep', 'closureBack')
    },
    async submit () {
      try {
        if (await this.$refs.observer.validate()) {
          if (this.$dayjs(this.startDate).isAfter(this.$dayjs(this.endDate))) {
            throw Error('The start date is greater than the end date')
          }
          if (this.$dayjs(this.startDate).isBefore(this.$dayjs())) {
            throw Error('You cannot create dates in the past')
          }
          await Closure.insert({
            enduserCode: this.enduser.enduserCode,
            startDate: this.startDate,
            endDate: this.endDate,
            comment: this.comment
          })
          this.$buefy.toast.open({ message: 'Closure dates added', type: 'is-primary' })
          this.startDate = null
          this.endDate = null
          this.comment = null
        }
        this.refresh()
        this.archiveAction(`[Pickup management] Added a closure date on ${this.enduser.enduserCode} (${this.startDate} -> ${this.endDate})`)
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    async deleteClosure (closureId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting closure dates',
        message: 'Are you sure you want to <b>delete</b> this closure? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Closure.delete(closureId)
            this.$buefy.toast.open({ message: 'Closure deleted', type: 'is-primary' })
            this.refresh()
            this.archiveAction(`[Pickup management] Deletted a closure date on ${this.enduser.enduserCode})`)
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    }
  }

}
</script>

<style scoped>
#form {
  margin-top: 2rem;
  min-height: 520px;
}

.submit-section {
  margin-top: 1rem;
}

.centered-items{
  display: flex;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  width: 25%;
  text-align: center;
}

.cards {
  flex-wrap: wrap;
}

.p-right {
  position: absolute;
  right: 1px;
}

</style>
