import gql from 'graphql-tag'

const ShippingInstructionEdit = gql`
  mutation (
    $id: ID!,
    $departurePlantId: String
  ) {
    shippingInstructionEdit(
      id: $id,
      departurePlantId: $departurePlantId
    )
}`

const ShippingInstructionDelete = gql`
  mutation($id: ID!) {
    shippingInstructionDelete(id: $id)
  }
`
const ShippingInstructionCreateOrder = gql`
  mutation shippingInstructionCreateOrder($id: ID!) {
    shippingInstructionCreateOrder(id: $id)
  }
`

const ShippingInstructionUpdateDB = gql`
  mutation shippingInstructionUpdateDB {
    shippingInstructionUpdateDB
  }
`
export { ShippingInstructionDelete, ShippingInstructionCreateOrder, ShippingInstructionEdit, ShippingInstructionUpdateDB }
