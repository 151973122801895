<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body section">
      <div class="flex">
        <div>
          <button class="button is-warning is-outlined is-small" @click="close">
            <b-icon icon="chevron-left" />
            <span>{{ $t('global.back') }}</span>
          </button>
        </div>
      </div>
      <div class="has-text-centered">
        <h1 class="title">
          {{ $t('pages.pickup_management.signature.title') }}
        </h1>
        <span>{{ selectedGroup.collectGroup }}</span>
      </div>
      <div v-if="selectedGroup" class="section">
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
          tag="form"
          @submit.prevent="submit"
        >
          <div class="container f-row f-center">
            <b-field :label="$t('pages.pickup_management.signature.first_name')" class="m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.firstname"
                  name="firstname"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.last_name')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.lastname"
                  name="lastname"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.job')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.job"
                  name="job"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.phone')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.phone"
                  name="phone"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
          </div>
          <div class="container f-row f-center">
            <b-field :label="$t('pages.pickup_management.signature.address')" class="m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.address"
                  name="address"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.postal_code')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.postalCode"
                  name="postalCode"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.city')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.city"
                  name="city"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.signature.country')" class="m-left m-right">
              <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
                <b-input
                  v-model="signature.country"
                  name="country"
                  type="text"
                  required
                />
              </ValidationProvider>
            </b-field>
          </div>
          <h1 class="subtitle m-top">
            {{ $t('pages.pickup_management.signature.preview') }} :
          </h1>
          <signaturePreview :signature="signature" />
          <div class="f-center m-top">
            <button
              class="is-primary button"
              type="submit"
            >
              {{ $t('global.submit') }}
            </button>
          </div>
        </ValidationObserver>
        <div class="f-center m-top">
          <button
            v-if="existingSignature"
            class="is-danger button"
            @click="remove"
          >
            {{ $t('global.remove') }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import EnduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import signaturePreview from './signaturePreview'
import { mapActions } from 'vuex'

export default {
  components: {
    signaturePreview
  },
  props: {
    selectedGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      existingSignature: null,
      signature: {
        firstname: '',
        lastname: '',
        job: '',
        phone: '',
        address: '',
        city: '',
        postalCode: '',
        country: ''
      }
    }
  },
  computed: {
  },
  mounted: async function () {
    try {
      await this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  methods: {
    ...mapActions(['archiveAction']),
    loadData: async function () {
      this.existingSignature = await EnduserDeclarationAdmin.getSignature(this.selectedGroup.collectGroup)
      if (this.existingSignature) {
        this.signature = this.existingSignature
      }
    },
    close: function () {
      this.$emit('close')
    },
    submit: async function () {
      try {
        this.signature.collectGroup = this.selectedGroup.collectGroup
        await EnduserDeclarationAdmin.insertSignature(this.signature)
        this.$buefy.toast.open({ message: this.$t('pages.pickup_management.signature.created'), type: 'is-primary' })
        this.existingSignature = this.signature
        this.archiveAction(`Submited a signature on group ${this.selectedGroup.collectGroup}`)
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    remove: async function () {
      this.$buefy.dialog.confirm({
        title: this.$t('pages.pickup_management.signature.remove.title'),
        message: this.$t('pages.pickup_management.signature.remove.message'),
        confirmText: this.$t('pages.pickup_management.signature.remove.confirm'),
        cancelText: this.$t('pages.pickup_management.signature.remove.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await EnduserDeclarationAdmin.deleteSignature(this.selectedGroup.collectGroup)
            this.$buefy.toast.open({ message: this.$t('pages.pickup_management.signature.deleted'), type: 'is-primary' })
            this.signature = {
              firstname: '',
              lastname: '',
              job: '',
              phone: '',
              address: '',
              city: '',
              postalCode: '',
              country: ''
            }
            this.existingSignature = null
            this.archiveAction(`[Pickup management] Deletted a signature on group ${this.selectedGroup.collectGroup}`)
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    }
  }
}
</script>

<style>
.f-column {
  display: flex;
  flex-direction: column;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.m-left {
  margin-left: 1rem;
}
.m-right {
  margin-right: 2rem;
}

.f-vspace {
  justify-content: space-between;
}

.m-top {
  margin-top: 2rem;
}

.f-center {
  display: flex;
  justify-content: center;
}

</style>
