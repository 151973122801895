import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/file-import'

const fileImport = axios.create({
  baseURL
})

interceptorsSetup(fileImport)

export default class FileImport {
  static async all () {
    const result = await fileImport.get(`/`)

    return result.data
  }
  static async find (fipId) {
    const result = await fileImport.get(`/${fipId}/`)

    return result.data
  }

  static async getAllHistory () {
    const result = await fileImport.get(`/history/`)

    return result.data
  }

  static async getHistory (fipId) {
    const result = await fileImport.get(`/${fipId}/history/`)

    return result.data
  }

  static async findHistory (fipId, fihId) {
    const result = await fileImport.get(`/${fipId}/history/${fihId}`)

    return result.data
  }
  static async download (fipId, fihId) {
    const result = await fileImport.get(`/${fipId}/history/${fihId}/download`, {
      responseType: 'blob'
    })
    return result
  }
  static async upload (fipId, files) {
    const filesFormData = new FormData()
    files.forEach(file => {
      filesFormData.append('files', file)
    })

    const result = await fileImport.post(`/${fipId}/files`, filesFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      } })

    return result.data
  }
}
