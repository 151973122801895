<template>
  <section class="section">
    <b-loading v-model="isLoading" />
    <b-field :label="$t('global.materials')">
      <b-radio-button
        v-for="(el, i) in material"
        :key="i"
        v-model="selectedMaterial"
        :native-value="el"
        type="is-primary"
      >
        <span>{{ el }}</span>
      </b-radio-button>
    </b-field>
    <b-field :label="$t('pages.new_crates.production_plant')">
      <b-taginput
        v-model="selectedPlant"
        :data="plants"
        autocomplete
        open-on-focus
      />
    </b-field>
    <div class="d-flex-row d-flex-space-evenly timelines">
      <Timeline
        v-for="(el, i) in selectedPlant"
        :key="i"
        :plant="el"
        class="d-flex-column"
        :material="selectedMaterial"
        :all-data="data"
        @to-save="toSave"
      />
    </div>
    <div v-if="selectedMaterial">
      <input id="fileUpload" type="file" hidden @change="loadTextFromFile">
      <b-button class="btn" @click="chooseFiles">
        {{ $t('pages.new_crates.upload_csv') }}
      </b-button>
      <b-button class="btn" type="is-primary" @click="getTemplate">
        {{ $t('pages.new_crates.get_csv_template') }}
      </b-button>
    </div>
    <b-button class="btn" type="is-green" @click="save">
      {{ $t('global.save') }}
    </b-button>
  </section>
</template>

<script>
import { saveAs } from 'file-saver'

import NewCrates from '@/services/v2/newCrates'
import Timeline from './Timeline'

export default {
  components: {
    Timeline
  },
  data () {
    return {
      timeLineChange: '',
      material: [
        'GPS52',
        'GPS6'
      ],
      plantMaterials: {
        GPS52: [
          'CNJ0',
          'CNJ2',
          'CNJ3'
        ],
        GPS6: [
          'CNJ0'
        ]
      },
      selectedMaterial: '',
      selectedPlant: [],
      plants: [],
      data: [],
      isSaved: true,
      isLoading: false
    }
  },
  watch: {
    'selectedMaterial' () {
      this.plants = this.plantMaterials[this.selectedMaterial]
      this.selectedPlant = this.plantMaterials[this.selectedMaterial]
    }
  },
  mounted: async function () {
    this.selectedMaterial = this.material[0]
    this.plants = this.plantMaterials[this.selectedMaterial]
    this.selectedPlant = this.plantMaterials[this.selectedMaterial]
    this.isLoading = true
    this.data = await NewCrates.getNewCratesData()
    this.isLoading = false
  },
  beforeRouteLeave: function (to, from, next) {
    if (!this.isSaved) {
      this.$buefy.dialog.confirm({
        message: this.$t('pages.new_crates.want_to_save_new_crates'),
        confirmText: this.$t('global.yes'),
        cancelText: this.$t('global.no'),
        type: 'is-primary',
        onConfirm: async () => {
          this.save()
          next()
        },
        onCancel: () => {
          this.isSaved = true
          next()
        }
      })
    } else {
      next()
    }
  },
  methods: {
    toSave: function () {
      this.isSaved = false
    },
    save: async function () {
      this.$buefy.toast.open(this.$t('pages.new_crates.saving_new_crates'))
      NewCrates.setNewCratesData(this.data)
        .then(async () => {
          this.$buefy.notification.open({ type: 'is-success', message: this.$t('pages.new_crates.new_crates_saved'), duration: 1000, hasIcon: true })
          this.data = await NewCrates.getNewCratesData()
          this.isSaved = true
        })
        .catch(() => {
          this.isSaved = true
          this.$buefy.notification.open({ type: 'is-danger', message: this.$t('pages.new_crates.error_saving_new_crates'), duration: 1000, hasIcon: true })
        })
    },
    chooseFiles: function () {
      document.getElementById('fileUpload').click()
    },
    loadTextFromFile: async function (el) {
      const file = el.target.files[0]
      const reader = new FileReader()
      reader.onload = async (event) => {
        const tmp = this.parseCVS(event.target.result)
        if (!this.checkData(tmp)) {
          return
        }
        this.mergeDatas(tmp)
      }
      await reader.readAsText(file)
    },
    checkData: function (datas) {
      for (const i in datas) {
        if (datas[i] === undefined || datas[i].Plant === undefined || datas[i].Material === undefined || datas[i].Quantity === undefined || datas[i].First_day_of_the_week === undefined) {
          this.$buefy.notification.open({ type: 'is-danger', message: this.$t('pages.new_crates.error_csv'), duration: 5000, hasIcon: true })
          return false
        }
      }
      return true
    },
    parseCVS: function (data) {
      let allTextLines = data.split(/\r\n|\n/)
      let headers = allTextLines[0].split(';')
      let lines = []

      for (var i = 0; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(';')
        if (data.length === headers.length) {
          let tmp = {}
          for (let j = 0; j < headers.length; j++) {
            tmp[headers[j]] = data[j]
          }
          lines.push(tmp)
        }
      }
      return lines
    },
    mergeDatas: function (newData) {
      this.isSaved = false
      this.data.forEach((data) => {
        for (let i = 0; i < newData.length; i++) {
          const testFunc = el => (
            el.plant === newData[i].Plant &&
            el.material === newData[i].Material &&
            parseInt(el.week) === parseInt(newData[i].First_day_of_the_week)
          )
          let index = data.findIndex(testFunc)
          if (index !== -1) {
            if (data[index].type === 'SAP') {
              this.$buefy.notification.open({ type: 'is-danger', message: this.$t('pages.new_crates.cannot_change_sap_data'), duration: 5000, hasIcon: true })
            } else {
              data[index].quantity = parseInt(newData[i].Quantity)
              data[index].type = 'filled'
            }
          }
        }
      })
    },
    getTemplate: async function () {
      try {
        this.isDownloading = true
        const result = await NewCrates.getTemplate()
        saveAs(result.data, 'new_crates_templ.csv')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
      this.isDownloading = false
    }
  }
}
</script>
<style scoped lang="scss">
  .timeline {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .btn {
    margin: 10px;
  }

.timelines {
  width: 100%;
}
</style>
