import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserClosure'

const enduserClosure = axios.create({
  baseURL
})

interceptorsSetup(enduserClosure)

export default class EnduserClosure {
  static async getAll () {
    const result = await enduserClosure.get('/')
    return result.data
  }

  static async get (enduserCode) {
    const result = await enduserClosure.get('/' + enduserCode)
    return result.data
  }

  static async insert (data) {
    const result = await enduserClosure.post('/', data)
    return result.data
  }

  static async delete (id) {
    const result = await enduserClosure.delete(`/${id}`)
    return result.data
  }
}
