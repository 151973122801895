import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/reportTranslation'

const reportTranslation = axios.create({
  baseURL
})

interceptorsSetup(reportTranslation)

export default class ReportTranslation {
  static async getAll () {
    const { data: result } = await reportTranslation.get(`/`)
    return result
  }

  static async insert (data) {
    const result = await reportTranslation.post(`/`, data)
    return result.data
  }

  static async delete (data) {
    const result = await reportTranslation.delete(`/${data.country}/${data.sheetNbr}/${data.index}`)
    return result.data
  }
}
