<template>
  <section class="section">
    <b-loading :active.sync="loading" is-full-page />
    <div class="container">
      <h1 class="title">
        Roles
      </h1>
      <div class="columns">
        <div class="column">
          <a class="button is-link is-outlined" @click="addRole()"><b-icon icon="plus" /> <span>Add </span></a>
        </div>
      </div>
      <b-table
        :loading="loading"
        :narrowed="true"
        :striped="true"
        :paginated="true"
        :data="roles"
      >
        <template slot="header" slot-scope="props">
          <b-tooltip
            v-if="!!props.column.meta"
            :active="!!props.column.meta"
            :label="props.column.meta"
            dashed
          >
            <template>{{ props.column.label }}</template>
          </b-tooltip>
          <span v-else>{{ props.column.label }}</span>
        </template>
        <b-table-column v-slot="props" field="name" label="Name" sortable>
          <template>{{ props.row.name }}</template>
        </b-table-column>
        <b-table-column v-slot="props" label="Actions">
          <span class="field is-grouped">
            <p class="control">
              <a class="button is-small is-info is-outlined" @click="showEditForm(props.row)">
                <template>Edit</template>
              </a>
            </p>
            <p class="control">
              <a class="button is-small is-danger is-outlined" @click="deleteRole(props.row.id)">
                <template>Delete</template>
              </a>
            </p>
          </span>
        </b-table-column>
        <template slot="empty">
          <nothing-here />
        </template>
      </b-table>
    </div>
    <b-modal
      :active.sync="isEditing"
      scroll="keep"
      has-modal-card
      @submitCreate="submitUpdateRole"
    >
      <RoleForm
        :role="roleToEdit"
        :rights="rights"
        :is-submitting="isSubmitting"
        @submitUpdate="submitUpdateRole"
        @submitCreate="submitCreateRole"
      />
    </b-modal>
  </section>
</template>

<script>
import Role from '@/services/v2/role'
import Right from '@/services/v2/right'
import RoleForm from './role-form'

export default {
  components: {
    RoleForm
  },
  data () {
    return {
      loading: false,
      roles: [],
      rights: [],
      isSubmitting: false,
      isEditing: false,
      roleToEdit: null
    }
  },
  computed: {},
  mounted: async function () {
    this.loading = true
    try {
      this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
    this.loading = false
  },
  methods: {
    loadData: async function () {
      try {
        this.roles = await Role.getRoles()
        this.rights = await Right.getRights()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    showEditForm: function (role) {
      this.roleToEdit = role
      this.isEditing = true
    },
    submitUpdateRole: async function (data) {
      try {
        this.isSubmitting = true
        await Role.updateRoleRights(this.roleToEdit.id, data.rights, data.role)
        this.loadData()
        this.isEditing = false
        this.$buefy.toast.open({
          message: 'Role updated',
          type: 'is-primary'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    submitCreateRole: async function (role) {
      try {
        this.isSubmitting = true
        await Role.createRole(role)
        this.loadData()
        this.isEditing = false
        this.$buefy.toast.open({
          message: 'Role created',
          type: 'is-primary'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    addRole () {
      this.roleToEdit = null
      this.isEditing = true
    },
    async deleteRole (roleId) {
      try {
        this.$buefy.dialog.confirm({
          title: 'Delete role',
          message: 'Are you sure you want to <b>delete</b> this role? This action cannot be undone.',
          confirmText: 'Delete',
          cancelText: 'Cancel',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              await Role.deleteRole(roleId)
              await this.loadData()
              this.$buefy.toast.open({
                message: 'Role deleted',
                type: 'is-primary'
              })
            } catch (error) {
              this.$buefy.toast.open({
                message: error.message || error,
                type: 'is-danger'
              })
            }
          }
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style>
</style>
