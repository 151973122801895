import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'
import store from '@/store/index'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes,
  linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
  const user = store.state.auth.user
  const { authorize } = to.meta
  // Check if auth route
  if (authorize) {
    if (!store.getters.isAuthenticated) {
      next({ path: '/login', query: { redirect: to.fullPath } })
    }

    const rights = user?.role?.rights?.map(e => e.name)
    if (authorize.length && !authorize.some(r => rights.includes(r))) {
      next({ path: '/' })
    }
  }
  next()
})

router.afterEach((to, from) => {
  const title = `${to.meta.title} | Global Packaging Services`
  window.document.documentElement.scrollTop = 0
  window.document.title = title
})

export default router
