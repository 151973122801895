import axios from 'axios'
import dayjs from 'dayjs'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/customers'

const customers = axios.create({
  baseURL
})

interceptorsSetup(customers)

export async function getAll () {
  const result = await customers.get('/')
  return result.data
}

export async function getAllEdi () {
  const result = await customers.get('/edi')
  return result.data
}

export async function getEndusersByCustomer (username) {
  const result = await customers.get(`/endusers`)
  return result.data
}

export async function getCollections (from, to, enduser, country) {
  const result = await customers
    .get(`/collections`, {
      params: {
        from: dayjs(from).isValid() ? dayjs(from).format('YYYY-MM-DD') : null,
        to: dayjs(to).isValid()
          ? dayjs(to).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        enduser: enduser,
        country: country
      }
    })
  return result.data
}
export async function getCollectionDetail (from, to, enduserId, skip, limit) {
  const result = await customers
    .get(`/endusers/${enduserId}/collections`, {
      params: {
        from: from,
        to: to,
        skip: skip,
        limit: limit
      }
    })
  return result.data
}

export async function getCollectionDetailCount (from, to, enduserId) {
  const result = await customers
    .get(`/endusers/${enduserId}/collections/count`, {
      params: {
        from: from,
        to: to
      }
    })
  return result.data
}

export async function getDeliveriesByCustomer (id) {
  const result = await customers.get(`/${id}/deliveries`)
  return result.data
}

export async function getCategories () {
  const result = await customers.get(`/categories`)
  return result.data
}

export async function getFiles () {
  const { data: file } = await customers(`/files`)
  return file
}
// SHIPPING
export async function getShippings (fromDate, toDate) {
  const result = await customers.get(`/shippings`, {
    params: {
      fromDate: dayjs(fromDate).isValid() ? dayjs(fromDate).format('YYYY-MM-DD') : null,
      toDate: dayjs(toDate).isValid() ? dayjs(toDate).format('YYYY-MM-DD') : null
    } })
  return result.data
}
// STOCKS

export async function getStockByCustomer (min, max) {
  const result = await customers.get(`/stock`)
  return result.data
}

export async function getStockByEnduser (min, max) {
  const result = await customers.get(`/endusers/stock`)
  return result.data
}

export async function getStockCountCustomer (enduserId) {
  const result = await customers.get(`/stock-count`)
  return result.data.total
}

export async function getStockCountEnduser (enduserId) {
  const result = await customers.get(`/endusers/${enduserId}/stock-count`)
  return result.data.total
}

export async function getStockDetail (skip, limit, enduserId) {
  const result = await customers
    .get(enduserId ? `/endusers/${enduserId}/stock/` : `/stock/self`, {
      params: {
        skip: skip,
        limit: limit
      }
    })
  return result.data
}

// DOWNLOAD ROUTES
export async function downloadShippings (fromDate, toDate) {
  const result = await customers.get(`/shippings`, {
    responseType: 'blob',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    params: {
      fromDate: dayjs(fromDate).isValid() ? dayjs(fromDate).format('YYYY-MM-DD') : null,
      toDate: dayjs(toDate).isValid() ? dayjs(toDate).format('YYYY-MM-DD') : null
    }
  })
  return result.data
}

export async function downloadStock (offset, limit) {
  const result = await customers.get(`/endusers/stock`, {
    responseType: 'blob',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
  return result.data
}

export async function downloadStockEnduser (offset, limit, enduserId) {
  const result = await customers
    .get(enduserId ? `/endusers/${enduserId}/stock/` : `/stock/self`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      params: {
        offset: offset,
        limit: limit
      }
    })
  return result.data
}

export async function downloadCollections (from, to, enduser, country) {
  const result = await customers
    .get(`/collections`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      params: {
        from: dayjs(from).isValid() ? dayjs(from).format('YYYY-MM-DD') : null,
        to: dayjs(to).isValid()
          ? dayjs(to).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        country: country
      }
    })
  return result.data
}

export async function loadCustomerFileSettings (customerId) {
  const result = await customers.get(`${customerId}/file-settings`)
  return result.data
}

export async function editCustomerFileSettings (customerId, editing) {
  if (editing.id !== null) {
    const result = await customers.put(`${customerId}/file-settings`, editing)
    return result.data
  } else {
    const result = await customers.post(`${customerId}/file-settings`, editing)
    return result.data
  }
}

export async function deleteCustomerFileSettings (customerId, fipId) {
  const result = await customers.delete(`${customerId}/file-settings/${fipId}`)
  return result.data
}

/**
 * not Used
 */

export async function getExportRecap (customerId, fipId) {
  const result = await customers.get(`${customerId}/export-recap/${fipId}`)
  return result.data
}

export async function getExportRecapAll () {
  const result = await customers.get(`/export-recap`)
  return result.data
}

export async function getExportDetails (table, id) {
  const result = await customers.get(`export-details/${table}/${id}`)
  return result.data
}

export async function deleteExportDetails (id, table) {
  const result = await customers.delete(`export-details/${table}/${id}`)
  return result.data
}

/**
 * to rm
 */

export async function editShippingInstruction (data) {
  const result = await customers.put(`/shipping-instructions`, data)
  return result.data
}

export async function createShippingInstructionsVersion (id) {
  const result = await customers.post(`/shipping-instructions/${id}/version`)
  return result.data
}

// export async function createShippingInstructionsOrder (id) {
//   const result = await customers.post(`/shipping-instructions/${id}/order`)
//   return result.data
// }

/** */

export async function submitEdiChange (detailId, table, id, column, value) {
  const result = await customers.put(`/export-details/${table}/${detailId}/${id}`, { column, value })
  return result.data
}

export async function checkEdiLines (table, lines, id) {
  const result = await customers.post(`/export-details/${table}/${id}/check`, { lines })
  return result.data
}

export async function integrateEdiLines (table, lines, id) {
  const result = await customers.post(`/export-details/${table}/${id}/integrate`, { lines })
  return result.data
}

export async function deleteEdiLines (table, lines, id) {
  const result = await customers.post(`/export-details/${table}/${id}/delete`, { lines })
  return result.data
}

export async function duplicateCustomerFileSettings (fromCustomer, fromId, toCustomer) {
  const result = await customers.post(`/${fromCustomer}/file-settings/${fromId}/duplicate/${toCustomer}`)
  return result.data
}
