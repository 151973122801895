
export const columns = [
  { headerName: 'Status',
    filter: 'agTextColumnFilter',
    filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
    field: 'status',
    width: 150,
    cellRenderer: (params) => {
      if (params.value === 'V') {
        if (params.data.billOfLading) {
          return 'Done'
        } else return 'Created'
      } else if (params.value === 'C') {
        return 'Canceled'
      } else if (params.value === 'U') {
        if (params.data.version > 0) {
          return 'Updated'
        } else return 'New'
      } else return null
    },

    cellClassRules: {
      'rag-green': function (params) { return params.value === 'V' && params.data.billOfLading },
      'rag-yellow': function (params) { return params.value === 'U' && params.data.version > 0 },
      'rag-orange': function (params) { return params.value === 'U' && params.data.version === 0 },
      'rag-blue': function (params) { return params.value === 'V' && !params.data.billOfLading },
      'rag-red': function (params) { return params.value === 'C' }
    }
  },
  { headerName: 'SI number',
    filter: 'agTextColumnFilter',
    field: 'ref',
    width: 150
  },
  { headerName: 'SI version',
    filter: 'agNumberColumnFilter',
    field: 'version',
    width: 150
  },
  // { headerName: 'Id',
  //   filter: 'agTextColumnFilter',
  //   field: 'id',
  //   width: 150
  // },
  { headerName: 'Customer code',
    filter: 'agTextColumnFilter',
    field: 'customerId',
    width: 150
  },
  { headerName: 'Customer name',
    filter: 'agTextColumnFilter',
    field: 'customerName',
    width: 190
  },
  { headerName: 'Load port',
    filter: 'agTextColumnFilter',
    field: 'loadingPortId',
    width: 150
  },
  { headerName: 'Processor code',
    filter: 'agTextColumnFilter',
    field: 'processorId',
    width: 150
  },
  { headerName: 'Processor name',
    filter: 'agTextColumnFilter',
    field: 'processorName',
    width: 150
  },
  { headerName: 'Processor consignee',
    filter: 'agTextColumnFilter',
    field: 'processorConsignee',
    width: 150
  },
  { headerName: 'Material code',
    filter: 'agTextColumnFilter',
    field: 'material',
    width: 150
  },
  { headerName: 'SI date',
    filter: 'agDateColumnFilter',
    field: 'createdAt',
    width: 150,
    sort: 'desc',
    cellRenderer: (data) => {
      return data.value && new Date(data.value).toLocaleDateString()
    }
  },
  { headerName: 'Lead days',
    filter: 'agNumberColumnFilter',
    field: 'leadDays',
    width: 150
  },
  { headerName: 'Etd date',
    filter: 'agDateColumnFilter',
    field: 'estimatedTimeOfDeparture',
    width: 150,
    cellRenderer: (data) => {
      return data.value && new Date(data.value).toLocaleDateString()
    }
  },
  { headerName: 'Quantity',
    filter: 'agNumberColumnFilter',
    field: 'quantity',
    width: 150
  },
  { headerName: 'Departure plant',
    filter: 'agTextColumnFilter',
    field: 'departurePlantId',
    width: 150
  },
  { headerName: 'Arrival port',
    filter: 'agTextColumnFilter',
    field: 'arrivalPortId',
    width: 150
  },
  { headerName: 'Enduser code',
    filter: 'agTextColumnFilter',
    field: 'enduserId',
    width: 150
  },
  { headerName: 'Enduser name',
    filter: 'agTextColumnFilter',
    field: 'enduserName',
    width: 150
  },
  { headerName: 'Enduser consignee',
    filter: 'agTextColumnFilter',
    field: 'enduserConsignee',
    width: 150
  },
  { headerName: 'Bill of lading',
    filter: 'agNumberColumnFilter',
    field: 'billOfLading',
    width: 150
  },
  { headerName: 'Vessel',
    filter: 'agTextColumnFilter',
    field: 'vessel',
    width: 150
  },
  { headerName: 'Ctnr number',
    filter: 'agTextColumnFilter',
    field: 'ctnrNumber',
    width: 150
  },
  { headerName: 'Actual delivery date',
    filter: 'agDateColumnFilter',
    field: 'deliveredAt',
    width: 150,
    cellRenderer: (data) => {
      return data.value && new Date(data.value).toLocaleDateString()
    }
  },
  { headerName: 'Delivered quantity',
    filter: 'agNumberColumnFilter',
    field: 'deliveredQuantity',
    width: 150
  },
  { headerName: 'Purchase order number',
    filter: 'agTextColumnFilter',
    field: 'purchaseOrderNumber',
    width: 150
  },
  { headerName: 'Planned delivery date',
    filter: 'agDateColumnFilter',
    field: 'expectedDeliveryDate',
    width: 150,
    cellRenderer: (data) => {
      return data.value && new Date(data.value).toLocaleDateString()
    }
  },
  {
    headerName: 'EDI import',
    filter: 'agTextColumnFilter',
    field: 'ediImport',
    width: 150
  },
  {
    headerName: 'Delivery date',
    filter: 'agDateColumnFilter',
    field: 'deliveryDate',
    width: 150
  }
]
