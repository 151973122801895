import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/map'

const report = axios.create({
  baseURL
})

interceptorsSetup(report)

export default class Map {
  static async updateCoords (data) {
    const { data: result } = await report.post(`/updateCoords`, data)
    return result
  }
}
