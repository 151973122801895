<template>
  <div
    class="modal-card"
  >
    <div class="modal-card-head centered-items">
      {{ $t('pages.pickup_management.closures.title') }}
    </div>
    <div
      class="modal-card-body"
    >
      <div v-if="creatingClosure" id="calendar-step-cd-screen" class="closureForm">
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
          tag="form"
          @submit.prevent="submit"
        >
          <div id="form">
            <h2 class="subtitle">
              {{ $t('pages.pickup_management.closures.add_closing_date') }} :
            </h2>
            <b-field :label="$t('global.enduser')">
              <multiselect
                v-model="selectedEnduser"
                :options="endusers"
                :multiple="false"
                :close-on-select="true"
                :custom-label="n => {return n.enduserCode + ' - ' + n.enduserName}"
                track-by="name"
              />
            </b-field>
            <b-field :label="$t('pages.pickup_management.closures.start_date')">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="start date"
              >
                <b-datepicker
                  v-model="newClosure.startDate"
                  name="start date"
                  icon="calendar-alt"
                  :placeholder="$t('global.click_to_select')"
                  :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                  editable
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.closures.end_date')">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="end date"
              >
                <b-datepicker
                  v-model="newClosure.endDate"
                  name="end date"
                  icon="calendar-alt"
                  placeholder="Click to select..."
                  :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                  editable
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field :label="$t('pages.pickup_management.closures.comments')">
              <ValidationProvider>
                <textarea v-model="newClosure.comment" class="input" />
              </ValidationProvider>
            </b-field>
            <div class="centered-items buttons">
              <b-button
                class="button "
                @click="() => creatingClosure = false"
              >
                {{ $t('global.cancel') }}
              </b-button>
              <button
                class="is-primary button"
                type="submit"
              >
                {{ $t('global.submit') }}
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div
        v-if="computedGridData"
      >
        <div class="buttons">
          <b-button icon-left="arrow-left" class="is-warning is-small" style="margin-bottom: 30px" @click="close">
            {{ $t('global.back') }}
          </b-button>
          <b-button icon-left="plus" class="is-info is-small" style="margin-bottom: 30px" @click="() => {creatingClosure = true}">
            {{ $t('global.add') }}
          </b-button>
          <b-button
            class="is-small"
            :class="hidingPastDates ? 'is-primary' : 'is-danger'"
            icon-left="low-vision"
            style="margin-bottom: 30px"
            @click="hidePastDates"
          >
            {{ hidingPastDates ? $t('pages.pickup_management.closures.display_past_dates') : $t('pages.pickup_management.closures.hide_past_dates') }}
          </b-button>
        </div>
        <b-loading :active.sync="loading" is-full-page />
        <ag-grid-vue
          style="width: 100%; height: 1000px;"
          class="ag-theme-balham"
          :row-data="computedGridData"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="multiple"
          :group-hide-open-parents="true"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { AllModules } from '@ag-grid-enterprise/all-modules'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
// import Closure from '@/services/v2/enduserClosure'
import { mapActions } from 'vuex'
import Closure from '@/services/v2/enduserClosure'

export default {
  components: {
  },
  mixins: [sortAndFilterMixins],
  props: {
    endusersData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      modules: AllModules,
      closuresData: null,
      updated: false,
      creatingClosure: false,
      newClosure: {},
      selectedEnduser: null,
      endusers: [],
      hidingPastDates: true,

      gridOptions: {
        masterDetail: true,
        suppressAggFuncInHeader: true,
        getContextMenuItems: this.gridActions,

        autoGroupColumnDef: {
          pinned: 'left' // force pinned left. Does not work in columnDef
        },
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [
          {
            headerName: this.$t('global.enduser'),
            width: 300,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            rowGroup: true,
            aggFunc: this.defaultStringAgg,
            valueGetter: (params) => {
              return params && params.data ? params.data.enduserCode + ' - ' + params.data.enduserName : ''
            },
            cellClass: 'rag-pale-background'
          },
          {
            headerName: this.$t('global.country'),
            width: 130,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            aggFunc: this.defaultStringAgg,
            valueGetter: (params) => {
              return params && params.data ? params.data.country : ''
            },
            cellClass: 'rag-pale-background'
          },
          {
            headerName: this.$t('pages.pickup_management.closures.start_date'),
            width: 130,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            filter: 'agDateColumnFilter',
            comparator: this.dateSort,
            aggFunc: this.lastDateAgg,

            valueGetter: (params) => {
              return params && params.data && params.data.startDate ? this.$dayjs(params.data.startDate).local().format('DD/MM/YYYY') : ''
            },
            cellClass: 'rag-pale-background'
          },
          {
            headerName: this.$t('pages.pickup_management.closures.end_date'),
            width: 130,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            filter: 'agDateColumnFilter',
            comparator: this.dateSort,
            aggFunc: this.lastDateAgg,

            valueGetter: (params) => {
              return params && params.data && params.data.endDate ? this.$dayjs(params.data.endDate).local().format('DD/MM/YYYY') : ''
            },
            cellClass: 'rag-pale-background'
          },
          {
            headerName: this.$t('pages.pickup_management.closures.comments'),
            width: 500,
            filterParams: { suppressAndOrCondition: true, newRowsAction: 'keep' },
            filter: 'agTextColumnFilter',
            aggFunc: this.defaultStringAgg,

            valueGetter: (params) => {
              return params && params.data ? params.data.comment : ''
            },
            tooltipValueGetter: params => {
              return params.value
            },
            cellClass: 'rag-pale-background'
          }
        ],
        detailRowHeight: 350,
        groupDefaultExpanded: 1,
        enableBrowserTooltips: true

      }
    }
  },
  computed: {
    computedGridData: function () {
      let data = []
      let alreadyAdded = []
      try {
        if (this.closuresData) {
          this.closuresData.forEach(e => {
            if (e.closures && e.closures.length > 0 && !alreadyAdded.includes(e.enduserCode)) {
              e.closures.forEach(c => {
                let obj = c
                c.enduserCode = e.enduserCode
                c.enduserName = e.enduserName
                c.country = e.country
                if (this.hidingPastDates) {
                  if (this.$dayjs(c.endDate).isAfter(this.$dayjs())) {
                    data.push(obj)
                  }
                } else {
                  data.push(obj)
                }
              })
              alreadyAdded.push(e.enduserCode)
            }
          })
        }
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message, duration: 3000 })
      }
      return data
    }
  },
  watch: {
  },
  mounted: async function () {
    try {
      this.loadData()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  methods: {
    ...mapActions(['archiveAction']),
    close () {
      this.$emit('close', this.updated)
    },
    loadData: function () {
      this.closuresData = this.endusersData
      this.endusersData.forEach(ed => {
        const exist = this.endusers.find(e => e.enduserCode === ed.enduserCode)
        if (!exist) {
          this.endusers.push(ed)
        }
      })
    },
    async submit () {
      try {
        if (await this.$refs.observer.validate() && this.selectedEnduser) {
          if (this.$dayjs(this.newClosure.startDate).isAfter(this.$dayjs(this.newClosure.endDate))) {
            throw Error('The start date is greater than the end date')
          }
          if (this.$dayjs(this.newClosure.startDate).isBefore(this.$dayjs())) {
            throw Error('You cannot create dates in the past')
          }
          const id = await Closure.insert({
            enduserCode: this.selectedEnduser.enduserCode,
            startDate: this.newClosure.startDate,
            endDate: this.newClosure.endDate,
            comment: this.newClosure.comment
          })
          this.newClosure.id = id[0]
          this.$buefy.toast.open({ message: 'Closure dates added', type: 'is-primary' })
          if (!this.selectedEnduser.closures) {
            this.selectedEnduser.closures = []
          }
          this.selectedEnduser.closures.push(this.newClosure)
          this.$forceUpdate()
          this.gridOptions.api.refreshCells()
          this.archiveAction(`[Pickup management] Added a closure date on ${this.selectedEnduser.enduserCode} (${this.newClosure.startDate} -> ${this.newClosure.endDate})`)
        } else {
          throw Error('Form invalid')
        }
        this.updated = true
        this.newClosure = {}
        this.selectedEnduser = null
        this.creatingClosure = null
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    async deleteClosure (data) {
      this.$buefy.dialog.confirm({
        title: 'Deleting closure dates',
        message: 'Are you sure you want to <b>delete</b> this closure? This action cannot be undone.',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.updated = true
            await Closure.delete(data.id)
            this.$buefy.toast.open({ message: 'Closure deleted', type: 'is-primary' })
            this.closuresData.forEach(cd => {
              if (cd.closures) {
                cd.closures = cd.closures.filter(c => {
                  return c !== data
                })
              }
            })

            this.$forceUpdate()
            this.gridOptions.api.refreshCells()
            this.archiveAction(`[Pickup management] Deletted a closure date on ${data.enduserCode})`)
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    },
    hidePastDates: function () {
      this.hidingPastDates = !this.hidingPastDates
    },
    gridActions (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (params.node.data) {
        customMenu.push({
          name: 'Delete closure',
          action: () => {
            this.deleteClosure(params.node.data)
          },
          icon: '<i class="fas fa-fw fa-times" ></i>'
        })
      }

      const result = [...customMenu, ...genericMenu]
      return result
    }
  }
}
</script>

<style scoped>
.closureForm {
  margin-bottom: 2rem;
}
</style>
