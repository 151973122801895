import { extend, localize } from 'vee-validate'
import { min, required, email, digits, max_value as maxValue } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

// Install rules
extend('required', {
  ...required,
  message: 'This field is required'
})
extend('min', min)
extend('email', email)
extend('digits', digits)
extend('max_value', maxValue)

// Install messages
localize({
  en
})
