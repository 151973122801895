<template>
  <div class="modal-card" :style="{minHeight: 60 + 'vh'}">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Distributior email configuration
      </p>
      <button
        type="button"
        class="delete"
        @click="$emit('on-close')"
      />
    </header>
    <section class="modal-card-body">
      <div class="mb-5">
        <b-tag type="is-info" size="is-medium" class="mb-2" rounded>
          {{ distributor.id }} - {{ distributor.name }}
        </b-tag>
        <b-tag type="is-info" size="is-medium" rounded>
          {{ distributor.address || '' }} {{ distributor.city }}, {{ distributor.country }}
        </b-tag>
      </div>
      <b-loading v-model="isLoading" :is-full-page="false" />
      <div class="box">
        <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="submit">
          <b-field label="Start date" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-datepicker
                v-model="selectedDate"
                placeholder="Click to select..."
                icon="calendar-days"
                trap-focus
                rounded
              />
            </ValidationProvider>
          </b-field>
          <b-field label="Language" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-select v-model="selectedLang" placeholder="select a language" rounded>
                <option
                  v-for="lang in languages"
                  :key="lang"
                  :value="lang"
                >
                  {{ lang }}
                </option>
              </b-select>
            </ValidationProvider>
          </b-field>
          <b-field label="Template" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-select v-model="selectedTemplate" placeholder="Click to select..." expanded rounded>
                <option v-for="template in templates" :key="template.id" :value="template.id">
                  {{ template.name }}
                </option>
              </b-select>
            </ValidationProvider>
          </b-field>
          <b-field label="Frequency (days)" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-input v-model="inputFrequency" placeholder="30" rounded />
            </ValidationProvider>
          </b-field>
          <b-field label="Send to" message="use , to seperate multiple emails" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-input v-model="inputEmail" placeholder="john@client.com" rounded />
            </ValidationProvider>
          </b-field>
          <b-field label="Send from" horizontal>
            <ValidationProvider v-slot="{ errors }" :rules="{required: true}">
              <b-input v-model="sendFrom" rounded />
            </ValidationProvider>
          </b-field>
          <b-field v-if="showStatus" label="Status" horizontal>
            <b-select v-model="inputStatus" rounded>
              <option v-for="option in ['Active', 'Pending', 'Inactive']" :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <div class="mt level-right">
            <b-button
              type="submit"
              class="button is-primary"
              :disabled="!valid"
              :loading="isLoading"
              @click.prevent="submit"
            >
              Save
            </b-button>
          </div>
        </validationobserver>
      </div>
    </section>
  </div>
</template>

<script>
import Distribution from '../../services/v2/distribution'

export default {
  props: {
    distributor: {
      default: () => {},
      type: Object
    },
    curConfig: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      templates: [],
      selectedTemplate: null,
      inputFrequency: null,
      inputEmail: this.distributor.email,
      selectedDate: null,
      sendFrom: this.$store.state.auth.user.email,
      languages: [],
      inputStatus: '',
      selectedLang: null,
      isLoading: false,
      showStatus: false
    }
  },
  watch: {
    async selectedLang () {
      this.isLoading = true
      this.templates = await Distribution.getTemplates({ lang: this.selectedLang })
      this.isLoading = false
    }
  },
  async mounted () {
    if (this.curConfig) {
      this.showStatus = true
      this.selectedDate = new Date(this.curConfig.startDate)
      this.selectedLang = this.curConfig.lang
      this.selectedTemplate = this.curConfig.templateId
      this.inputFrequency = this.curConfig.frequency
      this.inputEmail = this.curConfig.sendTo
      this.sendFrom = this.curConfig.sendFrom
      this.inputStatus = this.curConfig.status
    }
    await this.loadData()
  },
  methods: {
    // unselectableDates (day) {
    //   return day.getTime() < new Date().getTime() + 24 * 60 * 60 * 1000
    // },
    async loadData () {
      this.languages = this.$i18n.availableLocales
    },

    async submit () {
      this.isLoading = true
      try {
        await Distribution.postSetting({
          configId: this.curConfig ? this.curConfig.configId : null,
          distributorCode: this.distributor.id,
          lang: this.selectedLang,
          templateId: this.selectedTemplate,
          frequency: this.inputFrequency,
          sendTo: this.inputEmail,
          sendFrom: this.sendFrom,
          startDate: this.selectedDate,
          status: this.inputStatus
        })
        this.$buefy.toast.open({
          message: 'Successful',
          type: 'is-primary' })
        this.$emit('on-close')
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger' })
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
.mt {
  margin-top: 5rem;
}
</style>
