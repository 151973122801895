import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/rights'

const rights = axios.create({
  baseURL
})

interceptorsSetup(rights)

export default class Right {
  static async getRights () {
    const { data: result } = await rights.get(`/`)
    return result
  }
}
