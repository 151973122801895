<template>
  <section class="section">
    <b-loading :active.sync="isLoading" is-full-page />
    <div class="container is-fluid">
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <h1 class="title">
            Groups
          </h1>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-9">
          <div class="field">
            <div class="control">
              <button class="button" @click="isCreating = true">
                <b-icon icon="plus" size="is-small" />
                <span> Add group</span>
              </button>
            </div>
          </div>
        </div>

        <ag-grid-vue
          style="width: 100%; height: 1000px;"
          class="ag-theme-alpine"
          :row-data="groups"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-page-size="20"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="single"
          @grid-ready="gridReady"
        />
      </div>
    </div>

    <b-modal :active.sync="isCreating" scroll="keep" has-modal-card>
      <GroupForm
        :endusers="endusers"
        :customers="customers"
        :is-submitting="isSubmitting"
        @submit="submitCreateGroup"
      />
    </b-modal>
    <b-modal :active.sync="isEditing" scroll="keep" has-modal-card>
      <GroupForm
        :endusers="endusers"
        :customers="customers"
        :editable-group="editableGroup"
        :is-submitting="isSubmitting"
        @submit="submitUpdateGroup"
      />
    </b-modal>
  </section>
</template>

<script>
import GroupForm from './group-form'
import * as Group from '@/services/v1/group'
import Customer from '@/services/v2/customer'
import Enduser from '@/services/v2/enduser'
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  components: {
    GroupForm
  },
  data () {
    return {
      modules: AllModules,
      isCreating: false,
      isEditing: false,
      editableGroup: {},
      isSubmitting: false,
      showModal: false,
      isLoading: true,
      fileSettingGroup: null,
      groups: [],
      endusers: [],
      customers: [],
      groupsFilters:
        this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED'
          ? ['END USER', 'CUSTOMER', 'HUB']
          : ['END USER'],

      gridOptions: {
        masterDetail: true,
        getContextMenuItems: this.gridActions,
        suppressAggFuncInHeader: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true
        },

        columnDefs: [
          {
            headerName: 'Id',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'id'
          },
          {
            headerName: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'name'
          },
          {
            headerName: 'Type',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'type',
            cellClass: params => {
              if (params.value) {
                switch (params.value) {
                  case 'HUB':
                    return 'rag-green'
                  case 'CUSTOMER':
                    return 'rag-info'
                  case 'END USER':
                    return 'rag-purple'
                  default:
                    return ''
                }
              } else {
                return null
              }
            }
          },
          {
            headerName: 'Customers',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'customers',
            cellRenderer: params => {
              return params.data.customers.reduce((acc, curr) => {
                return (
                  (acc ? acc + ' <br/> ' : '') + (curr.name ? curr.name : '')
                )
              }, '')
            },
            autoHeight: true
          },
          {
            headerName: 'Plants',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'plants',
            cellRenderer: params => {
              return params.data.plants.reduce((acc, curr) => {
                return (
                  (acc ? acc + ' <br/> ' : '') + (curr.name ? curr.name : '')
                )
              }, '')
            },
            autoHeight: true
          },
          {
            headerName: 'End users',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'endusers',
            cellRenderer: params => {
              return params.data.endusers.reduce((acc, curr) => {
                return (
                  (acc ? acc + ' <br/> ' : '') + (curr.name ? curr.name : '')
                )
              }, '')
            },
            autoHeight: true
          }
        ]
      }
    }
  },
  mounted: async function () {
    await this.getAll()
    this.isLoading = false
  },
  methods: {
    gridReady: function (gridApi) {
      try {
        gridApi.api.sizeColumnsToFit()
        this.gridApi = gridApi
      } catch (e) {}
    },
    gridActions: function (params) {
      let customMenu = []
      const genericMenu = ['copy', 'copyWithHeaders', 'separator', 'export']
      if (params.node && params.node.data) {
        if (params.node.data.provider !== 'aad') {
          customMenu.push({
            name: 'Update',
            action: () => {
              this.showEditForm(params.node.data)
            },
            icon: '<i class="fas fa-fw fa-edit" ></i>'
          })
          customMenu.push({
            name: 'Delete',
            action: () => {
              this.deleteGroup(params.node.data)
            },
            icon: '<i class="fas fa-fw fa-trash" ></i>'
          })
        }
      }
      const result = [...customMenu, ...genericMenu]
      return result
    },
    getAll: async function () {
      try {
        this.customers = await Customer.getAll({})
        this.endusers = await Enduser.getAll({})
        const builder = this.$gql.query({
          operation: 'groups',
          fields: [
            'totalCount',
            {
              edges: [
                {
                  node: [
                    'id',
                    'name',
                    'type',
                    { powerBis: ['id', 'roles'] },
                    { customers: ['id', 'name'] },
                    { plants: ['id', 'name'] },
                    { endusers: ['id', 'name'] }
                  ]
                }
              ]
            }
          ],
          variables: {
            filter: {
              type: 'filterInputGroup',
              value: { type: { in: this.groupsFilters } }
            }
          }
        })
        const result = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`
            ${builder.query}
          `,
          variables: builder.variables
        })

        this.groups = result.data.groups.edges.map(e => e.node)
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    submitUpdateGroup: async function (group) {
      try {
        this.isSubmitting = true
        await Group.updateGroup(group)
        await this.getAll()
        this.isEditing = false
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    submitCreateGroup: async function (group) {
      this.isSubmitting = true
      try {
        await Group.createGroup(group)
        await this.getAll()
        this.isCreating = false
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    deleteGroup: async function (group) {
      this.$buefy.dialog.confirm({
        title: 'Deleting group',
        message:
          'Are you sure you want to <b>delete</b> this group? This action cannot be undone.',
        confirmText: 'Delete Group',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Group.deleteGroup(group.id)
            await this.getAll()
          } catch (error) {
            this.$buefy.toast.open({
              message: error.message || error,
              type: 'is-danger'
            })
          }
        }
      })
    },
    showEditForm (group) {
      this.isEditing = true
      this.editableGroup = group
    }
  }
}
</script>

<style></style>
