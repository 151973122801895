import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import { setupCache } from 'axios-cache-interceptor'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/collect'

const collect = axios.create({
  baseURL
})

interceptorsSetup(collect)

const collectCache = setupCache(collect)

export default class Collect {
  static async getGroups () {
    const { data: result } = await collectCache.get(`/groups`)
    return result
  }

  static async getEndusers (group) {
    const { data: result } = await collectCache.get(`/${group}/endusers`)
    return result
  }

  static async getAll (filters) {
    const { data: result } = await collectCache.get(`/all/`, { params: filters })
    return result
  }

  static async getAssociatedCollects (collectId, enduserCode) {
    const { data: result } = await collectCache.get(`/associated/`, { params: { collectId: collectId, enduserCode: enduserCode } })
    return result
  }

  static async insertCollect (data) {
    const { data: result } = await collect.post(`/`, data)
    return result
  }

  static async deleteCollect (collectId) {
    const { data: result } = await collect.delete(`/${collectId}`)
    return result
  }
}
