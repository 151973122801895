<template>
  <div>
    <p v-if="data.type === 'SAP'" class="number color2">
      {{ data.quantity }}
    </p>
    <div v-if="data.type === 'filled'">
      <b-dropdown v-if="!isEditing">
        <template #trigger="{}">
          <p class="number">
            {{ data.quantity }}
          </p>
        </template>
        <b-dropdown-item @click="edition">
          {{ $t('global.update') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="data.type === 'error'" style="">
      <b-dropdown v-if="!isEditing">
        <template #trigger="{}">
          <b-icon
            icon="exclamation-circle"
            type="is-warning"
            size="is-medium"
          />
        </template>
        <b-dropdown-item @click="edition">
          {{ $t('pages.new_crates.fill') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-input
      v-if="isEditing"
      ref="nb"
      v-model="data.quantity"
      placeholder="Number"
      size="is-small"
      type="number"
      @input="onInput"
      @keydown.enter.native="$event.target.blur()"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      isEditing: false
    }
  },
  mounted () {
  },
  methods: {
    edition: function () {
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.nb.focus()
      })
    },
    onInput: function (i) {
      if (i <= 0) {
        this.data.quantity = 0
      }
      this.data.quantity = parseInt(i, 10)
    },
    onBlur: function () {
      if (isNaN(this.data.quantity)) {
        this.data.quantity = 0
      }
      this.isEditing = false
      this.data.type = 'filled'
      this.$emit('to-save')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../../../assets/sass/main.scss';

  .my-color {
    color: $primary;
  }

  .color2 {
    color: red;
  }

  .number {
    font-size: 16px;
  }

  .date {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 0;
    font-size: 10px;
  }

  .timeline {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
</style>
