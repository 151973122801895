var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container is-fluid"},[_c('Header',_vm._b({},'Header',_vm.$route.meta,false)),(_vm.currentPlant)?_c('h2',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.currentPlant.name)+" - "+_vm._s(_vm.$t('pages.plants.forecast.delay'))+": "+_vm._s(+_vm.currentPlant.shipmentDelay || 0)+" "+_vm._s(_vm.$t('global.days').toLowerCase())+" ")]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column card my-card"},[_c('b-tabs',{attrs:{"type":"is-toggle","position":"is-centered"}},[_c('b-tab-item',{attrs:{"label":_vm.$t('pages.plants.forecast.parameters')}},[_c('Parameters',{attrs:{"allow-sunday":_vm.forecastParams.allowSunday,"allow-saturday":_vm.forecastParams.allowSaturday,"range-dates":_vm.forecastParams.rangeDates},on:{"allowSunday":(event) => {
                _vm.forecastParams.allowSunday = event
                _vm.refreshForecast({})
              },"allowSaturday":(event) => {
                _vm.forecastParams.allowSaturday = event
                _vm.refreshForecast({})
              },"rangeDates":(event) => {
                _vm.forecastParams.rangeDates = event
                _vm.getAll()
              },"save":_vm.saveForecastParameters}})],1)],1)],1),_c('div',{staticClass:"column card my-card"},[_c('b-tabs',{attrs:{"type":"is-toggle","position":"is-centered"}},[_c('b-tab-item',{attrs:{"label":_vm.$t('pages.plants.forecast.insert_by_range')}},[_c('InsertByRange',{attrs:{"props-allow-sunday":_vm.forecastParams.allowSunday,"props-allow-saturday":_vm.forecastParams.allowSaturday},on:{"updateOperations":_vm.updateOperations}})],1),_c('b-tab-item',{attrs:{"label":_vm.$t('pages.plants.forecast.delete_by_range')}},[_c('DeleteByRange',{attrs:{"props-allow-sunday":_vm.forecastParams.allowSunday,"props-allow-saturday":_vm.forecastParams.allowSaturday},on:{"deleteOperations":_vm.deleteOperations}})],1)],1)],1)]),_c('b-field',[_c('b-button',{attrs:{"icon-left":"save"},on:{"click":_vm.saveForecast}},[_vm._v(" "+_vm._s(_vm.$t('pages.plants.forecast.save_forecast'))+" ")]),_c('b-button',{attrs:{"icon-left":"redo"},on:{"click":_vm.getAll}},[_vm._v(" "+_vm._s(_vm.$t('pages.plants.forecast.reset_forecast'))+" ")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('b-loading',{staticStyle:{"position":"absolute","right":"20px","top":"20px"},attrs:{"active":_vm.loading,"is-full-page":false,"can-cancel":false}}),_c('ag-grid-vue',{staticClass:"ag-theme-balham",staticStyle:{"width":"100%","height":"900px"},attrs:{"grid-options":_vm.gridOptions,"modules":_vm.modules,"row-data":_vm.productionForecast,"animated-rows":true},on:{"firstDataRendered":_vm.firstDataRendered}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }